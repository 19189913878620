import { FC } from "react";

interface CardProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
  style?: {};
}

export const Card: FC<CardProps> = ({ children, className, style }) => {
  return (
    <div className={`card ${className}`} style={style}>
      {children}
    </div>
  );
};
