import { applyActionCode, sendEmailVerification } from "firebase/auth";
import Swal from "sweetalert2";
import { auth, logOut, signIn, signUp } from "../api/firebase/firebaseConfig";
import { types } from "../types/types";

export const startLoginEmailPassword = async (
  email: string,
  password: string
) => {
  return signIn(email, password)
    .then(({ user }) => {
      localStorage.setItem("authorization", (user as any).accessToken);
      return user;
    })
    .catch((e) => {
      Swal.fire({
        title: "Oups",
        text: e,
        icon: "error",
      });
      throw new Error(e);
    });
};

export const startRegisterWithEmailPasswordName = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  telNumber: string
) => {
  return signUp(email, password)
    .then(async ({ user }) => {
      localStorage.setItem("authorization", (user as any).accessToken);
      const response = await fetch("http://localhost:8080/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: (user as any).accessToken,
        },
        body: JSON.stringify({
          query: `mutation PCreateUser($data: UserCreateInput!) {
                PCreateUserResolver(data: $data) {
                  id
                  email
                  role
                  firebaseId
                }
              }`,
          operationName: "PCreateUser",
          variables: {
            data: {
              email: "e.user.email!",
              firebaseId: "e.user.uid",
              firstName: firstName,
              secondName: lastName,
              telephone: telNumber,
            },
          },
        }),
      })
        .then((e) => {
          console.log(e);
          sendEmailVerification(user)
            .then(() => {
              console.log("Verificacion enviada! ");
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });

      console.log(response);
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const startLogout = () => {
  return async (dispatch: any) => {
    await logOut();
    localStorage.removeItem("authorization");
    dispatch(logout());
  };
};

export const login = (id: string, email: string) => {
  return {
    type: types.login,
    payload: {
      id,
      email,
    },
  };
};

export const logout = () => {
  return {
    type: types.logout,
  };
};
