import { DateTime } from "luxon";
import { Button } from "react-bootstrap";
import { ImCheckmark, ImCross } from "react-icons/im";
import { BsExclamationLg } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import {
  formatToDeleteTravel,
  formatToQueryTravelById,
} from "../helpers/formatForQuery";
import { useAuthenticationContext } from "../contexts/AuthenticationContext";
import voca from "voca";
import { useMemo } from "react";
import { Pressable } from "./microcomponents/Pressable";

export const OneTravel = ({
  // travel,
  PDeleteTravel,
}: {
  // travel: Travel;
  PDeleteTravel: any;
}) => {
  const navigate = useNavigate();
  const deleteTravel = async () => {
    const confirmation = await Swal.fire({
      icon: "warning",
      title: "Desea eliminar su viaje?",
      text: "Si su viaje contiene una reservacion, puede ser penalizado",
      showCancelButton: true,
      allowOutsideClick: false,
      cancelButtonText: "Volver",
      confirmButtonText: "Eliminar",
    });
    if (confirmation.isConfirmed) {
      // PDeleteTravel({ variables: formatToDeleteTravel(travel.id) });
      // navigate("/user/my-travels");
    }
  };

  return (
    <div
      className="reservations-one-reservation"
      // onClick={() => navigate(`${travel.id}`)}
    >
      {/* <div className="reservations-one-reservation-title">
        <h4>{travel.traject[0].steps[0].city} a </h4>
        <h4>
          {travel.traject[0].steps[travel.traject[0].steps.length - 1].city}
        </h4>
      </div>
      <div className="reservations-one-reservation-hour">
        <h5>
          {DateTime.fromISO(travel.startHour).setLocale("es").toLocaleString({
            month: "long",
            day: "2-digit",
          })}
          {", "}
          {DateTime.fromISO(travel.startHour).setLocale("es").toLocaleString({
            hour: "2-digit",
            minute: "2-digit",
          })}
        </h5>
      </div>
      <div className="reservations-one-reservation-status">
        <h5>
          {travel.Status === "NOSTARTED"
            ? "ACTIVO"
            : travel.Status === "SUCCESS"
            ? "TERMINADO"
            : travel.Status === "DELETED"
            ? "CANCELADO"
            : "EN VERIFICACION"}
        </h5>
      </div>
      <div className="reservations-one-reservation-buttons">
        <button
          className="btn btn-primary"
          onClick={() => navigate(`${travel.id}`)}
        >
          Ver
        </button>
        {travel.Status !== "DELETED" && (
          <Button onClick={deleteTravel}>Eliminar</Button>
        )}
      </div> */}
    </div>
  );
};

export const TravelExpand = () => {
  const navigate = useNavigate();
  const { travelId } = useParams();
  // const { data, loading, startPolling } = useTravelQuery({
  //   variables: {
  //     travelId: travelId!,
  //   },
  //   pollInterval: 1000,
  // });

  // const { userAuth } = useAuthenticationContext();
  // startPolling(1000);
  // console.log(data, loading);
  // const [PDeleteTravel] = usePDeleteTravelMutation({
  //   onCompleted: () => {
  //     Swal.fire({
  //       icon: "success",
  //       title: "Tu viaje fue eliminado",
  //       text: "",
  //     });
  //   },
  //   refetchQueries: "all",
  // });
  // // startPolling(100);
  // const [AcceptOrDeclineReservation] = useAcceptOrDeclineReservationMutation({
  //   onCompleted: (e) => {
  //     console.log(e);
  //     Swal.fire({
  //       icon: "success",
  //       title: "La reservacion fue aceptada",
  //       text: "",
  //     });
  //   },
  // });
  // const travel = data && data.PSearchOneActiveTravelByConductor;

  // const reservations = useMemo(() => {
  //   let reservations = 0;
  //   travel?.traject[0].fragments.forEach((item) => {
  //     item.DirectReservation.forEach((reservation) => {
  //       if (
  //         !reservation.Status.includes("ABORTED") &&
  //         !reservation.Status.includes("PAYMENT")
  //       ) {
  //         reservations = reservations + 1;
  //       }
  //     });
  //   });
  //   return reservations;
  // }, [travel]);

  // const handleDelete = async () => {
  //   const confirmation = await Swal.fire({
  //     icon: "warning",
  //     title: "Desea eliminar su viaje?",
  //     text: "Si su viaje contiene una reservacion, puede ser penalizado",
  //     showCancelButton: true,
  //     allowOutsideClick: false,
  //     cancelButtonText: "Volver",
  //     confirmButtonText: "Eliminar",
  //   });
  //   if (confirmation.isConfirmed) {
  //     PDeleteTravel({ variables: formatToDeleteTravel(travelId!) });
  //     navigate("/user/my-travels");
  //   }
  // };

  // const handleAcceptReservation = async (resId: string) => {
  //   await AcceptOrDeclineReservation({
  //     variables: {
  //       status: { set: ReservationStatus.Accepted },
  //       reservationId: resId,
  //     },
  //   });
  //   Swal.fire({
  //     icon: "success",
  //     title: "La reservacion fue aceptada",
  //     text: "",
  //   });
  // };

  // const handleDeclineReservation = async (resId: string) => {
  //   await AcceptOrDeclineReservation({
  //     variables: {
  //       status: { set: ReservationStatus.Abortedbyacceptation },
  //       reservationId: resId,
  //     },
  //   });
  //   Swal.fire({
  //     icon: "success",
  //     title: "La reservacion fue rechazada",
  //     text: "",
  //   });
  // };

  // const [CreateConversation] = useCreateConversationMutation();
  // const goToConversation = async () => {
  //   if (!userAuth) {
  //     console.log("se connecter");
  //   }

  // await CreateConversation({
  //   variables: {
  //     data: {
  //       travel: {
  //         connect: {
  //           id: travel?.id,
  //         },
  //       },
  //       userPassenger: {
  //         connect: {
  //           firebaseId: userAuth?.uid,
  //         },
  //       },
  //       userConductor: {
  //         connect: {
  //           id: travel?.conductorUser.id,
  //         },
  //       },
  //     },
  //   },
  // });
  // };

  return (
    <div className="container-information">
      {/* {!loading && data && (
        <div className="travel-container">
          <h3 className="center mb-5">
            {voca.capitalize(
              DateTime.fromISO(travel!.startHour).toLocaleString(
                {
                  weekday: "long",
                  day: "numeric",
                  month: "long",
                },
                {
                  locale: "es-ES",
                }
              )
            )}
          </h3>

          {travel!.traject[0].steps.map((item, index, array) => (
            <Pressable to={`/place/${item.placeId}`}>
              <div className="travel-step-container">
                <h4> {item.city}</h4>
                <h4>
                  {DateTime.fromISO(item.date).toLocaleString({
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </h4>
              </div>
            </Pressable>
          ))}
          <div className="travel-informations">
            <div>
              <h5>Lugares disponibles</h5>
              <h5>Estado </h5>
            </div>
            <div className="travel-information-right">
              <h5> {travel!.places}</h5>
              <h5>
                {travel?.Status === "NOSTARTED"
                  ? " Activo"
                  : travel?.Status === "SUCCESS"
                  ? " Terminado"
                  : travel?.Status === "DELETED"
                  ? " Cancelado"
                  : " En verificacion"}
              </h5>
            </div>
          </div>
          <div className="travel-passengers-container">
            {reservations !== 0 ? (
              <h4>Pasajeros </h4>
            ) : (
              <h5>No tenes ninguna reservacion todavia...</h5>
            )}
            {travel!.traject[0].fragments.map((item) => (
              <>
                {item.DirectReservation.map((item) => (
                  <>
                    {!item.Status.includes("ABORTED") && (
                      <div
                        className={`travel-passenger-container ${
                          item.Status === "ACCEPTANCEWAITING" &&
                          "travel-passenger-container-waiting"
                        }`}
                      >
                        <div className=" travel-passenger-photo">
                          <div className="user-photo-medium">
                            <img
                              src={item!.passenger!.photoURL!}
                              height={60}
                              width={60}
                              alt="foto de perfil"
                            />
                          </div>
                        </div>
                        <div className="travel-passenger-information">
                          <h5>
                            {
                              // item.passenger.firstName
                              "Joaquin"
                            }
                          </h5>

                          <h6>
                            {item.directFragment.startStep.city} a{" "}
                            {item.directFragment.endStep.city}
                          </h6>
                          <h6>{item.price} $</h6>
                        </div>
                        <div className="travel-passenger-status">
                          <h6>
                            {item.Status === "ACCEPTANCEWAITING" ? (
                              <h6>
                                <BsExclamationLg />
                                En espera de aceptacion
                              </h6>
                            ) : (
                              item.Status
                            )}
                          </h6>
                        </div>
                        <div className="travel-passenger-buttons">
                          {item.Status === "ACCEPTANCEWAITING" && (
                            <div>
                              <Button
                                className="btn btn-primary btn-accept"
                                onClick={() => handleAcceptReservation(item.id)}
                              >
                                <ImCheckmark />
                              </Button>
                              <Button
                                className="btn btn-primary btn-decline"
                                onClick={() =>
                                  handleDeclineReservation(item.id)
                                }
                              >
                                <ImCross color="#0e747c" />
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </>
            ))}
          </div>

          {travel?.Status !== "DELETED" && (
            <Pressable to={""} onClick={() => handleDelete()}>
              <h4>Eliminar mi viaje</h4>
            </Pressable>
          )}
        </div>
      )} */}
    </div>
  );
};
