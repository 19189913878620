import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { TravelsFind } from "../TravelsFind";
import { SearchHome } from "../SearchHome";

export const SearchTravel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { origin, destination, date } = queryString.parse(location.search);

  const [showTravels, setShowTravels] = useState(false);

  const [originSearch, setOriginSearch] = useState(
    origin !== null ? (origin as string) : ""
  );
  const [destinationSearch, setDestinationSearch] = useState(
    destination !== null ? (destination as string) : ""
  );

  const now = new Date(Date.now());
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const [dateSearch, setDateSearch] = useState(
    date !== "" ? (date as unknown as Date) : today
  );
  console.log(dateSearch);

  const HandleSearch = () => {
    navigate(
      `?origin=${originSearch}&destination=${destinationSearch}&date=${dateSearch}`
    );
    setShowTravels(false);
    setShowTravels(true);
    // const { data, loading, error } = usePSearchTravelQuery({
    //   variables: {
    //     origin: originSearch,
    //     destination: destinationSearch,
    //     date: dateSearch.toISOString(),
    //   },
    // });
  };

  console.log(originSearch, destinationSearch, dateSearch);

  return (
    <div className="search-container">
      <SearchHome
        origin={originSearch}
        destination={destinationSearch}
        setOrigin={setOriginSearch}
        setDestination={setDestinationSearch}
        setDate={setDateSearch}
        date={dateSearch}
        orientation="horizontal"
        onPressButton={() => HandleSearch()}
      />

      <div className="search-travelsfounds">
        {showTravels && <TravelsFind />}
      </div>
    </div>
  );
};
