import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import React, { FC, useState } from "react";
import { searchGeneralOptions } from "../settings/searchSettings";
import DatePicker from "react-date-picker/dist/entry.nostyle";
import { DateTime } from "luxon";

interface InputSearchProps {
  title: string;
  placeholder: string;
  type?: string;
  name?: string;
  onChangeText?: () => {};
  setValuesForm?: any;
  value?: any;
  setValue?: any;
}
export const InputSearch: FC<InputSearchProps> = ({
  title,
  placeholder,
  type = "text",
  name,
  value,
  setValue,
  setValuesForm,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries: ["places"],
  });

  const [autocomplete, setAutocomplete] = useState<
    google.maps.places.Autocomplete | undefined
  >();

  return (
    <div className="home-input-container">
      <div className="home-input-title">{title}</div>

      {type === "text" ? (
        isLoaded ? (
          <Autocomplete
            options={searchGeneralOptions}
            onLoad={(a) => setAutocomplete(a)}
            onPlaceChanged={() => {
              const cityName = autocomplete?.getPlace()?.address_components
                ? autocomplete.getPlace().address_components![0].long_name
                : autocomplete?.getPlace().formatted_address;
              setValue(cityName);
            }}
          >
            <input
              className="home-input"
              name={name}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder={placeholder}
            />
          </Autocomplete>
        ) : (
          <input
            name="name"
            className="home-input"
            value={value}
            placeholder={placeholder}
          />
        )
      ) : type === "number" ? (
        <input
          className="home-input"
          type="number"
          value={value}
          placeholder={placeholder}
        />
      ) : (
        <DatePicker
          maxDetail="month"
          minDate={new Date(Date.now())}
          className="home-input home-input-date"
          locale="es-ES"
          value={value}
          onChange={(e: Date) => {
            const event = { target: {} };
            const date = DateTime.fromISO(e.toISOString()).set({
              hour: 0,
              minute: 0,
              second: 0,
            });
            event.target = {
              name: name ? name : "date",
              value: date.toJSDate(),
            };
            setValue && setValue(date.toJSDate());
            setValuesForm && setValuesForm(event);
          }}
          name="date"
        />
      )}
    </div>
  );
};
