import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { Button } from "react-bootstrap";
import { IoClose } from "react-icons/io5";

export interface IModalContext {
  modalProps: { open: boolean; children: JSX.Element };
  setModalProps: Dispatch<
    SetStateAction<{ open: boolean; children: JSX.Element }>
  >;
  modal: JSX.Element;
  resetModal: () => void;
}

const ModalContext = createContext<IModalContext>({
  modalProps: { open: false, children: <></> },
  setModalProps: () => {},
  modal: <></>,
  resetModal: () => {},
});

export const ModalContextProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [modalProps, setModalProps] = useState<{
    open: boolean;
    children: JSX.Element;
  }>({ open: false, children: <></> });

  const resetModal = () => {
    setModalProps({ children: <></>, open: false });
  };

  const modal = useMemo(() => {
    if (modalProps.open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return (
      <div
        className={`center-modal center-modal-${
          modalProps.open ? "open" : "close"
        }`}
      >
        <div
          className={`card-modal card-modal-${
            modalProps.open ? "open" : "close"
          }`}
        >
          <div className="modal-close-button">
            <IoClose
              className="close-icon-modal"
              size={32}
              onClick={() => {
                resetModal();
              }}
            />
          </div>
          <div className={`children-card-modal`}>{modalProps.children}</div>
          {/* <div className={`button-card-modal`}>
            <Button
              onClick={() => {
                resetModal();
              }}
            >
              Cerrar
            </Button>
          </div> */}
        </div>
      </div>
    );
  }, [modalProps]);
  const value = {
    modalProps,
    setModalProps,
    modal,
    resetModal,
  };
  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

export const useModalContext = (): IModalContext => {
  const context = useContext(ModalContext);
  if (!context) {
    throw Error("useAppContext must be inside AppContext");
  }
  return context;
};
