import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { travelAction } from "../reducers/travelReducer";
import { InputSearch } from "./InputSearch";

interface SelectDateProps {
  date: Date;
  setDate: ({ target }: { target: EventTarget & HTMLInputElement }) => void;
  actionOnChange: (date: Date) => travelAction;
}
export const SelectDate: FC<SelectDateProps> = ({
  date,
  setDate,
  actionOnChange,
}) => {
  const dispatch = useDispatch();
  return (
    <div>
      <InputSearch
        title="Cual es la fecha de tu viaje?"
        type="datetime-local"
        placeholder="Ingrese la fecha"
        setValuesForm={setDate}
        value={date}
      />
      {/* <input
        type="datetime-local"
        placeholder="date"
        name="date"
        value={date as unknown as string}
        onChange={(e) => {
          setDate(e);
          dispatch(actionOnChange(new Date(e.target.value)));
        }}
        className="home-input"
      /> */}
    </div>
  );
};
