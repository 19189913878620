import React from "react";
import { Step } from "../reducers/travelReducer";

interface TravelSummaryProps {
  steps?: Step[];
  startDate?: Date;
  price?: number;
  places?: number;
  progress: number;
}

export const TravelSummary = ({
  steps,
  startDate,
  price,
  places,
  progress,
}: TravelSummaryProps) => {
  const startDateFormat = new Date(startDate!).toLocaleString("es-ES", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
  return (
    <div className="travel-summary-container">
      {progress > 0 && (
        // @ts-ignore
        <div className="travel-summary-item">
          <div className="home-input-title">Cuando?</div>
          {startDateFormat}
        </div>
      )}
      {progress > 0 && (
        <div className="travel-summary-item">
          {steps?.map((step, index) => (
            <div className="travel-summary-item">
              <div className="home-input-title">Etapa numero {index + 1}: </div>
              {step.city}
            </div>
          ))}
        </div>
      )}
      {progress > 2 && (
        <div className="travel-summary-item">
          <div className="home-input-title">Lugares : </div>
          {places}
        </div>
      )}
      {progress > 3 && (
        <div className="travel-summary-item">
          <div className="home-input-title">Precio : </div>
          {price} $</div>
      )}
    </div>
  );
};
