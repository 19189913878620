import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  User as UserAuth,
} from "firebase/auth";
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import Swal from "sweetalert2";
import { auth, logOut, signUpWithGoogle } from "../api/firebase/firebaseConfig";
import {
  useGetUserLazyQuery,
  useGetUserQuery,
  usePCreateUserMutation,
  User,
} from "../graphql/generated";
import { envType, useAppContext } from "./AppContext";

export interface IAuthenticationContext {
  userAuth: UserAuth | null;
  setUserAuth: (user: UserAuth | null) => void;
  CreateUser: any;
  user: User | null;
  refetch: any;
  loading: boolean;
  loginRedirect: any;
}

const AuthenticationContext = createContext<IAuthenticationContext>({
  userAuth: null,
  setUserAuth: () => {},
  user: null,
  refetch: () => {},
  loading: false,
  CreateUser: () => {},
  loginRedirect: () => {},
});

export const AuthenticationContextProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [userAuth, setUserAuth] = useState<UserAuth | null>(null);

  const [user, setUser] = useState<User | null>(null);

  const { environment } = useAppContext();

  const [loading, setLoading] = useState(true);

  const [query, { data, loading: _, error, refetch }] = useGetUserLazyQuery({
    onError: (e) => {
      console.log(JSON.stringify(e));
    },
  });

  const [CreateUser] = usePCreateUserMutation({
    onCompleted: (result) => {
      if (result.PCreateUserResolver.__typename === "CreateUserResultSuccess") {
        Swal.fire({
          title: "Tu cuenta fue creada con exito",
          text: "Solo algunos pasos mas para empezar a viajar",
          icon: "success",
        });
      } else if (
        result.PCreateUserResolver.__typename === "CreateUserResultError"
      ) {
        Swal.fire({
          title: "Tu cuenta no pudo ser creada",
          text: result.PCreateUserResolver.code,
          icon: "error",
          showCancelButton: true,
          cancelButtonText: "No, gracias",
          confirmButtonText: "Ver las instrucciones",
        });
      }
    },
    onError: (error) => {
      error.message.includes("telefono") &&
        Swal.fire({
          title: "Tu cuenta no pudo ser creada",
          text: "Parece que el numero de telefono indicado esta asociado a otra cuenta, si el numero te pertenece y no tenes o no podes acceder a tu cuenta, segui las instrucciones",
          icon: "error",
          showCancelButton: true,
          cancelButtonText: "No, gracias",
          confirmButtonText: "Ver las instrucciones",
        });
      error.message.includes("email") &&
        Swal.fire({
          title: "Tu cuenta no pudo ser creada",
          text: "Parece que el email  indicado esta asociado a otra cuenta",
          icon: "error",
          showConfirmButton: true,
        });
      !error.message.includes("email") &&
        !error.message.includes("telefono") &&
        Swal.fire({
          title: "Tu cuenta no pudo ser creada",
          text: error.message,
          icon: "error",
          showConfirmButton: true,
        });
    },
  });

  // const [CreateUserWithGoogle] = usePCreateUserWithGoogleMutation({
  //   onError: async (error) => {
  //     console.log(error);
  //     error.message.includes("email") &&
  //       Swal.fire({
  //         title: "Tu cuenta no pudo ser creada",
  //         text: "Parece que el email  indicado esta asociado a otra cuenta, por favor contectate",
  //         icon: "error",
  //         showConfirmButton: true,
  //       }).then(() => {
  //         window.location.assign("/auth/login");
  //       });
  //     await logOut();
  //   },
  // });

  // const connectWithGoogle = async () => {
  //   await signUpWithGoogle().then(async (result) => {
  //     const { token, userEmail } = result;

  //     console.log(result);

  //     await refetch({}).then(async (data) => {
  //       console.log(data);

  //       if (!data.data?.user) {
  //         await CreateUserWithGoogle({
  //           variables: {
  //             token: token,
  //           },
  //           refetchQueries: "all",
  //         }).then(async () => {
  //           await Swal.fire({
  //             title: "Tu cuenta fue creada con exito",
  //             text: "Solo algunos pasos mas para empezar a viajar",
  //             icon: "success",
  //           });
  //         });
  //       }
  //       if (data.data?.user?.isActive) {
  //         window.location.assign("/");
  //       } else {
  //         window.location.assign("/user/confirm-phone-number");
  //       }
  //     });
  //   });
  // };

  useEffect(() => {
    setLoading(true);
    setUserAuth(auth.currentUser);
    onAuthStateChanged(auth, async (userUpdate) => {
      if (userUpdate?.uid) {
        await localStorage.setItem(
          "authorization",
          `Bearer ${(userUpdate as any).accessToken}`
        );
        setUserAuth(userUpdate);
        refetch({}).then((data) => {
          setUser(data.data.SearchPrivateUser as User);
        });
      } else {
        setUser(null);
        setUserAuth(null);
        localStorage.removeItem("authorization");
      }
      setLoading(false);
    });
  }, [refetch, auth.currentUser, data]);

  const loginRedirect = (email: string) => {
    console.log("redirecting");
    refetch({})
      .then((data) => {
        if (data.data.SearchPrivateUser?.isActive) {
          if (environment === envType.BACKOFFICE) {
            window.location.assign("/");
          } else {
            window.location.assign("/");
          }
        } else {
          window.location.assign("/admin/confirm-phone-number");
        }
      })
      .catch((error) => {
        Swal.fire("Error", JSON.stringify(error));
      });
  };

  const value = {
    userAuth,
    setUserAuth,
    user,
    refetch,
    loading,
    CreateUser,
    loginRedirect,
  };
  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthenticationContext = (): IAuthenticationContext => {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw Error(
      "useAuthenticationContext must be inside AuthenticationContext"
    );
  }
  return context;
};
