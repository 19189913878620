import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import image from "../../assets/images/Homebg.jpg";
import mapSS from "../../assets/images/mapSS.png";
import searchSS from "../../assets/images/searchSS.png";
import { useAppContext } from "../../contexts/AppContext";
import { HomeInfo } from "../HomeInfo";
import { Question } from "../Question";
import { SearchHome } from "../SearchHome";
import { InputEmailHome } from "../InputEmailHome";
import { Card } from "../Card";
import downloadQR from "../../assets/images/DownloadQR.png";
import appStore from "../../assets/images/App_Store_Badge.png";
import playStore from "../../assets/images/google-play-badge.png";

export const Home = () => {
  const { mode } = useAppContext();
  const [width, setWidth] = useState(
    window.innerWidth > 1100 ? "large" : "short"
  );
  const [originSearch, setOriginSearch] = useState("");
  const navigate = useNavigate();
  const [destinationSearch, setDestinationSearch] = useState("");
  const now = new Date(Date.now());
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const [dateSearch, setDateSearch] = useState(today);

  const questions = [
    {
      id: 1,
      text: "Me Llevás ? es una plataforma de viajes compartidos, que pone conecta a conductores que viajan ocasionalmente de forma no profesional y pasajeros.",
      title: "¿ Qué es Me Llevás ? ",
    },
    {
      id: 3,
      text: "Para inscribirte necesitas completar tu perfil con datos esenciales, tales como tu nombre y apellido, tu teléfono, y tu dirección de correo electrónico. Tené en cuenta que mientras más información publiques en tu perfil, más probabilidades tenés de que las personas compartan un viaje con vos. ",
      title: "¿ Qué necesito para inscribirme ?",
    },
    {
      id: 4,
      text: "Para publicar un viaje necesitas indicar desde donde salis y hacia donde vas, y si buscas pasajeros en ciudades de paso. Tenes que indicar también que dia y a que hora salis y cuantos lugares tenes disponibles en tu auto. Acordate que por razones de seguridad, para poder publicar un viaje tenes que tener un número de teléfono asociado a tu cuenta. ",
      title: "¿ Cómo hago para publicar un viaje ? ",
    },
    {
      id: 5,
      text: "Para buscar y reservar un viaje, no tenés más que ingresar la ciudad de origen, la ciudad de destino y el día de tu viaje. Nosotros buscaremos entre todos los viajes publicados los que se adapten a tu búsqueda. Si tenés alguna duda, podés enviarle un mensaje al conductor a través de la aplicación para asegurarte que el viaje corresponde a lo que estás buscando. ",
      title: "¿ Cómo hago para reservar un viaje ?",
    },
  ];

  useEffect(() => {
    window.addEventListener("resize", () => {
      window.innerWidth > 1100 && width === "short" && setWidth("large");
      window.innerWidth < 1100 && width === "large" && setWidth("short");
    });

    return () =>
      window.addEventListener("resize", () => {
        window.innerWidth > 1100 && width === "short" && setWidth("large");
        window.innerWidth < 1100 && width === "large" && setWidth("short");
      });
  }, [width]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 100,
        behavior: "smooth",
      });
    }, 1000);
  }, []);

  return (
    <>
      <div className="home-header">
        {mode === "app" ? (
          <div className="home-title">Comparti tu primer viaje hoy</div>
        ) : (
          <div className="home-title">
            Te esperamos pronto para empezar a compartir caminos.
          </div>
        )}
        <div className="home-content home-first-row">
          {mode === "app" && (
            <SearchHome
              origin={originSearch}
              destination={destinationSearch}
              setOrigin={setOriginSearch}
              setDestination={setDestinationSearch}
              setDate={setDateSearch}
              date={dateSearch}
              onPressButton={() => {
                navigate(
                  `search?origin=${originSearch}&destination=${destinationSearch}&date=${dateSearch}`
                );
              }}
            />
          )}
          <div className="home-column m-0">
            <div
              style={{
                backgroundColor: "rgba(251, 250, 246, 0.4)",
                padding: 16,
                borderRadius: 8,
                borderWidth: 1,
                borderColor: "#81b9bf",
                borderStyle: "solid",
              }}
            >
              <h3>Proximamente disponible</h3>
              <p>
                Me Llevás ? es una plataforma de viajes compartidos, que permite
                a conductores que viajan ocasionalmente de forma no profesional
                compartir sus vehiculos con personas que deseen realizar el
                mismo trayecto. Pronto podras descargar la aplicación para
                cualquier dispositivo escaneando el siguiente codigo QR.
              </p>
            </div>
            <div className="download-modal">
              <div className="text-card-modal">
                <div className="mb-2">
                  {/* <h3>Descargá la aplicacion</h3> */}
                </div>
                {/* <text>
                  Con la cámara de tu teléfono, escaneá el codigo QR para
                  descargar la aplicación y empezar a viajar.{" "}
                </text> */}
              </div>
              {/* <div className="division"></div> */}
              <div className="image-card-modal-home">
                <img src={downloadQR} className="download-qr-home" />
              </div>
              {/* <div className="division" /> */}
              <div className="download-card-modal" style={{ marginTop: 16 }}>
                <img src={appStore} className="download-stores" />
                <img src={playStore} className="download-google-stores" />
              </div>
            </div>
            {/* {mode === "preApp" && (
              <div className="faq-container">
                {questions.map((question) => (
                  <Question {...question} />
                ))}
              </div>
            )} */}
          </div>
          <div className="home-column" style={{ padding: 0 }}>
            <img src={searchSS} alt="" className="home-screenshot" />
          </div>

          {mode === "app" && width === "large" && <HomeInfo />}
        </div>
        {/* {width === "large" && (
        <img src={image} alt="" className="d-block w-100 home-image" />
      )} */}
      </div>
      <div className="home-content home-third-row ">
        <h2>Por viajes más seguros</h2>
        <div className="home-column">
          <div className="mb-5">
            <p>
              Antes de comprometerte a viajar con alguien, tomate todo el tiempo
              que consideres necesario para sentirte seguro.
            </p>
          </div>
          <div>
            <div
              className="home-row"
              style={
                {
                  // alignSelf: "center",
                  // marginTop: 0,
                  // marginRight: 0,
                  // paddingRight: 0,
                  // width: "70%",
                }
              }
            >
              <Card
                className="mh-16 mv-8 flex-1 flip"
                style={{ backgroundColor: "rgba(251, 250, 246, 0.8)" }}
              >
                <div className="mb-2">
                  <h3>Confianza</h3>
                </div>
                <text
                  style={{
                    fontFamily: "Inter",
                  }}
                >
                  En el momento de reservar o aceptar la reserva de alguien,
                  revisá el usuario de la persona. Asegurate que tenga una foto
                  de perfil que la identifique. Tambien, si la persona ya
                  compartió viajes en la plataforma, podes ver las puntuaciones
                  que otras personas le asignaron.
                </text>
              </Card>
              <Card
                className="mh-16 mv-8 flex-1 flip"
                style={{ backgroundColor: "rgba(251, 250, 246, 0.8)" }}
              >
                <div className="mb-2">
                  <h3>Seguridad</h3>
                </div>
                <text
                  style={{
                    fontFamily: "Inter",
                  }}
                >
                  Prioriza los viajes que tengan horarios de origen y de destino
                  seguros, preferentement de dia, como asi tambien aquellos que
                  estén en zonas que te hagan sentir seguro. Si tenés dudas,
                  siempre podés comunicarte con la otra persona y negociar los
                  puntos de origen y destino, si el conductor asi lo desea.
                </text>
              </Card>
              <Card
                className="mh-16 mv-16 flex-1 flip"
                style={{ backgroundColor: "rgba(251, 250, 246, 0.8)" }}
              >
                <div className="mb-2">
                  <h3>Diálogo</h3>
                </div>
                <text
                  style={{
                    fontFamily: "Inter",
                  }}
                >
                  Si todavía tenés dudas acerca de la persona y/o del viaje,
                  siempre tenés la posibilidad de contactarla a través de
                  nuestra mensajería y entrar en confianza con la otra persona.
                  Recomendamos evitar de dar información sensible.
                </text>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <div
        className=" home-content flex-direction-column route-bg"
        style={{
          paddingTop: 80,
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(251, 250, 246, 0.1)",
            padding: 16,
            borderRadius: 4,
            borderWidth: 1,
            // borderColor: "#81b9bf",
            // borderStyle: "solid",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              color: "black",
              // fontSize: 40,
              fontWeight: "bolder",
              lineHeight: 1.5,
            }}
          >
            ¿ Cómo funciona Me Llevás ?{" "}
          </h2>
        </div>
        <div
          className="home-second-row"
          style={{
            justifyContent: "center",
            alignSelf: "center",
            // backgroundColor: "red",
          }}
        >
          <div className="home-column ">
            <img src={mapSS} alt="" className="home-screenshot" />
          </div>
          <div
            className="home-column"
            style={{
              alignSelf: "center",
              marginTop: 0,
              justifyContent: "center",
              margin: 0,
              padding: 0,
            }}
          >
            {mode === "preApp" && (
              <div className="faq-container">
                {questions.map((question) => (
                  <Question {...question} />
                ))}
              </div>
            )}

            {/* <InputEmailHome /> */}
          </div>
        </div>
        {/* <img src={searchSS} alt="" className="home-screenshot" />
      
        {mode === "preApp" && (
          <div className="faq-container">
            {questions.map((question) => (
              <Question {...question} />
            ))}
          </div>
        )} */}
      </div>
    </>
  );
};
