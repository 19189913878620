import React from "react";
import { useSelector } from "react-redux";

import { OneReservation } from "./OneReservation";

export const MyReservations = () => {
  const userFirebaseId = useSelector((state) => (state as any).auth);
  // const { data, loading, error, startPolling } =
  //   usePSearchValidReservationsQuery({
  //     variables: {
  //       where: {
  //         passenger: {
  //           is: { firebaseId: { equals: userFirebaseId.firebaseId } },
  //         },
  //       },
  //     },
  //     pollInterval: 100,
  //   });
  // startPolling(1000);
  return (
    <div className="container-information">
      <h3>Tus reservaciones</h3>
      {/* <div className="travel-results-container">
        {!loading &&
        data &&
        data?.reservations.filter((reser) => !reser.Status.includes("ABORTED"))
          .length > 0 ? (
          data?.reservations.map((res) => {
            if (!res.Status.includes("ABORTED")) {
              return <OneReservation reservation={res as Reservation} />;
            }
            return <></>;
          })
        ) : (
          <h5>No tiene ninguna reservacion</h5>
        )}
      </div> */}
    </div>
  );
};
