import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

const httpLink = createHttpLink({
  uri: "https://staging.backend.mellevas.app/graphql",
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: "ws://192.168.43.114:8080/",
    connectionParams: {
      authorization: localStorage.getItem("authorization")
        ? localStorage.getItem("authorization")
        : "",
    },
  })
);

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("authorization");
  return {
    headers: {
      ...headers,
      authorization: token ? token : "",
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

export const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});
