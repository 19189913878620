import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { ConversationContextProvider } from "../../contexts/ConversationContext";
import { FiSend } from "../../../node_modules/react-icons/fi";
import { useConversationContext } from "../../contexts/ConversationContext";
import { Link, useLocation, useParams } from "react-router-dom";
import { useAuthenticationContext } from "../../contexts/AuthenticationContext";
import Swal from "sweetalert2";
import Logo from "../../assets/images/Logo + text.png";
import voca from "voca";
import { AiOutlineEye } from "react-icons/ai";
import { BsCheck2 } from "react-icons/bs";
import conversationplaceholder from "../../assets/images/conversation-placeholder.svg";
import { isMobile } from "react-device-detect";

export const ChatScreen = () => {
  const location = useLocation();
  console.log(location);
  return (
    // <ConversationContextProvider>
    <>
      {isMobile ? (
        <div>Descarga la aplicacion para utilizar esta funcionalidad</div>
      ) : (
        <div className="chat-container">
          <ChatLeftSide />
          <ChatRightSide />
        </div>
      )}
    </>
    // </ConversationContextProvider>
  );
};

const ChatLeftSide = () => {
  const { subscribeToConversation, unsubscribeToConversation, conversations } =
    useConversationContext();

  const { userAuth } = useAuthenticationContext();

  const { conversationId } = useParams();

  //   console.log(conversationId, userAuth);

  useEffect(() => {
    // subscribeToConversation({
    //   where: {
    //     OR: [
    //       {
    //         userConductor: {
    //           is: {
    //             firebaseId: {
    //               equals: userAuth?.uid,
    //             },
    //           },
    //         },
    //       },
    //       {
    //         userPassenger: {
    //           is: {
    //             firebaseId: {
    //               equals: userAuth?.uid,
    //             },
    //           },
    //         },
    //       },
    //     ],
    //   },
    // });
    return () => {
      //   unsubscribeToConversation();
    };
  }, []);

  //   console.log(
  //     conversations?.filter((conversation) => {
  //       return conversation.messages.length > 0;
  //     }),
  //     conversations
  //   );

  console.log(conversations);
  return (
    <>
      {true && (
        <div className="chat-conversations-container">
          {conversations?.map((conversation) => (
            <Link
              to={`/user/messages/${conversation.id}`}
              className="chat-one-conversation-text"
            >
              <div className="chat-one-conversation">
                <div className="chat-one-conversation-user-info">
                  <div className="user-photo-medium">
                    <img
                      src={
                        userAuth?.uid ===
                        conversation?.userConductor?.firebaseId
                          ? conversation!.userPassenger!.photoURL!
                          : conversation!.userConductor!.photoURL!
                      }
                      height={60}
                      width={60}
                      alt="foto de perfil"
                    />
                  </div>
                  <div>
                    <h4>
                      {userAuth?.uid === conversation?.userConductor?.firebaseId
                        ? conversation?.userPassenger?.firstName
                        : conversation?.userConductor?.firstName}
                    </h4>

                    <h6>
                      {conversation.fragment.startStep.city} -{" "}
                      {conversation.fragment.endStep.city}
                    </h6>
                  </div>
                </div>
                <div className="chat-one-conversation-number">
                  {conversation.messages.filter((m: any) => {
                    //   console.log(m);
                    return m.seen === false &&
                      m.sender.firebaseId !== userAuth?.uid
                      ? true
                      : false;
                  }).length !== 0 &&
                    conversation.messages.filter((m: any) => {
                      // console.log(m);
                      return m.seen === false &&
                        m.sender.firebaseId !== userAuth?.uid
                        ? true
                        : false;
                    }).length}
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </>
  );
};

const ChatRightSide = () => {
  const { conversationId } = useParams();
  //   console.log(conversationId);

  const {
    subscribeToMessages,
    unsubscribeToMessages,
    messages,
    conversations,
  } = useConversationContext();

  const { userAuth } = useAuthenticationContext();
  const conversation = conversations.filter(
    (conversation) => conversation.id === conversationId
  )[0];

  // const { data, loading, error, refetch } = useGetFragmentQuery();

  useEffect(() => {
    // refetch({
    //   where: {
    //     id: {
    //       equals: conversation?.fragmentId,
    //     },
    //   },
    // });
  }, [conversation]);

  useEffect(() => {
    if (conversationId) {
      subscribeToMessages({
        conversationId: conversationId,
        orderBy: [
          {
            createdAt: "desc",
          },
        ],
      });
    }

    return () => {
      unsubscribeToMessages();
    };
  }, [conversationId]);

  //   console.log(messages[0]?.sender.firebaseId, userAuth?.uid);
  console.log(messages);
  return (
    <>
      <div className="chat-conversation-container">
        {conversationId ? (
          <>
            <div className="chat-conversation-expanded">
              <div className="chat-conversation-header">
                <div className="chat-conversation-header-travel-user">
                  <div className="user-photo-large">
                    {/* <img
                      src={
                        data?.fragments[0].Traject?.Travel.conductorUser
                          .email === userAuth?.email
                          ? conversation?.userPassenger?.photoURL!
                          : conversation?.userConductor?.photoURL!
                      }
                      height={80}
                      width={80}
                      alt="foto de perfil"
                    /> */}
                  </div>
                  <h4>
                    {/* {data?.fragments[0].Traject?.Travel.conductorUser.email ===
                    userAuth?.email
                      ? voca.capitalize(conversation?.userPassenger.firstName!)
                      : voca.capitalize(conversation?.userConductor.firstName!)} */}
                  </h4>
                </div>
                <div className="chat-conversation-logo">
                  <img src={Logo} alt="Logo" height={"100%"} />
                </div>
                <div className="chat-conversation-header-travel">
                  {/* <Link to={`/search/${data?.fragments[0].id}`}>
                    <h4>
                      {data?.fragments[0].startStep.city} -{" "}
                      {data?.fragments[0].endStep.city}
                    </h4>
                  </Link>
                  <h4>
                    {DateTime.fromISO(
                      data?.fragments[0].startStep.date
                    ).toLocaleString(
                      { day: "2-digit", month: "long", weekday: "long" },
                      { locale: "es-ES" }
                    )}
                  </h4> */}
                </div>
              </div>
              <div className="chat-conversation-messages">
                {messages.map((message) => (
                  <Message message={message} userAuth={userAuth} />
                ))}
              </div>
            </div>
            <InputContainer />
          </>
        ) : (
          <div className="chat-placeholder">
            <img
              alt="qsdq"
              height="400"
              className="d-inline-block align-top navbar-logo"
              src={conversationplaceholder}
            />
          </div>
        )}
      </div>
    </>
  );
};

const Message = ({ message, userAuth }: any) => {
  const { markMessage } = useConversationContext();

  //   console.log(message.content);

  !message.seen &&
    message.sender.firebaseId !== userAuth.uid &&
    markMessage({
      variables: {
        where: {
          id: message.id,
        },
      },
    });
  return (
    <div>
      <div
        className={`${
          message.sender.firebaseId === userAuth?.uid
            ? "chat-message-self"
            : "chat-message-other"
        }`}
      >
        <div
          className={`${
            message.sender.firebaseId === userAuth?.uid
              ? "chat-message-self-content"
              : "chat-message-other-content"
          }`}
        >
          <h5>{voca.capitalize(message.sender.firstName)}</h5>
          <h6>{message.content}</h6>
          <div className="chat-message-info-date">
            <h6>
              {DateTime.fromISO(message.createdAt).toLocaleString({
                day: "2-digit",
                month: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </h6>
            {message.sender.firebaseId === userAuth?.uid &&
              (message.seen ? (
                <AiOutlineEye className="btn-icon" />
              ) : (
                <BsCheck2 className="btn-icon" />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const InputContainer = () => {
  const [message, setMessage] = useState("");
  const { conversationId } = useParams();

  const { userAuth } = useAuthenticationContext();
  const { sendMessage } = useConversationContext();

  //   console.log(params);

  const handleSendMessage = () => {
    // e.preventDefault();
    message !== "" &&
      sendMessage({
        variables: {
          data: {
            sender: {
              connect: {
                firebaseId: userAuth?.uid,
              },
            },
            content: voca.capitalize(message),
            Conversation: {
              connect: {
                id: conversationId,
              },
            },
          },
        },
      })
        .then(() => {
          setMessage("");
        })
        .catch((e: any) => {
          Swal.fire({
            title: "Oups",
            text: e,
          });
          //   setMessage("");
        });
  };
  return (
    <div className="chat-insert-message">
      <form className="chat-input-container">
        <div className="chat-input">
          <textarea
            className="chat-input-self"
            value={message}
            placeholder="Escribi tu mensaje aca..."
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            onKeyPress={(e) => {
              e.which === 13 && handleSendMessage();
            }}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleSendMessage}
        >
          Enviar
          <FiSend className="btn-icon" />
        </button>
      </form>
    </div>
  );
};
