import { FC } from "react";
import {
  QuestionContextProvider,
  useQuestionContext,
} from "../contexts/QuestionContext";

interface QuestionContentProps {
  id: number;
  title: string;
  text: string;
}

interface QuestionProps {
  id: number;
  title: string;
  text: string;
}

export const QuestionContent: FC<QuestionContentProps> = ({
  id,
  text,
  title,
}) => {
  const { open, setOpen } = useQuestionContext();
  return (
    <div
      className="faq-question-container"
      onMouseLeave={() => setOpen(false)}
      onMouseEnter={() => {
        setOpen(true);
      }}
    >
      <div className="faq-question" key={id}>
        <h3 className="faq-title">{title}</h3>
        <div className="faq-icon">{open ? <h3>^</h3> : <h3>+</h3>}</div>
      </div>
      <div className={`faq-text-container-${open ? "open" : "close"}`}>
        <p className={`faq-text`}>{text}</p>
      </div>
    </div>
  );
};
export const Question: FC<QuestionProps> = (props) => {
  return (
    <QuestionContextProvider>
      <QuestionContent {...props} />
    </QuestionContextProvider>
  );
};
