import { ReactElement, SetStateAction, useEffect, useState } from "react";
import { useAuthenticationContext } from "../contexts/AuthenticationContext";
import voca from "voca";
import { uploadPhoto } from "../api/firebase/firebaseConfig";
import Swal from "sweetalert2";
import image from "../assets/images/Logo + text.png";
interface InputTextProps {
  active?: boolean;
  title: string;
  value: any;
  setValue: React.Dispatch<SetStateAction<any>>;
  withPlaceholder?: boolean;
}

export const InputText = ({
  active = false,
  title,
  value,
  setValue,
  withPlaceholder = true,
}: InputTextProps): ReactElement<InputTextProps> => {
  return (
    <div>
      <h5>{title}</h5>
      {active ? (
        <input
          className="info-user-input"
          placeholder={
            withPlaceholder ? "Ingrese su " + voca.decapitalize(title) : ""
          }
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      ) : (
        <div className="info-user-one">{value}</div>
      )}
    </div>
  );
};

export const UserInformation = () => {
  const { userAuth, user } = useAuthenticationContext();
  const [modifyActive, setModifyActive] = useState(false);
  const [changePhotoActive, setChangePhotoActive] = useState(false);

  const [photoUrl, setPhotoUrl] = useState<File>();

  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.secondName);
  const [numberTelephone, setNumberTelephone] = useState(user?.telephone);
  const [ville, setVille] = useState(user?.ville);
  const [birthDate, setBirthDate] = useState(user?.birthDate);

  // const [UpdateUser] = useUpdateUserMutation({ refetchQueries: "all" });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFirstName(user?.firstName);
    setLastName(user?.secondName);
    setBirthDate(user?.birthDate);
    setNumberTelephone(user?.telephone);
    setVille(user?.ville);
  }, [user]);

  console.log(user, userAuth);

  const handleChangePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setPhotoUrl(e.target.files[0]);
    }
  };

  const handleUpdate = async () => {
    // setLoading(true);
    // photoUrl &&
    //   (await uploadPhoto(photoUrl, userAuth).then((url) => {
    //     // console.log(userAuth.uid);
    //     UpdateUser({
    //       variables: {
    //         where: {
    //           firebaseId: userAuth?.uid,
    //         },
    //         data: {
    //           photoURL: {
    //             set: url,
    //           },
    //         },
    //       },
    //     }).then(() => {
    //       Swal.fire({
    //         title: "Tu foto fue subida con exito",
    //         icon: "success",
    //       });
    //     });
    //     setChangePhotoActive(false);
    //   }));
    // setLoading(false);
  };

  console.log(user);

  return (
    <div className="container-information">
      <div className="user-photo-large">
        <img
          src={user ? user!.photoURL! : ""}
          height={80}
          width={80}
          alt="foto de perfil"
        />
      </div>
      <h2>
        {user?.firstName} {user?.secondName}
      </h2>
      {changePhotoActive ? (
        <>
          <label>
            <h5>Seleccione una imagen</h5>
            {photoUrl?.name}
            <input
              type={"file"}
              onChange={(e) => handleChangePhoto(e)}
              className="input-photo"
            />
          </label>
          <button onClick={handleUpdate} className="btn btn-normal">
            Subir
          </button>{" "}
        </>
      ) : (
        <>
          <button
            className="btn btn-normal"
            onClick={() => setChangePhotoActive(true)}
          >
            {" "}
            Cambiar mi foto
          </button>
        </>
      )}
      <InputText
        title={"Nombre"}
        value={firstName}
        active={modifyActive}
        setValue={setFirstName}
      />
      {/* <h */}
      <InputText
        title={"Apellido"}
        value={lastName}
        active={modifyActive}
        setValue={setLastName}
      />
      <InputText
        title={"Ciudad de residencia"}
        value={ville}
        active={modifyActive}
        setValue={setVille}
      />
      <InputText
        title={"Fecha de nacimiento"}
        value={birthDate}
        active={modifyActive}
        setValue={setBirthDate}
      />
      {/* <button
        className="btn btn-primary"
        onClick={() => {
          modifyActive && console.log("Guardar");
          modifyActive &&
            UpdateUser({
              variables: {
                where: {
                  firebaseId: userAuth?.uid,
                },
                data: {
                  firstName: {
                    set: firstName,
                  },
                  secondName: {
                    set: lastName,
                  },
                  telephone: {
                    set: numberTelephone,
                  },
                  ville: {
                    set: ville,
                  },
                  birthDate: {
                    set: birthDate,
                  },
                },
              },
            });
          setModifyActive(!modifyActive);
        }}
      >
        {modifyActive ? "Guardar" : "Modificar"}
      </button> */}

      <InputText
        title={"Numero de teléfono"}
        value={numberTelephone}
        active={false}
        setValue={setNumberTelephone}
      />
      <InputText
        title={"Correo electronico asociado"}
        value={userAuth?.email}
        active={false}
        setValue={setNumberTelephone}
      />
      {loading && (
        <div className="updating-waiting">
          <img
            src={image}
            alt=""
            height={200}
            width={200}
            className="waiting-image"
          />
          <h3>Subiendo...</h3>
        </div>
      )}
    </div>
  );
};
