import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
} from "@apollo/client";
import { createContext, FC, PropsWithChildren, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { cleanTravel } from "../actions/travel";

export interface ITravelContext {
  createTravel: // | ((
  //     data: MutationFunctionOptions<
  //       PCreateTravelMutation,
  //       Exact<{ data: TravelCreateInput }>,
  //       DefaultContext,
  //       ApolloCache<any>
  //     >
  //   ) => Promise<
  //     FetchResult<
  //       PCreateTravelMutation,
  //       Record<string, any>,
  //       Record<string, any>
  //     >
  //   >)
  () => void;
  deleteTravel: // | ((
  //     options?:
  //       | MutationFunctionOptions<
  //           PDeleteTravelMutation,
  //           Exact<{ where: TravelWhereUniqueInput }>,
  //           DefaultContext,
  //           ApolloCache<any>
  //         >
  //       | undefined
  //   ) => Promise<
  //     FetchResult<
  //       PDeleteTravelMutation,
  //       Record<string, any>,
  //       Record<string, any>
  //     >
  //   >)
  () => void;
}

const TravelContext = createContext<ITravelContext>({
  createTravel: () => {},
  deleteTravel: () => {},
});

export const TravelContextProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [PCreateTravelMutation] = usePCreateTravelMutation({
  //   onCompleted: () =>
  //     Swal.fire({
  //       icon: "success",
  //       title: "Tu viaje fue publicado",
  //     }).then(() => {
  //       dispatch(cleanTravel());
  //       navigate("/");
  //     }),
  //   refetchQueries: "all",
  //   onError: (e) => {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Tu viaje no pudo ser publicado",
  //       text: JSON.stringify(e),
  //     });
  //   },
  // });

  // const [PDeleteTravel] = usePDeleteTravelMutation({
  //   onCompleted: () => {
  //     Swal.fire({
  //       icon: "success",
  //       title: "Tu viaje fue eliminado con exito",
  //     });
  //   },
  //   refetchQueries: "all",
  // });

  const value: ITravelContext = {
    // createTravel: PCreateTravelMutation,
    // deleteTravel: PDeleteTravel,
    createTravel: () => {},
    deleteTravel: () => {},
  };

  return (
    <TravelContext.Provider value={value}>{children}</TravelContext.Provider>
  );
};

export const useTravelContext = () => {
  const context = useContext(TravelContext);
  if (!context) {
    throw Error("useTravelContext must be inside AuthenticationContext");
  }
  return context;
};
