import React, { useState } from "react";
import { Button, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useAuthenticationContext } from "../contexts/AuthenticationContext";
import { useTravelContext } from "../contexts/TravelContext";

import { formatForQuery } from "../helpers/formatForQuery";
import { OneTravel } from "./Travel";

interface MyTravelProps {}

export const MyTravels = () => {
  const [, updateComponent] = useState({});
  const { userAuth, user } = useAuthenticationContext();
  const { deleteTravel } = useTravelContext();

  // const { data, loading, error } = useTravelsQuery({
  //   variables: {
  //     userId: user!.id,
  //   },
  // });

  return (
    <div className="container-information">
      <div className="reservations-container">
        <h3>Mis viajes como conductor</h3>

        <Nav className="travel-tabs" variant="tabs" defaultActiveKey="/home">
          <Nav.Item>
            <Nav.Link className="travel-tabs-link">Proximos</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="travel-tabs-link">Pasados</Nav.Link>
          </Nav.Item>
        </Nav>
        {/* <div className="travel-results-container">
          {!loading ? (
            data && data?.PSearchManyActiveTravelByConductor.length !== 0 ? (
              data?.PSearchManyActiveTravelByConductor.map((item) => {
                return (
                  <OneTravel
                    travel={item as Travel}
                    PDeleteTravel={deleteTravel}
                  />
                );
              })
            ) : (
              <div className="center-items">
                <div className="travel-results">
                  <h5>Parece que no hay ningun viaje...</h5>
                </div>
                <Button>Publicar un viaje</Button>
              </div>
            )
          ) : (
            <div className="travel-results">
              <h5>Cargando</h5>
            </div>
          )}
        </div> */}
        {}
      </div>
    </div>
  );
};
