import { signInWithEmailAndPassword } from "firebase/auth";
import { FormEvent, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { IoIosMail, IoLogoFacebook } from "react-icons/io";
import Swal from "sweetalert2";
import { startRegisterWithEmailPasswordName } from "../../actions/auth";
import {
  signUpWithGoogle,
  signUpWithFacebook,
  auth,
  logOut,
} from "../../api/firebase/firebaseConfig";
import { useAuthenticationContext } from "../../contexts/AuthenticationContext";
import { useForm } from "../../hooks/useForm";
import {
  PCreateUserMutation,
  PCreateUserMutationResult,
} from "../../graphql/generated";

export const RegisterScreen = () => {
  // const { userAuth, refetch } = useAuthenticationContext();

  const [authMode, setAuthMode] = useState<string | null>(null);
  return (
    <>
      {authMode === null && (
        <div className="auth-howto-container">
          <h4>Como te queres inscribir?</h4>
          {/* <button
            className="btn btn-primary auth-howto-btn fb"
            onClick={() => {
              signUpWithFacebook().then(() => {
                // window.location.replace("/");
              });
            }}
          >
            <IoLogoFacebook size={28} className="btn-icon" />
            Continuar con Facebook
          </button>
          <button
            className="btn btn-primary auth-howto-btn google"
            onClick={
              () => ConnectGoogle()
            }
          >
            <FcGoogle size={28} className="btn-icon" />
            Continuar con Google
          </button> */}
          <button
            className="btn btn-primary auth-howto-btn"
            onClick={() => {
              setAuthMode("mail");
            }}
          >
            <IoIosMail size={24} className="btn-icon" />
            Continuar con un email
          </button>
        </div>
      )}
      {authMode === "mail" && <EmailPasswordForm />}
    </>
  );
};

const EmailPasswordForm = () => {
  const { user, userAuth, CreateUser } = useAuthenticationContext();
  const [formValues, handleInputChange] = useForm({
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    password: "",
    confirmationPassword: "",
  });

  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);

  const {
    email,
    password,
    confirmationPassword,
    firstName,
    lastName,
    telephone,
  } = formValues;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    CreateUser({
      onCompleted: async (result: PCreateUserMutation) => {
        if (
          result.PCreateUserResolver.__typename === "CreateUserResultSuccess"
        ) {
          await signInWithEmailAndPassword(auth, email, password);
          setTimeout(() => {
            setShowConfirmationMessage(true);
          }, 2000);
        }
      },
      variables: {
        email: email,
        password: password,
        phoneNumber: telephone,
        firstName: firstName,
        lastName: lastName,
      },
    });

    //   startRegisterWithEmailPasswordName(
    //     email,
    //     password,
    //     firstName,
    //     lastName,
    //     telephone
    //   )
    //     .then(() => {
    //       setTimeout(() => {
    //         setShowConfirmationMessage(true);
    //       }, 2000);
    //     })
    //     .catch((e) => {
    //       Swal.fire({
    //         title: "Oups",
    //         text: e,
    //         icon: "error",
    //       });
    //     });
    // };
  };
  return (
    <div>
      {showConfirmationMessage === false ? (
        <form onSubmit={(e) => handleSubmit(e)} className="auth__form">
          <h3>Inscribite!</h3>
          <div className="d-flex flex-row w-100 mt-8">
            <div className="w-100">
              <h5>Nombre</h5>
              <input
                type="text"
                placeholder="Ingresa tu nombre"
                name="firstName"
                value={firstName}
                onChange={handleInputChange}
                className="auth__input ml-0"
                autoComplete="off"
                title="Nombre"
                required={true}
              />
            </div>
            <div className="w-100">
              <h5>Apellido</h5>
              <input
                type="text"
                placeholder="Ingresa tu apellido"
                name="lastName"
                value={lastName}
                onChange={handleInputChange}
                className="auth__input mr-0"
                autoComplete="off"
                required={true}
              />
            </div>
          </div>
          <div className="w-100">
            <h5>Direccion de correo electronico</h5>
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={email}
              onChange={handleInputChange}
              className="auth__input"
              autoComplete="off"
              required={true}
            />
          </div>
          <div className="w-100">
            <h5>Numero de telefono</h5>
            <input
              type="text"
              placeholder="Telefono"
              name="telephone"
              value={telephone}
              onChange={handleInputChange}
              className="auth__input"
              autoComplete="off"
              required={true}
            />
          </div>
          <div className="w-100">
            <h5>Contraseña</h5>
            <input
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={handleInputChange}
              className="auth__input"
              required={true}
            />
          </div>
          <div className="w-100">
            <h5>Confirmar contraseña</h5>
            <input
              type="password"
              placeholder=" Confirmation password"
              name="confirmationPassword"
              value={confirmationPassword}
              onChange={handleInputChange}
              className="auth__input"
              required={true}
            />{" "}
          </div>

          <button type="submit" className="btn btn-primary btn-block">
            Registrate !
          </button>
        </form>
      ) : (
        <div>
          <h3 className="mb-2 center">
            Felicitaciones, tu cuenta fue creada con exito!
          </h3>
          <h5 className="mb-5">
            {" "}
            Te enviamos un correo electronico a {email} para confirmar que la
            direccion de correo electronico es correcta
          </h5>

          <h6 className="mb-5">
            Para poder activar tu cuenta, te pediremos tambien que confirmes tu
            numero de telefono
          </h6>
          <button
            className="btn btn-primary center"
            onClick={() => {
              window.location.assign("/user/confirm-phone-number");
            }}
          >
            Confirmar mi numero de telefono
          </button>
        </div>
      )}
    </div>
  );
};
