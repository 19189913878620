import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuthenticationContext } from "../../contexts/AuthenticationContext";
import {
  usePSendCodeMutation,
  usePVerifyCodeMutation,
} from "../../graphql/generated";

export const ConfirmPhoneNumberScreen = () => {
  const { user, userAuth, loading } = useAuthenticationContext();

  const [phoneNumber, setPhoneNumber] = useState(user?.telephone);

  const [code, setCode] = useState("");

  const [listenCode, setListenCode] = useState(false);
  console.log(user, userAuth, loading);

  const [SendCode] = usePSendCodeMutation();

  const [VerifyCode] = usePVerifyCodeMutation();

  useEffect(() => {
    setPhoneNumber(user?.telephone);
  }, [user]);

  const handleSendCode = () => {
    phoneNumber &&
      SendCode({
        variables: {
          phoneNumber: phoneNumber!,
        },
      })
        .then((result) => {
          if (
            result.data?.PVerifyPhoneNumber.__typename ===
            "VerifyNumberResultSuccess"
          ) {
            Swal.fire({
              title: "Se envio un codigo a tu numero ",
              text: "Ingresa el codigo para poder verificar tu numero de telefono",
              icon: "success",
            });
            setListenCode(true);
          } else if (
            result.data?.PVerifyPhoneNumber.__typename ===
            "VerifyNumberResultError"
          ) {
            Swal.fire({
              title: "Oups",
              text: result.data.PVerifyPhoneNumber.code,
              icon: "error",
            });
          }
        })
        .catch((e) => {
          Swal.fire({
            title: "Oups",
            text: e,
            icon: "error",
          });
        });
  };

  const handleVerifyCode = () => {
    code &&
      VerifyCode({
        variables: {
          phoneNumber: phoneNumber!,
          code: code,
        },
      })
        .then((result) => {
          if (
            result.data?.PVerifyCodePhoneNumber.__typename ===
            "VerifyNumberCodeResultSuccess"
          ) {
            console.log("succes");
            Swal.fire({
              title: "Tu numero fue verificado con exito",
              text: "Ya podes utilizar la aplicacion",
              icon: "success",
            }).then(() => {
              window.location.assign("/user/my-information");
            });
          } else if (
            result.data?.PVerifyCodePhoneNumber.__typename ===
            "VerifyNumberCodeResultError"
          ) {
            Swal.fire({
              title: "Oups",
              text: result.data.PVerifyCodePhoneNumber.code,
              icon: "error",
            });
          }
          // setListenCode(true);
        })
        .catch((e) => {
          Swal.fire({
            title: "Oups",
            text: e,
            icon: "error",
          });
        });
  };
  return (
    <div className="auth__box-container">
      {!listenCode ? (
        <>
          {" "}
          <h4 className="mb-3">Confirma tu numero de telefono</h4>
          <h6 className="mb-5">
            Tu numero de telefono va a ser utilizado para cualquier cambio
            importante en tu viaje, quedate tranquilo/a no te enviaremos
            publicidad
          </h6>
          <h6>
            Un codigo sera enviado a tu numero de telefono para verificarlo
          </h6>
          <div className="d-flex flex-row auth__input">
            {/* <div className="center ml-2 mr-2">
          <p className="center mb-0 ">+54</p>
        </div> */}
            <input
              className="auth__phone"
              placeholder="Ingresa tu numero de telefono"
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              value={phoneNumber ? phoneNumber : ""}
            />
          </div>
          <button className="btn btn-primary" onClick={() => handleSendCode()}>
            Enviar codigo
          </button>
        </>
      ) : (
        <>
          <h4 className="mb-3">
            Ingresa el codigo que recibiste a {phoneNumber}
          </h4>
          <h6 className="mb-5">
            Tu numero de telefono va a ser utilizado para cualquier cambio
            importante en tu viaje, quedate tranquilo/a no te enviaremos
            publicidad
          </h6>
          <input
            className="auth__input"
            placeholder="Codigo"
            onChange={(e) => {
              setCode(e.target.value);
            }}
            value={code}
          />
          <div onClick={() => handleSendCode()}>
            <Link to="">No recibiste tu codigo? Reenvialo</Link>
          </div>
          <div onClick={() => setListenCode(false)}>
            <Link to="">Cambiar el numero</Link>
          </div>

          <button
            className="btn btn-primary"
            onClick={() => {
              handleVerifyCode();
            }}
          >
            Verificar codigo
          </button>
        </>
      )}
    </div>
  );
};
