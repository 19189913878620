import { Step } from "../reducers/travelReducer";

export interface stepToSend {
  order: number;
  place: string;
  date: Date;
  city: string;
  placeId: string;
  distanceFromOrigin: number;
}
export const formatSteps = (
  steps: Step[],
  startDate: Date,
  directions: google.maps.DirectionsResult
): stepToSend[] => {
  const stepsTo = steps.map((step: Step, index: number) => {
    const time =
      index === 0
        ? Date.parse(startDate as unknown as string)
        : directions.routes[0].legs
            .slice(0, index - 0)
            .reduce(
              (previousValue, currentValue) =>
                previousValue + currentValue.duration!.value * 1000,
              Date.parse(startDate as unknown as string)
            );
    const distance =
      index === 0
        ? 0
        : directions.routes[0].legs
            .slice(0, index - 0)
            .reduce(
              (previousValue, currentValue) =>
                previousValue + currentValue?.distance!.value / 1000,
              0
            );
    return {
      order: index,
      place: step.address,
      date: new Date(time),
      city: step.city,
      placeId: step.placeId,
      distanceFromOrigin: Math.floor(distance),
    };
  });

  return stepsTo;
};

export const formatStepsToPublish = (
  steps: stepToSend[],
  totalPrice: number,
  places: number,
  directReservation: boolean,
  userFirebaseId?: string ,
) => {
  const totalDistance = steps[steps.length - 1].distanceFromOrigin;
  console.log('jhsdbfsd')
  const data = {
    data: {
      conductorUser: {
        connect: {
          firebaseId: userFirebaseId,
        },
      },
      places: Number(places),
      totalPrice: Number(totalPrice),
      totalDistance: Number(totalDistance),
      directReservation: directReservation,
      startHour: steps[0].date,
      traject: {
        create: [
          {
            steps: {
              createMany: {
                data: steps,
              },
            },
          },
        ],
      },
    },
  };

  return data;
};
