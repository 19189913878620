import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from "react";

export interface IQuestionContext {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>,
}

const QuestionContext = createContext<IQuestionContext>({
  open: false,
  setOpen: () => {},
});

export const QuestionContextProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const value = {
    open,
    setOpen,
  };
  return <QuestionContext.Provider value={value}>{children}</QuestionContext.Provider>;
};

export const useQuestionContext = (): IQuestionContext => {
  const context = useContext(QuestionContext);
  if (!context) {
    throw Error("useAppContext must be inside AppContext");
  }
  return context;
};
