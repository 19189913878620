import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

export enum envType {
  PRINCIPAL,
  BACKOFFICE,
}

export interface IAppContext {
  mode: "app" | "preApp";
  environment?: envType;
}

const AppContext = createContext<IAppContext>({
  mode: "preApp",
  environment: envType.PRINCIPAL,
});

export const AppContextProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  let mode: "app" | "preApp" | "backoffice" = "preApp";

  const [env, setEnv] = useState<envType>(envType.PRINCIPAL);

  useEffect(() => {
    if (window.location.host.split(".").includes("backoffice")) {
      setEnv(envType.BACKOFFICE);
    }
    console.log({ host: window.location.host });
  }, [window.location.host]);

  const value = {
    mode,
    environment: env,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = (): IAppContext => {
  const context = useContext(AppContext);
  if (!context) {
    throw Error("useAppContext must be inside AppContext");
  }
  return context;
};
