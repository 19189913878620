interface TextInputProps {
  value?: string | null;
  setValue: (a: string) => void;
  label?: string;
  mode?: "input" | "textarea";
  disabled?: boolean;
  placeholder?: string;
  multiline?: boolean;
}

export const TextInput = ({
  label,
  value,
  setValue,
  disabled,
  placeholder,
  multiline,
  mode = "input",
}: TextInputProps) => {
  return (
    <div className={`input-container`}>
      <h5>{label}</h5>
      {mode === "input" ? (
        <input
          disabled={disabled}
          className={`input ${disabled ? "input-disabled" : ""}`}
          placeholder={placeholder}
          value={value ?? undefined}
          onChange={(e) => setValue(e.target.value)}
          aria-multiline={multiline}
        />
      ) : (
        <textarea
          disabled={disabled}
          className={`input ${disabled ? "input-disabled" : ""}`}
          placeholder={placeholder}
          value={value ?? undefined}
          onChange={(e) => setValue(e.target.value)}
          aria-multiline={multiline}
        />
      )}
    </div>
  );
};
