import { useEffect, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import markerIcon from "../assets/images/MarkerLogo (6).png";

interface AppMapProps {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
  directions?: google.maps.DirectionsResult;
}
export const AppMap = ({ center, zoom, directions }: AppMapProps) => {
  const [mapLoad, setMapLoad] = useState<google.maps.Map | undefined>();
  const [polylineSelected, setPolylineSelected] = useState();
  const [directionsRendered, setDirectionsRendered] = useState<
    google.maps.DirectionsRenderer | undefined
  >();
  const [polylines, setPolylines] = useState<google.maps.Polyline[]>([]);

  useEffect(() => {
    directions
      ? directions.routes.forEach((route, index) => {
          const polyline = new google.maps.Polyline({
            path: route.overview_path,
            map: mapLoad,
            strokeWeight: index === polylineSelected ? 7 : 3,
            strokeColor: index === polylineSelected ? "#f0533b" : `#0e747c`,
            visible: true,
          });
          polyline.addListener("click", () => {
            polylines.forEach((polyline) => {
              polyline.setMap(null);
            });
            setPolylines([]);
            setPolylineSelected(index as unknown as undefined);
          });
          polylines.push(polyline);
        })
      : polylines.forEach((poly) => {
          console.log("cleaning...");
          poly.setOptions({
            visible: false,
          });
        });
  }, [polylineSelected, directions, mapLoad, polylines]);

  useEffect(() => {
    if (directions) {
      console.log("cargando direciones..", directions);
      setDirectionsRendered(
        new google.maps.DirectionsRenderer({
          directions: directions,
          hideRouteList: true,
          map: mapLoad,
          suppressPolylines: true,
          markerOptions: {
            icon: markerIcon,
          },
        })
      );
    } else {
      if (directionsRendered) {
        console.log("borrando...", directionsRendered);

        for (const key in polylines) {
          polylines[key].setVisible(false);
        }

        directionsRendered.setMap(null);
        directionsRendered.setOptions({
          suppressPolylines: false,
          markerOptions: {
            icon: null,
          },
        });
        setDirectionsRendered(undefined);
      }
    }
  }, [directions]);

  console.log("appear", mapLoad);

  return (
    <GoogleMap
      center={center}
      onLoad={(map) =>
        setTimeout(() =>
          setMapLoad(map as unknown as undefined)
        ) as unknown as void
      }
      zoom={zoom}
      mapContainerStyle={{
        position: "relative",
        width: "100%",
        height: "100%",
        zIndex: 2,
      }}
    >
      {mapLoad && <Marker icon={markerIcon} position={center} />}
    </GoogleMap>
  );
};
