export const searchGeneralOptions = {
  types: ["(cities)"],
  componentRestrictions: {
    country: "ar",
  },
};

export const ParisCoord = { lat: 48.8566, lng: 2.35222 };

export const ArgCoord = { lat: -34.61, lng: -58.37 };
