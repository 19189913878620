import React, { FC } from "react";
import { Button } from "react-bootstrap";
import { InputSearch } from "./InputSearch";

interface SearchHomeProps {
  orientation?: "horizontal" | "vertical";
  setOrigin?: any;
  setDestination?: any;
  setDate?: any;
  origin?: any;
  destination?: any;
  date?: any;
  onPressButton?: any;
}

export const SearchHome: FC<SearchHomeProps> = ({
  orientation,
  setDate,
  setDestination,
  setOrigin,
  date,
  origin,
  destination,
  onPressButton
}) => {
  return (
    <div
      className={`home-search ${
        orientation === "horizontal" && "home-search-horizontal"
      }`}
    >
      <InputSearch
        title="Origen"
        value={origin}
        setValue={setOrigin}
        placeholder="Ingrese una ciudad"
      />
      <InputSearch
        title="Destino"
        value={destination}
        setValue={setDestination}
        placeholder="Ingrese una ciudad"
      />
      <InputSearch
        title="Cuando ?"
        type="datetime-local"
        value={date}
        placeholder="Ingrese la fecha"
        setValue={setDate}
      />
      <InputSearch title="Pasajeros" placeholder="1" type="number" />
      <Button onClick={onPressButton} className="home-search-button">Buscar</Button>
    </div>
  );
};
