import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { forwardRef, useEffect, useMemo, useState } from "react";
import {
  Travel,
  useTravelsAdminLazyQuery,
  useTravelsAdminQuery,
  useUpdateAppBasicContentMutation,
  AppContentType,
  useGetLatestAppBasicContentQuery,
} from "../../../graphql/generated";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";

import { ContentState, convertToRaw, EditorState } from "draft-js";
import { melToolbar } from "../../HtmlEditor/toolbar";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { TextInput } from "../../commons/TextInput";

const TermsOfUseScreen = ({
  type = AppContentType.TermsOfUse,
}: {
  type: AppContentType;
}) => {
  const htmlToEditorState = (html: string): EditorState => {
    const value = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(
      value.contentBlocks,
      value?.entityMap
    );
    return EditorState.createWithContent(contentState);
  };

  const {
    data: dataTerms,
    loading: loadingTerms,
    error: errorTerms,
    refetch,
  } = useGetLatestAppBasicContentQuery({
    variables: {
      type,
    },
  });

  const [html, setHtml] = useState<EditorState>(htmlToEditorState(""));

  const [htmlBrut, setHtmlBrut] = useState<string>("");

  useEffect(() => {
    if (dataTerms) {
      setHtml(
        htmlToEditorState(dataTerms?.getLatestAppBasicContent.content.html)
      );
    }
  }, [dataTerms?.getLatestAppBasicContent]);

  const [updateTerms, { data, loading, error }] =
    useUpdateAppBasicContentMutation();

  const onSubmit = async () => {
    console.log("here", {
      html: draftToHtml(convertToRaw(html.getCurrentContent())),
    });
    await updateTerms({
      variables: {
        html: draftToHtml(convertToRaw(html.getCurrentContent())),
        type,
      },

      onCompleted(data) {
        refetch();
        Swal.fire({
          title: "Tu cambio fue guardado. ",
          text: "¿Queres descargarlas?",
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
              JSON.stringify({
                type: data.updateAppBasicContent.type,
                content: data.updateAppBasicContent.content,
              })
            )}`;
            const link = document.createElement("a");
            link.href = jsonString;
            link.download = "faq.json";

            link.click();
          }
        });
      },
      onError(error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
        });
      },
    });
  };
  const onSubmitBrut = async () => {
    // console.log("here", {
    //   html: draftToHtml(convertToRaw(html.getCurrentContent())),
    // });
    await updateTerms({
      variables: {
        html: htmlBrut,
        type,
      },

      onCompleted(data) {
        refetch();
        Swal.fire({
          title: "Tu cambio fue guardado. ",
          text: "¿Queres descargarlas?",
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
              JSON.stringify({
                type: data.updateAppBasicContent.type,
                content: data.updateAppBasicContent.content,
              })
            )}`;
            const link = document.createElement("a");
            link.href = jsonString;
            link.download = "faq.json";

            link.click();
          }
        });
      },
      onError(error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
        });
      },
    });
  };

  useEffect(() => {
    window.scrollTo({ top: -0 });
  }, [type]);

  if (loadingTerms) {
    return <>Loading...</>;
  }

  return (
    <div
      style={{
        zIndex: 100,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          marginBottom: 24,
        }}
      >
        <h3>
          {type === AppContentType.TermsOfUse
            ? "Términos y condiciones"
            : type === AppContentType.ConfidentialityPolicy
            ? "Politicas de privacidad"
            : ""}
        </h3>
      </div>
      <Editor
        editorState={html}
        toolbar={melToolbar}
        // toolbar={{
        //   inline: { inDropdown: true },
        //   list: { inDropdown: true },
        //   textAlign: { inDropdown: true },
        //   link: { inDropdown: true },
        //   history: { inDropdown: true },
        // }}
        editorStyle={{
          padding: "0px 10px 10px",
          height: "400px",
          backgroundColor: "white",
        }}
        onEditorStateChange={setHtml}
      />

      <Button className="btn btn-normal" onClick={onSubmit}>
        Guardar
      </Button>

      <TextInput value={htmlBrut} setValue={setHtmlBrut} />

      <Button className="btn btn-normal" onClick={onSubmitBrut}>
        Guardar bruto
      </Button>
    </div>
  );
};

export const AppContentScreenEditor = forwardRef(TermsOfUseScreen);
