import React, { useState } from "react";
import Swal from "sweetalert2";
import { resetPassword } from "../../api/firebase/firebaseConfig";

export const ResetPasswordScreen = () => {
  const [email, setEmail] = useState("");

  const handleResetPassword = () => {
    resetPassword(email)
      .then(() => {
        Swal.fire({
          icon: "success",
          title:
            "Te enviamos un mail con  las instrucciones para cambiar tu contrasena",
        }).then(() => {
          window.location.assign("/");
        });
      })
      .catch((e) => {
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Oups",
          text: e,
        });
      });
  };
  return (
    <>
      <h4 className="auth__title">Reinicia tu contraseña</h4>
      <h6 className="mb-5">
        Para reiniciar tu contraseña te enviaremos un correo electronico
      </h6>
      <h5>Email</h5>
      <input
        type="text"
        placeholder="Email"
        name="email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        className="auth__input mb-5"
        autoComplete="off"
      />
      <button className="btn btn-primary center" onClick={handleResetPassword}>
        Enviar
      </button>
    </>
  );
};
