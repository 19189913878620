import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { CreditCardForm } from "./CreditCardForm";
import { IoIosCash } from "react-icons/io";
import { FcAdvance, FcPrivacy } from "react-icons/fc";
import { DateTime } from "luxon";
import { start } from "repl";

export const PayTravel = () => {
  const navigate = useNavigate();
  const [succes, setSucces] = useState();
  const [cardVisible, setCardVisible] = useState(false);
  const { travelId } = useParams();
  const { reservationId } = useParams();

  // const { data, loading, error, startPolling } = useGetReservationToPayQuery({
  //   variables: { where: { id: { equals: reservationId } } },
  //   pollInterval: 500,
  // });

  // startPolling(5000);

  // const [payReservation] = usePayReservationMutation({
  //   onCompleted: () => {
  //     Swal.fire({
  //       icon: succes ? "success" : "error",
  //       title: succes ? "Tu pago fue aceptado" : "Tu pago fue aceptado",
  //       text: succes
  //         ? "El conductor debe aceptar su reservacion"
  //         : "Vuelve a intentarlo",
  //     }).then(() => {
  //       succes ? navigate(`/user/my-reservations`) : setCardVisible(false);
  //     });
  //   },
  //   refetchQueries: "all",
  // });

  const acceptPayReservation = () => {
    // reservationId &&
    //   payReservation({
    //     variables: {
    //       status: { set: ReservationPaymentStatus.Accepted },
    //       reservationId: reservationId,
    //     },
    //   })
    //     .then(() =>
    //       Swal.fire({
    //         icon: "success",
    //         title: "Tu reservacion fue pagada con exito",
    //       }).then(() => {
    //         navigate("/");
    //       })
    //     )
    //     .catch((e) => {
    //       Swal.fire({
    //         icon: "error",
    //         title: "No se pudo procesar tu pago",
    //         text: e,
    //       });
    //     });
  };

  const refusePayReservation = () => {
    // reservationId &&
    //   payReservation({
    //     variables: {
    //       status: { set: ReservationPaymentStatus.Refused },
    //       reservationId: reservationId,
    //     },
    //   });
  };

  // const showCreditCard = () => {
  //   setCardVisible(true);
  // };

  // const startDate = DateTime.fromISO(
  //   data?.reservations[0].directFragment.startStep.date
  // )
  //   .setLocale("es")
  //   .toLocaleString({
  //     weekday: "long",
  //     month: "long",
  //     day: "2-digit",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //   })
  //   .toUpperCase();

  // const endDate = new Date(data?.reservations[0].directFragment.endStep.date)
  //   .toLocaleDateString("es-ES", {
  //     year: "numeric",
  //     month: "long",
  //     day: "2-digit",
  //   })
  //   .toLocaleUpperCase();
  // useEffect(() => {
  //   console.log(data?.reservations[0]);
  //   data?.reservations[0].Status === "ABORTEDBYTIMEOUT" &&
  //     Swal.fire({
  //       icon: "error",
  //       title: "Se te acabo el tiempo",
  //       text: "Vuelve a comenzar el proceso de reservacion",
  //       timer: 3000,
  //     });
  //   setTimeout(() => {
  //     if (data?.reservations[0].Status === "ABORTEDBYTIMEOUT") {
  //       navigate("/");
  //     }
  //   }, 3000);
  // }, [data?.reservations[0].Status]);

  // console.log(data?.reservations);
  return (
    <div className="payTravel-container">
      {/* <h6>
        Recorda que tenés hasta las{" "}
        {DateTime.fromISO(data?.reservations[0].createdAt)
          .plus({ minutes: 15 })
          .toLocaleString({
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          })}{" "}
        horas para completar tu reservacion
      </h6>
      {!cardVisible ? (
        <div className="payTravel-card">
          <div className="home-input-title">Reservacion</div>
          <h5>Verifica los datos del viaje antes de pagar</h5>
          <div className="payTravel-container-information">
            <div className="search-travel-information-title">
              {data && data.reservations[0].directFragment.startStep.city}{" "}
              <FcAdvance />{" "}
              {data && data.reservations[0].directFragment.endStep.city}
            </div>
            <div className="search-travel-information-title">{startDate}</div>
            <div className="search-travel-information-title">Conductor </div>
            {data &&
              data.reservations[0].directFragment.Traject?.Travel.conductorUser
                .firstName}
            <div className="search-travel-information-title">Precio </div>
            {data && data.reservations[0].directFragment.price} $
          </div>
          <Button className="payTravel-button" onClick={showCreditCard}>
            Continuar al pago!{" "}
          </Button>
        </div>
      ) : (
        <>
          {data?.reservations[0].Status === "PAYMENTWAITING" && (
            <div className="payTravel-card">
              <CreditCardForm />
              <Button
                className="payTravel-button-pay"
                onClick={acceptPayReservation}
              >
                <div className="payTravel-button-icon">
                  <FcPrivacy size={32} />
                </div>
                Pagar {"  "}
              </Button>
            </div>
          )}
        </>
      )}
      <Button onClick={refusePayReservation}>Pago rechazado</Button> */}
    </div>
  );
};
