import { useEffect, useState } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { store } from "../../store/store";
import { Step } from "../../reducers/travelReducer";
import { TravelSummary } from "../TravelSummary";
import { AppMap } from "../Map";
import { formatSteps, formatStepsToPublish } from "../../helpers/formatSteps";
import { AddTravelStepWrapper } from "../AddTravelStepWrapper";
import { useForm } from "../../hooks/useForm";
import { ArgCoord } from "../../settings/searchSettings";
import { useTravelContext } from "../../contexts/TravelContext";
import { useAuthenticationContext } from "../../contexts/AuthenticationContext";

export const AddTravelScreen = () => {
  const [, upadteComponent] = useState({});
  const [center, setCenter] = useState(ArgCoord);
  const [zoom, setZoom] = useState(6);

  const [stepCreate, setStepCreate] = useState(0);

  const [valuesForm, setValuesForm, reset] = useForm({
    date: new Date(Date.now()),
    prix: 0,
    places: 0,
    directReservation: false,
    directionsResponse: undefined,
  });

  const [viewMap, setViewMap] = useState(true);
  const [directReservation, setDirectReservation] = useState(false);
  const [prix, setPrix] = useState<number>(0);

  const [directionsResponse, setDirectionsResponse] =
    useState<google.maps.DirectionsResult>();
  const [, setDistance] = useState<number | undefined>();

  const { createTravel } = useTravelContext();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries: ["places"],
  });
  let steps: Step[] = useSelector((state) => (state as any).travel.steps);

  // const user = useSelector((state) => (state as any).auth);

  const { user } = useAuthenticationContext();
  store.subscribe(() => {
    upadteComponent({});
  });

  const calculateRoute = async () => {
    const directionsService = new google.maps.DirectionsService();
    const waypoints = steps.map((step: Step) => {
      const position = new google.maps.LatLng(step.latitude, step.longitude);
      return { location: position };
    });
    const results = await directionsService.route({
      origin: waypoints.shift()!.location,
      destination: waypoints.pop()!.location,
      waypoints: waypoints,
      travelMode: google.maps.TravelMode.DRIVING,
      provideRouteAlternatives: true,
      optimizeWaypoints: true,
    });
    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance?.value! / 1000);
    setPrix(
      ((results.routes[0].legs[0].distance?.value! / 1000) * 0.25).toFixed(
        0
      ) as unknown as number
    );
  };

  useEffect(() => {
    if (steps.length > 1) {
      calculateRoute();
      console.log("Calculating route...");
    } else {
      setDirectionsResponse(undefined);
    }

    return () => {};
  }, [steps.length]);

  const handlePublish = async () => {
    const stepsToSend = formatSteps(
      steps,
      valuesForm.date,
      directionsResponse!
    );

    if (user) {
      const data = formatStepsToPublish(
        stepsToSend,
        prix,
        Number(valuesForm.places),
        directReservation,
        user?.firebaseId as string
      );
      console.log(data);
      // createTravel({ variables: data });
    } else {
      throw new Error("Debes conectarte para publicar un viaje");
    }
  };

  if (!isLoaded) {
    return <div>Cargando...</div>;
  }

  const handleOnBack = () => {
    setStepCreate(stepCreate - 1);
  };
  const handleOnNext = () => {
    setStepCreate(stepCreate + 1);
  };

  return (
    <div className=" add-travel">
      <div className="add-travel-maps-container">
        <div className={`add-travel-header  `}>
          <AddTravelStepWrapper
            step={stepCreate}
            onBack={handleOnBack}
            onNext={handleOnNext}
            valuesForm={valuesForm}
            setValuesForm={setValuesForm}
            setCenter={setCenter}
            setZoom={setZoom}
            setViewMap={setViewMap}
            setDirectReservation={setDirectReservation}
            directReservation={directReservation}
          />
          {stepCreate === 3 && (
            <div>
              <h5> Precio por lugar : </h5>
              <input
                type="number"
                placeholder="Lugares"
                name="Lugares"
                value={prix}
                onChange={(e) => {
                  setPrix(Number(e.target.value));
                }}
                className="home-input"
              />
            </div>
          )}
        </div>

        <div className="add-travel-map-self ">
          {viewMap && (
            <AppMap
              center={center}
              zoom={zoom}
              directions={directionsResponse}
            />
          )}
        </div>

        {stepCreate !== 0 && (
          <div className=" add-travel-summary-maps ">
            <TravelSummary
              steps={steps}
              places={valuesForm.places}
              startDate={valuesForm.date}
              price={prix}
              progress={stepCreate}
            />
            {stepCreate > 3 && (
              <button className="btn btn-primary" onClick={handlePublish}>
                Publicar
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
