import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
} from "firebase/auth";

import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import Swal from "sweetalert2";

// Your web app's Firebase configuration
console.log("credentias", process.env.REACT_APP_GOOGLE_CREDENTIALS);
const firebaseConfig = JSON.parse(process.env.REACT_APP_GOOGLE_CREDENTIALS);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const signUp = async (email, password) => {
  return await createUserWithEmailAndPassword(auth, email, password);
};

export const signIn = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password);
};

export const logOut = async () => {
  await signOut(auth);
};

export const storage = getStorage();

export const uploadPhoto = async (file, currentUser) => {
  const fileRef = ref(storage, currentUser.uid + ".png");
  const URL = await uploadBytes(fileRef, file).then(async (e) => {
    return await getDownloadURL(fileRef).then(async (photoURL) => {
      console.log(photoURL);

      await updateProfile(currentUser, { photoURL });
    });
  });

  return await getDownloadURL(fileRef);
};
// export function useAut uploc() {
//   const [currentUser, setCurrentUser] = useState("");

//   useEffect(() => {
//     const currentUser = onAuthStateChanged(auth, (user) => {
//       if (user) {
//         setCurrentUser(user);
//         localStorage.setItem("authorization", user.accessToken)
//       } else {
//         setCurrentUser("No user connected");
//         localStorage.removeItem("authorization")
//       }
//     });
//   }, []);

//   return currentUser;
// }

const googleProvider = new GoogleAuthProvider();

const facebookProvider = new FacebookAuthProvider();

export const signUpWithGoogle = async () => {
  return await signInWithPopup(auth, googleProvider)
    .then(async (result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = await auth.currentUser.getIdToken();
      localStorage.setItem("authorization", token);

      return { token: token, userEmail: result.user.email };
    })
    .catch((e) => {
      return e;
    });
};

export const signInWithGoogle = async () => {
  return await signInWithPopup(auth, googleProvider);
};

export const signUpWithFacebook = async () => {
  await signInWithPopup(auth, facebookProvider)
    .then(async (result) => {
      const credential = FacebookAuthProvider.credentialFromResult(result);
      const token = await auth.currentUser.getIdToken();
      console.log(await auth.currentUser.getIdToken(), credential);
      localStorage.setItem("authorization", token);
      const response = await fetch("http://localhost:8080/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({
          query: `mutation PCreateUser($data: UserCreateInput!) {
                PCreateUserResolver(data: $data) {
                  id
                  email
                  role
                  firebaseId
                }
              }`,
          operationName: "PCreateUser",
          variables: {
            data: {
              email: "e.user.email!",
              firebaseId: "e.user.uid",
            },
          },
        }),
      });

      console.log(response);
    })
    .catch((e) => console.log(e));
};

export const signInWithFacebook = async () => {
  return await signInWithPopup(auth, facebookProvider);
};

export const resetPassword = async (email) => {
  return await sendPasswordResetEmail(auth, email, {
    url: "http://localhost:3000/auth/login",
  });
};
