import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-links">
        <div className="footer-column">
          <Link className="navbar-link" to="/">
            <div className="footer-link">Propuesta</div>
          </Link>
        </div>
        <div className="footer-column">
          <Link
            className="footer-link"
            to="mailto:contacto@mellevas.app?subject=Formar parte de Me Llevás ?"
          >
            <div className="footer-link">Forma parte de Me Llevás ?</div>
          </Link>
          <div className="footer-link">
            <Link
              className="footer-link"
              to="mailto:contacto@mellevas.app?subject=Inversionistas"
            >
              Inversionistas
            </Link>
          </div>
        </div>
        <div className="footer-column">
          <div className="footer-link">
            {" "}
            <Link className="footer-link" to="/faq">
              Preguntas frecuentes
            </Link>
          </div>
        </div>

        <div className="footer-column">
          <Link className="footer-link" to="/terms-of-use">
            Terminos y condiciones
          </Link>
          <Link className="footer-link" to="/privacity">
            <div className="footer-link">Politicas de privacidad</div>
          </Link>
        </div>
      </div>
      <div className="footer-informations">
        <div className="footer-text-info">Chaine, 2023</div>
        <div className="footer-text-info">contacto@chaine.ar</div>
      </div>
    </div>
  );
};
