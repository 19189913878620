import { onAuthStateChanged, User } from "firebase/auth";
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { auth } from "../api/firebase/firebaseConfig";
// import {
//   Conversation,
//   Message,
//   useCreateConversationMutation,
//   useGetConversationsQuery,
//   useGetMessagesQuery,
//   usePMarkMessageMutation,
//   useSendMessageMutation,
// } from "../graphql/generated";

export interface IConversationContext {
  sendMessage: any;
  markMessage: any;
  createConversation: any;
  searchConversation: any;
  searchMessages: any;
  // conversations: Conversation[];
  conversations: any[];
  subscribeToConversation: any;
  unsubscribeToConversation: any;
  // messages: Message[];
  messages: any[];
  subscribeToMessages: any;
  unsubscribeToMessages: any;
}

const ConversationContext = createContext<IConversationContext>({
  sendMessage: () => {},
  markMessage: () => {},
  createConversation: () => {},
  searchConversation: () => {},
  searchMessages: () => {},
  conversations: [],
  subscribeToConversation: () => {},
  unsubscribeToConversation: () => {},
  messages: [],
  subscribeToMessages: () => {},
  unsubscribeToMessages: () => {},
});

export const ConversationContextProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [conversations, setConversations] = useState<any | null>([]);

  const [messages, setMessages] = useState<any | null>([]);

  // const [sendMessage] = useSendMessageMutation();

  // const [seenMessage] = usePMarkMessageMutation();

  // const [createConversation] = useCreateConversationMutation();

  //@ts-ignore
  // const conversationQuery = useGetConversationsQuery();
  // //@ts-ignore
  // const messagesQuery = useGetMessagesQuery();

  // const subscribeToConversation = (searchConversationVariables: any) => {
  //   conversationQuery
  //     .refetch(searchConversationVariables)
  //     .then((result) =>
  //       setConversations(result.data.PSearchConversationsByUser)
  //     );
  //   conversationQuery.startPolling(500);
  // };

  // const unsubscribeToConversation = () => {
  //   setConversations([]);
  //   conversationQuery.stopPolling();
  // };

  // const subscribeToMessages = (searchMessagesVariables: any) => {
  //   messagesQuery.refetch(searchMessagesVariables).then((result) => {
  //     setMessages(result.data.PSearchConversationById.messages);
  //   });
  //   messagesQuery.startPolling(500);
  // };

  // const unsubscribeToMessages = () => {
  //   setMessages([]);
  //   messagesQuery.stopPolling();
  // };
  const value = {
    // sendMessage,
    // markMessage: seenMessage,
    markMessage: () => {},
    sendMessage: () => {},
    // createConversation,
    // searchMessages: messagesQuery.refetch,
    // searchConversation: conversationQuery.refetch,
    searchConversation: () => {},
    searchMessages: () => {},
    // messages: messagesQuery.data?.PSearchConversationById
    //   ? (messagesQuery.data.PSearchConversationById.messages as Message[])
    //   : ([] as Message[]),
    messages: [],
    conversations: [],
    // conversations: conversationQuery.data
    //   ? (conversationQuery.data.PSearchConversationsByUser as Conversation[])
    //   : ([] as Conversation[]),
    // subscribeToConversation,
    createConversation: () => {},
    subscribeToConversation: () => {},
    unsubscribeToConversation: () => {},
    subscribeToMessages: () => {},
    unsubscribeToMessages: () => {},
  };
  return (
    <ConversationContext.Provider value={value}>
      {children}
    </ConversationContext.Provider>
  );
};

export const useConversationContext = (): IConversationContext => {
  const context = useContext(ConversationContext);
  if (!context) {
    throw Error("useConversationContext must be inside ConversationContext");
  }
  return context;
};
