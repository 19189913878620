import { useEffect, useMemo, useRef, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  AppFaqContentCategoryInput,
  AppFaqContentPostInput,
  useGetLatestAppFaqContentQuery,
  useUpdateAppFaqContentMutation,
} from "../../../graphql/generated";
import { Button } from "react-bootstrap";
import { TextInput } from "../../commons/TextInput";
import Select from "react-select";
import Swal from "sweetalert2";

export const FaqScreen = () => {
  return (
    <div className="faq-container">
      <Routes>
        <Route path="/" element={<FaqMain />} />
        <Route path="edit-posts" element={<FaqContentEditPosts />} />
        <Route
          path="edit-categories"
          element={<AppFaqContentEditCategories />}
        />
      </Routes>
    </div>
  );
};

const LINKS = [
  {
    path: "edit-posts",
    label: "Editar los articulos",
  },
  {
    path: "edit-categories",
    label: "Modificar las categorias",
  },
];

export const FaqMain = () => {
  const navigate = useNavigate();

  return (
    <div className="faq-main">
      {LINKS.map((link) => (
        <Button className="btn btn-normal" onClick={() => navigate(link.path)}>
          {link.label}
        </Button>
      ))}
    </div>
  );
};

export const FaqContentEditPosts = () => {
  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useGetLatestAppFaqContentQuery({ fetchPolicy: "no-cache" });

  const [
    updateAppFaqContentMutation,
    { loading: mutationLoading, error: mutationError },
  ] = useUpdateAppFaqContentMutation();

  const firstRender = useRef(true);

  const content = useMemo(() => {
    return queryData?.getLatestAppFaqContent.content;
  }, [queryData]);

  let postsArray = useMemo(() => {
    const previousPosts = content?.categories.flatMap((category, index) =>
      category.posts.map((post) => ({
        ...post,
        categoryIndex: index,
      }))
    );

    const newEmptyPost: AppFaqContentPostInput & { categoryIndex: number } = {
      title: "",
      content: "",
      categoryIndex: 0,
    };

    return previousPosts ? [...(previousPosts ?? []), newEmptyPost] : undefined;
  }, [content]);

  const [posts, setPosts] = useState(postsArray);
  const [title, setTitle] = useState(
    queryData?.getLatestAppFaqContent.content.title
  );

  useEffect(() => {
    if (firstRender.current && postsArray) {
      setPosts(postsArray);
      setTitle(queryData?.getLatestAppFaqContent.content.title);
      firstRender.current = false;
    }
  }, [postsArray]);

  const saveChanges = ({
    title,
    content,
    categoryIndex,
    key,
  }: AppFaqContentPostInput & {
    categoryIndex: number;
    key: number;
  }) => {
    setPosts((ps) =>
      ps?.map((p, index) => {
        if (index === key) return { title, content, categoryIndex };
        return p;
      })
    );
  };

  const renderPosts = useMemo(() => {
    console.log("recalculating posts");
    return () =>
      posts?.map((post, index) => (
        <AppFaqContentEditSinglePost
          post={post}
          save={saveChanges}
          index={index}
          categoryChoices={content?.categories.map((category, index) => ({
            name: category.title,
            id: index,
          }))}
        />
      ));
  }, [posts]);

  if (queryLoading) {
    return <div>Cargando...</div>;
  }

  if (queryError) {
    return <div>Error servidor</div>;
  }

  const onSubmit = async (
    data: Record<string, object | string>
  ): Promise<void> => {
    const latestFaq = queryData?.getLatestAppFaqContent;
    const { posts } = data as {
      posts: (AppFaqContentPostInput & { categoryIndex: number })[];
    };

    const newCategories = latestFaq?.content.categories.map((category) => ({
      ...category,
      posts: [] as AppFaqContentPostInput[],
      __typename: undefined,
    }));

    posts
      .filter((post) => post.title)
      .forEach(({ title, subtitle, content, categoryIndex }) => {
        console.log(
          { title, subtitle, content, categoryIndex },
          newCategories?.[categoryIndex]
        );
        newCategories?.[categoryIndex].posts.push({
          title,
          subtitle,
          content,
        });
      });

    if (!newCategories) return;

    await updateAppFaqContentMutation({
      variables: {
        categories: newCategories,
        title: title === "" ? "Preguntas frecuentes" : title,
      },
      onCompleted(data) {
        Swal.fire({
          title: "Las faq fueron modificadas. ",
          text: "¿Queres descargarlas?",
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
              JSON.stringify({
                title: title === "" ? "Preguntas frecuentes" : title,
                categories: newCategories,
              })
            )}`;
            const link = document.createElement("a");
            link.href = jsonString;
            link.download = "faq.json";

            link.click();
          }
        });
      },
      onError(error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
        });
      },
    });
  };

  return (
    <div className="faq-main">
      <div className="faq-alert">
        <h3>Atencion! toda pregunta sin titulo sera borrada</h3>
      </div>
      <TextInput
        label="Titulo de la sección"
        setValue={(e) => {
          setTitle(e);
        }}
        value={title}
        multiline
      />
      {renderPosts()}{" "}
      <Button
        className="btn btn-normal"
        onClick={() => (posts ? onSubmit({ posts }) : {})}
      >
        Guardar
      </Button>
      <Button
        className="btn btn-normal"
        onClick={() => {
          const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(queryData?.getLatestAppFaqContent.content)
          )}`;
          const link = document.createElement("a");
          link.href = jsonString;
          link.download = "faq.json";

          link.click();
        }}
      >
        Descargar version actual
      </Button>
    </div>
  );
};

const AppFaqContentEditSinglePost = ({
  post,
  categoryChoices,
  save,
  index,
}: {
  post: AppFaqContentPostInput & {
    categoryIndex: number;
  };

  save: ({
    title,
    content,
    categoryIndex,
    key,
  }: AppFaqContentPostInput & {
    categoryIndex: number;
    key: number;
  }) => void;
  index: number;

  categoryChoices?: { name: string; id: number }[];
}) => {
  const [thisPost, setThisPost] = useState({ ...post, editing: false });
  return (
    <div className="faq-posts">
      <TextInput
        disabled={!thisPost.editing}
        label="Titulo"
        setValue={(e) => {
          setThisPost((p) => ({ ...p, title: e }));
        }}
        value={thisPost.title}
        mode="textarea"
        multiline
      />

      <TextInput
        disabled={!thisPost.editing}
        label="Contenido"
        setValue={(e) => {
          setThisPost((p) => ({ ...p, content: e }));
        }}
        value={thisPost.content}
        mode="textarea"
        multiline
      />
      <Select
        isDisabled={!thisPost.editing}
        options={categoryChoices?.map((category) => ({
          value: category.id,
          label: category.name,
        }))}
        value={{
          value: categoryChoices?.[thisPost.categoryIndex].id,
          label: categoryChoices?.[thisPost.categoryIndex].name,
        }}
        placeholder={"Seleccione une categoria"}
        className="input-select"
        onChange={(value) =>
          value?.value &&
          setThisPost((p) => ({ ...p, categoryIndex: value!.value! }))
        }
      />
      <Button
        className="btn btn-normal"
        onClick={
          thisPost.editing
            ? () => {
                setThisPost((p) => ({ ...p, editing: !p.editing }));
                save({ ...thisPost, key: index });
              }
            : () => setThisPost((p) => ({ ...p, editing: !p.editing }))
        }
      >
        {thisPost.editing ? "Listo" : "Editar"}
      </Button>
    </div>
  );
};

const AppFaqContentEditCategories = () => {
  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useGetLatestAppFaqContentQuery({ fetchPolicy: "no-cache" });

  const [
    updateAppFaqContentMutation,
    { loading: mutationLoading, error: mutationError },
  ] = useUpdateAppFaqContentMutation();

  const firstRender = useRef(true);

  const content = useMemo(() => {
    return queryData?.getLatestAppFaqContent.content;
  }, [queryData]);

  let categoriesArray = useMemo(() => {
    const previousCategories = content?.categories;

    const newEmptyCategory: AppFaqContentCategoryInput = {
      title: "",
      posts: [],
    };

    return previousCategories
      ? [...(previousCategories ?? []), newEmptyCategory]
      : undefined;
  }, [content]);

  const [categories, setCategories] = useState(categoriesArray);
  // const [title, setTitle] = useState(
  //   queryData?.getLatestAppFaqContent.content.title
  // );

  useEffect(() => {
    if (firstRender.current && categoriesArray) {
      setCategories(categoriesArray);

      firstRender.current = false;
    }
  }, [categoriesArray]);

  const saveChanges = ({
    title,
    description,
    key,
  }: AppFaqContentCategoryInput & {
    key: number;
  }) => {
    setCategories((cs) =>
      cs?.map((c, index) => {
        if (index === key) return { title, description, posts: c.posts };
        return c;
      })
    );
  };

  const onSubmit = async (
    data: Record<string, object | string>
  ): Promise<void> => {
    const latestFaq = queryData?.getLatestAppFaqContent;
    const { newCategories } = data as {
      newCategories: AppFaqContentCategoryInput[];
    };

    const newCategoriesInput = categories
      ?.filter((_, index) => newCategories[index].title)
      .map((category, index) => ({
        title: newCategories[index].title,
        description: newCategories[index].description,
        posts: category.posts.map((post) => ({
          ...post,
          __typename: undefined,
        })),
        __typename: undefined,
      }));

    if (!newCategoriesInput) return;

    await updateAppFaqContentMutation({
      variables: {
        categories: newCategoriesInput,
        title: latestFaq?.content.title,
      },
      onCompleted(data) {
        Swal.fire({
          title: "Las categorias fueron modificadas. ",
          text: "¿Queres descargarlas?",
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
              JSON.stringify({
                title: latestFaq?.content.title,
                categories: newCategoriesInput,
              })
            )}`;
            const link = document.createElement("a");
            link.href = jsonString;
            link.download = "faqWithCategories.json";

            link.click();
          }
        });
      },
      onError(error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
        });
      },
    });
  };

  return (
    <>
      <div className="faq-alert">
        <h3>
          Atencion! toda categoria sin titulo sera borrada ( y con ella sus
          preguntas )
        </h3>
      </div>
      {categories?.map((c, index) => (
        <AppFaqContentEditSingleCategory
          category={c}
          save={saveChanges}
          index={index}
        />
      ))}
      <Button
        className="btn btn-normal"
        onClick={() =>
          categories ? onSubmit({ newCategories: categories }) : {}
        }
      >
        Guardar
      </Button>
    </>
  );
};

const AppFaqContentEditSingleCategory = ({
  category,
  save,
  index,
}: {
  category: AppFaqContentCategoryInput;
  save: ({
    title,
    description,
    key,
  }: AppFaqContentCategoryInput & {
    key: number;
  }) => void;
  index: number;
}) => {
  const [thisCategory, setThisCategory] = useState({
    ...category,
    editing: false,
  });
  return (
    <div className="faq-categories">
      <TextInput
        disabled={!thisCategory.editing}
        label="Titulo"
        setValue={(e) => {
          setThisCategory((p) => ({ ...p, title: e }));
        }}
        value={thisCategory.title}
        mode="textarea"
        multiline
      />

      <TextInput
        disabled={!thisCategory.editing}
        label="Descripcion"
        setValue={(e) => {
          setThisCategory((p) => ({ ...p, content: e }));
        }}
        value={thisCategory.description}
        mode="textarea"
        multiline
      />
      <div className="faq-categories-length">
        <h4>{category.posts.length} preguntas</h4>
      </div>
      <Button
        className="btn btn-normal"
        onClick={
          thisCategory.editing
            ? () => {
                setThisCategory((p) => ({ ...p, editing: !p.editing }));
                save({ ...thisCategory, key: index });
              }
            : () => setThisCategory((p) => ({ ...p, editing: !p.editing }))
        }
      >
        {thisCategory.editing ? "Listo" : "Editar"}
      </Button>
    </div>
  );
};
