import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { AddTravelScreen } from "../components/Screens/AddTravelScreen";
import { Home } from "../components/Screens/Home";
import { SearchTravel } from "../components/Screens/SearchTravel";
import { UserScreen } from "../components/Screens/UserScreen";
import { PayTravel } from "../components/PayTravel";
import { TravelExpandBySearch } from "../components/SeeTravel";
import { MapScreen } from "../components/Screens/MapScreen";
import { AuthRouter } from "./AuthRouter";
import { envType, useAppContext } from "../contexts/AppContext";
import { AdminRouter } from "./AdminRouter";
import { Button } from "react-bootstrap";
import { AppContentScreen } from "../components/TermsOfUse";
import { Download } from "../components/Download";
import { HomeBackOfficeScreen } from "../components/Screens/HomeBackOfficeScreen";
import { AppContentType } from "../graphql/generated";
import { FAQScreen } from "../components/Screens/FAQ";

export const MelRouter = () => {
  const { mode, environment } = useAppContext();
  const navigate = useNavigate();
  return (
    <div className="app-container">
      <Routes>
        <Route
          path="/"
          element={
            environment === envType.BACKOFFICE ? (
              <HomeBackOfficeScreen />
            ) : (
              <Home />
            )
          }
        />

        <Route path="/faq" element={<FAQScreen />} />
        <Route path="/faq/:cat?/:question?" element={<FAQScreen />} />
        <Route
          path="/terms-of-use"
          element={<AppContentScreen type={AppContentType.TermsOfUse} />}
        />
        <Route
          path="/privacity"
          element={
            <AppContentScreen type={AppContentType.ConfidentialityPolicy} />
          }
        />
        <Route path="/download" element={<Download />} />

        {environment === envType.PRINCIPAL ? (
          mode === "app" && (
            <>
              <Route path="/auth/*" element={<AuthRouter />} />
              <Route path="/place/:placeId" element={<MapScreen />} />
              <Route path="add/*" element={<AddTravelScreen />} />
              <Route path="search/" element={<SearchTravel />} />
              <Route
                path="search/:travelId"
                element={<TravelExpandBySearch />}
              />
              <Route
                path="search/pay/:travelId/:reservationId"
                element={<PayTravel />}
              />
              <Route path="/user/*" element={<UserScreen />} />
              <Route path="/*" element={<Navigate to={"/"} />} />
            </>
          )
        ) : (
          <>
            <Route path="/auth/*" element={<AuthRouter />} />
            <Route path="/admin/*" element={<AdminRouter />} />
          </>
        )}
      </Routes>
    </div>
  );
};
