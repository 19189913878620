import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { login } from "../actions/auth";
import { auth } from "../api/firebase/firebaseConfig";
import { MelApp } from "../MelApp";
import { AuthRouter } from "./AuthRouter";
import image from "../assets/images/Logo + text.png";
import { useAuthenticationContext } from "../contexts/AuthenticationContext";
import { envType, useAppContext } from "../contexts/AppContext";

export const AppRouter = () => {
  const dispatch = useDispatch();

  const [checking, setChecking] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const { userAuth, loading } = useAuthenticationContext();
  const { environment } = useAppContext();

  if (loading) {
    return <>Cargando...</>;
  }

  return (
    <Routes>
      <Route path="/*" element={<MelApp />} />
      <Route path="*" element={<Navigate replace to="/auth/login" />} />
    </Routes>
  );
};

export const PublicRoute = ({
  isAuth,
  children,
}: {
  isAuth: boolean;
  children: any;
}) => {
  return isAuth ? <Navigate to="/" /> : children;
};

export const PrivateRoute = ({
  isAuth,
  children,
}: {
  isAuth: boolean;
  children: any;
}) => {
  return isAuth ? children : <Navigate to="/auth/login" />;
};
