import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuthenticationContext } from "../contexts/AuthenticationContext";
import { Role } from "../graphql/generated";

export const AdminSideBar = () => {
  const { user } = useAuthenticationContext();
  return (
    <div className="sidebar-container">
      <Nav className="flex-column">
        <Nav.Link className="navbar-link sidebar-item">
          <Link className="navbar-link" to="users">
            Usuarios
          </Link>
        </Nav.Link>
        <Nav.Link className="navbar-link sidebar-item">
          <Link className="navbar-link" to="travels">
            Viajes
          </Link>
        </Nav.Link>
        <Nav.Link className="navbar-link sidebar-item">
          <Link className="navbar-link" to="faq">
            FAQ
          </Link>
        </Nav.Link>
        <Nav.Link className="navbar-link sidebar-item">
          <Link className="navbar-link" to="terms-of-use">
            Terminos y condiciones
          </Link>
        </Nav.Link>
        <Nav.Link className="navbar-link sidebar-item">
          <Link className="navbar-link" to="privacity">
            Politicas de privacidad
          </Link>
        </Nav.Link>
        {user?.role === Role.Superadmin && (
          <Nav.Link className="navbar-link sidebar-item">
            <Link className="navbar-link" to="queries-wl">
              Whitelist-queries
            </Link>
          </Nav.Link>
        )}
      </Nav>
    </div>
  );
};
