import { useEffect, useMemo, useState } from "react";
import {
  AppContentType,
  useGetLatestAppBasicContentQuery,
} from "../graphql/generated";

export const AppContentScreen: ({
  type = AppContentType.TermsOfUse,
}: {
  type: AppContentType;
}) => JSX.Element = ({ type }) => {
  const { data, loading, error } = useGetLatestAppBasicContentQuery({
    variables: { type: type },
  });

  const [styles, setStyles] = useState({
    padding: window.innerWidth > 500 ? 100 : 40,
    paddingTop: 32,
    minWidth: window.innerWidth,
  });

  // const styles = useMemo(
  //   () => ({ padding: window.innerWidth > 500 ? 100 : 40, paddingTop: 32 }),
  //   [window.innerWidth]
  // );

  useEffect(() => {
    window.addEventListener("resize", () => {
      window.innerWidth > 700 &&
        setStyles({
          padding: 100,
          paddingTop: 32,
          minWidth: window.innerWidth,
        });

      window.innerWidth > 500 &&
        window.innerWidth < 700 &&
        setStyles({
          padding: 60,
          paddingTop: 32,
          minWidth: window.innerWidth,
        });
      window.innerWidth < 500 &&
        setStyles({
          padding: 40,
          paddingTop: 32,
          minWidth: window.innerWidth,
        });
    });
  }, []);

  if (loading) {
    return <>Cargando...</>;
  }

  if (error || !data) {
    return <>Intentelo de nuevo mas tarde</>;
  }

  return (
    <div style={{ ...styles }}>
      <td
        dangerouslySetInnerHTML={{
          __html: data?.getLatestAppBasicContent.content.html,
        }}
      />
    </div>
  );
};
