import { useEffect } from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AnyAction } from "redux";
import { startLogout } from "../actions/auth";
import { BiUserCircle } from "react-icons/bi";
import logo from "../assets/images/LogoTextMedium.svg";
import downloadQR from "../assets/images/DownloadQR.png";
import appStore from "../assets/images/App_Store_Badge.png";
import playStore from "../assets/images/google-play-badge.png";
import {
  IoIosMail,
  IoMdDownload,
  IoIosPerson,
  IoIosWarning,
  IoIosQrScanner,
} from "react-icons/io";
import { IoQrCode, IoQrCodeOutline } from "react-icons/io5";
import { useAuthenticationContext } from "../contexts/AuthenticationContext";
import { NotificationIcon } from "./microcomponents/NotificationIcon";
import { useConversationContext } from "../contexts/ConversationContext";
import { MessagesIcon } from "./microcomponents/MessagesIcon";
import { envType, useAppContext } from "../contexts/AppContext";
import { Role } from "../graphql/generated";
import { useModalContext } from "../contexts/ModalContext";

export const NavBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = (e: any) => {
    e.preventDefault();
    dispatch(startLogout() as unknown as AnyAction);
  };

  const { mode, environment } = useAppContext();

  const { setModalProps } = useModalContext();

  const { userAuth, user } = useAuthenticationContext();
  const { subscribeToConversation, conversations, unsubscribeToConversation } =
    useConversationContext();

  const onPress = () => {
    const children = (
      <div className="download-modal">
        <div className="text-card-modal">
          <div className="mb-2">
            <h3>Escaneá el codigo QR</h3>
          </div>
          <text>
            Con la cámara de tu teléfono, escaneá el codigo QR para descargar la
            aplicación y empezar a viajar.{" "}
          </text>
        </div>
        <div className="division"></div>
        <div className="image-card-modal">
          <img src={downloadQR} className="download-qr" />
        </div>
        <div className="division" />
        <div className="download-card-modal">
          <img src={appStore} className="download-stores" />
          <img src={playStore} className="download-google-stores" />
        </div>
      </div>
    );

    setModalProps({ open: true, children });
  };

  // const { data, loading, error, startPolling, stopPolling } =
  //   useNotificationsByUserQuery({
  //     variables: {
  //       where: {
  //         userRecipient: {
  //           is: {
  //             firebaseId: {
  //               equals: userAuth?.uid,
  //             },
  //           },
  //         },
  //       },
  //     },
  //   });

  // useEffect(() => {
  //   startPolling(1000);
  //   console.log(userAuth);
  //   userAuth &&
  //     subscribeToConversation({
  //       userId: user?.id,
  //     });

  //   return () => {
  //     unsubscribeToConversation();
  //     stopPolling();
  //   };
  // }, [user]);

  return (
    <div>
      <Navbar className="navbar-container" expand="xl">
        <Navbar.Brand className="navbar-brand">
          <img
            alt="qsdq"
            height="80"
            className="d-inline-block align-top navbar-logo"
            src={logo}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          className="navbar-toggle"
          aria-controls="basic-navbar-nav"
        />
        <Navbar.Collapse id="basic-navbar-nav" className="navbar-expanded">
          <Nav className="navbar-central">
            <Nav.Link>
              {environment === envType.PRINCIPAL ? (
                <Link className="navbar-link" to="/">
                  Propuesta
                </Link>
              ) : (
                <Link className="navbar-link" to="/admin">
                  Backoffice
                </Link>
              )}
            </Nav.Link>

            {mode === "app" && (
              <>
                {" "}
                <Nav.Link>
                  {" "}
                  <Link className="navbar-link" to="/search">
                    Buscar un viaje
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  {" "}
                  <Link className="navbar-link" to="/add">
                    Publicar un viaje
                  </Link>
                </Nav.Link>
              </>
            )}
          </Nav>
          <Nav>
            {mode === "preApp" && environment === envType.PRINCIPAL ? (
              <>
                <Button className="btn " onClick={onPress}>
                  <div
                    style={{
                      alignSelf: "center",
                      marginRight: "8px",
                      marginBottom: "2px",
                      // bottom: 20,
                    }}
                  >
                    {/* <IoMdDownload size={24} /> */}
                    {/* <IoIosQrScanner size={24} /> */}
                    <IoQrCodeOutline size={26} />
                  </div>
                  {/* <div
                    style={{
                      alignSelf: "flex-end",
                      marginRight: "8px",
                      // justifyContent: "flex-end",
                    }}
                  > */}
                  <div
                    style={{
                      alignSelf: "center",
                      marginRight: "8px",
                      textAlign: "center",
                      marginTop: "0px",
                      fontFamily: "Poppins",
                      // fontWeight: "light",
                    }}
                  >
                    Descargar la aplicación
                  </div>
                  {/* </div> */}
                </Button>
                {/* <Nav.Link>
                  {" "}
                  <Link className="navbar-link-connect" to="/privacity">
                    Politicas de privacidad
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  {" "}
                  <Link className="navbar-link-connect" to="/terms-of-use">
                    Terminos y condiciones
                  </Link>
                </Nav.Link> */}
              </>
            ) : (
              <></>
            )}
            {mode === "app" ||
              (environment === envType.BACKOFFICE &&
                (user && userAuth ? (
                  <>
                    <div className="user-photo-small">
                      <img
                        src={
                          user?.photoURL
                            ? user?.photoURL
                            : "https://firebasestorage.googleapis.com/v0/b/mellevas-7410c.appspot.com/o/fotos-template%2Fpinguino-min.jpg?alt=media&token=d182688b-a70c-4039-bac5-d80a61447672"
                        }
                        height={50}
                        width={50}
                        alt="foto de perfil"
                      />
                    </div>

                    <NavDropdown
                      title={
                        <>
                          {user?.firstName} {"  "}
                        </>
                      }
                      className="navbar-dropdown"
                    >
                      <NavDropdown.Item
                        onClick={() => navigate("/user/my-information")}
                        className="navbar-dropdown-item"
                      >
                        Mi perfil
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        className="navbar-dropdown-item"
                        onClick={() => navigate("/user/my-travels")}
                      >
                        Mis viajes
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="navbar-dropdown-item"
                        onClick={() => navigate("/user/my-reservations")}
                      >
                        Mis reservaciones
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={handleLogout}
                        className="navbar-dropdown-last-item"
                      >
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link>
                      <Link to="/user/notifications" className="navbar-link">
                        {/* <NotificationIcon
                          count={
                            data?.notifications.filter(
                              (notification) => notification.seen === false
                            ).length
                          }
                        /> */}
                      </Link>
                    </Nav.Link>
                    <Nav.Link>
                      <Link to="/user/messages" className="navbar-link">
                        {/* <MessagesIcon
                          count={
                            conversations.filter(
                              (conv) =>
                                conv.messages.filter(
                                  (message: any) =>
                                    !message.seen &&
                                    message.sender.firebaseId !== userAuth?.uid
                                ).length > 0
                            ).length
                          }
                        /> */}
                      </Link>
                    </Nav.Link>
                  </>
                ) : (
                  <>
                    <Nav.Link>
                      {" "}
                      <Link className="navbar-link-connect" to="/auth/login">
                        {/* <BiUserCircle size={28} /> */}
                        Conectate
                      </Link>
                    </Nav.Link>
                    <Nav.Link>
                      {" "}
                      <Link className="navbar-link-connect" to="/auth/register">
                        {/* <BiUserCircle size={28} /> */}
                        Crear una cuenta
                      </Link>
                    </Nav.Link>
                  </>
                )))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <NavBarInfo />
    </div>
  );
};

const NavBarInfo = () => {
  const { user, userAuth } = useAuthenticationContext();

  return (
    <div>
      {user &&
        userAuth &&
        (user.isActive ? (
          userAuth.emailVerified ||
          [Role.Superadmin, Role.Admin].includes(user.role) ? (
            <></>
          ) : (
            <div className="navbar-info">
              <IoIosWarning size={20} className="navbar-icon" />
              Parece que no has verificado tu mail, recorda que es necesario
              para recuperar tu contrasena
            </div>
          )
        ) : (
          <div
            className="navbar-info navbar-warning"
            onClick={() => {
              window.location.assign("/user/confirm-phone-number");
            }}
          >
            <IoIosWarning size={20} className="navbar-icon" />
            {
              "  Parece que no verificaste tu telefono todavia, recorda que es necesario para viajar"
            }
          </div>
        ))}
    </div>
  );
};
