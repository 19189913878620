import { FC } from "react";
import {
  FcGlobe,
  FcMoneyTransfer,
  FcConferenceCall,
  FcLandscape,
} from "react-icons/fc";

interface SearchHomeProps {
  orientation?: "horizontal" | "vertical";
}

export const HomeInfo: FC<SearchHomeProps> = ({ orientation }) => {
  return (
    <div className={`home-info`}>
      <div className="home-info-title">
        <FcGlobe /> Medio ambiente
        <p className="home-info-text">
          Me Llevás ? colabora con el cuidado del medioambiente. Compartiendo
          viajes reducimos la huella de carbono, ahorrando millones de toneladas
          de CO2.
        </p>
      </div>
      <div className="home-info-title">
        <FcConferenceCall /> Comunidad
        <p className="home-info-text">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Est, hic ab
          veritatis
        </p>
      </div>
      <div className="home-info-title">
        <FcMoneyTransfer /> Economia
        <p className="home-info-text">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Est, hic ab
          veritatis
        </p>
      </div>
      <div className="home-info-title">
        <FcLandscape /> Descubrir
        <p className="home-info-text">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Est, hic ab
          veritatis
        </p>
      </div>
    </div>
  );
};
