import { Footer } from "./components/Footer";
import { NavBar } from "./components/NavBar";
import { envType, useAppContext } from "./contexts/AppContext";
import { ConversationContextProvider } from "./contexts/ConversationContext";
import { useModalContext } from "./contexts/ModalContext";
import { MelRouter } from "./routers/MelRouter";

export const MelApp = () => {
  const { environment } = useAppContext();
  const { modal } = useModalContext();
  return (
    <>
      {modal}
      {environment === envType.BACKOFFICE ? (
        <ConversationContextProvider>
          <NavBar />
          <MelRouter />
          <Footer />
        </ConversationContextProvider>
      ) : (
        <ConversationContextProvider>
          <NavBar />
          <MelRouter />
          <Footer />
        </ConversationContextProvider>
      )}
    </>
  );
};
