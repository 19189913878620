import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { OneTravelFound } from "./OneTravelFound";

export const TravelsFind = () => {
  const location = useLocation();
  const {
    origin = "",
    destination = "",
    date = "",
  } = queryString.parse(location.search);

  // const { data, loading, error } = usePSearchTravelQuery({
  //   variables: {
  //     origin: origin as string,
  //     destination: destination as string,
  //     date: date as string,
  //   },
  // });

  return <></>;
  // return loading ? (
  //   <div>Cargando...</div>
  // ) : data?.PSearchTravel.length === 0 ? (
  //   <div>Ningun viaje encontrado</div>
  // ) : (
  //   <div>
  //     {data?.PSearchTravel.map((travel) => (
  //       <OneTravelFound travel={travel as Fragment} />
  //     ))}
  //   </div>
  // );
};
