import { useEffect, useMemo, useState } from "react";
import {
  AppContentType,
  useGetFaqQuery,
  useGetLatestAppBasicContentQuery,
} from "../../graphql/generated";
import { Question } from "../Question";
import { mainTheme } from "../../theme";
import { useNavigate, useParams } from "react-router-dom";

type whatToShowCategory = "categories" | "question" | "content";

export const FAQScreen: ({}: {}) => JSX.Element = ({}) => {
  const [styles, setStyles] = useState({
    padding: window.innerWidth > 500 ? 100 : 40,
    paddingTop: mainTheme.defaultPadding,
    // minWidth: window.innerWidth,
  });

  const { cat, question } = useParams();

  const whatToShow: whatToShowCategory[] = [
    "categories",
    "question",
    "content",
  ];

  const stylesAdapt = useMemo(
    () => ({ widht: (window.innerWidth * 10) / 12 }),
    [window.innerWidth]
  );

  const navigate = useNavigate();

  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>(
    Number(cat)
  );

  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(
    Number(question)
  );

  const [whatToShowIndex, setWhatToShowIndex] = useState(
    question !== undefined ? 2 : cat !== undefined ? 1 : 0
  );

  const { data, loading, error } = useGetFaqQuery();

  const categories = useMemo(
    () =>
      data?.getLatestAppFaqContent?.content?.categories.filter(
        (c) => c.posts.length > 0
      ) ?? [],
    [data]
  );
  const selectedCategory = useMemo(
    () =>
      selectedCategoryIndex !== undefined
        ? categories[selectedCategoryIndex]
        : undefined,
    [selectedCategoryIndex, categories]
  );
  const questions = useMemo(
    () => selectedCategory?.posts ?? [],
    [selectedCategory]
  );
  const isFAQ = -1 === -1;

  //   useEffect(() => {
  //     if(question) {
  //         setWhatToShowIndex(2)
  //     } else if(cat) {}
  //   },[cat, question])

  if (loading) {
    return <>Cargando...</>;
  }

  if (error || !data) {
    return <>Intentelo de nuevo mas tarde</>;
  }

  return (
    <div
      style={{
        // ...styles,
        // paddingBottom: mainTheme.defaultPadding,
        backgroundColor: mainTheme.colors.greyBackground,
        paddingTop: mainTheme.defaultPadding,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderWidth: 1,
        borderColor: mainTheme.colors.primary,
        borderRadius: mainTheme.defaultPadding / 4,
        borderStyle: "solid",
        alignSelf: "center",
        marginTop: mainTheme.defaultMargin,
        marginBottom: mainTheme.defaultMargin,
        width: window.innerWidth > 1200 ? 1000 : (window.innerWidth * 10) / 12,
      }}
    >
      <div
        style={{
          display: "flex",
          //   alignContent: "center",
          width: "100%",
          paddingLeft: mainTheme.defaultPadding,
          paddingBottom: mainTheme.defaultPadding,
          paddingTop: mainTheme.defaultPadding,
          borderWidth: 1,
          borderColor: mainTheme.colors.deepGrey,
          //   borderRadius: mainTheme.defaultPadding / 2,
          borderBottomStyle: "solid",
          alignItems: "center",
          verticalAlign: "center",
          justifyContent: "center",

          //   marginBottom: mainTheme.defaultMargin / 2,
        }}
      >
        {whatToShowIndex > 0 && (
          <div
            onClick={() => {
              //   setSelectedCategoryIndex(0);
              setWhatToShowIndex((w) => w - 1);
              //   navigate(whatToShowIndex === 2 ? `/faq/${cat}` : `/faq`);
            }}
            style={{
              fontSize: 32,
              flex: 1,
              cursor: "pointer",
              //   background: "red",
              justifySelf: "center",
            }}
          >
            {"<"}
          </div>
        )}
        <h3
          style={{
            textAlign: "center",
            justifySelf: "center",
            // marginBottom: mainTheme.defaultPadding,
            flex: 100,
            // background: "red",
          }}
        >
          Preguntas frecuentes
        </h3>
      </div>
      {/* {selectedCategoryIndex} */}
      {/* <text
        style={{ textAlign: "center", marginBottom: mainTheme.defaultPadding }}
      >
        Aquí encontrarás toda la información que necesites para conocer el
        funcionamiento de la plataforma.
      </text> */}
      <div
        style={{
          //   borderWidth: 1,
          //   borderColor: mainTheme.colors.primary,
          //   borderRadius: mainTheme.defaultPadding / 4,
          //   borderStyle: "solid",

          paddingLeft: mainTheme.defaultPadding,
          paddingRight: mainTheme.defaultPadding,
          //   marginTop: mainTheme.defaultMargin,
          width: "100%",
          //   window.innerWidth > 1000 ? 900 : (window.innerWidth * 9) / 10,
          //   flex: 1,
        }}
      >
        {whatToShow[whatToShowIndex] === "question" &&
          (questions.length > 0 ? (
            <>
              {questions.map((q, index, array) => (
                <div
                  onClick={() => {
                    setSelectedQuestionIndex(index);
                    setWhatToShowIndex((w) => w + 1);
                  }}
                  style={{
                    //   paddingBottom: mainTheme.defaultPadding,
                    //   paddingTop: mainTheme.defaultPadding,
                    //   paddingLeft: mainTheme.defaultPadding,
                    padding: mainTheme.defaultPadding,

                    borderBottomWidth: 1,
                    //   borderTopWidth: 1,
                    borderColor: mainTheme.colors.deepGrey,
                    borderBottomStyle:
                      index === array.length - 1 ? "none" : "solid",
                    //   borderTopStyle: index === 0 ? "none" : "none",
                    //   borderTopStyle: index === array.length - 1 ? "solid" : "solid",
                    display: "flex",
                    //   backgroundColor: "red",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onMouseOver={(event: any) => {
                    event.target.style.backgroundColor =
                      mainTheme.colors.lightGrey;
                  }}
                  onMouseLeave={(event: any) => {
                    event.target.style.background = "none";
                  }}
                >
                  <text
                    style={{
                      // fontSize: 24,
                      // width: 800,
                      alignSelf: "center",
                      // backgroundColor: "red",
                    }}
                    onMouseOver={(event: any) => {
                      event.target.style.backgroundColor =
                        mainTheme.colors.lightGrey;
                    }}
                    onMouseLeave={(event: any) => {
                      event.target.style.background = "none";
                    }}
                  >
                    {q.title}
                  </text>
                  <text
                    style={{
                      fontSize: 24,
                      alignSelf: "center",
                      // backgroundColor: "",
                    }}
                    onMouseOver={(event: any) => {
                      event.target.style.backgroundColor =
                        mainTheme.colors.lightGrey;
                    }}
                    onMouseLeave={(event: any) => {
                      event.target.style.background = "none";
                    }}
                  >
                    {">"}
                  </text>
                </div>
              ))}
            </>
          ) : (
            <div style={{ padding: 32 }}>
              Te pedimos disculpas, parece que esta categoría no existe.
            </div>
          ))}
        {whatToShow[whatToShowIndex] === "categories" && (
          <>
            {categories.map((q, index, array) => (
              <div
                onClick={() => {
                  setSelectedCategoryIndex(index);
                  setWhatToShowIndex((w) => w + 1);
                }}
                style={{
                  //   paddingBottom: mainTheme.defaultPadding,
                  //   paddingTop: mainTheme.defaultPadding,
                  //   paddingLeft: mainTheme.defaultPadding,
                  padding: mainTheme.defaultPadding,

                  borderBottomWidth: 1,
                  //   borderTopWidth: 1,
                  borderColor: mainTheme.colors.deepGrey,
                  borderBottomStyle:
                    index === array.length - 1 ? "none" : "solid",
                  //   borderTopStyle: index === 0 ? "none" : "none",
                  //   borderTopStyle: index === array.length - 1 ? "solid" : "solid",
                  display: "flex",
                  //   backgroundColor: "red",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onMouseOver={(event: any) => {
                  event.target.style.backgroundColor =
                    mainTheme.colors.lightGrey;
                }}
                onMouseLeave={(event: any) => {
                  event.target.style.background = "none";
                }}
              >
                <text
                  style={{
                    // fontSize: 24,
                    // width: 800,
                    alignSelf: "center",
                    // backgroundColor: "red",
                  }}
                  onMouseOver={(event: any) => {
                    event.target.style.backgroundColor =
                      mainTheme.colors.lightGrey;
                  }}
                  onMouseLeave={(event: any) => {
                    event.target.style.background = "none";
                  }}
                >
                  {q.title}
                </text>
                <text
                  style={{
                    fontSize: 24,
                    alignSelf: "center",
                    // backgroundColor: "",
                  }}
                  onMouseOver={(event: any) => {
                    event.target.style.backgroundColor =
                      mainTheme.colors.lightGrey;
                  }}
                  onMouseLeave={(event: any) => {
                    event.target.style.background = "none";
                  }}
                >
                  {">"}
                </text>
              </div>
            ))}
          </>
        )}
        {whatToShow[whatToShowIndex] === "content" &&
          (questions[selectedQuestionIndex] ? (
            questions[selectedQuestionIndex] && (
              <div style={{ padding: 32 }}>
                <h3 style={{ marginBottom: 32 }}>
                  {questions[selectedQuestionIndex].title}
                </h3>
                <text>{questions[selectedQuestionIndex].content}</text>
              </div>
            )
          ) : (
            <div style={{ padding: 32 }}>
              Te pedimos disculpas, parece que esta pregunta no existe.
            </div>
          ))}
      </div>
    </div>
  );
};
