import React, { FC, useState } from "react";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import { updateDate, updatePlaces } from "../actions/travel";
import { PlacesStepForm } from "./PlacesStepForm";
import { SelectDate } from "./SelectDate";
import { TravelStepsForm } from "./TravelStepsForm";
import TimePicker from "react-time-picker";
import { DateTime } from "luxon";

interface AddTravelStepWrapperProps {
  step: number;
  onBack: () => void;
  onNext: () => void;
  valuesForm: {
    date: Date;
  };
  setValuesForm: ({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) => void;

  setCenter: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    }>
  >;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
  setViewMap: React.Dispatch<React.SetStateAction<boolean>>;
  setDirectReservation: (arg0: boolean) => void;
  directReservation: boolean;
}
export const AddTravelStepWrapper: FC<AddTravelStepWrapperProps> = ({
  step,
  onBack,
  onNext,
  valuesForm,
  setValuesForm,
  setCenter,
  setZoom,
  setViewMap,
  setDirectReservation,
  directReservation,
}) => {
  const [continueActive, setContinueActive] = useState(true);

  return (
    <div className="add-travel-wrapper">
      <div className="add-travel-buttons">
        <button
          className={`btn btn-primary ${step === 0 && "hidden"}`}
          onClick={() => {
            onBack();
            // switch (step) {
            //   case 1:
            //     break;

            //   default:
            //     break;
            // }
          }}
        >
          <IoMdArrowBack />
        </button>

        <button className="btn btn-primary " onClick={() => onNext()}>
          <IoMdArrowForward />
        </button>
      </div>
      <div className="travel-summary-container">
        <div className="add-travel-form">
          {step === 0 && (
            <>
              <SelectDate
                actionOnChange={updateDate}
                setDate={setValuesForm}
                date={valuesForm.date}
              />
            </>
          )}
          {step === 1 && (
            // <TravelStepsForm
            //   setCenter={setCenter}
            //   setViewMap={setViewMap}
            //   setZoom={setZoom}
            // />
            <div className="home-input-container">
              <div className="home-input-title">A que hora?</div>
              <TimePicker
                className="home-input"
                value={`${valuesForm.date.getHours()}:${valuesForm.date.getMinutes()}`}
                onChange={(time) => {
                  console.log(time);
                  if (time) {
                    const hours = time.toString().split(":")[0];
                    const minutes = time.toString().split(":")[1];
                    console.log(hours, minutes);

                    const date = DateTime.fromJSDate(valuesForm.date)
                      .set({ hour: 0, minute: 0 })
                      .plus({
                        hours: Number(hours),
                        minutes: Number(minutes),
                      });
                    const event: { target: any } = {
                      target: {},
                    };
                    event.target = {
                      name: "date",
                      value: date.toJSDate(),
                    };
                    console.log(date);
                    setValuesForm(event);
                  }
                  // console.log(date.plus({hours: }));
                }}
                clearIcon={null}
                clockIcon={null}
                disableClock={true}
              />
            </div>
          )}
          {step === 2 && (
            <TravelStepsForm
              setCenter={setCenter}
              setViewMap={setViewMap}
              setZoom={setZoom}
            />
          )}

          {step === 3 && (
            <PlacesStepForm
              setDirectReservation={setDirectReservation}
              valuesForm={valuesForm}
              setValuesForm={setValuesForm}
              actionOnChangePlaces={updatePlaces}
              directReservation={directReservation}
            />
          )}
        </div>
      </div>
    </div>
  );
};
