import { Step, travelAction } from "../reducers/travelReducer";
import { types } from "../types/types";

export const addstep = (step: Step): travelAction => {
  return {
    type: types.addStep,
    payload: {
      step: step,
    },
  };
};

export const updateDate = (date: Date): travelAction => {
  return {
    type: types.updateDate,
    payload: {
      date: date,
    },
  };
};
export const updatePlaces = (places: number): travelAction => {
  return {
    type: types.updatePlaces,
    payload: {
      places: places,
    },
  };
};
export const cleanTravel = (): travelAction => {
  return {
    type: types.cleanTravel,

  };
};
