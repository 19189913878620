import { isAndroid, isIOS, isMobile } from "react-device-detect";
import {
  AppContentType,
  useGetLatestAppBasicContentQuery,
} from "../graphql/generated";
import { Navigate, useNavigate } from "react-router-dom";

export const Download: () => JSX.Element = () => {
  const navigation = useNavigate();

  if (isMobile && isIOS) {
    window.location.href = "https://apps.apple.com/app/me-llevás/id6443919040";
    return <></>;
  }

  if (isMobile && isAndroid) {
    window.location.href = "market://details?id=com.chaine.mellevas";
    // "https://play.google.com/store/apps/details?id=com.chaine.mellevas";
    return <> Allo</>;
  }

  return (
    <>
      Allo
      <Navigate to={"../"} />
    </>
  );
};
