// import {StackNavigationOptions} from '@react-navigation/stack';
// import {Platform, StyleSheet} from 'react-native';

export const mainTheme = {
  defaultPadding: 32,
  defaultMargin: 48,
  squareButtonBorderRadius: 8,
  smallFontSize: 12,
  defaultFontSize: 14,
  largeFontsize: 18,
  backIconSize: 24,
  titleFontSize: 24,
  titleHeaderFontSize: 18,
  defaultIconSize: 24,
  roundness: 4,
  backgroundBorderRadius: 30,
  boldMainFontFamily: "Poppins-SemiBold",
  regularMainFontFamily: "Poppins-Regular",
  italicMainFontFamily: "Poppins-Regular_Italic",
  boldSecondaryFontFamily: "Inter-SemiBold",
  regularSecondaryFontFamily: "Inter-Regular",
  italicSecondaryFontFamily: "Inter-Regular_Italic",
  // TODO: Fix this.
  // On android the TextInput has a kind of padding around the text.
  // This padding is not on the Text element.
  // So we have to add it manually to keep visual conformity between all our inputs.
  textInputNaturalAndroidPadding: 3,
  colors: {
    primary: "#0e747c", // Blue
    secondary: "#f0533b", // Pink
    secondaryClair: "#f5b175",
    tertiary: "#fcb944",
    greyBackground: "#F9FAFC",
    blueBackground: "#E5F6FF",
    sandBackground: "#fbfaf6",
    muted: "#C2C6CC",
    text: "#091f26",
    placeholder: "#777777",
    separator: "#E8E8E8",
    inactiveSvgPrimary: "#CBCBDD",
    inactiveSvgSecondary: "#FFF",
    black: "#222222",
    deepGrey: "#909090",
    deepBlue: "#282D87",
    strokeGrey: "#E8E8E8",
    lightGrey: "#EFEFEF",
    white: "#FFFFFF",
    cloudBlue: "#81b9bf",
    skyBlue: "#78a4d7",
    success: "#198754",
    waiting: "#fcb944",
    rejected: "#DE3D4C",
    error: "#DE3D4C",
    warning: "#FFCB8F",
    warning2: "#FFB259",
  },
};

export const buttonStyles = {
  primary: {
    color: mainTheme.colors.primary,
    alignSelf: "center",
    padding: 12,
    height: 56,
    position: "relative",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: mainTheme.backgroundBorderRadius / 2,

    elevation: 3,
  },
  tertiary: {
    backgroundColor: mainTheme.colors.blueBackground,
    padding: 0,
    paddingHorizontal: 10,
    minWidth: 77,
    maxWidth: 126,
    height: 26,
    iconStyle: {
      color: mainTheme.colors.primary,
      left: 4,
    },
    textStyle: {
      color: mainTheme.colors.primary,
      fontSize: mainTheme.titleHeaderFontSize,
      bottom: 1,
      zIndex: 2,
    },
  },
};

export const navBarButtonStyle = {
  button: {
    width: 160,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    borderRadius: 100,
  },

  text: {
    paddingVertical: 8,
    fontSize: 14,
    fontFamily: "Inter-SemiBold",
    color: "white",
  },
};

export const textStyles = {
  sectionTitle: {
    fontFamily: mainTheme.boldSecondaryFontFamily,
    fontSize: mainTheme.titleFontSize,
    color: mainTheme.colors.primary,
  },
  blockTitle: {
    fontFamily: mainTheme.boldMainFontFamily,
    color: mainTheme.colors.primary,
    fontSize: mainTheme.defaultFontSize,
  },
  smallTitle: {
    fontSize: 14,
    marginTop: 24,
    marginBottom: 16,
    fontWeight: "bold",
  },
  smallDetail: {
    color: mainTheme.colors.placeholder,
    fontSize: 12,
    marginTop: 4,
  },
  "Editorial text/H1": {
    fontFamily: "Poppins-SemiBold",
    fontSize: 28,
    lineHeight: 32,
  },
  "Editorial text/H1.5": {
    fontFamily: "Poppins-SemiBold",
    fontSize: 24,
    lineHeight: 30,
  },
  "Editorial text/H2": {
    fontFamily: "Poppins-SemiBold",
    fontSize: 20,
    lineHeight: 28,
  },
  "Editorial text/H3": {
    fontFamily: "Poppins-Medium",
    fontSize: 16,
    lineHeight: 24,
  },
  "Editorial text/H4": {
    fontFamily: "Poppins-Medium",
    fontSize: 14,
    lineHeight: 22,
  },
  "Editorial text/page title": {
    fontFamily: "Inter-SemiBold",
    fontSize: 16,
    lineHeight: 24,
  },
  "Editorial text/Heading": {
    fontFamily: "Inter-Medium",
    fontSize: 18,
    lineHeight: 24,
  },
  "Editorial text/body": {
    fontFamily: "Inter-Regular",
    fontSize: 14,
    lineHeight: 22,
  },
  "Editorial text/mention": {
    fontFamily: "Inter-Medium",
    fontSize: 12,
    lineHeight: 20,
  },
};

export const commonStyles = {
  arrowButton: {
    aspectRatio: 1,
    transform: [{ scale: 1.2 }],
  },
};

export const modalStyle = {
  headerTintColor: mainTheme.colors.primary,
  headerTitleAlign: "center",
  headerTitleStyle: {
    color: mainTheme.colors.primary,
    fontSize: mainTheme.titleHeaderFontSize,
    fontFamily: "Inter-SemiBold",
  },
  headerRightContainerStyle: {
    paddingRight: mainTheme.defaultPadding,
  },
  headerLeftContainerStyle: {
    paddingLeft: mainTheme.defaultPadding,
  },
  headerStyle: {
    backgroundColor: "#F9FAFC",
    shadowColor: "transparent",
    shadowOpacity: 0,
    elevation: 0,
  },
};

export const cardStyle = {
  headerTintColor: mainTheme.colors.primary,
  headerTitleAlign: "center",
  headerTitleStyle: {
    color: "white",
    fontSize: mainTheme.titleHeaderFontSize,
    fontFamily: "Poppins-Medium",
  },
  headerRightContainerStyle: {
    paddingRight: mainTheme.defaultPadding,
  },
  headerLeftContainerStyle: {
    paddingLeft: mainTheme.defaultPadding,
  },
  headerStyle: {
    backgroundColor: mainTheme.colors.primary,
    shadowColor: "transparent",
    shadowOpacity: 0,
    elevation: 0,
  },
};
