import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleanTravel } from "../actions/travel";
import { ArgCoord } from "../settings/searchSettings";
import { InputStep } from "./InputStep";

interface TravelStepFormProps {
  setCenter: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    }>
  >;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
  setViewMap: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TravelStepsForm: FC<TravelStepFormProps> = ({
  setCenter,
  setZoom,
  setViewMap,
}) => {
  const [addStep, setAddStep] = useState(false);
  const dispatch = useDispatch();
  // let steps: Step[] = useSelector((state) => (state as any).travel.steps);

  return (
    <div className="search-travel-form-steps">
      {/* {steps.length === 0 && (
        <div className="search-travel-steps-input">
          <div className="h4 text-center home-input-title">Indique el origen</div>
          <InputStep setCenter={setCenter} setZoom={setZoom} />
        </div>
      )}
      {steps.length === 1 && (
        <div>
          <div className="h4 text-center home-input-title">Indique el destino</div>
          <InputStep setCenter={setCenter} setZoom={setZoom} />
        </div>
      )}
      {addStep && (
        <div>
          <div className="h5">Indique la nueva etapa</div>
          <InputStep setCenter={setCenter} setZoom={setZoom} />
        </div>
      )}
      {steps.length > 1 && !addStep && (
        <>
          <h6>Desea agregar una etapa intermediaria? </h6>
          <button
            className="btn btn-primary btn-block"
            onClick={(e) => {
              e.preventDefault();
              setAddStep(!addStep);
            }}
          >
            Si
          </button>
        </>
      )}
      {steps.length > 0 && (
        <button
          className="btn btn-primary "
          onClick={(e) => {
            e.preventDefault();
            dispatch(cleanTravel());
            setCenter(ArgCoord);
            setZoom(6);
            setViewMap(false);
            setViewMap(true);
          }}
        >
          Recomenzar
        </button>
      )} */}
    </div>
  );
};
