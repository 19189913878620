import { Navigate, Route, Routes } from "react-router-dom";
import { AdminSideBar } from "../components/SideBar";
import { FaqScreen } from "../components/admin/faq/Faq";
import { ConfirmPhoneNumberScreen } from "../components/auth/ConfirmPhoneNumber";
import { useAuthenticationContext } from "../contexts/AuthenticationContext";
import { AppContentType, Role } from "../graphql/generated";
import { useDispatch } from "react-redux";
import { startLogout } from "../actions/auth";
import { AnyAction } from "redux";
import Swal from "sweetalert2";
import { QueriesWLScreen } from "../components/admin/queries-whitelist/QueriesWL";
import { TravelsAdmin } from "../components/admin/travels/Travels";
import { AppContentScreenEditor } from "../components/admin/termsOfUse/TermsOfUse";

export const AdminRouter = () => {
  const { user, userAuth } = useAuthenticationContext();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(startLogout() as unknown as AnyAction);
  };

  if (!user || user?.role === Role.User) {
    Swal.fire({
      title: "Lo sentimos",
      text: "Acceso denegado. Si formas parte del equipo Me Llevas, contacta al administrador.",
    });
    return <Navigate replace to="/" />;
  }
  return (
    <div className="admin-main">
      <AdminSideBar />
      <div className="admin-container">
        <Routes>
          <Route
            path="terms-of-use/*"
            element={
              <AppContentScreenEditor type={AppContentType.TermsOfUse} />
            }
          />
          <Route
            path="privacity/*"
            element={
              <AppContentScreenEditor
                type={AppContentType.ConfidentialityPolicy}
              />
            }
          />
          <Route path="travels/" element={<TravelsAdmin />} />
          <Route path="travels/:travelId" element={<>HQSD</>} />
          <Route path="faq/*" element={<FaqScreen />} />
          <Route path="queries-wl/*" element={<QueriesWLScreen />} />
          <Route
            path="confirm-phone-number"
            element={<ConfirmPhoneNumberScreen />}
          />
        </Routes>
      </div>
    </div>
  );
};
