import { types } from "../types/types";

export const authReducer = (
  state = {},
  action: {
    type: string;
    payload: {
      id: string;
      email: string;
    };
  }
) => {
  switch (action.type) {
    case types.login:
      return {
        firebaseId: action.payload.id,
        email: action.payload.email,
      };

    case types.logout:
      return {};
    default:
      return state;
  }
};
