import { FcGoogle } from "react-icons/fc";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { AnyAction } from "redux";
import { startLoginEmailPassword } from "../../actions/auth";
import { signUpWithGoogle } from "../../api/firebase/firebaseConfig";
import { useAuthenticationContext } from "../../contexts/AuthenticationContext";
import { useForm } from "../../hooks/useForm";

export const LoginScreen = () => {
  const dispatch = useDispatch();

  const { user, userAuth, loading, loginRedirect } = useAuthenticationContext();

  const [formValues, handleInputChange] = useForm({
    email: "",
    password: "",
  });

  const { email, password } = formValues;

  const handleLogin = async (e: any) => {
    e.preventDefault();
    startLoginEmailPassword(email, password).then((e) => {
      localStorage.setItem("authorization", `Bearer ${(e as any).accessToken}`);
      setTimeout(() => {
        loginRedirect(e.email);
      }, 1500);
    });
  };

  if (user) {
    loginRedirect();
  }
  return (
    <div>
      <h4 className="auth__title">Ingresa</h4>
      <form onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="Email"
          name="email"
          value={email}
          onChange={handleInputChange}
          className="auth__input"
          autoComplete="off"
        />

        <input
          type="password"
          placeholder="Password"
          name="password"
          value={password}
          onChange={handleInputChange}
          className="auth__input"
        />

        <div>
          <Link to="/auth/register">Crear una cuenta</Link>
        </div>
        <div onClick={() => {}}>
          <Link to="/auth/reset-password">Olvide mi contraseña</Link>
        </div>
        <button type="submit" className="btn btn-primary ">
          Ingresa
        </button>
      </form>
      <button
        className="btn btn-primary auth-howto-btn google"
        onClick={() => {
          signUpWithGoogle().then(({ userEmail }) => {
            // loginRedirect();
            setTimeout(() => {
              loginRedirect(userEmail);
            }, 1500);
          });
        }}
      >
        <FcGoogle size={28} className="btn-icon" />
        Continuar con Google
      </button>
    </div>
  );
};
