import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/images/home_backoffice.svg";

export const HomeBackOfficeScreen: () => JSX.Element = () => {
  const navigate = useNavigate();

  //   if (loading) {
  //     return <>Cargando...</>;
  //   }

  //   if (error || !data) {
  //     return <>Intentelo de nuevo mas tarde</>;
  //   }

  return (
    <div
      style={{
        padding: 40,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // backgroundColor: "red",
      }}
    >
      <h2>Backoffice Me Llevás ? </h2>
      <div
        style={{
          margin: 40,
          //   borderWidth: 1,
          //   borderColor: "grey",
          //   borderStyle: "solid",
          padding: 24,
          borderRadius: 8,
          backgroundColor: "rgba(251, 250, 246, 1)",
        }}
      >
        <img
          alt="Logo"
          height="300"
          className="d-inline-block align-top navbar-logo"
          src={logo}
        />
      </div>
      <Button style={{ width: "100%" }} onClick={() => navigate("/admin")}>
        Entrar
      </Button>
    </div>
  );
};
