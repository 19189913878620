import { IoMdNotifications } from "react-icons/io";
import { Link } from "react-router-dom";
import { useAuthenticationContext } from "../contexts/AuthenticationContext";

export const NotiicationScreen = () => {
  const { userAuth } = useAuthenticationContext();

  // const { data, loading } = useNotificationsByUserQuery({
  //   variables: {
  //     where: {
  //       userRecipient: {
  //         is: {
  //           firebaseId: {
  //             equals: userAuth?.uid,
  //           },
  //         },
  //       },
  //     },
  //   },
  // });
  return (
    <div className="container-information">
      <div className="">
        <h3>Notificaciones</h3>
        <div className="notifications-container">
          {/* {!loading ? (
            data && data?.notifications.length !== 0 ? (
              data?.notifications.map((notificacion) => {
                return (
                  <Link
                    className={`notification-container`}
                    to="/user/notifications"
                  >
                    <div>
                      <h5>{notificacion.title}</h5>
                      <p>{notificacion.message}</p>
                    </div>
                    <div>
                      {!notificacion.seen && (
                        <IoMdNotifications
                          size={28}
                          className="notification-icon"
                        />
                      )}
                    </div>
                  </Link>
                );
              })
            ) : (
              <div className="center-items">
                <div className="travel-results">
                  <h5>Parece que no tenés ninguna notificacion...</h5>
                </div>
              </div>
            )
          ) : (
            <div className="travel-results">
              <h5>Cargando</h5>
            </div>
          )} */}
        </div>
        {}
      </div>
    </div>
  );
};
