import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuthenticationContext } from "../../contexts/AuthenticationContext";
import { ConfirmPhoneNumberScreen } from "../auth/ConfirmPhoneNumber";
import { MyReservations } from "../MyReservations";
import { MyTravels } from "../MyTravels";
import { NotiicationScreen } from "../Notifications";
import { TravelExpand } from "../Travel";
import { UserInformation } from "../UserInformation";
import { ChatScreen } from "./Chat";

export const UserScreen = () => {
  const { userAuth, user, loading } = useAuthenticationContext();

  console.log(userAuth, user, loading);
  return (
    <div className="user-screen-container">
      {userAuth ? (
        <Routes>
          <Route path={"my-information"} element={<UserInformation />} />
          <Route path={"messages/:conversationId"} element={<ChatScreen />} />
          <Route path={"messages/*"} element={<ChatScreen />} />
          <Route path={"my-travels"} element={<MyTravels />} />
          <Route path={"my-reservations"} element={<MyReservations />} />
          <Route path={"notifications"} element={<NotiicationScreen />} />
          <Route path={`my-travels/:travelId`} element={<TravelExpand />} />
          <Route
            path="confirm-phone-number"
            element={<ConfirmPhoneNumberScreen />}
          />
        </Routes>
      ) : (
        loading === false && <Navigate to="/" />
      )}
    </div>
  );
};
