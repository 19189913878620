import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
};

export type AcceptOrDeclineReservationResult = AcceptOrDeclineReservationResultError | AcceptOrDeclineReservationResultSuccess;

export type AcceptOrDeclineReservationResultError = {
  __typename?: 'AcceptOrDeclineReservationResultError';
  code: AcceptOrDeclineReservationResultErrorDto;
};

export enum AcceptOrDeclineReservationResultErrorDto {
  NotFound = 'NOT_FOUND',
  ReservationIsNotWaitingForAcceptation = 'RESERVATION_IS_NOT_WAITING_FOR_ACCEPTATION',
  UnauthorizedToModify = 'UNAUTHORIZED_TO_MODIFY'
}

export type AcceptOrDeclineReservationResultSuccess = {
  __typename?: 'AcceptOrDeclineReservationResultSuccess';
  reservation: Reservation;
};

export type AppBasicContent = {
  __typename?: 'AppBasicContent';
  content: AppBasicContentHtmlDto;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  type: AppContentType;
};

export type AppBasicContentHtmlDto = {
  __typename?: 'AppBasicContentHtmlDto';
  html: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type AppContent = {
  __typename?: 'AppContent';
  content: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  type: AppContentType;
};

export type AppContentCreateInput = {
  content: Scalars['JSON'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  type: AppContentType;
};

export type AppContentOrderByWithRelationInput = {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export enum AppContentScalarFieldEnum {
  Content = 'content',
  CreatedAt = 'createdAt',
  Id = 'id',
  Type = 'type'
}

export enum AppContentType {
  ConfidentialityPolicy = 'CONFIDENTIALITY_POLICY',
  CookiePolicy = 'COOKIE_POLICY',
  Faq = 'FAQ',
  LegalNotice = 'LEGAL_NOTICE',
  TermsOfUse = 'TERMS_OF_USE'
}

export type AppContentWhereInput = {
  AND?: InputMaybe<Array<AppContentWhereInput>>;
  NOT?: InputMaybe<Array<AppContentWhereInput>>;
  OR?: InputMaybe<Array<AppContentWhereInput>>;
  content?: InputMaybe<JsonFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumAppContentTypeFilter>;
};

export type AppContentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type AppFaqContent = {
  __typename?: 'AppFaqContent';
  content: AppFaqContentContentDto;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type AppFaqContentCategoryDto = {
  __typename?: 'AppFaqContentCategoryDto';
  description?: Maybe<Scalars['String']>;
  posts: Array<AppFaqContentPostDto>;
  subcategories?: Maybe<Array<AppFaqContentCategoryDto>>;
  title: Scalars['String'];
};

export type AppFaqContentCategoryInput = {
  description?: InputMaybe<Scalars['String']>;
  posts: Array<AppFaqContentPostInput>;
  subcategories?: InputMaybe<Array<AppFaqContentCategoryInput>>;
  title: Scalars['String'];
};

export type AppFaqContentContentDto = {
  __typename?: 'AppFaqContentContentDto';
  categories: Array<AppFaqContentCategoryDto>;
  title?: Maybe<Scalars['String']>;
};

export type AppFaqContentPostDto = {
  __typename?: 'AppFaqContentPostDto';
  content: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type AppFaqContentPostInput = {
  content: Scalars['String'];
  subtitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export enum AppType {
  App = 'App',
  BackOffice = 'BackOffice',
  Web = 'Web'
}

export type AtPlaceProof = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type AtTimeAtPlaceProof = {
  __typename?: 'AtTimeAtPlaceProof';
  DriverDisclaimReservation?: Maybe<DriverDisclaimReservation>;
  DriverReservation?: Maybe<Reservation>;
  PassengerDisclaimReservation?: Maybe<PassengerDisclaimReservation>;
  PassengerReservation?: Maybe<Reservation>;
  date: Scalars['DateTime'];
  driverDisclaimReservationId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  passengerDisclaimReservationId?: Maybe<Scalars['String']>;
};

export type AtTimeAtPlaceProofCreateNestedOneWithoutDriverDisclaimReservationInput = {
  connect?: InputMaybe<AtTimeAtPlaceProofWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AtTimeAtPlaceProofCreateOrConnectWithoutDriverDisclaimReservationInput>;
  create?: InputMaybe<AtTimeAtPlaceProofCreateWithoutDriverDisclaimReservationInput>;
};

export type AtTimeAtPlaceProofCreateNestedOneWithoutDriverReservationInput = {
  connect?: InputMaybe<AtTimeAtPlaceProofWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AtTimeAtPlaceProofCreateOrConnectWithoutDriverReservationInput>;
  create?: InputMaybe<AtTimeAtPlaceProofCreateWithoutDriverReservationInput>;
};

export type AtTimeAtPlaceProofCreateNestedOneWithoutPassengerDisclaimReservationInput = {
  connect?: InputMaybe<AtTimeAtPlaceProofWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AtTimeAtPlaceProofCreateOrConnectWithoutPassengerDisclaimReservationInput>;
  create?: InputMaybe<AtTimeAtPlaceProofCreateWithoutPassengerDisclaimReservationInput>;
};

export type AtTimeAtPlaceProofCreateNestedOneWithoutPassengerReservationInput = {
  connect?: InputMaybe<AtTimeAtPlaceProofWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AtTimeAtPlaceProofCreateOrConnectWithoutPassengerReservationInput>;
  create?: InputMaybe<AtTimeAtPlaceProofCreateWithoutPassengerReservationInput>;
};

export type AtTimeAtPlaceProofCreateOrConnectWithoutDriverDisclaimReservationInput = {
  create: AtTimeAtPlaceProofCreateWithoutDriverDisclaimReservationInput;
  where: AtTimeAtPlaceProofWhereUniqueInput;
};

export type AtTimeAtPlaceProofCreateOrConnectWithoutDriverReservationInput = {
  create: AtTimeAtPlaceProofCreateWithoutDriverReservationInput;
  where: AtTimeAtPlaceProofWhereUniqueInput;
};

export type AtTimeAtPlaceProofCreateOrConnectWithoutPassengerDisclaimReservationInput = {
  create: AtTimeAtPlaceProofCreateWithoutPassengerDisclaimReservationInput;
  where: AtTimeAtPlaceProofWhereUniqueInput;
};

export type AtTimeAtPlaceProofCreateOrConnectWithoutPassengerReservationInput = {
  create: AtTimeAtPlaceProofCreateWithoutPassengerReservationInput;
  where: AtTimeAtPlaceProofWhereUniqueInput;
};

export type AtTimeAtPlaceProofCreateWithoutDriverDisclaimReservationInput = {
  DriverReservation?: InputMaybe<ReservationCreateNestedOneWithoutDriverWasAtPlaceInput>;
  PassengerDisclaimReservation?: InputMaybe<PassengerDisclaimReservationCreateNestedOneWithoutWasAtPlaceInDisclaimInput>;
  PassengerReservation?: InputMaybe<ReservationCreateNestedOneWithoutPassengerWasAtPlaceInput>;
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

export type AtTimeAtPlaceProofCreateWithoutDriverReservationInput = {
  DriverDisclaimReservation?: InputMaybe<DriverDisclaimReservationCreateNestedOneWithoutWasAtPlaceInDisclaimInput>;
  PassengerDisclaimReservation?: InputMaybe<PassengerDisclaimReservationCreateNestedOneWithoutWasAtPlaceInDisclaimInput>;
  PassengerReservation?: InputMaybe<ReservationCreateNestedOneWithoutPassengerWasAtPlaceInput>;
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

export type AtTimeAtPlaceProofCreateWithoutPassengerDisclaimReservationInput = {
  DriverDisclaimReservation?: InputMaybe<DriverDisclaimReservationCreateNestedOneWithoutWasAtPlaceInDisclaimInput>;
  DriverReservation?: InputMaybe<ReservationCreateNestedOneWithoutDriverWasAtPlaceInput>;
  PassengerReservation?: InputMaybe<ReservationCreateNestedOneWithoutPassengerWasAtPlaceInput>;
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

export type AtTimeAtPlaceProofCreateWithoutPassengerReservationInput = {
  DriverDisclaimReservation?: InputMaybe<DriverDisclaimReservationCreateNestedOneWithoutWasAtPlaceInDisclaimInput>;
  DriverReservation?: InputMaybe<ReservationCreateNestedOneWithoutDriverWasAtPlaceInput>;
  PassengerDisclaimReservation?: InputMaybe<PassengerDisclaimReservationCreateNestedOneWithoutWasAtPlaceInDisclaimInput>;
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

export type AtTimeAtPlaceProofOrderByWithRelationInput = {
  DriverDisclaimReservation?: InputMaybe<DriverDisclaimReservationOrderByWithRelationInput>;
  DriverReservation?: InputMaybe<ReservationOrderByWithRelationInput>;
  PassengerDisclaimReservation?: InputMaybe<PassengerDisclaimReservationOrderByWithRelationInput>;
  PassengerReservation?: InputMaybe<ReservationOrderByWithRelationInput>;
  date?: InputMaybe<SortOrder>;
  driverDisclaimReservationId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  passengerDisclaimReservationId?: InputMaybe<SortOrder>;
};

export type AtTimeAtPlaceProofRelationFilter = {
  is?: InputMaybe<AtTimeAtPlaceProofWhereInput>;
  isNot?: InputMaybe<AtTimeAtPlaceProofWhereInput>;
};

export type AtTimeAtPlaceProofUpdateOneWithoutDriverDisclaimReservationInput = {
  connect?: InputMaybe<AtTimeAtPlaceProofWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AtTimeAtPlaceProofCreateOrConnectWithoutDriverDisclaimReservationInput>;
  create?: InputMaybe<AtTimeAtPlaceProofCreateWithoutDriverDisclaimReservationInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<AtTimeAtPlaceProofUpdateWithoutDriverDisclaimReservationInput>;
  upsert?: InputMaybe<AtTimeAtPlaceProofUpsertWithoutDriverDisclaimReservationInput>;
};

export type AtTimeAtPlaceProofUpdateOneWithoutDriverReservationInput = {
  connect?: InputMaybe<AtTimeAtPlaceProofWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AtTimeAtPlaceProofCreateOrConnectWithoutDriverReservationInput>;
  create?: InputMaybe<AtTimeAtPlaceProofCreateWithoutDriverReservationInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<AtTimeAtPlaceProofUpdateWithoutDriverReservationInput>;
  upsert?: InputMaybe<AtTimeAtPlaceProofUpsertWithoutDriverReservationInput>;
};

export type AtTimeAtPlaceProofUpdateOneWithoutPassengerDisclaimReservationInput = {
  connect?: InputMaybe<AtTimeAtPlaceProofWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AtTimeAtPlaceProofCreateOrConnectWithoutPassengerDisclaimReservationInput>;
  create?: InputMaybe<AtTimeAtPlaceProofCreateWithoutPassengerDisclaimReservationInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<AtTimeAtPlaceProofUpdateWithoutPassengerDisclaimReservationInput>;
  upsert?: InputMaybe<AtTimeAtPlaceProofUpsertWithoutPassengerDisclaimReservationInput>;
};

export type AtTimeAtPlaceProofUpdateOneWithoutPassengerReservationInput = {
  connect?: InputMaybe<AtTimeAtPlaceProofWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AtTimeAtPlaceProofCreateOrConnectWithoutPassengerReservationInput>;
  create?: InputMaybe<AtTimeAtPlaceProofCreateWithoutPassengerReservationInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<AtTimeAtPlaceProofUpdateWithoutPassengerReservationInput>;
  upsert?: InputMaybe<AtTimeAtPlaceProofUpsertWithoutPassengerReservationInput>;
};

export type AtTimeAtPlaceProofUpdateWithoutDriverDisclaimReservationInput = {
  DriverReservation?: InputMaybe<ReservationUpdateOneWithoutDriverWasAtPlaceInput>;
  PassengerDisclaimReservation?: InputMaybe<PassengerDisclaimReservationUpdateOneWithoutWasAtPlaceInDisclaimInput>;
  PassengerReservation?: InputMaybe<ReservationUpdateOneWithoutPassengerWasAtPlaceInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type AtTimeAtPlaceProofUpdateWithoutDriverReservationInput = {
  DriverDisclaimReservation?: InputMaybe<DriverDisclaimReservationUpdateOneWithoutWasAtPlaceInDisclaimInput>;
  PassengerDisclaimReservation?: InputMaybe<PassengerDisclaimReservationUpdateOneWithoutWasAtPlaceInDisclaimInput>;
  PassengerReservation?: InputMaybe<ReservationUpdateOneWithoutPassengerWasAtPlaceInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type AtTimeAtPlaceProofUpdateWithoutPassengerDisclaimReservationInput = {
  DriverDisclaimReservation?: InputMaybe<DriverDisclaimReservationUpdateOneWithoutWasAtPlaceInDisclaimInput>;
  DriverReservation?: InputMaybe<ReservationUpdateOneWithoutDriverWasAtPlaceInput>;
  PassengerReservation?: InputMaybe<ReservationUpdateOneWithoutPassengerWasAtPlaceInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type AtTimeAtPlaceProofUpdateWithoutPassengerReservationInput = {
  DriverDisclaimReservation?: InputMaybe<DriverDisclaimReservationUpdateOneWithoutWasAtPlaceInDisclaimInput>;
  DriverReservation?: InputMaybe<ReservationUpdateOneWithoutDriverWasAtPlaceInput>;
  PassengerDisclaimReservation?: InputMaybe<PassengerDisclaimReservationUpdateOneWithoutWasAtPlaceInDisclaimInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type AtTimeAtPlaceProofUpsertWithoutDriverDisclaimReservationInput = {
  create: AtTimeAtPlaceProofCreateWithoutDriverDisclaimReservationInput;
  update: AtTimeAtPlaceProofUpdateWithoutDriverDisclaimReservationInput;
};

export type AtTimeAtPlaceProofUpsertWithoutDriverReservationInput = {
  create: AtTimeAtPlaceProofCreateWithoutDriverReservationInput;
  update: AtTimeAtPlaceProofUpdateWithoutDriverReservationInput;
};

export type AtTimeAtPlaceProofUpsertWithoutPassengerDisclaimReservationInput = {
  create: AtTimeAtPlaceProofCreateWithoutPassengerDisclaimReservationInput;
  update: AtTimeAtPlaceProofUpdateWithoutPassengerDisclaimReservationInput;
};

export type AtTimeAtPlaceProofUpsertWithoutPassengerReservationInput = {
  create: AtTimeAtPlaceProofCreateWithoutPassengerReservationInput;
  update: AtTimeAtPlaceProofUpdateWithoutPassengerReservationInput;
};

export type AtTimeAtPlaceProofWhereInput = {
  AND?: InputMaybe<Array<AtTimeAtPlaceProofWhereInput>>;
  DriverDisclaimReservation?: InputMaybe<DriverDisclaimReservationRelationFilter>;
  DriverReservation?: InputMaybe<ReservationRelationFilter>;
  NOT?: InputMaybe<Array<AtTimeAtPlaceProofWhereInput>>;
  OR?: InputMaybe<Array<AtTimeAtPlaceProofWhereInput>>;
  PassengerDisclaimReservation?: InputMaybe<PassengerDisclaimReservationRelationFilter>;
  PassengerReservation?: InputMaybe<ReservationRelationFilter>;
  date?: InputMaybe<DateTimeFilter>;
  driverDisclaimReservationId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  latitude?: InputMaybe<FloatNullableFilter>;
  longitude?: InputMaybe<FloatNullableFilter>;
  passengerDisclaimReservationId?: InputMaybe<StringNullableFilter>;
};

export type AtTimeAtPlaceProofWhereUniqueInput = {
  driverDisclaimReservationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  passengerDisclaimReservationId?: InputMaybe<Scalars['String']>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type Conversation = {
  __typename?: 'Conversation';
  _count?: Maybe<ConversationCount>;
  createdAt: Scalars['DateTime'];
  fragment: Fragment;
  fragmentId: Scalars['String'];
  id: Scalars['String'];
  isClosed: Scalars['Boolean'];
  isConfirmed: Scalars['Boolean'];
  lastMessageDate: Scalars['DateTime'];
  messages: Array<Message>;
  userConductor: User;
  userConductorId: Scalars['String'];
  userPassenger: User;
  userPassengerId: Scalars['String'];
};


export type ConversationMessagesArgs = {
  cursor?: InputMaybe<MessageWhereUniqueInput>;
  distinct?: InputMaybe<Array<MessageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MessageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MessageWhereInput>;
};

export type ConversationCount = {
  __typename?: 'ConversationCount';
  messages: Scalars['Int'];
};

export type ConversationCreateManyFragmentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']>;
  lastMessageDate?: InputMaybe<Scalars['DateTime']>;
  userConductorId: Scalars['String'];
  userPassengerId: Scalars['String'];
};

export type ConversationCreateManyFragmentInputEnvelope = {
  data: Array<ConversationCreateManyFragmentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ConversationCreateManyUserConductorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  fragmentId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']>;
  lastMessageDate?: InputMaybe<Scalars['DateTime']>;
  userPassengerId: Scalars['String'];
};

export type ConversationCreateManyUserConductorInputEnvelope = {
  data: Array<ConversationCreateManyUserConductorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ConversationCreateManyUserPassengerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  fragmentId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']>;
  lastMessageDate?: InputMaybe<Scalars['DateTime']>;
  userConductorId: Scalars['String'];
};

export type ConversationCreateManyUserPassengerInputEnvelope = {
  data: Array<ConversationCreateManyUserPassengerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ConversationCreateNestedManyWithoutFragmentInput = {
  connect?: InputMaybe<Array<ConversationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConversationCreateOrConnectWithoutFragmentInput>>;
  create?: InputMaybe<Array<ConversationCreateWithoutFragmentInput>>;
  createMany?: InputMaybe<ConversationCreateManyFragmentInputEnvelope>;
};

export type ConversationCreateNestedManyWithoutUserConductorInput = {
  connect?: InputMaybe<Array<ConversationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConversationCreateOrConnectWithoutUserConductorInput>>;
  create?: InputMaybe<Array<ConversationCreateWithoutUserConductorInput>>;
  createMany?: InputMaybe<ConversationCreateManyUserConductorInputEnvelope>;
};

export type ConversationCreateNestedManyWithoutUserPassengerInput = {
  connect?: InputMaybe<Array<ConversationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConversationCreateOrConnectWithoutUserPassengerInput>>;
  create?: InputMaybe<Array<ConversationCreateWithoutUserPassengerInput>>;
  createMany?: InputMaybe<ConversationCreateManyUserPassengerInputEnvelope>;
};

export type ConversationCreateNestedOneWithoutMessagesInput = {
  connect?: InputMaybe<ConversationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConversationCreateOrConnectWithoutMessagesInput>;
  create?: InputMaybe<ConversationCreateWithoutMessagesInput>;
};

export type ConversationCreateOrConnectWithoutFragmentInput = {
  create: ConversationCreateWithoutFragmentInput;
  where: ConversationWhereUniqueInput;
};

export type ConversationCreateOrConnectWithoutMessagesInput = {
  create: ConversationCreateWithoutMessagesInput;
  where: ConversationWhereUniqueInput;
};

export type ConversationCreateOrConnectWithoutUserConductorInput = {
  create: ConversationCreateWithoutUserConductorInput;
  where: ConversationWhereUniqueInput;
};

export type ConversationCreateOrConnectWithoutUserPassengerInput = {
  create: ConversationCreateWithoutUserPassengerInput;
  where: ConversationWhereUniqueInput;
};

export type ConversationCreateWithoutFragmentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']>;
  lastMessageDate?: InputMaybe<Scalars['DateTime']>;
  messages?: InputMaybe<MessageCreateNestedManyWithoutConversationInput>;
  userConductor: UserCreateNestedOneWithoutConversationAsConductorInput;
  userPassenger: UserCreateNestedOneWithoutConversationAsPassengerInput;
};

export type ConversationCreateWithoutMessagesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  fragment: FragmentCreateNestedOneWithoutConversationInput;
  id?: InputMaybe<Scalars['String']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']>;
  lastMessageDate?: InputMaybe<Scalars['DateTime']>;
  userConductor: UserCreateNestedOneWithoutConversationAsConductorInput;
  userPassenger: UserCreateNestedOneWithoutConversationAsPassengerInput;
};

export type ConversationCreateWithoutUserConductorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  fragment: FragmentCreateNestedOneWithoutConversationInput;
  id?: InputMaybe<Scalars['String']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']>;
  lastMessageDate?: InputMaybe<Scalars['DateTime']>;
  messages?: InputMaybe<MessageCreateNestedManyWithoutConversationInput>;
  userPassenger: UserCreateNestedOneWithoutConversationAsPassengerInput;
};

export type ConversationCreateWithoutUserPassengerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  fragment: FragmentCreateNestedOneWithoutConversationInput;
  id?: InputMaybe<Scalars['String']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']>;
  lastMessageDate?: InputMaybe<Scalars['DateTime']>;
  messages?: InputMaybe<MessageCreateNestedManyWithoutConversationInput>;
  userConductor: UserCreateNestedOneWithoutConversationAsConductorInput;
};

export type ConversationListRelationFilter = {
  every?: InputMaybe<ConversationWhereInput>;
  none?: InputMaybe<ConversationWhereInput>;
  some?: InputMaybe<ConversationWhereInput>;
};

export type ConversationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ConversationOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  fragment?: InputMaybe<FragmentOrderByWithRelationInput>;
  fragmentId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isClosed?: InputMaybe<SortOrder>;
  isConfirmed?: InputMaybe<SortOrder>;
  lastMessageDate?: InputMaybe<SortOrder>;
  messages?: InputMaybe<MessageOrderByRelationAggregateInput>;
  userConductor?: InputMaybe<UserOrderByWithRelationInput>;
  userConductorId?: InputMaybe<SortOrder>;
  userPassenger?: InputMaybe<UserOrderByWithRelationInput>;
  userPassengerId?: InputMaybe<SortOrder>;
};

export type ConversationRelationFilter = {
  is?: InputMaybe<ConversationWhereInput>;
  isNot?: InputMaybe<ConversationWhereInput>;
};

export enum ConversationScalarFieldEnum {
  CreatedAt = 'createdAt',
  FragmentId = 'fragmentId',
  Id = 'id',
  IsClosed = 'isClosed',
  IsConfirmed = 'isConfirmed',
  LastMessageDate = 'lastMessageDate',
  UserConductorId = 'userConductorId',
  UserPassengerId = 'userPassengerId'
}

export type ConversationScalarWhereInput = {
  AND?: InputMaybe<Array<ConversationScalarWhereInput>>;
  NOT?: InputMaybe<Array<ConversationScalarWhereInput>>;
  OR?: InputMaybe<Array<ConversationScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fragmentId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isClosed?: InputMaybe<BoolFilter>;
  isConfirmed?: InputMaybe<BoolFilter>;
  lastMessageDate?: InputMaybe<DateTimeFilter>;
  userConductorId?: InputMaybe<StringFilter>;
  userPassengerId?: InputMaybe<StringFilter>;
};

export type ConversationUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isClosed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isConfirmed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastMessageDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ConversationUpdateManyWithWhereWithoutFragmentInput = {
  data: ConversationUpdateManyMutationInput;
  where: ConversationScalarWhereInput;
};

export type ConversationUpdateManyWithWhereWithoutUserConductorInput = {
  data: ConversationUpdateManyMutationInput;
  where: ConversationScalarWhereInput;
};

export type ConversationUpdateManyWithWhereWithoutUserPassengerInput = {
  data: ConversationUpdateManyMutationInput;
  where: ConversationScalarWhereInput;
};

export type ConversationUpdateManyWithoutFragmentInput = {
  connect?: InputMaybe<Array<ConversationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConversationCreateOrConnectWithoutFragmentInput>>;
  create?: InputMaybe<Array<ConversationCreateWithoutFragmentInput>>;
  createMany?: InputMaybe<ConversationCreateManyFragmentInputEnvelope>;
  delete?: InputMaybe<Array<ConversationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ConversationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ConversationWhereUniqueInput>>;
  set?: InputMaybe<Array<ConversationWhereUniqueInput>>;
  update?: InputMaybe<Array<ConversationUpdateWithWhereUniqueWithoutFragmentInput>>;
  updateMany?: InputMaybe<Array<ConversationUpdateManyWithWhereWithoutFragmentInput>>;
  upsert?: InputMaybe<Array<ConversationUpsertWithWhereUniqueWithoutFragmentInput>>;
};

export type ConversationUpdateManyWithoutUserConductorInput = {
  connect?: InputMaybe<Array<ConversationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConversationCreateOrConnectWithoutUserConductorInput>>;
  create?: InputMaybe<Array<ConversationCreateWithoutUserConductorInput>>;
  createMany?: InputMaybe<ConversationCreateManyUserConductorInputEnvelope>;
  delete?: InputMaybe<Array<ConversationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ConversationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ConversationWhereUniqueInput>>;
  set?: InputMaybe<Array<ConversationWhereUniqueInput>>;
  update?: InputMaybe<Array<ConversationUpdateWithWhereUniqueWithoutUserConductorInput>>;
  updateMany?: InputMaybe<Array<ConversationUpdateManyWithWhereWithoutUserConductorInput>>;
  upsert?: InputMaybe<Array<ConversationUpsertWithWhereUniqueWithoutUserConductorInput>>;
};

export type ConversationUpdateManyWithoutUserPassengerInput = {
  connect?: InputMaybe<Array<ConversationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConversationCreateOrConnectWithoutUserPassengerInput>>;
  create?: InputMaybe<Array<ConversationCreateWithoutUserPassengerInput>>;
  createMany?: InputMaybe<ConversationCreateManyUserPassengerInputEnvelope>;
  delete?: InputMaybe<Array<ConversationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ConversationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ConversationWhereUniqueInput>>;
  set?: InputMaybe<Array<ConversationWhereUniqueInput>>;
  update?: InputMaybe<Array<ConversationUpdateWithWhereUniqueWithoutUserPassengerInput>>;
  updateMany?: InputMaybe<Array<ConversationUpdateManyWithWhereWithoutUserPassengerInput>>;
  upsert?: InputMaybe<Array<ConversationUpsertWithWhereUniqueWithoutUserPassengerInput>>;
};

export type ConversationUpdateOneWithoutMessagesInput = {
  connect?: InputMaybe<ConversationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConversationCreateOrConnectWithoutMessagesInput>;
  create?: InputMaybe<ConversationCreateWithoutMessagesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ConversationUpdateWithoutMessagesInput>;
  upsert?: InputMaybe<ConversationUpsertWithoutMessagesInput>;
};

export type ConversationUpdateWithWhereUniqueWithoutFragmentInput = {
  data: ConversationUpdateWithoutFragmentInput;
  where: ConversationWhereUniqueInput;
};

export type ConversationUpdateWithWhereUniqueWithoutUserConductorInput = {
  data: ConversationUpdateWithoutUserConductorInput;
  where: ConversationWhereUniqueInput;
};

export type ConversationUpdateWithWhereUniqueWithoutUserPassengerInput = {
  data: ConversationUpdateWithoutUserPassengerInput;
  where: ConversationWhereUniqueInput;
};

export type ConversationUpdateWithoutFragmentInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isClosed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isConfirmed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastMessageDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  messages?: InputMaybe<MessageUpdateManyWithoutConversationInput>;
  userConductor?: InputMaybe<UserUpdateOneRequiredWithoutConversationAsConductorInput>;
  userPassenger?: InputMaybe<UserUpdateOneRequiredWithoutConversationAsPassengerInput>;
};

export type ConversationUpdateWithoutMessagesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fragment?: InputMaybe<FragmentUpdateOneRequiredWithoutConversationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isClosed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isConfirmed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastMessageDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userConductor?: InputMaybe<UserUpdateOneRequiredWithoutConversationAsConductorInput>;
  userPassenger?: InputMaybe<UserUpdateOneRequiredWithoutConversationAsPassengerInput>;
};

export type ConversationUpdateWithoutUserConductorInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fragment?: InputMaybe<FragmentUpdateOneRequiredWithoutConversationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isClosed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isConfirmed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastMessageDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  messages?: InputMaybe<MessageUpdateManyWithoutConversationInput>;
  userPassenger?: InputMaybe<UserUpdateOneRequiredWithoutConversationAsPassengerInput>;
};

export type ConversationUpdateWithoutUserPassengerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fragment?: InputMaybe<FragmentUpdateOneRequiredWithoutConversationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isClosed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isConfirmed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastMessageDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  messages?: InputMaybe<MessageUpdateManyWithoutConversationInput>;
  userConductor?: InputMaybe<UserUpdateOneRequiredWithoutConversationAsConductorInput>;
};

export type ConversationUpsertWithWhereUniqueWithoutFragmentInput = {
  create: ConversationCreateWithoutFragmentInput;
  update: ConversationUpdateWithoutFragmentInput;
  where: ConversationWhereUniqueInput;
};

export type ConversationUpsertWithWhereUniqueWithoutUserConductorInput = {
  create: ConversationCreateWithoutUserConductorInput;
  update: ConversationUpdateWithoutUserConductorInput;
  where: ConversationWhereUniqueInput;
};

export type ConversationUpsertWithWhereUniqueWithoutUserPassengerInput = {
  create: ConversationCreateWithoutUserPassengerInput;
  update: ConversationUpdateWithoutUserPassengerInput;
  where: ConversationWhereUniqueInput;
};

export type ConversationUpsertWithoutMessagesInput = {
  create: ConversationCreateWithoutMessagesInput;
  update: ConversationUpdateWithoutMessagesInput;
};

export type ConversationWhereInput = {
  AND?: InputMaybe<Array<ConversationWhereInput>>;
  NOT?: InputMaybe<Array<ConversationWhereInput>>;
  OR?: InputMaybe<Array<ConversationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fragment?: InputMaybe<FragmentRelationFilter>;
  fragmentId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isClosed?: InputMaybe<BoolFilter>;
  isConfirmed?: InputMaybe<BoolFilter>;
  lastMessageDate?: InputMaybe<DateTimeFilter>;
  messages?: InputMaybe<MessageListRelationFilter>;
  userConductor?: InputMaybe<UserRelationFilter>;
  userConductorId?: InputMaybe<StringFilter>;
  userPassenger?: InputMaybe<UserRelationFilter>;
  userPassengerId?: InputMaybe<StringFilter>;
};

export type ConversationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Coordinate = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export enum Country {
  Arg = 'ARG'
}

export type CreateConversationResult = CreateConversationResultError | CreateConversationResultSuccess;

export type CreateConversationResultError = {
  __typename?: 'CreateConversationResultError';
  code: CreateConversationResultErrorDto;
};

export enum CreateConversationResultErrorDto {
  InternalError = 'INTERNAL_ERROR',
  NoPassenger = 'NO_PASSENGER',
  TravelStartedYet = 'TRAVEL_STARTED_YET'
}

export type CreateConversationResultSuccess = {
  __typename?: 'CreateConversationResultSuccess';
  conversation: Conversation;
};

export type CreateDriveScoreResult = CreateDriveScoreResultError | CreateDriveScoreResultSuccess;

export type CreateDriveScoreResultError = {
  __typename?: 'CreateDriveScoreResultError';
  code: CreateDriveScoreResultErrorDto;
};

export enum CreateDriveScoreResultErrorDto {
  AnotherScoreExists = 'ANOTHER_SCORE_EXISTS',
  BadReservationStatus = 'BAD_RESERVATION_STATUS',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  TravelNotFinishedYet = 'TRAVEL_NOT_FINISHED_YET',
  UnauthorizedToModify = 'UNAUTHORIZED_TO_MODIFY'
}

export type CreateDriveScoreResultSuccess = {
  __typename?: 'CreateDriveScoreResultSuccess';
  score: Score;
};

export type CreateMessageResult = CreateMessageResultError | CreateMessageResultSuccess;

export type CreateMessageResultError = {
  __typename?: 'CreateMessageResultError';
  code: CreateMessageResultErrorDto;
};

export enum CreateMessageResultErrorDto {
  ConversationClosed = 'CONVERSATION_CLOSED',
  InternalError = 'INTERNAL_ERROR',
  MaxMessagesAttempt = 'MAX_MESSAGES_ATTEMPT',
  NoConversation = 'NO_CONVERSATION',
  NoUrlOrNumberAllowed = 'NO_URL_OR_NUMBER_ALLOWED',
  Unhautorized = 'UNHAUTORIZED'
}

export type CreateMessageResultSuccess = {
  __typename?: 'CreateMessageResultSuccess';
  message: Message;
};

export type CreatePassengerScoreResult = CreatePassengerScoreResultError | CreatePassengerScoreResultSuccess;

export type CreatePassengerScoreResultError = {
  __typename?: 'CreatePassengerScoreResultError';
  code: CreatePassengerScoreResultErrorDto;
};

export enum CreatePassengerScoreResultErrorDto {
  AnotherScoreExists = 'ANOTHER_SCORE_EXISTS',
  BadReservationStatus = 'BAD_RESERVATION_STATUS',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  TravelNotFinishedYet = 'TRAVEL_NOT_FINISHED_YET',
  UnauthorizedToModify = 'UNAUTHORIZED_TO_MODIFY'
}

export type CreatePassengerScoreResultSuccess = {
  __typename?: 'CreatePassengerScoreResultSuccess';
  score: Score;
};

export type CreateReservationResult = CreateReservationResultError | CreateReservationResultSuccess;

export type CreateReservationResultError = {
  __typename?: 'CreateReservationResultError';
  code: CreateReservationResultErrorDto;
};

export enum CreateReservationResultErrorDto {
  NoMorePlaces = 'NO_MORE_PLACES',
  NoReservationPossibleInYourTravel = 'NO_RESERVATION_POSSIBLE_IN_YOUR_TRAVEL',
  UnavailableTravel = 'UNAVAILABLE_TRAVEL',
  YouAreWaitingForAcceptation = 'YOU_ARE_WAITING_FOR_ACCEPTATION',
  YouHaveAlreadyTriedToReserve = 'YOU_HAVE_ALREADY_TRIED_TO_RESERVE'
}

export type CreateReservationResultSuccess = {
  __typename?: 'CreateReservationResultSuccess';
  reservation: Reservation;
};

export type CreateUserResult = CreateUserResultError | CreateUserResultSuccess;

export type CreateUserResultError = {
  __typename?: 'CreateUserResultError';
  code: CreateUserResultErrorDto;
};

export enum CreateUserResultErrorDto {
  AdminNeeded = 'ADMIN_NEEDED',
  EmailAlreadyExists = 'EMAIL_ALREADY_EXISTS',
  FirebaseError = 'FIREBASE_ERROR'
}

export type CreateUserResultSuccess = {
  __typename?: 'CreateUserResultSuccess';
  user: User;
};

export enum Currency {
  Ars = 'ARS',
  Bob = 'BOB',
  Clp = 'CLP',
  Cop = 'COP',
  Eur = 'EUR',
  Pen = 'PEN',
  Pyg = 'PYG',
  Usd = 'USD',
  Uyu = 'UYU',
  Vef = 'VEF'
}

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DeclareConflictInReservationResult = DeclareConflictInReservationResultError | DeclareConflictInReservationResultSuccess;

export type DeclareConflictInReservationResultError = {
  __typename?: 'DeclareConflictInReservationResultError';
  code: DeclareConflictInReservationResultErrorDto;
};

export enum DeclareConflictInReservationResultErrorDto {
  AlreadyExists = 'ALREADY_EXISTS',
  AlreadyStarted = 'ALREADY_STARTED',
  InvalidReason = 'INVALID_REASON',
  InvalidStatus = 'INVALID_STATUS',
  NotFound = 'NOT_FOUND',
  TooEarly = 'TOO_EARLY',
  UnauthorizedToModify = 'UNAUTHORIZED_TO_MODIFY'
}

export type DeclareConflictInReservationResultSuccess = {
  __typename?: 'DeclareConflictInReservationResultSuccess';
  conflict: ReservationConflict;
};

export type DriverDeleteReservationResult = DriverDeleteReservationResultError | DriverDeleteReservationResultSuccess;

export type DriverDeleteReservationResultError = {
  __typename?: 'DriverDeleteReservationResultError';
  code: DriverDeleteReservationResultErrorDto;
};

export enum DriverDeleteReservationResultErrorDto {
  ImpossibleToDelete = 'IMPOSSIBLE_TO_DELETE',
  NotFound = 'NOT_FOUND',
  UnauthorizedToModify = 'UNAUTHORIZED_TO_MODIFY'
}

export type DriverDeleteReservationResultSuccess = {
  __typename?: 'DriverDeleteReservationResultSuccess';
  reservation: Reservation;
};

export type DriverDisclaimReservation = {
  __typename?: 'DriverDisclaimReservation';
  ReservationConflict: ReservationConflict;
  WasAtPlaceInDisclaim?: Maybe<AtTimeAtPlaceProof>;
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  reason: DriverReasonInConflict;
  reservationConflictId: Scalars['String'];
};

export type DriverDisclaimReservationCreateNestedOneWithoutReservationConflictInput = {
  connect?: InputMaybe<DriverDisclaimReservationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverDisclaimReservationCreateOrConnectWithoutReservationConflictInput>;
  create?: InputMaybe<DriverDisclaimReservationCreateWithoutReservationConflictInput>;
};

export type DriverDisclaimReservationCreateNestedOneWithoutWasAtPlaceInDisclaimInput = {
  connect?: InputMaybe<DriverDisclaimReservationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverDisclaimReservationCreateOrConnectWithoutWasAtPlaceInDisclaimInput>;
  create?: InputMaybe<DriverDisclaimReservationCreateWithoutWasAtPlaceInDisclaimInput>;
};

export type DriverDisclaimReservationCreateOrConnectWithoutReservationConflictInput = {
  create: DriverDisclaimReservationCreateWithoutReservationConflictInput;
  where: DriverDisclaimReservationWhereUniqueInput;
};

export type DriverDisclaimReservationCreateOrConnectWithoutWasAtPlaceInDisclaimInput = {
  create: DriverDisclaimReservationCreateWithoutWasAtPlaceInDisclaimInput;
  where: DriverDisclaimReservationWhereUniqueInput;
};

export type DriverDisclaimReservationCreateWithoutReservationConflictInput = {
  WasAtPlaceInDisclaim?: InputMaybe<AtTimeAtPlaceProofCreateNestedOneWithoutDriverDisclaimReservationInput>;
  comment: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reason: DriverReasonInConflict;
};

export type DriverDisclaimReservationCreateWithoutWasAtPlaceInDisclaimInput = {
  ReservationConflict: ReservationConflictCreateNestedOneWithoutDriverDisclaimReservationInput;
  comment: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reason: DriverReasonInConflict;
};

export type DriverDisclaimReservationOrderByWithRelationInput = {
  ReservationConflict?: InputMaybe<ReservationConflictOrderByWithRelationInput>;
  WasAtPlaceInDisclaim?: InputMaybe<AtTimeAtPlaceProofOrderByWithRelationInput>;
  comment?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  reason?: InputMaybe<SortOrder>;
  reservationConflictId?: InputMaybe<SortOrder>;
};

export type DriverDisclaimReservationRelationFilter = {
  is?: InputMaybe<DriverDisclaimReservationWhereInput>;
  isNot?: InputMaybe<DriverDisclaimReservationWhereInput>;
};

export type DriverDisclaimReservationUpdateOneWithoutReservationConflictInput = {
  connect?: InputMaybe<DriverDisclaimReservationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverDisclaimReservationCreateOrConnectWithoutReservationConflictInput>;
  create?: InputMaybe<DriverDisclaimReservationCreateWithoutReservationConflictInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DriverDisclaimReservationUpdateWithoutReservationConflictInput>;
  upsert?: InputMaybe<DriverDisclaimReservationUpsertWithoutReservationConflictInput>;
};

export type DriverDisclaimReservationUpdateOneWithoutWasAtPlaceInDisclaimInput = {
  connect?: InputMaybe<DriverDisclaimReservationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverDisclaimReservationCreateOrConnectWithoutWasAtPlaceInDisclaimInput>;
  create?: InputMaybe<DriverDisclaimReservationCreateWithoutWasAtPlaceInDisclaimInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DriverDisclaimReservationUpdateWithoutWasAtPlaceInDisclaimInput>;
  upsert?: InputMaybe<DriverDisclaimReservationUpsertWithoutWasAtPlaceInDisclaimInput>;
};

export type DriverDisclaimReservationUpdateWithoutReservationConflictInput = {
  WasAtPlaceInDisclaim?: InputMaybe<AtTimeAtPlaceProofUpdateOneWithoutDriverDisclaimReservationInput>;
  comment?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  reason?: InputMaybe<EnumDriverReasonInConflictFieldUpdateOperationsInput>;
};

export type DriverDisclaimReservationUpdateWithoutWasAtPlaceInDisclaimInput = {
  ReservationConflict?: InputMaybe<ReservationConflictUpdateOneRequiredWithoutDriverDisclaimReservationInput>;
  comment?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  reason?: InputMaybe<EnumDriverReasonInConflictFieldUpdateOperationsInput>;
};

export type DriverDisclaimReservationUpsertWithoutReservationConflictInput = {
  create: DriverDisclaimReservationCreateWithoutReservationConflictInput;
  update: DriverDisclaimReservationUpdateWithoutReservationConflictInput;
};

export type DriverDisclaimReservationUpsertWithoutWasAtPlaceInDisclaimInput = {
  create: DriverDisclaimReservationCreateWithoutWasAtPlaceInDisclaimInput;
  update: DriverDisclaimReservationUpdateWithoutWasAtPlaceInDisclaimInput;
};

export type DriverDisclaimReservationWhereInput = {
  AND?: InputMaybe<Array<DriverDisclaimReservationWhereInput>>;
  NOT?: InputMaybe<Array<DriverDisclaimReservationWhereInput>>;
  OR?: InputMaybe<Array<DriverDisclaimReservationWhereInput>>;
  ReservationConflict?: InputMaybe<ReservationConflictRelationFilter>;
  WasAtPlaceInDisclaim?: InputMaybe<AtTimeAtPlaceProofRelationFilter>;
  comment?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  reason?: InputMaybe<EnumDriverReasonInConflictFilter>;
  reservationConflictId?: InputMaybe<StringFilter>;
};

export type DriverDisclaimReservationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  reservationConflictId?: InputMaybe<Scalars['String']>;
};

export enum DriverReasonInConflict {
  DriverDidNotAssist = 'DriverDidNotAssist',
  Other = 'Other',
  PassengerDidNotAssist = 'PassengerDidNotAssist',
  PassengerIsNotTheSame = 'PassengerIsNotTheSame'
}

export type EnumAppContentTypeFilter = {
  equals?: InputMaybe<AppContentType>;
  in?: InputMaybe<Array<AppContentType>>;
  not?: InputMaybe<NestedEnumAppContentTypeFilter>;
  notIn?: InputMaybe<Array<AppContentType>>;
};

export type EnumAppTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<AppType>;
};

export type EnumAppTypeFilter = {
  equals?: InputMaybe<AppType>;
  in?: InputMaybe<Array<AppType>>;
  not?: InputMaybe<NestedEnumAppTypeFilter>;
  notIn?: InputMaybe<Array<AppType>>;
};

export type EnumCountryFilter = {
  equals?: InputMaybe<Country>;
  in?: InputMaybe<Array<Country>>;
  not?: InputMaybe<NestedEnumCountryFilter>;
  notIn?: InputMaybe<Array<Country>>;
};

export type EnumCurrencyFieldUpdateOperationsInput = {
  set?: InputMaybe<Currency>;
};

export type EnumCurrencyFilter = {
  equals?: InputMaybe<Currency>;
  in?: InputMaybe<Array<Currency>>;
  not?: InputMaybe<NestedEnumCurrencyFilter>;
  notIn?: InputMaybe<Array<Currency>>;
};

export type EnumDriverReasonInConflictFieldUpdateOperationsInput = {
  set?: InputMaybe<DriverReasonInConflict>;
};

export type EnumDriverReasonInConflictFilter = {
  equals?: InputMaybe<DriverReasonInConflict>;
  in?: InputMaybe<Array<DriverReasonInConflict>>;
  not?: InputMaybe<NestedEnumDriverReasonInConflictFilter>;
  notIn?: InputMaybe<Array<DriverReasonInConflict>>;
};

export type EnumNotificationCategoryFieldUpdateOperationsInput = {
  set?: InputMaybe<NotificationCategory>;
};

export type EnumNotificationCategoryFilter = {
  equals?: InputMaybe<NotificationCategory>;
  in?: InputMaybe<Array<NotificationCategory>>;
  not?: InputMaybe<NestedEnumNotificationCategoryFilter>;
  notIn?: InputMaybe<Array<NotificationCategory>>;
};

export type EnumNotificationObjectReferenceTypeNullableFilter = {
  equals?: InputMaybe<NotificationObjectReferenceType>;
  in?: InputMaybe<Array<NotificationObjectReferenceType>>;
  not?: InputMaybe<NestedEnumNotificationObjectReferenceTypeNullableFilter>;
  notIn?: InputMaybe<Array<NotificationObjectReferenceType>>;
};

export type EnumPassengerReasonInConflictFieldUpdateOperationsInput = {
  set?: InputMaybe<PassengerReasonInConflict>;
};

export type EnumPassengerReasonInConflictFilter = {
  equals?: InputMaybe<PassengerReasonInConflict>;
  in?: InputMaybe<Array<PassengerReasonInConflict>>;
  not?: InputMaybe<NestedEnumPassengerReasonInConflictFilter>;
  notIn?: InputMaybe<Array<PassengerReasonInConflict>>;
};

export type EnumReservationAcceptanceStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ReservationAcceptanceStatus>;
};

export type EnumReservationAcceptanceStatusFilter = {
  equals?: InputMaybe<ReservationAcceptanceStatus>;
  in?: InputMaybe<Array<ReservationAcceptanceStatus>>;
  not?: InputMaybe<NestedEnumReservationAcceptanceStatusFilter>;
  notIn?: InputMaybe<Array<ReservationAcceptanceStatus>>;
};

export type EnumReservationPaymentStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ReservationPaymentStatus>;
};

export type EnumReservationPaymentStatusFilter = {
  equals?: InputMaybe<ReservationPaymentStatus>;
  in?: InputMaybe<Array<ReservationPaymentStatus>>;
  not?: InputMaybe<NestedEnumReservationPaymentStatusFilter>;
  notIn?: InputMaybe<Array<ReservationPaymentStatus>>;
};

export type EnumReservationPaymentStatusNullableFilter = {
  equals?: InputMaybe<ReservationPaymentStatus>;
  in?: InputMaybe<Array<ReservationPaymentStatus>>;
  not?: InputMaybe<NestedEnumReservationPaymentStatusNullableFilter>;
  notIn?: InputMaybe<Array<ReservationPaymentStatus>>;
};

export type EnumReservationStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ReservationStatus>;
};

export type EnumReservationStatusFilter = {
  equals?: InputMaybe<ReservationStatus>;
  in?: InputMaybe<Array<ReservationStatus>>;
  not?: InputMaybe<NestedEnumReservationStatusFilter>;
  notIn?: InputMaybe<Array<ReservationStatus>>;
};

export type EnumReservationStatusNullableFilter = {
  equals?: InputMaybe<ReservationStatus>;
  in?: InputMaybe<Array<ReservationStatus>>;
  not?: InputMaybe<NestedEnumReservationStatusNullableFilter>;
  notIn?: InputMaybe<Array<ReservationStatus>>;
};

export type EnumRoleFieldUpdateOperationsInput = {
  set?: InputMaybe<Role>;
};

export type EnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type EnumTravelPaymentStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<TravelPaymentStatus>;
};

export type EnumTravelPaymentStatusFilter = {
  equals?: InputMaybe<TravelPaymentStatus>;
  in?: InputMaybe<Array<TravelPaymentStatus>>;
  not?: InputMaybe<NestedEnumTravelPaymentStatusFilter>;
  notIn?: InputMaybe<Array<TravelPaymentStatus>>;
};

export type EnumTravelPaymentTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<TravelPaymentType>;
};

export type EnumTravelPaymentTypeFilter = {
  equals?: InputMaybe<TravelPaymentType>;
  in?: InputMaybe<Array<TravelPaymentType>>;
  not?: InputMaybe<NestedEnumTravelPaymentTypeFilter>;
  notIn?: InputMaybe<Array<TravelPaymentType>>;
};

export type EnumTravelStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<TravelStatus>;
};

export type EnumTravelStatusFilter = {
  equals?: InputMaybe<TravelStatus>;
  in?: InputMaybe<Array<TravelStatus>>;
  not?: InputMaybe<NestedEnumTravelStatusFilter>;
  notIn?: InputMaybe<Array<TravelStatus>>;
};

export type EnumUserStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<UserStatus>;
};

export type EnumUserStatusFilter = {
  equals?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  not?: InputMaybe<NestedEnumUserStatusFilter>;
  notIn?: InputMaybe<Array<UserStatus>>;
};

export type EnumUserTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<UserType>;
};

export type EnumUserTypeFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type Fragment = {
  __typename?: 'Fragment';
  Conversation: Array<Conversation>;
  DirectReservation: Array<Reservation>;
  IndirectReservation: Array<FragmentsInReservation>;
  Traject?: Maybe<Traject>;
  _count?: Maybe<FragmentCount>;
  endStep: Step;
  endStepId: Scalars['String'];
  id: Scalars['String'];
  places: Scalars['Int'];
  price: Scalars['Float'];
  startStep: Step;
  startStepId: Scalars['String'];
  trajectId?: Maybe<Scalars['String']>;
};


export type FragmentConversationArgs = {
  cursor?: InputMaybe<ConversationWhereUniqueInput>;
  distinct?: InputMaybe<Array<ConversationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ConversationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ConversationWhereInput>;
};


export type FragmentDirectReservationArgs = {
  cursor?: InputMaybe<ReservationWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReservationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReservationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReservationWhereInput>;
};


export type FragmentIndirectReservationArgs = {
  cursor?: InputMaybe<FragmentsInReservationWhereUniqueInput>;
  distinct?: InputMaybe<Array<FragmentsInReservationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FragmentsInReservationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FragmentsInReservationWhereInput>;
};

export type FragmentCount = {
  __typename?: 'FragmentCount';
  Conversation: Scalars['Int'];
  DirectReservation: Scalars['Int'];
  IndirectReservation: Scalars['Int'];
};

export type FragmentCreateManyEndStepInput = {
  id?: InputMaybe<Scalars['String']>;
  places: Scalars['Int'];
  price: Scalars['Float'];
  startStepId: Scalars['String'];
  trajectId?: InputMaybe<Scalars['String']>;
};

export type FragmentCreateManyEndStepInputEnvelope = {
  data: Array<FragmentCreateManyEndStepInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FragmentCreateManyStartStepInput = {
  endStepId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  places: Scalars['Int'];
  price: Scalars['Float'];
  trajectId?: InputMaybe<Scalars['String']>;
};

export type FragmentCreateManyStartStepInputEnvelope = {
  data: Array<FragmentCreateManyStartStepInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FragmentCreateManyTrajectInput = {
  endStepId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  places: Scalars['Int'];
  price: Scalars['Float'];
  startStepId: Scalars['String'];
};

export type FragmentCreateManyTrajectInputEnvelope = {
  data: Array<FragmentCreateManyTrajectInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FragmentCreateNestedManyWithoutEndStepInput = {
  connect?: InputMaybe<Array<FragmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FragmentCreateOrConnectWithoutEndStepInput>>;
  create?: InputMaybe<Array<FragmentCreateWithoutEndStepInput>>;
  createMany?: InputMaybe<FragmentCreateManyEndStepInputEnvelope>;
};

export type FragmentCreateNestedManyWithoutStartStepInput = {
  connect?: InputMaybe<Array<FragmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FragmentCreateOrConnectWithoutStartStepInput>>;
  create?: InputMaybe<Array<FragmentCreateWithoutStartStepInput>>;
  createMany?: InputMaybe<FragmentCreateManyStartStepInputEnvelope>;
};

export type FragmentCreateNestedManyWithoutTrajectInput = {
  connect?: InputMaybe<Array<FragmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FragmentCreateOrConnectWithoutTrajectInput>>;
  create?: InputMaybe<Array<FragmentCreateWithoutTrajectInput>>;
  createMany?: InputMaybe<FragmentCreateManyTrajectInputEnvelope>;
};

export type FragmentCreateNestedOneWithoutConversationInput = {
  connect?: InputMaybe<FragmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FragmentCreateOrConnectWithoutConversationInput>;
  create?: InputMaybe<FragmentCreateWithoutConversationInput>;
};

export type FragmentCreateNestedOneWithoutDirectReservationInput = {
  connect?: InputMaybe<FragmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FragmentCreateOrConnectWithoutDirectReservationInput>;
  create?: InputMaybe<FragmentCreateWithoutDirectReservationInput>;
};

export type FragmentCreateNestedOneWithoutIndirectReservationInput = {
  connect?: InputMaybe<FragmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FragmentCreateOrConnectWithoutIndirectReservationInput>;
  create?: InputMaybe<FragmentCreateWithoutIndirectReservationInput>;
};

export type FragmentCreateOrConnectWithoutConversationInput = {
  create: FragmentCreateWithoutConversationInput;
  where: FragmentWhereUniqueInput;
};

export type FragmentCreateOrConnectWithoutDirectReservationInput = {
  create: FragmentCreateWithoutDirectReservationInput;
  where: FragmentWhereUniqueInput;
};

export type FragmentCreateOrConnectWithoutEndStepInput = {
  create: FragmentCreateWithoutEndStepInput;
  where: FragmentWhereUniqueInput;
};

export type FragmentCreateOrConnectWithoutIndirectReservationInput = {
  create: FragmentCreateWithoutIndirectReservationInput;
  where: FragmentWhereUniqueInput;
};

export type FragmentCreateOrConnectWithoutStartStepInput = {
  create: FragmentCreateWithoutStartStepInput;
  where: FragmentWhereUniqueInput;
};

export type FragmentCreateOrConnectWithoutTrajectInput = {
  create: FragmentCreateWithoutTrajectInput;
  where: FragmentWhereUniqueInput;
};

export type FragmentCreateWithoutConversationInput = {
  DirectReservation?: InputMaybe<ReservationCreateNestedManyWithoutDirectFragmentInput>;
  IndirectReservation?: InputMaybe<FragmentsInReservationCreateNestedManyWithoutFragmentInput>;
  Traject?: InputMaybe<TrajectCreateNestedOneWithoutFragmentsInput>;
  endStep: StepCreateNestedOneWithoutPreviousFragmentInput;
  id?: InputMaybe<Scalars['String']>;
  places: Scalars['Int'];
  price: Scalars['Float'];
  startStep: StepCreateNestedOneWithoutNextFragmentInput;
};

export type FragmentCreateWithoutDirectReservationInput = {
  Conversation?: InputMaybe<ConversationCreateNestedManyWithoutFragmentInput>;
  IndirectReservation?: InputMaybe<FragmentsInReservationCreateNestedManyWithoutFragmentInput>;
  Traject?: InputMaybe<TrajectCreateNestedOneWithoutFragmentsInput>;
  endStep: StepCreateNestedOneWithoutPreviousFragmentInput;
  id?: InputMaybe<Scalars['String']>;
  places: Scalars['Int'];
  price: Scalars['Float'];
  startStep: StepCreateNestedOneWithoutNextFragmentInput;
};

export type FragmentCreateWithoutEndStepInput = {
  Conversation?: InputMaybe<ConversationCreateNestedManyWithoutFragmentInput>;
  DirectReservation?: InputMaybe<ReservationCreateNestedManyWithoutDirectFragmentInput>;
  IndirectReservation?: InputMaybe<FragmentsInReservationCreateNestedManyWithoutFragmentInput>;
  Traject?: InputMaybe<TrajectCreateNestedOneWithoutFragmentsInput>;
  id?: InputMaybe<Scalars['String']>;
  places: Scalars['Int'];
  price: Scalars['Float'];
  startStep: StepCreateNestedOneWithoutNextFragmentInput;
};

export type FragmentCreateWithoutIndirectReservationInput = {
  Conversation?: InputMaybe<ConversationCreateNestedManyWithoutFragmentInput>;
  DirectReservation?: InputMaybe<ReservationCreateNestedManyWithoutDirectFragmentInput>;
  Traject?: InputMaybe<TrajectCreateNestedOneWithoutFragmentsInput>;
  endStep: StepCreateNestedOneWithoutPreviousFragmentInput;
  id?: InputMaybe<Scalars['String']>;
  places: Scalars['Int'];
  price: Scalars['Float'];
  startStep: StepCreateNestedOneWithoutNextFragmentInput;
};

export type FragmentCreateWithoutStartStepInput = {
  Conversation?: InputMaybe<ConversationCreateNestedManyWithoutFragmentInput>;
  DirectReservation?: InputMaybe<ReservationCreateNestedManyWithoutDirectFragmentInput>;
  IndirectReservation?: InputMaybe<FragmentsInReservationCreateNestedManyWithoutFragmentInput>;
  Traject?: InputMaybe<TrajectCreateNestedOneWithoutFragmentsInput>;
  endStep: StepCreateNestedOneWithoutPreviousFragmentInput;
  id?: InputMaybe<Scalars['String']>;
  places: Scalars['Int'];
  price: Scalars['Float'];
};

export type FragmentCreateWithoutTrajectInput = {
  Conversation?: InputMaybe<ConversationCreateNestedManyWithoutFragmentInput>;
  DirectReservation?: InputMaybe<ReservationCreateNestedManyWithoutDirectFragmentInput>;
  IndirectReservation?: InputMaybe<FragmentsInReservationCreateNestedManyWithoutFragmentInput>;
  endStep: StepCreateNestedOneWithoutPreviousFragmentInput;
  id?: InputMaybe<Scalars['String']>;
  places: Scalars['Int'];
  price: Scalars['Float'];
  startStep: StepCreateNestedOneWithoutNextFragmentInput;
};

export type FragmentListRelationFilter = {
  every?: InputMaybe<FragmentWhereInput>;
  none?: InputMaybe<FragmentWhereInput>;
  some?: InputMaybe<FragmentWhereInput>;
};

export type FragmentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FragmentOrderByWithRelationInput = {
  Conversation?: InputMaybe<ConversationOrderByRelationAggregateInput>;
  DirectReservation?: InputMaybe<ReservationOrderByRelationAggregateInput>;
  IndirectReservation?: InputMaybe<FragmentsInReservationOrderByRelationAggregateInput>;
  Traject?: InputMaybe<TrajectOrderByWithRelationInput>;
  endStep?: InputMaybe<StepOrderByWithRelationInput>;
  endStepId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  places?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  startStep?: InputMaybe<StepOrderByWithRelationInput>;
  startStepId?: InputMaybe<SortOrder>;
  trajectId?: InputMaybe<SortOrder>;
};

export type FragmentRelationFilter = {
  is?: InputMaybe<FragmentWhereInput>;
  isNot?: InputMaybe<FragmentWhereInput>;
};

export enum FragmentScalarFieldEnum {
  EndStepId = 'endStepId',
  Id = 'id',
  Places = 'places',
  Price = 'price',
  StartStepId = 'startStepId',
  TrajectId = 'trajectId'
}

export type FragmentScalarWhereInput = {
  AND?: InputMaybe<Array<FragmentScalarWhereInput>>;
  NOT?: InputMaybe<Array<FragmentScalarWhereInput>>;
  OR?: InputMaybe<Array<FragmentScalarWhereInput>>;
  endStepId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  places?: InputMaybe<IntFilter>;
  price?: InputMaybe<FloatFilter>;
  startStepId?: InputMaybe<StringFilter>;
  trajectId?: InputMaybe<StringNullableFilter>;
};

export type FragmentUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type FragmentUpdateManyWithWhereWithoutEndStepInput = {
  data: FragmentUpdateManyMutationInput;
  where: FragmentScalarWhereInput;
};

export type FragmentUpdateManyWithWhereWithoutStartStepInput = {
  data: FragmentUpdateManyMutationInput;
  where: FragmentScalarWhereInput;
};

export type FragmentUpdateManyWithWhereWithoutTrajectInput = {
  data: FragmentUpdateManyMutationInput;
  where: FragmentScalarWhereInput;
};

export type FragmentUpdateManyWithoutEndStepInput = {
  connect?: InputMaybe<Array<FragmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FragmentCreateOrConnectWithoutEndStepInput>>;
  create?: InputMaybe<Array<FragmentCreateWithoutEndStepInput>>;
  createMany?: InputMaybe<FragmentCreateManyEndStepInputEnvelope>;
  delete?: InputMaybe<Array<FragmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FragmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FragmentWhereUniqueInput>>;
  set?: InputMaybe<Array<FragmentWhereUniqueInput>>;
  update?: InputMaybe<Array<FragmentUpdateWithWhereUniqueWithoutEndStepInput>>;
  updateMany?: InputMaybe<Array<FragmentUpdateManyWithWhereWithoutEndStepInput>>;
  upsert?: InputMaybe<Array<FragmentUpsertWithWhereUniqueWithoutEndStepInput>>;
};

export type FragmentUpdateManyWithoutStartStepInput = {
  connect?: InputMaybe<Array<FragmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FragmentCreateOrConnectWithoutStartStepInput>>;
  create?: InputMaybe<Array<FragmentCreateWithoutStartStepInput>>;
  createMany?: InputMaybe<FragmentCreateManyStartStepInputEnvelope>;
  delete?: InputMaybe<Array<FragmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FragmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FragmentWhereUniqueInput>>;
  set?: InputMaybe<Array<FragmentWhereUniqueInput>>;
  update?: InputMaybe<Array<FragmentUpdateWithWhereUniqueWithoutStartStepInput>>;
  updateMany?: InputMaybe<Array<FragmentUpdateManyWithWhereWithoutStartStepInput>>;
  upsert?: InputMaybe<Array<FragmentUpsertWithWhereUniqueWithoutStartStepInput>>;
};

export type FragmentUpdateManyWithoutTrajectInput = {
  connect?: InputMaybe<Array<FragmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FragmentCreateOrConnectWithoutTrajectInput>>;
  create?: InputMaybe<Array<FragmentCreateWithoutTrajectInput>>;
  createMany?: InputMaybe<FragmentCreateManyTrajectInputEnvelope>;
  delete?: InputMaybe<Array<FragmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FragmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FragmentWhereUniqueInput>>;
  set?: InputMaybe<Array<FragmentWhereUniqueInput>>;
  update?: InputMaybe<Array<FragmentUpdateWithWhereUniqueWithoutTrajectInput>>;
  updateMany?: InputMaybe<Array<FragmentUpdateManyWithWhereWithoutTrajectInput>>;
  upsert?: InputMaybe<Array<FragmentUpsertWithWhereUniqueWithoutTrajectInput>>;
};

export type FragmentUpdateOneRequiredWithoutConversationInput = {
  connect?: InputMaybe<FragmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FragmentCreateOrConnectWithoutConversationInput>;
  create?: InputMaybe<FragmentCreateWithoutConversationInput>;
  update?: InputMaybe<FragmentUpdateWithoutConversationInput>;
  upsert?: InputMaybe<FragmentUpsertWithoutConversationInput>;
};

export type FragmentUpdateOneRequiredWithoutDirectReservationInput = {
  connect?: InputMaybe<FragmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FragmentCreateOrConnectWithoutDirectReservationInput>;
  create?: InputMaybe<FragmentCreateWithoutDirectReservationInput>;
  update?: InputMaybe<FragmentUpdateWithoutDirectReservationInput>;
  upsert?: InputMaybe<FragmentUpsertWithoutDirectReservationInput>;
};

export type FragmentUpdateOneRequiredWithoutIndirectReservationInput = {
  connect?: InputMaybe<FragmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FragmentCreateOrConnectWithoutIndirectReservationInput>;
  create?: InputMaybe<FragmentCreateWithoutIndirectReservationInput>;
  update?: InputMaybe<FragmentUpdateWithoutIndirectReservationInput>;
  upsert?: InputMaybe<FragmentUpsertWithoutIndirectReservationInput>;
};

export type FragmentUpdateWithWhereUniqueWithoutEndStepInput = {
  data: FragmentUpdateWithoutEndStepInput;
  where: FragmentWhereUniqueInput;
};

export type FragmentUpdateWithWhereUniqueWithoutStartStepInput = {
  data: FragmentUpdateWithoutStartStepInput;
  where: FragmentWhereUniqueInput;
};

export type FragmentUpdateWithWhereUniqueWithoutTrajectInput = {
  data: FragmentUpdateWithoutTrajectInput;
  where: FragmentWhereUniqueInput;
};

export type FragmentUpdateWithoutConversationInput = {
  DirectReservation?: InputMaybe<ReservationUpdateManyWithoutDirectFragmentInput>;
  IndirectReservation?: InputMaybe<FragmentsInReservationUpdateManyWithoutFragmentInput>;
  Traject?: InputMaybe<TrajectUpdateOneWithoutFragmentsInput>;
  endStep?: InputMaybe<StepUpdateOneRequiredWithoutPreviousFragmentInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  startStep?: InputMaybe<StepUpdateOneRequiredWithoutNextFragmentInput>;
};

export type FragmentUpdateWithoutDirectReservationInput = {
  Conversation?: InputMaybe<ConversationUpdateManyWithoutFragmentInput>;
  IndirectReservation?: InputMaybe<FragmentsInReservationUpdateManyWithoutFragmentInput>;
  Traject?: InputMaybe<TrajectUpdateOneWithoutFragmentsInput>;
  endStep?: InputMaybe<StepUpdateOneRequiredWithoutPreviousFragmentInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  startStep?: InputMaybe<StepUpdateOneRequiredWithoutNextFragmentInput>;
};

export type FragmentUpdateWithoutEndStepInput = {
  Conversation?: InputMaybe<ConversationUpdateManyWithoutFragmentInput>;
  DirectReservation?: InputMaybe<ReservationUpdateManyWithoutDirectFragmentInput>;
  IndirectReservation?: InputMaybe<FragmentsInReservationUpdateManyWithoutFragmentInput>;
  Traject?: InputMaybe<TrajectUpdateOneWithoutFragmentsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  startStep?: InputMaybe<StepUpdateOneRequiredWithoutNextFragmentInput>;
};

export type FragmentUpdateWithoutIndirectReservationInput = {
  Conversation?: InputMaybe<ConversationUpdateManyWithoutFragmentInput>;
  DirectReservation?: InputMaybe<ReservationUpdateManyWithoutDirectFragmentInput>;
  Traject?: InputMaybe<TrajectUpdateOneWithoutFragmentsInput>;
  endStep?: InputMaybe<StepUpdateOneRequiredWithoutPreviousFragmentInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  startStep?: InputMaybe<StepUpdateOneRequiredWithoutNextFragmentInput>;
};

export type FragmentUpdateWithoutStartStepInput = {
  Conversation?: InputMaybe<ConversationUpdateManyWithoutFragmentInput>;
  DirectReservation?: InputMaybe<ReservationUpdateManyWithoutDirectFragmentInput>;
  IndirectReservation?: InputMaybe<FragmentsInReservationUpdateManyWithoutFragmentInput>;
  Traject?: InputMaybe<TrajectUpdateOneWithoutFragmentsInput>;
  endStep?: InputMaybe<StepUpdateOneRequiredWithoutPreviousFragmentInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type FragmentUpdateWithoutTrajectInput = {
  Conversation?: InputMaybe<ConversationUpdateManyWithoutFragmentInput>;
  DirectReservation?: InputMaybe<ReservationUpdateManyWithoutDirectFragmentInput>;
  IndirectReservation?: InputMaybe<FragmentsInReservationUpdateManyWithoutFragmentInput>;
  endStep?: InputMaybe<StepUpdateOneRequiredWithoutPreviousFragmentInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  startStep?: InputMaybe<StepUpdateOneRequiredWithoutNextFragmentInput>;
};

export type FragmentUpsertWithWhereUniqueWithoutEndStepInput = {
  create: FragmentCreateWithoutEndStepInput;
  update: FragmentUpdateWithoutEndStepInput;
  where: FragmentWhereUniqueInput;
};

export type FragmentUpsertWithWhereUniqueWithoutStartStepInput = {
  create: FragmentCreateWithoutStartStepInput;
  update: FragmentUpdateWithoutStartStepInput;
  where: FragmentWhereUniqueInput;
};

export type FragmentUpsertWithWhereUniqueWithoutTrajectInput = {
  create: FragmentCreateWithoutTrajectInput;
  update: FragmentUpdateWithoutTrajectInput;
  where: FragmentWhereUniqueInput;
};

export type FragmentUpsertWithoutConversationInput = {
  create: FragmentCreateWithoutConversationInput;
  update: FragmentUpdateWithoutConversationInput;
};

export type FragmentUpsertWithoutDirectReservationInput = {
  create: FragmentCreateWithoutDirectReservationInput;
  update: FragmentUpdateWithoutDirectReservationInput;
};

export type FragmentUpsertWithoutIndirectReservationInput = {
  create: FragmentCreateWithoutIndirectReservationInput;
  update: FragmentUpdateWithoutIndirectReservationInput;
};

export type FragmentWhereInput = {
  AND?: InputMaybe<Array<FragmentWhereInput>>;
  Conversation?: InputMaybe<ConversationListRelationFilter>;
  DirectReservation?: InputMaybe<ReservationListRelationFilter>;
  IndirectReservation?: InputMaybe<FragmentsInReservationListRelationFilter>;
  NOT?: InputMaybe<Array<FragmentWhereInput>>;
  OR?: InputMaybe<Array<FragmentWhereInput>>;
  Traject?: InputMaybe<TrajectRelationFilter>;
  endStep?: InputMaybe<StepRelationFilter>;
  endStepId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  places?: InputMaybe<IntFilter>;
  price?: InputMaybe<FloatFilter>;
  startStep?: InputMaybe<StepRelationFilter>;
  startStepId?: InputMaybe<StringFilter>;
  trajectId?: InputMaybe<StringNullableFilter>;
};

export type FragmentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type FragmentsInReservation = {
  __typename?: 'FragmentsInReservation';
  fragment: Fragment;
  fragmentId: Scalars['String'];
  id: Scalars['String'];
  reservation: Reservation;
  reservationId: Scalars['String'];
};

export type FragmentsInReservationCreateManyFragmentInput = {
  id?: InputMaybe<Scalars['String']>;
  reservationId: Scalars['String'];
};

export type FragmentsInReservationCreateManyFragmentInputEnvelope = {
  data: Array<FragmentsInReservationCreateManyFragmentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FragmentsInReservationCreateManyReservationInput = {
  fragmentId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
};

export type FragmentsInReservationCreateManyReservationInputEnvelope = {
  data: Array<FragmentsInReservationCreateManyReservationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FragmentsInReservationCreateNestedManyWithoutFragmentInput = {
  connect?: InputMaybe<Array<FragmentsInReservationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FragmentsInReservationCreateOrConnectWithoutFragmentInput>>;
  create?: InputMaybe<Array<FragmentsInReservationCreateWithoutFragmentInput>>;
  createMany?: InputMaybe<FragmentsInReservationCreateManyFragmentInputEnvelope>;
};

export type FragmentsInReservationCreateNestedManyWithoutReservationInput = {
  connect?: InputMaybe<Array<FragmentsInReservationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FragmentsInReservationCreateOrConnectWithoutReservationInput>>;
  create?: InputMaybe<Array<FragmentsInReservationCreateWithoutReservationInput>>;
  createMany?: InputMaybe<FragmentsInReservationCreateManyReservationInputEnvelope>;
};

export type FragmentsInReservationCreateOrConnectWithoutFragmentInput = {
  create: FragmentsInReservationCreateWithoutFragmentInput;
  where: FragmentsInReservationWhereUniqueInput;
};

export type FragmentsInReservationCreateOrConnectWithoutReservationInput = {
  create: FragmentsInReservationCreateWithoutReservationInput;
  where: FragmentsInReservationWhereUniqueInput;
};

export type FragmentsInReservationCreateWithoutFragmentInput = {
  id?: InputMaybe<Scalars['String']>;
  reservation: ReservationCreateNestedOneWithoutIndirectFragmentInput;
};

export type FragmentsInReservationCreateWithoutReservationInput = {
  fragment: FragmentCreateNestedOneWithoutIndirectReservationInput;
  id?: InputMaybe<Scalars['String']>;
};

export type FragmentsInReservationListRelationFilter = {
  every?: InputMaybe<FragmentsInReservationWhereInput>;
  none?: InputMaybe<FragmentsInReservationWhereInput>;
  some?: InputMaybe<FragmentsInReservationWhereInput>;
};

export type FragmentsInReservationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FragmentsInReservationOrderByWithRelationInput = {
  fragment?: InputMaybe<FragmentOrderByWithRelationInput>;
  fragmentId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  reservation?: InputMaybe<ReservationOrderByWithRelationInput>;
  reservationId?: InputMaybe<SortOrder>;
};

export enum FragmentsInReservationScalarFieldEnum {
  FragmentId = 'fragmentId',
  Id = 'id',
  ReservationId = 'reservationId'
}

export type FragmentsInReservationScalarWhereInput = {
  AND?: InputMaybe<Array<FragmentsInReservationScalarWhereInput>>;
  NOT?: InputMaybe<Array<FragmentsInReservationScalarWhereInput>>;
  OR?: InputMaybe<Array<FragmentsInReservationScalarWhereInput>>;
  fragmentId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  reservationId?: InputMaybe<StringFilter>;
};

export type FragmentsInReservationUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type FragmentsInReservationUpdateManyWithWhereWithoutFragmentInput = {
  data: FragmentsInReservationUpdateManyMutationInput;
  where: FragmentsInReservationScalarWhereInput;
};

export type FragmentsInReservationUpdateManyWithWhereWithoutReservationInput = {
  data: FragmentsInReservationUpdateManyMutationInput;
  where: FragmentsInReservationScalarWhereInput;
};

export type FragmentsInReservationUpdateManyWithoutFragmentInput = {
  connect?: InputMaybe<Array<FragmentsInReservationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FragmentsInReservationCreateOrConnectWithoutFragmentInput>>;
  create?: InputMaybe<Array<FragmentsInReservationCreateWithoutFragmentInput>>;
  createMany?: InputMaybe<FragmentsInReservationCreateManyFragmentInputEnvelope>;
  delete?: InputMaybe<Array<FragmentsInReservationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FragmentsInReservationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FragmentsInReservationWhereUniqueInput>>;
  set?: InputMaybe<Array<FragmentsInReservationWhereUniqueInput>>;
  update?: InputMaybe<Array<FragmentsInReservationUpdateWithWhereUniqueWithoutFragmentInput>>;
  updateMany?: InputMaybe<Array<FragmentsInReservationUpdateManyWithWhereWithoutFragmentInput>>;
  upsert?: InputMaybe<Array<FragmentsInReservationUpsertWithWhereUniqueWithoutFragmentInput>>;
};

export type FragmentsInReservationUpdateManyWithoutReservationInput = {
  connect?: InputMaybe<Array<FragmentsInReservationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FragmentsInReservationCreateOrConnectWithoutReservationInput>>;
  create?: InputMaybe<Array<FragmentsInReservationCreateWithoutReservationInput>>;
  createMany?: InputMaybe<FragmentsInReservationCreateManyReservationInputEnvelope>;
  delete?: InputMaybe<Array<FragmentsInReservationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FragmentsInReservationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FragmentsInReservationWhereUniqueInput>>;
  set?: InputMaybe<Array<FragmentsInReservationWhereUniqueInput>>;
  update?: InputMaybe<Array<FragmentsInReservationUpdateWithWhereUniqueWithoutReservationInput>>;
  updateMany?: InputMaybe<Array<FragmentsInReservationUpdateManyWithWhereWithoutReservationInput>>;
  upsert?: InputMaybe<Array<FragmentsInReservationUpsertWithWhereUniqueWithoutReservationInput>>;
};

export type FragmentsInReservationUpdateWithWhereUniqueWithoutFragmentInput = {
  data: FragmentsInReservationUpdateWithoutFragmentInput;
  where: FragmentsInReservationWhereUniqueInput;
};

export type FragmentsInReservationUpdateWithWhereUniqueWithoutReservationInput = {
  data: FragmentsInReservationUpdateWithoutReservationInput;
  where: FragmentsInReservationWhereUniqueInput;
};

export type FragmentsInReservationUpdateWithoutFragmentInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  reservation?: InputMaybe<ReservationUpdateOneRequiredWithoutIndirectFragmentInput>;
};

export type FragmentsInReservationUpdateWithoutReservationInput = {
  fragment?: InputMaybe<FragmentUpdateOneRequiredWithoutIndirectReservationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type FragmentsInReservationUpsertWithWhereUniqueWithoutFragmentInput = {
  create: FragmentsInReservationCreateWithoutFragmentInput;
  update: FragmentsInReservationUpdateWithoutFragmentInput;
  where: FragmentsInReservationWhereUniqueInput;
};

export type FragmentsInReservationUpsertWithWhereUniqueWithoutReservationInput = {
  create: FragmentsInReservationCreateWithoutReservationInput;
  update: FragmentsInReservationUpdateWithoutReservationInput;
  where: FragmentsInReservationWhereUniqueInput;
};

export type FragmentsInReservationWhereInput = {
  AND?: InputMaybe<Array<FragmentsInReservationWhereInput>>;
  NOT?: InputMaybe<Array<FragmentsInReservationWhereInput>>;
  OR?: InputMaybe<Array<FragmentsInReservationWhereInput>>;
  fragment?: InputMaybe<FragmentRelationFilter>;
  fragmentId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  reservation?: InputMaybe<ReservationRelationFilter>;
  reservationId?: InputMaybe<StringFilter>;
};

export type FragmentsInReservationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type GetTicketProofResult = GetTicketProofResultError | GetTicketProofResultSuccess;

export type GetTicketProofResultError = {
  __typename?: 'GetTicketProofResultError';
  code: GetTicketProofResultErrorDto;
};

export enum GetTicketProofResultErrorDto {
  InvalidReservationStatus = 'INVALID_RESERVATION_STATUS',
  NoReservation = 'NO_RESERVATION',
  TooEarly = 'TOO_EARLY',
  Unauthorized = 'UNAUTHORIZED'
}

export type GetTicketProofResultSuccess = {
  __typename?: 'GetTicketProofResultSuccess';
  ticket: Scalars['String'];
};

export type IAmHereResult = IAmHereResultError | IAmHereResultSuccess;

export type IAmHereResultError = {
  __typename?: 'IAmHereResultError';
  code: IAmHereResultErrorDto;
};

export enum IAmHereResultErrorDto {
  AlreadyExists = 'ALREADY_EXISTS',
  InvalidStatus = 'INVALID_STATUS',
  NotFound = 'NOT_FOUND',
  TooEarly = 'TOO_EARLY',
  TooFar = 'TOO_FAR',
  TooLate = 'TOO_LATE',
  UnauthorizedToModify = 'UNAUTHORIZED_TO_MODIFY'
}

export type IAmHereResultSuccess = {
  __typename?: 'IAmHereResultSuccess';
  proof: ReservationConflict;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type JsonFilter = {
  equals?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
};

export type MarkMessageResult = MarkMessageResultError | MarkMessageResultSuccess;

export type MarkMessageResultError = {
  __typename?: 'MarkMessageResultError';
  code: MarkMessageResultErrorDto;
};

export enum MarkMessageResultErrorDto {
  InternalError = 'INTERNAL_ERROR',
  NoConversation = 'NO_CONVERSATION',
  NoSender = 'NO_SENDER'
}

export type MarkMessageResultSuccess = {
  __typename?: 'MarkMessageResultSuccess';
  message: Message;
};

export type MarkNotificationResult = MarkNotificationResultError | MarkNotificationResultSuccess;

export type MarkNotificationResultError = {
  __typename?: 'MarkNotificationResultError';
  code: MarkNotificationResultErrorDto;
};

export enum MarkNotificationResultErrorDto {
  InternalError = 'INTERNAL_ERROR',
  NotFound = 'NOT_FOUND',
  SeenYet = 'SEEN_YET',
  Unauthorized = 'UNAUTHORIZED'
}

export type MarkNotificationResultSuccess = {
  __typename?: 'MarkNotificationResultSuccess';
  notification: Notification;
};

export type Message = {
  __typename?: 'Message';
  Conversation?: Maybe<Conversation>;
  content: Scalars['String'];
  conversationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  seen: Scalars['Boolean'];
  seenAt?: Maybe<Scalars['DateTime']>;
  sender: User;
  userId: Scalars['String'];
};

export type MessageCreateManyConversationInput = {
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  seen?: InputMaybe<Scalars['Boolean']>;
  seenAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type MessageCreateManyConversationInputEnvelope = {
  data: Array<MessageCreateManyConversationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MessageCreateManySenderInput = {
  content: Scalars['String'];
  conversationId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  seen?: InputMaybe<Scalars['Boolean']>;
  seenAt?: InputMaybe<Scalars['DateTime']>;
};

export type MessageCreateManySenderInputEnvelope = {
  data: Array<MessageCreateManySenderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MessageCreateNestedManyWithoutConversationInput = {
  connect?: InputMaybe<Array<MessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MessageCreateOrConnectWithoutConversationInput>>;
  create?: InputMaybe<Array<MessageCreateWithoutConversationInput>>;
  createMany?: InputMaybe<MessageCreateManyConversationInputEnvelope>;
};

export type MessageCreateNestedManyWithoutSenderInput = {
  connect?: InputMaybe<Array<MessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MessageCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<MessageCreateWithoutSenderInput>>;
  createMany?: InputMaybe<MessageCreateManySenderInputEnvelope>;
};

export type MessageCreateOrConnectWithoutConversationInput = {
  create: MessageCreateWithoutConversationInput;
  where: MessageWhereUniqueInput;
};

export type MessageCreateOrConnectWithoutSenderInput = {
  create: MessageCreateWithoutSenderInput;
  where: MessageWhereUniqueInput;
};

export type MessageCreateWithoutConversationInput = {
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  seen?: InputMaybe<Scalars['Boolean']>;
  seenAt?: InputMaybe<Scalars['DateTime']>;
  sender: UserCreateNestedOneWithoutMessageInput;
};

export type MessageCreateWithoutSenderInput = {
  Conversation?: InputMaybe<ConversationCreateNestedOneWithoutMessagesInput>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  seen?: InputMaybe<Scalars['Boolean']>;
  seenAt?: InputMaybe<Scalars['DateTime']>;
};

export type MessageListRelationFilter = {
  every?: InputMaybe<MessageWhereInput>;
  none?: InputMaybe<MessageWhereInput>;
  some?: InputMaybe<MessageWhereInput>;
};

export type MessageOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MessageOrderByWithRelationInput = {
  Conversation?: InputMaybe<ConversationOrderByWithRelationInput>;
  content?: InputMaybe<SortOrder>;
  conversationId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  seen?: InputMaybe<SortOrder>;
  seenAt?: InputMaybe<SortOrder>;
  sender?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum MessageScalarFieldEnum {
  Content = 'content',
  ConversationId = 'conversationId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Seen = 'seen',
  SeenAt = 'seenAt',
  UserId = 'userId'
}

export type MessageScalarWhereInput = {
  AND?: InputMaybe<Array<MessageScalarWhereInput>>;
  NOT?: InputMaybe<Array<MessageScalarWhereInput>>;
  OR?: InputMaybe<Array<MessageScalarWhereInput>>;
  content?: InputMaybe<StringFilter>;
  conversationId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  seen?: InputMaybe<BoolFilter>;
  seenAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type MessageUpdateManyMutationInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  seen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  seenAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type MessageUpdateManyWithWhereWithoutConversationInput = {
  data: MessageUpdateManyMutationInput;
  where: MessageScalarWhereInput;
};

export type MessageUpdateManyWithWhereWithoutSenderInput = {
  data: MessageUpdateManyMutationInput;
  where: MessageScalarWhereInput;
};

export type MessageUpdateManyWithoutConversationInput = {
  connect?: InputMaybe<Array<MessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MessageCreateOrConnectWithoutConversationInput>>;
  create?: InputMaybe<Array<MessageCreateWithoutConversationInput>>;
  createMany?: InputMaybe<MessageCreateManyConversationInputEnvelope>;
  delete?: InputMaybe<Array<MessageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MessageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MessageWhereUniqueInput>>;
  set?: InputMaybe<Array<MessageWhereUniqueInput>>;
  update?: InputMaybe<Array<MessageUpdateWithWhereUniqueWithoutConversationInput>>;
  updateMany?: InputMaybe<Array<MessageUpdateManyWithWhereWithoutConversationInput>>;
  upsert?: InputMaybe<Array<MessageUpsertWithWhereUniqueWithoutConversationInput>>;
};

export type MessageUpdateManyWithoutSenderInput = {
  connect?: InputMaybe<Array<MessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MessageCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<MessageCreateWithoutSenderInput>>;
  createMany?: InputMaybe<MessageCreateManySenderInputEnvelope>;
  delete?: InputMaybe<Array<MessageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MessageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MessageWhereUniqueInput>>;
  set?: InputMaybe<Array<MessageWhereUniqueInput>>;
  update?: InputMaybe<Array<MessageUpdateWithWhereUniqueWithoutSenderInput>>;
  updateMany?: InputMaybe<Array<MessageUpdateManyWithWhereWithoutSenderInput>>;
  upsert?: InputMaybe<Array<MessageUpsertWithWhereUniqueWithoutSenderInput>>;
};

export type MessageUpdateWithWhereUniqueWithoutConversationInput = {
  data: MessageUpdateWithoutConversationInput;
  where: MessageWhereUniqueInput;
};

export type MessageUpdateWithWhereUniqueWithoutSenderInput = {
  data: MessageUpdateWithoutSenderInput;
  where: MessageWhereUniqueInput;
};

export type MessageUpdateWithoutConversationInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  seen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  seenAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutMessageInput>;
};

export type MessageUpdateWithoutSenderInput = {
  Conversation?: InputMaybe<ConversationUpdateOneWithoutMessagesInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  seen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  seenAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type MessageUpsertWithWhereUniqueWithoutConversationInput = {
  create: MessageCreateWithoutConversationInput;
  update: MessageUpdateWithoutConversationInput;
  where: MessageWhereUniqueInput;
};

export type MessageUpsertWithWhereUniqueWithoutSenderInput = {
  create: MessageCreateWithoutSenderInput;
  update: MessageUpdateWithoutSenderInput;
  where: MessageWhereUniqueInput;
};

export type MessageWhereInput = {
  AND?: InputMaybe<Array<MessageWhereInput>>;
  Conversation?: InputMaybe<ConversationRelationFilter>;
  NOT?: InputMaybe<Array<MessageWhereInput>>;
  OR?: InputMaybe<Array<MessageWhereInput>>;
  content?: InputMaybe<StringFilter>;
  conversationId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  seen?: InputMaybe<BoolFilter>;
  seenAt?: InputMaybe<DateTimeNullableFilter>;
  sender?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type MessageWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  AcceptOrDeclineReservation: AcceptOrDeclineReservationResult;
  DeclareConflictInReservation: DeclareConflictInReservationResult;
  DriverDeleteReservation: DriverDeleteReservationResult;
  IAmHere: IAmHereResult;
  PCreateConversation: CreateConversationResult;
  PCreateDriverScore: CreateDriveScoreResult;
  PCreatePassengerScore: CreatePassengerScoreResult;
  PCreateReservationResolver: CreateReservationResult;
  PCreateTravel: Travel;
  PCreateUserResolver: CreateUserResult;
  PCreateUserWithGoogleResolver: CreateUserResult;
  PDeleteReservationResolver: Reservation;
  PDeleteTravel: Travel;
  PMarkMessage: MarkMessageResult;
  PMarkNotification: MarkNotificationResult;
  PSendMessage: CreateMessageResult;
  PUpdateTravel: Travel;
  PUpdateUser: UpdateUserResult;
  PVerifyCodePhoneNumber: VerifyNumberCodeResult;
  PVerifyFreePhoneNumber: VerifyNumberResult;
  PVerifyPhoneNumber: VerifyNumberResult;
  PayReservation: PayReservationResult;
  ScanTicketProofReservation: ScanTicketProofResult;
  addPrice: Price;
  createAppContent: AppContent;
  createVersionApp: VersionApp;
  createWhitelistsQueries: WhitelistsQueries;
  deleteVersionApp?: Maybe<VersionApp>;
  suscribeToPushNotification: UserPushToken;
  testPushNotifs: Scalars['Boolean'];
  unsuscribeToPushNotification: Scalars['Boolean'];
  updateAppBasicContent: AppBasicContent;
  updateAppFaqContent: AppFaqContent;
  updateReservation?: Maybe<Reservation>;
  updateTravel?: Maybe<Travel>;
  updateUser?: Maybe<User>;
  updateVersionApp?: Maybe<VersionApp>;
  updateWhitelistsQueries?: Maybe<WhitelistsQueries>;
};


export type MutationAcceptOrDeclineReservationArgs = {
  accept: Scalars['Boolean'];
  reservationId: Scalars['String'];
};


export type MutationDeclareConflictInReservationArgs = {
  AtPlaceProof?: InputMaybe<AtPlaceProof>;
  comment?: InputMaybe<Scalars['String']>;
  driverReason?: InputMaybe<DriverReasonInConflict>;
  passengerReason?: InputMaybe<PassengerReasonInConflict>;
  reservationId: Scalars['String'];
  typeOfUser: Scalars['String'];
};


export type MutationDriverDeleteReservationArgs = {
  reason: Scalars['String'];
  reservationId: Scalars['String'];
};


export type MutationIAmHereArgs = {
  AtPlaceProof: AtPlaceProof;
  reservationId: Scalars['String'];
};


export type MutationPCreateConversationArgs = {
  fragmentId: Scalars['String'];
  isDriver?: InputMaybe<Scalars['Boolean']>;
  isDriverPassengerId?: InputMaybe<Scalars['String']>;
};


export type MutationPCreateDriverScoreArgs = {
  reservationId: Scalars['String'];
  scoreMessage?: InputMaybe<Scalars['String']>;
  scoreNumber: Scalars['Float'];
};


export type MutationPCreatePassengerScoreArgs = {
  reservationId: Scalars['String'];
  scoreMessage?: InputMaybe<Scalars['String']>;
  scoreNumber: Scalars['Float'];
};


export type MutationPCreateReservationResolverArgs = {
  fragmentId: Scalars['String'];
  places: Scalars['Int'];
};


export type MutationPCreateTravelArgs = {
  data: TravelCreateInput;
};


export type MutationPCreateUserResolverArgs = {
  birthDate?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role>;
};


export type MutationPCreateUserWithGoogleResolverArgs = {
  birthDate?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};


export type MutationPDeleteReservationResolverArgs = {
  where: ReservationWhereUniqueInput;
};


export type MutationPDeleteTravelArgs = {
  where: TravelWhereUniqueInput;
};


export type MutationPMarkMessageArgs = {
  messageId: Scalars['String'];
};


export type MutationPMarkNotificationArgs = {
  notificationId: Scalars['String'];
};


export type MutationPSendMessageArgs = {
  content: Scalars['String'];
  conversationId: Scalars['String'];
};


export type MutationPUpdateTravelArgs = {
  data: TravelUpdateInput;
  where: TravelWhereUniqueInput;
};


export type MutationPUpdateUserArgs = {
  birthDate?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  photoURL?: InputMaybe<Scalars['String']>;
  secondName?: InputMaybe<Scalars['String']>;
  ville?: InputMaybe<Scalars['String']>;
};


export type MutationPVerifyCodePhoneNumberArgs = {
  code: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type MutationPVerifyFreePhoneNumberArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationPVerifyPhoneNumberArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationPayReservationArgs = {
  reservationId: Scalars['String'];
  status: EnumReservationPaymentStatusFieldUpdateOperationsInput;
};


export type MutationScanTicketProofReservationArgs = {
  proof: Scalars['String'];
};


export type MutationAddPriceArgs = {
  data: PriceCreateInput;
};


export type MutationCreateAppContentArgs = {
  data: AppContentCreateInput;
};


export type MutationCreateVersionAppArgs = {
  data: VersionAppCreateInput;
};


export type MutationCreateWhitelistsQueriesArgs = {
  data: WhitelistsQueriesCreateInput;
};


export type MutationDeleteVersionAppArgs = {
  where: VersionAppWhereUniqueInput;
};


export type MutationSuscribeToPushNotificationArgs = {
  registerToken: Scalars['String'];
};


export type MutationTestPushNotifsArgs = {
  userId: Scalars['String'];
};


export type MutationUnsuscribeToPushNotificationArgs = {
  registerToken: Scalars['String'];
};


export type MutationUpdateAppBasicContentArgs = {
  html: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  type: AppContentType;
};


export type MutationUpdateAppFaqContentArgs = {
  categories: Array<AppFaqContentCategoryInput>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateReservationArgs = {
  data: ReservationUpdateInput;
  where: ReservationWhereUniqueInput;
};


export type MutationUpdateTravelArgs = {
  data: TravelUpdateInput;
  where: TravelWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateVersionAppArgs = {
  data: VersionAppUpdateInput;
  where: VersionAppWhereUniqueInput;
};


export type MutationUpdateWhitelistsQueriesArgs = {
  data: WhitelistsQueriesUpdateInput;
  where: WhitelistsQueriesWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumAppContentTypeFilter = {
  equals?: InputMaybe<AppContentType>;
  in?: InputMaybe<Array<AppContentType>>;
  not?: InputMaybe<NestedEnumAppContentTypeFilter>;
  notIn?: InputMaybe<Array<AppContentType>>;
};

export type NestedEnumAppTypeFilter = {
  equals?: InputMaybe<AppType>;
  in?: InputMaybe<Array<AppType>>;
  not?: InputMaybe<NestedEnumAppTypeFilter>;
  notIn?: InputMaybe<Array<AppType>>;
};

export type NestedEnumCountryFilter = {
  equals?: InputMaybe<Country>;
  in?: InputMaybe<Array<Country>>;
  not?: InputMaybe<NestedEnumCountryFilter>;
  notIn?: InputMaybe<Array<Country>>;
};

export type NestedEnumCurrencyFilter = {
  equals?: InputMaybe<Currency>;
  in?: InputMaybe<Array<Currency>>;
  not?: InputMaybe<NestedEnumCurrencyFilter>;
  notIn?: InputMaybe<Array<Currency>>;
};

export type NestedEnumDriverReasonInConflictFilter = {
  equals?: InputMaybe<DriverReasonInConflict>;
  in?: InputMaybe<Array<DriverReasonInConflict>>;
  not?: InputMaybe<NestedEnumDriverReasonInConflictFilter>;
  notIn?: InputMaybe<Array<DriverReasonInConflict>>;
};

export type NestedEnumNotificationCategoryFilter = {
  equals?: InputMaybe<NotificationCategory>;
  in?: InputMaybe<Array<NotificationCategory>>;
  not?: InputMaybe<NestedEnumNotificationCategoryFilter>;
  notIn?: InputMaybe<Array<NotificationCategory>>;
};

export type NestedEnumNotificationObjectReferenceTypeNullableFilter = {
  equals?: InputMaybe<NotificationObjectReferenceType>;
  in?: InputMaybe<Array<NotificationObjectReferenceType>>;
  not?: InputMaybe<NestedEnumNotificationObjectReferenceTypeNullableFilter>;
  notIn?: InputMaybe<Array<NotificationObjectReferenceType>>;
};

export type NestedEnumPassengerReasonInConflictFilter = {
  equals?: InputMaybe<PassengerReasonInConflict>;
  in?: InputMaybe<Array<PassengerReasonInConflict>>;
  not?: InputMaybe<NestedEnumPassengerReasonInConflictFilter>;
  notIn?: InputMaybe<Array<PassengerReasonInConflict>>;
};

export type NestedEnumReservationAcceptanceStatusFilter = {
  equals?: InputMaybe<ReservationAcceptanceStatus>;
  in?: InputMaybe<Array<ReservationAcceptanceStatus>>;
  not?: InputMaybe<NestedEnumReservationAcceptanceStatusFilter>;
  notIn?: InputMaybe<Array<ReservationAcceptanceStatus>>;
};

export type NestedEnumReservationPaymentStatusFilter = {
  equals?: InputMaybe<ReservationPaymentStatus>;
  in?: InputMaybe<Array<ReservationPaymentStatus>>;
  not?: InputMaybe<NestedEnumReservationPaymentStatusFilter>;
  notIn?: InputMaybe<Array<ReservationPaymentStatus>>;
};

export type NestedEnumReservationPaymentStatusNullableFilter = {
  equals?: InputMaybe<ReservationPaymentStatus>;
  in?: InputMaybe<Array<ReservationPaymentStatus>>;
  not?: InputMaybe<NestedEnumReservationPaymentStatusNullableFilter>;
  notIn?: InputMaybe<Array<ReservationPaymentStatus>>;
};

export type NestedEnumReservationStatusFilter = {
  equals?: InputMaybe<ReservationStatus>;
  in?: InputMaybe<Array<ReservationStatus>>;
  not?: InputMaybe<NestedEnumReservationStatusFilter>;
  notIn?: InputMaybe<Array<ReservationStatus>>;
};

export type NestedEnumReservationStatusNullableFilter = {
  equals?: InputMaybe<ReservationStatus>;
  in?: InputMaybe<Array<ReservationStatus>>;
  not?: InputMaybe<NestedEnumReservationStatusNullableFilter>;
  notIn?: InputMaybe<Array<ReservationStatus>>;
};

export type NestedEnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type NestedEnumTravelPaymentStatusFilter = {
  equals?: InputMaybe<TravelPaymentStatus>;
  in?: InputMaybe<Array<TravelPaymentStatus>>;
  not?: InputMaybe<NestedEnumTravelPaymentStatusFilter>;
  notIn?: InputMaybe<Array<TravelPaymentStatus>>;
};

export type NestedEnumTravelPaymentTypeFilter = {
  equals?: InputMaybe<TravelPaymentType>;
  in?: InputMaybe<Array<TravelPaymentType>>;
  not?: InputMaybe<NestedEnumTravelPaymentTypeFilter>;
  notIn?: InputMaybe<Array<TravelPaymentType>>;
};

export type NestedEnumTravelStatusFilter = {
  equals?: InputMaybe<TravelStatus>;
  in?: InputMaybe<Array<TravelStatus>>;
  not?: InputMaybe<NestedEnumTravelStatusFilter>;
  notIn?: InputMaybe<Array<TravelStatus>>;
};

export type NestedEnumUserStatusFilter = {
  equals?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  not?: InputMaybe<NestedEnumUserStatusFilter>;
  notIn?: InputMaybe<Array<UserStatus>>;
};

export type NestedEnumUserTypeFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  ObjectReferenceId?: Maybe<Scalars['String']>;
  ObjectReferenceType?: Maybe<NotificationObjectReferenceType>;
  category: NotificationCategory;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  message: Scalars['String'];
  seen: Scalars['Boolean'];
  title: Scalars['String'];
  userRecipientId: Scalars['String'];
};

export enum NotificationCategory {
  Payment = 'PAYMENT',
  Reservation = 'RESERVATION',
  Travel = 'TRAVEL'
}

export type NotificationCreateManyUserRecipientInput = {
  ObjectReferenceId?: InputMaybe<Scalars['String']>;
  ObjectReferenceType?: InputMaybe<NotificationObjectReferenceType>;
  category: NotificationCategory;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  seen?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type NotificationCreateManyUserRecipientInputEnvelope = {
  data: Array<NotificationCreateManyUserRecipientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type NotificationCreateNestedManyWithoutUserRecipientInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutUserRecipientInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutUserRecipientInput>>;
  createMany?: InputMaybe<NotificationCreateManyUserRecipientInputEnvelope>;
};

export type NotificationCreateOrConnectWithoutUserRecipientInput = {
  create: NotificationCreateWithoutUserRecipientInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateWithoutUserRecipientInput = {
  ObjectReferenceId?: InputMaybe<Scalars['String']>;
  ObjectReferenceType?: InputMaybe<NotificationObjectReferenceType>;
  category: NotificationCategory;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  seen?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type NotificationListRelationFilter = {
  every?: InputMaybe<NotificationWhereInput>;
  none?: InputMaybe<NotificationWhereInput>;
  some?: InputMaybe<NotificationWhereInput>;
};

export enum NotificationObjectReferenceType {
  Chat = 'CHAT',
  Reservation = 'RESERVATION',
  Travel = 'TRAVEL'
}

export type NotificationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type NotificationScalarWhereInput = {
  AND?: InputMaybe<Array<NotificationScalarWhereInput>>;
  NOT?: InputMaybe<Array<NotificationScalarWhereInput>>;
  OR?: InputMaybe<Array<NotificationScalarWhereInput>>;
  ObjectReferenceId?: InputMaybe<StringNullableFilter>;
  ObjectReferenceType?: InputMaybe<EnumNotificationObjectReferenceTypeNullableFilter>;
  category?: InputMaybe<EnumNotificationCategoryFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringFilter>;
  seen?: InputMaybe<BoolFilter>;
  title?: InputMaybe<StringFilter>;
  userRecipientId?: InputMaybe<StringFilter>;
};

export type NotificationUpdateManyMutationInput = {
  ObjectReferenceId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ObjectReferenceType?: InputMaybe<NullableEnumNotificationObjectReferenceTypeFieldUpdateOperationsInput>;
  category?: InputMaybe<EnumNotificationCategoryFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  seen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type NotificationUpdateManyWithWhereWithoutUserRecipientInput = {
  data: NotificationUpdateManyMutationInput;
  where: NotificationScalarWhereInput;
};

export type NotificationUpdateManyWithoutUserRecipientInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutUserRecipientInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutUserRecipientInput>>;
  createMany?: InputMaybe<NotificationCreateManyUserRecipientInputEnvelope>;
  delete?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  update?: InputMaybe<Array<NotificationUpdateWithWhereUniqueWithoutUserRecipientInput>>;
  updateMany?: InputMaybe<Array<NotificationUpdateManyWithWhereWithoutUserRecipientInput>>;
  upsert?: InputMaybe<Array<NotificationUpsertWithWhereUniqueWithoutUserRecipientInput>>;
};

export type NotificationUpdateWithWhereUniqueWithoutUserRecipientInput = {
  data: NotificationUpdateWithoutUserRecipientInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpdateWithoutUserRecipientInput = {
  ObjectReferenceId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ObjectReferenceType?: InputMaybe<NullableEnumNotificationObjectReferenceTypeFieldUpdateOperationsInput>;
  category?: InputMaybe<EnumNotificationCategoryFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  seen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type NotificationUpsertWithWhereUniqueWithoutUserRecipientInput = {
  create: NotificationCreateWithoutUserRecipientInput;
  update: NotificationUpdateWithoutUserRecipientInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationWhereInput = {
  AND?: InputMaybe<Array<NotificationWhereInput>>;
  NOT?: InputMaybe<Array<NotificationWhereInput>>;
  OR?: InputMaybe<Array<NotificationWhereInput>>;
  ObjectReferenceId?: InputMaybe<StringNullableFilter>;
  ObjectReferenceType?: InputMaybe<EnumNotificationObjectReferenceTypeNullableFilter>;
  category?: InputMaybe<EnumNotificationCategoryFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringFilter>;
  seen?: InputMaybe<BoolFilter>;
  title?: InputMaybe<StringFilter>;
  userRecipient?: InputMaybe<UserRelationFilter>;
  userRecipientId?: InputMaybe<StringFilter>;
};

export type NotificationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type NullableEnumNotificationObjectReferenceTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<NotificationObjectReferenceType>;
};

export type NullableEnumReservationPaymentStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ReservationPaymentStatus>;
};

export type NullableEnumReservationStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ReservationStatus>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type PassengerDisclaimReservation = {
  __typename?: 'PassengerDisclaimReservation';
  ReservationConflict: ReservationConflict;
  WasAtPlaceInDisclaim?: Maybe<AtTimeAtPlaceProof>;
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  reason: PassengerReasonInConflict;
  reservationConflictId: Scalars['String'];
};

export type PassengerDisclaimReservationCreateNestedOneWithoutReservationConflictInput = {
  connect?: InputMaybe<PassengerDisclaimReservationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassengerDisclaimReservationCreateOrConnectWithoutReservationConflictInput>;
  create?: InputMaybe<PassengerDisclaimReservationCreateWithoutReservationConflictInput>;
};

export type PassengerDisclaimReservationCreateNestedOneWithoutWasAtPlaceInDisclaimInput = {
  connect?: InputMaybe<PassengerDisclaimReservationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassengerDisclaimReservationCreateOrConnectWithoutWasAtPlaceInDisclaimInput>;
  create?: InputMaybe<PassengerDisclaimReservationCreateWithoutWasAtPlaceInDisclaimInput>;
};

export type PassengerDisclaimReservationCreateOrConnectWithoutReservationConflictInput = {
  create: PassengerDisclaimReservationCreateWithoutReservationConflictInput;
  where: PassengerDisclaimReservationWhereUniqueInput;
};

export type PassengerDisclaimReservationCreateOrConnectWithoutWasAtPlaceInDisclaimInput = {
  create: PassengerDisclaimReservationCreateWithoutWasAtPlaceInDisclaimInput;
  where: PassengerDisclaimReservationWhereUniqueInput;
};

export type PassengerDisclaimReservationCreateWithoutReservationConflictInput = {
  WasAtPlaceInDisclaim?: InputMaybe<AtTimeAtPlaceProofCreateNestedOneWithoutPassengerDisclaimReservationInput>;
  comment: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reason: PassengerReasonInConflict;
};

export type PassengerDisclaimReservationCreateWithoutWasAtPlaceInDisclaimInput = {
  ReservationConflict: ReservationConflictCreateNestedOneWithoutPassengerDisclaimReservationInput;
  comment: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reason: PassengerReasonInConflict;
};

export type PassengerDisclaimReservationOrderByWithRelationInput = {
  ReservationConflict?: InputMaybe<ReservationConflictOrderByWithRelationInput>;
  WasAtPlaceInDisclaim?: InputMaybe<AtTimeAtPlaceProofOrderByWithRelationInput>;
  comment?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  reason?: InputMaybe<SortOrder>;
  reservationConflictId?: InputMaybe<SortOrder>;
};

export type PassengerDisclaimReservationRelationFilter = {
  is?: InputMaybe<PassengerDisclaimReservationWhereInput>;
  isNot?: InputMaybe<PassengerDisclaimReservationWhereInput>;
};

export type PassengerDisclaimReservationUpdateOneWithoutReservationConflictInput = {
  connect?: InputMaybe<PassengerDisclaimReservationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassengerDisclaimReservationCreateOrConnectWithoutReservationConflictInput>;
  create?: InputMaybe<PassengerDisclaimReservationCreateWithoutReservationConflictInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PassengerDisclaimReservationUpdateWithoutReservationConflictInput>;
  upsert?: InputMaybe<PassengerDisclaimReservationUpsertWithoutReservationConflictInput>;
};

export type PassengerDisclaimReservationUpdateOneWithoutWasAtPlaceInDisclaimInput = {
  connect?: InputMaybe<PassengerDisclaimReservationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassengerDisclaimReservationCreateOrConnectWithoutWasAtPlaceInDisclaimInput>;
  create?: InputMaybe<PassengerDisclaimReservationCreateWithoutWasAtPlaceInDisclaimInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PassengerDisclaimReservationUpdateWithoutWasAtPlaceInDisclaimInput>;
  upsert?: InputMaybe<PassengerDisclaimReservationUpsertWithoutWasAtPlaceInDisclaimInput>;
};

export type PassengerDisclaimReservationUpdateWithoutReservationConflictInput = {
  WasAtPlaceInDisclaim?: InputMaybe<AtTimeAtPlaceProofUpdateOneWithoutPassengerDisclaimReservationInput>;
  comment?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  reason?: InputMaybe<EnumPassengerReasonInConflictFieldUpdateOperationsInput>;
};

export type PassengerDisclaimReservationUpdateWithoutWasAtPlaceInDisclaimInput = {
  ReservationConflict?: InputMaybe<ReservationConflictUpdateOneRequiredWithoutPassengerDisclaimReservationInput>;
  comment?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  reason?: InputMaybe<EnumPassengerReasonInConflictFieldUpdateOperationsInput>;
};

export type PassengerDisclaimReservationUpsertWithoutReservationConflictInput = {
  create: PassengerDisclaimReservationCreateWithoutReservationConflictInput;
  update: PassengerDisclaimReservationUpdateWithoutReservationConflictInput;
};

export type PassengerDisclaimReservationUpsertWithoutWasAtPlaceInDisclaimInput = {
  create: PassengerDisclaimReservationCreateWithoutWasAtPlaceInDisclaimInput;
  update: PassengerDisclaimReservationUpdateWithoutWasAtPlaceInDisclaimInput;
};

export type PassengerDisclaimReservationWhereInput = {
  AND?: InputMaybe<Array<PassengerDisclaimReservationWhereInput>>;
  NOT?: InputMaybe<Array<PassengerDisclaimReservationWhereInput>>;
  OR?: InputMaybe<Array<PassengerDisclaimReservationWhereInput>>;
  ReservationConflict?: InputMaybe<ReservationConflictRelationFilter>;
  WasAtPlaceInDisclaim?: InputMaybe<AtTimeAtPlaceProofRelationFilter>;
  comment?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  reason?: InputMaybe<EnumPassengerReasonInConflictFilter>;
  reservationConflictId?: InputMaybe<StringFilter>;
};

export type PassengerDisclaimReservationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  reservationConflictId?: InputMaybe<Scalars['String']>;
};

export enum PassengerReasonInConflict {
  CarIsNotInConditions = 'CarIsNotInConditions',
  DriverDidNotAssist = 'DriverDidNotAssist',
  DriverIsNotTheSame = 'DriverIsNotTheSame',
  Other = 'Other',
  PassengerDidNotAssist = 'PassengerDidNotAssist'
}

export type PayReservationResult = PayReservationResultError | PayReservationResultSuccess;

export type PayReservationResultError = {
  __typename?: 'PayReservationResultError';
  code: PayReservationResultErrorDto;
};

export enum PayReservationResultErrorDto {
  ReservationIsNotWaitingForPayment = 'RESERVATION_IS_NOT_WAITING_FOR_PAYMENT',
  UnauthorizedToModify = 'UNAUTHORIZED_TO_MODIFY'
}

export type PayReservationResultSuccess = {
  __typename?: 'PayReservationResultSuccess';
  reservation: Reservation;
};

export type Price = {
  __typename?: 'Price';
  country: Country;
  date: Scalars['DateTime'];
  id: Scalars['String'];
  price: Scalars['Int'];
};

export type PriceCreateInput = {
  country?: InputMaybe<Country>;
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  price: Scalars['Int'];
};

export type PriceOrderByWithRelationInput = {
  country?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
};

export enum PriceScalarFieldEnum {
  Country = 'country',
  Date = 'date',
  Id = 'id',
  Price = 'price'
}

export type PriceWhereInput = {
  AND?: InputMaybe<Array<PriceWhereInput>>;
  NOT?: InputMaybe<Array<PriceWhereInput>>;
  OR?: InputMaybe<Array<PriceWhereInput>>;
  country?: InputMaybe<EnumCountryFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  price?: InputMaybe<IntFilter>;
};

export type PriceWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  GetTicketProofReservation: GetTicketProofResult;
  PSearchConversationById: SearchConversationResult;
  PSearchConversationsByUser: Array<Conversation>;
  PSearchManyActiveReservationByConductor: Array<Reservation>;
  PSearchManyActiveTravelByConductor: Array<Travel>;
  PSearchOneActiveTravelByConductor: Travel;
  PSearchReservationById: Reservation;
  PSearchTravel: Array<Fragment>;
  SearchNotificationsByUser: Array<Notification>;
  SearchPrivateUser: User;
  SearchUser: SearchUserResult;
  appContents: Array<AppContent>;
  checkForUser: Scalars['Boolean'];
  findManyWhitelistsQueries: Array<WhitelistsQueries>;
  findUniqueWhitelistsQueries?: Maybe<WhitelistsQueries>;
  getAllAppFaqContent: AppFaqContent;
  getLatestAppBasicContent: AppBasicContent;
  getLatestAppFaqContent: AppFaqContent;
  getPrice: Array<Price>;
  reservation?: Maybe<Reservation>;
  reservations: Array<Reservation>;
  testConnection: Scalars['Boolean'];
  travel?: Maybe<Travel>;
  travels: Array<Travel>;
  user?: Maybe<User>;
  users: Array<User>;
  versionApp?: Maybe<VersionApp>;
  versionApps: Array<VersionApp>;
};


export type QueryGetTicketProofReservationArgs = {
  mode: Scalars['String'];
  reservationId: Scalars['String'];
};


export type QueryPSearchConversationByIdArgs = {
  conversationId: Scalars['String'];
};


export type QueryPSearchConversationsByUserArgs = {
  userId: Scalars['String'];
};


export type QueryPSearchManyActiveReservationByConductorArgs = {
  userId: Scalars['String'];
};


export type QueryPSearchManyActiveTravelByConductorArgs = {
  userId: Scalars['String'];
};


export type QueryPSearchOneActiveTravelByConductorArgs = {
  travelId: Scalars['String'];
};


export type QueryPSearchReservationByIdArgs = {
  reservationId: Scalars['String'];
};


export type QueryPSearchTravelArgs = {
  date: Scalars['DateTime'];
  destinationCoordinates: Coordinate;
  orderBy?: InputMaybe<Array<FragmentOrderByWithRelationInput>>;
  originCoordinates: Coordinate;
  originUTCInHours: Scalars['Float'];
  places: Scalars['Float'];
};


export type QuerySearchNotificationsByUserArgs = {
  userId: Scalars['String'];
};


export type QuerySearchUserArgs = {
  userId: Scalars['String'];
};


export type QueryAppContentsArgs = {
  cursor?: InputMaybe<AppContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AppContentWhereInput>;
};


export type QueryCheckForUserArgs = {
  email: Scalars['String'];
  token?: InputMaybe<Scalars['String']>;
};


export type QueryFindManyWhitelistsQueriesArgs = {
  cursor?: InputMaybe<WhitelistsQueriesWhereUniqueInput>;
  distinct?: InputMaybe<Array<WhitelistsQueriesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WhitelistsQueriesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WhitelistsQueriesWhereInput>;
};


export type QueryFindUniqueWhitelistsQueriesArgs = {
  where: WhitelistsQueriesWhereUniqueInput;
};


export type QueryGetLatestAppBasicContentArgs = {
  type: AppContentType;
};


export type QueryGetPriceArgs = {
  cursor?: InputMaybe<PriceWhereUniqueInput>;
  distinct?: InputMaybe<Array<PriceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PriceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PriceWhereInput>;
};


export type QueryReservationArgs = {
  where: ReservationWhereUniqueInput;
};


export type QueryReservationsArgs = {
  cursor?: InputMaybe<ReservationWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReservationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReservationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReservationWhereInput>;
};


export type QueryTravelArgs = {
  where: TravelWhereUniqueInput;
};


export type QueryTravelsArgs = {
  cursor?: InputMaybe<TravelWhereUniqueInput>;
  distinct?: InputMaybe<Array<TravelScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TravelOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TravelWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryVersionAppArgs = {
  where: VersionAppWhereUniqueInput;
};


export type QueryVersionAppsArgs = {
  cursor?: InputMaybe<VersionAppWhereUniqueInput>;
  distinct?: InputMaybe<Array<VersionAppScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VersionAppOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VersionAppWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type Reservation = {
  __typename?: 'Reservation';
  DriverAtTimeAtPlaceProofId?: Maybe<Scalars['String']>;
  DriverWasAtPlace?: Maybe<AtTimeAtPlaceProof>;
  PassengerAtTimeAtPlaceProofId?: Maybe<Scalars['String']>;
  PassengerWasAtPlace?: Maybe<AtTimeAtPlaceProof>;
  ReservationConflict?: Maybe<ReservationConflict>;
  Status: ReservationStatus;
  StatusAcceptance: ReservationAcceptanceStatus;
  StatusPayment: ReservationPaymentStatus;
  TicketReservation: Array<TicketReservation>;
  _count?: Maybe<ReservationCount>;
  alreadyAskedForScore: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  directFragment: Fragment;
  directFragmentId: Scalars['String'];
  driverScore?: Maybe<Score>;
  driverScoreId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  indirectFragment: Array<FragmentsInReservation>;
  passenger: User;
  passengerId: Scalars['String'];
  passengerScore?: Maybe<Score>;
  passengerScoreId?: Maybe<Scalars['String']>;
  places: Scalars['Int'];
  price?: Maybe<Scalars['Float']>;
  reservationConflictId?: Maybe<Scalars['String']>;
};


export type ReservationTicketReservationArgs = {
  cursor?: InputMaybe<TicketReservationWhereUniqueInput>;
  distinct?: InputMaybe<Array<TicketReservationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TicketReservationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TicketReservationWhereInput>;
};


export type ReservationIndirectFragmentArgs = {
  cursor?: InputMaybe<FragmentsInReservationWhereUniqueInput>;
  distinct?: InputMaybe<Array<FragmentsInReservationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FragmentsInReservationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FragmentsInReservationWhereInput>;
};

export enum ReservationAcceptanceStatus {
  Accepted = 'ACCEPTED',
  Nonpayd = 'NONPAYD',
  Refused = 'REFUSED',
  Waiting = 'WAITING'
}

export type ReservationConflict = {
  __typename?: 'ReservationConflict';
  DriverDisclaimReservation?: Maybe<DriverDisclaimReservation>;
  PassengerDisclaimReservation?: Maybe<PassengerDisclaimReservation>;
  Reservation: Reservation;
  conflictResolution?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  reservationId: Scalars['String'];
};

export type ReservationConflictCreateNestedOneWithoutDriverDisclaimReservationInput = {
  connect?: InputMaybe<ReservationConflictWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReservationConflictCreateOrConnectWithoutDriverDisclaimReservationInput>;
  create?: InputMaybe<ReservationConflictCreateWithoutDriverDisclaimReservationInput>;
};

export type ReservationConflictCreateNestedOneWithoutPassengerDisclaimReservationInput = {
  connect?: InputMaybe<ReservationConflictWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReservationConflictCreateOrConnectWithoutPassengerDisclaimReservationInput>;
  create?: InputMaybe<ReservationConflictCreateWithoutPassengerDisclaimReservationInput>;
};

export type ReservationConflictCreateNestedOneWithoutReservationInput = {
  connect?: InputMaybe<ReservationConflictWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReservationConflictCreateOrConnectWithoutReservationInput>;
  create?: InputMaybe<ReservationConflictCreateWithoutReservationInput>;
};

export type ReservationConflictCreateOrConnectWithoutDriverDisclaimReservationInput = {
  create: ReservationConflictCreateWithoutDriverDisclaimReservationInput;
  where: ReservationConflictWhereUniqueInput;
};

export type ReservationConflictCreateOrConnectWithoutPassengerDisclaimReservationInput = {
  create: ReservationConflictCreateWithoutPassengerDisclaimReservationInput;
  where: ReservationConflictWhereUniqueInput;
};

export type ReservationConflictCreateOrConnectWithoutReservationInput = {
  create: ReservationConflictCreateWithoutReservationInput;
  where: ReservationConflictWhereUniqueInput;
};

export type ReservationConflictCreateWithoutDriverDisclaimReservationInput = {
  PassengerDisclaimReservation?: InputMaybe<PassengerDisclaimReservationCreateNestedOneWithoutReservationConflictInput>;
  Reservation: ReservationCreateNestedOneWithoutReservationConflictInput;
  conflictResolution?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
};

export type ReservationConflictCreateWithoutPassengerDisclaimReservationInput = {
  DriverDisclaimReservation?: InputMaybe<DriverDisclaimReservationCreateNestedOneWithoutReservationConflictInput>;
  Reservation: ReservationCreateNestedOneWithoutReservationConflictInput;
  conflictResolution?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
};

export type ReservationConflictCreateWithoutReservationInput = {
  DriverDisclaimReservation?: InputMaybe<DriverDisclaimReservationCreateNestedOneWithoutReservationConflictInput>;
  PassengerDisclaimReservation?: InputMaybe<PassengerDisclaimReservationCreateNestedOneWithoutReservationConflictInput>;
  conflictResolution?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
};

export type ReservationConflictOrderByWithRelationInput = {
  DriverDisclaimReservation?: InputMaybe<DriverDisclaimReservationOrderByWithRelationInput>;
  PassengerDisclaimReservation?: InputMaybe<PassengerDisclaimReservationOrderByWithRelationInput>;
  Reservation?: InputMaybe<ReservationOrderByWithRelationInput>;
  conflictResolution?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  reservationId?: InputMaybe<SortOrder>;
};

export type ReservationConflictRelationFilter = {
  is?: InputMaybe<ReservationConflictWhereInput>;
  isNot?: InputMaybe<ReservationConflictWhereInput>;
};

export type ReservationConflictUpdateOneRequiredWithoutDriverDisclaimReservationInput = {
  connect?: InputMaybe<ReservationConflictWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReservationConflictCreateOrConnectWithoutDriverDisclaimReservationInput>;
  create?: InputMaybe<ReservationConflictCreateWithoutDriverDisclaimReservationInput>;
  update?: InputMaybe<ReservationConflictUpdateWithoutDriverDisclaimReservationInput>;
  upsert?: InputMaybe<ReservationConflictUpsertWithoutDriverDisclaimReservationInput>;
};

export type ReservationConflictUpdateOneRequiredWithoutPassengerDisclaimReservationInput = {
  connect?: InputMaybe<ReservationConflictWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReservationConflictCreateOrConnectWithoutPassengerDisclaimReservationInput>;
  create?: InputMaybe<ReservationConflictCreateWithoutPassengerDisclaimReservationInput>;
  update?: InputMaybe<ReservationConflictUpdateWithoutPassengerDisclaimReservationInput>;
  upsert?: InputMaybe<ReservationConflictUpsertWithoutPassengerDisclaimReservationInput>;
};

export type ReservationConflictUpdateOneWithoutReservationInput = {
  connect?: InputMaybe<ReservationConflictWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReservationConflictCreateOrConnectWithoutReservationInput>;
  create?: InputMaybe<ReservationConflictCreateWithoutReservationInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ReservationConflictUpdateWithoutReservationInput>;
  upsert?: InputMaybe<ReservationConflictUpsertWithoutReservationInput>;
};

export type ReservationConflictUpdateWithoutDriverDisclaimReservationInput = {
  PassengerDisclaimReservation?: InputMaybe<PassengerDisclaimReservationUpdateOneWithoutReservationConflictInput>;
  Reservation?: InputMaybe<ReservationUpdateOneRequiredWithoutReservationConflictInput>;
  conflictResolution?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ReservationConflictUpdateWithoutPassengerDisclaimReservationInput = {
  DriverDisclaimReservation?: InputMaybe<DriverDisclaimReservationUpdateOneWithoutReservationConflictInput>;
  Reservation?: InputMaybe<ReservationUpdateOneRequiredWithoutReservationConflictInput>;
  conflictResolution?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ReservationConflictUpdateWithoutReservationInput = {
  DriverDisclaimReservation?: InputMaybe<DriverDisclaimReservationUpdateOneWithoutReservationConflictInput>;
  PassengerDisclaimReservation?: InputMaybe<PassengerDisclaimReservationUpdateOneWithoutReservationConflictInput>;
  conflictResolution?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ReservationConflictUpsertWithoutDriverDisclaimReservationInput = {
  create: ReservationConflictCreateWithoutDriverDisclaimReservationInput;
  update: ReservationConflictUpdateWithoutDriverDisclaimReservationInput;
};

export type ReservationConflictUpsertWithoutPassengerDisclaimReservationInput = {
  create: ReservationConflictCreateWithoutPassengerDisclaimReservationInput;
  update: ReservationConflictUpdateWithoutPassengerDisclaimReservationInput;
};

export type ReservationConflictUpsertWithoutReservationInput = {
  create: ReservationConflictCreateWithoutReservationInput;
  update: ReservationConflictUpdateWithoutReservationInput;
};

export type ReservationConflictWhereInput = {
  AND?: InputMaybe<Array<ReservationConflictWhereInput>>;
  DriverDisclaimReservation?: InputMaybe<DriverDisclaimReservationRelationFilter>;
  NOT?: InputMaybe<Array<ReservationConflictWhereInput>>;
  OR?: InputMaybe<Array<ReservationConflictWhereInput>>;
  PassengerDisclaimReservation?: InputMaybe<PassengerDisclaimReservationRelationFilter>;
  Reservation?: InputMaybe<ReservationRelationFilter>;
  conflictResolution?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  reservationId?: InputMaybe<StringFilter>;
};

export type ReservationConflictWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  reservationId?: InputMaybe<Scalars['String']>;
};

export type ReservationCount = {
  __typename?: 'ReservationCount';
  TicketReservation: Scalars['Int'];
  indirectFragment: Scalars['Int'];
};

export type ReservationCreateManyDirectFragmentInput = {
  DriverAtTimeAtPlaceProofId?: InputMaybe<Scalars['String']>;
  PassengerAtTimeAtPlaceProofId?: InputMaybe<Scalars['String']>;
  Status?: InputMaybe<ReservationStatus>;
  StatusAcceptance?: InputMaybe<ReservationAcceptanceStatus>;
  StatusPayment?: InputMaybe<ReservationPaymentStatus>;
  alreadyAskedForScore?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  driverScoreId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  passengerId: Scalars['String'];
  passengerScoreId?: InputMaybe<Scalars['String']>;
  places: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
  reservationConflictId?: InputMaybe<Scalars['String']>;
};

export type ReservationCreateManyDirectFragmentInputEnvelope = {
  data: Array<ReservationCreateManyDirectFragmentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ReservationCreateManyDriverScoreInput = {
  DriverAtTimeAtPlaceProofId?: InputMaybe<Scalars['String']>;
  PassengerAtTimeAtPlaceProofId?: InputMaybe<Scalars['String']>;
  Status?: InputMaybe<ReservationStatus>;
  StatusAcceptance?: InputMaybe<ReservationAcceptanceStatus>;
  StatusPayment?: InputMaybe<ReservationPaymentStatus>;
  alreadyAskedForScore?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  directFragmentId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  passengerId: Scalars['String'];
  passengerScoreId?: InputMaybe<Scalars['String']>;
  places: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
  reservationConflictId?: InputMaybe<Scalars['String']>;
};

export type ReservationCreateManyDriverScoreInputEnvelope = {
  data: Array<ReservationCreateManyDriverScoreInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ReservationCreateManyPassengerInput = {
  DriverAtTimeAtPlaceProofId?: InputMaybe<Scalars['String']>;
  PassengerAtTimeAtPlaceProofId?: InputMaybe<Scalars['String']>;
  Status?: InputMaybe<ReservationStatus>;
  StatusAcceptance?: InputMaybe<ReservationAcceptanceStatus>;
  StatusPayment?: InputMaybe<ReservationPaymentStatus>;
  alreadyAskedForScore?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  directFragmentId: Scalars['String'];
  driverScoreId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  passengerScoreId?: InputMaybe<Scalars['String']>;
  places: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
  reservationConflictId?: InputMaybe<Scalars['String']>;
};

export type ReservationCreateManyPassengerInputEnvelope = {
  data: Array<ReservationCreateManyPassengerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ReservationCreateManyPassengerScoreInput = {
  DriverAtTimeAtPlaceProofId?: InputMaybe<Scalars['String']>;
  PassengerAtTimeAtPlaceProofId?: InputMaybe<Scalars['String']>;
  Status?: InputMaybe<ReservationStatus>;
  StatusAcceptance?: InputMaybe<ReservationAcceptanceStatus>;
  StatusPayment?: InputMaybe<ReservationPaymentStatus>;
  alreadyAskedForScore?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  directFragmentId: Scalars['String'];
  driverScoreId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  passengerId: Scalars['String'];
  places: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
  reservationConflictId?: InputMaybe<Scalars['String']>;
};

export type ReservationCreateManyPassengerScoreInputEnvelope = {
  data: Array<ReservationCreateManyPassengerScoreInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ReservationCreateNestedManyWithoutDirectFragmentInput = {
  connect?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReservationCreateOrConnectWithoutDirectFragmentInput>>;
  create?: InputMaybe<Array<ReservationCreateWithoutDirectFragmentInput>>;
  createMany?: InputMaybe<ReservationCreateManyDirectFragmentInputEnvelope>;
};

export type ReservationCreateNestedManyWithoutDriverScoreInput = {
  connect?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReservationCreateOrConnectWithoutDriverScoreInput>>;
  create?: InputMaybe<Array<ReservationCreateWithoutDriverScoreInput>>;
  createMany?: InputMaybe<ReservationCreateManyDriverScoreInputEnvelope>;
};

export type ReservationCreateNestedManyWithoutPassengerInput = {
  connect?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReservationCreateOrConnectWithoutPassengerInput>>;
  create?: InputMaybe<Array<ReservationCreateWithoutPassengerInput>>;
  createMany?: InputMaybe<ReservationCreateManyPassengerInputEnvelope>;
};

export type ReservationCreateNestedManyWithoutPassengerScoreInput = {
  connect?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReservationCreateOrConnectWithoutPassengerScoreInput>>;
  create?: InputMaybe<Array<ReservationCreateWithoutPassengerScoreInput>>;
  createMany?: InputMaybe<ReservationCreateManyPassengerScoreInputEnvelope>;
};

export type ReservationCreateNestedOneWithoutDriverWasAtPlaceInput = {
  connect?: InputMaybe<ReservationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReservationCreateOrConnectWithoutDriverWasAtPlaceInput>;
  create?: InputMaybe<ReservationCreateWithoutDriverWasAtPlaceInput>;
};

export type ReservationCreateNestedOneWithoutIndirectFragmentInput = {
  connect?: InputMaybe<ReservationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReservationCreateOrConnectWithoutIndirectFragmentInput>;
  create?: InputMaybe<ReservationCreateWithoutIndirectFragmentInput>;
};

export type ReservationCreateNestedOneWithoutPassengerWasAtPlaceInput = {
  connect?: InputMaybe<ReservationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReservationCreateOrConnectWithoutPassengerWasAtPlaceInput>;
  create?: InputMaybe<ReservationCreateWithoutPassengerWasAtPlaceInput>;
};

export type ReservationCreateNestedOneWithoutReservationConflictInput = {
  connect?: InputMaybe<ReservationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReservationCreateOrConnectWithoutReservationConflictInput>;
  create?: InputMaybe<ReservationCreateWithoutReservationConflictInput>;
};

export type ReservationCreateOrConnectWithoutDirectFragmentInput = {
  create: ReservationCreateWithoutDirectFragmentInput;
  where: ReservationWhereUniqueInput;
};

export type ReservationCreateOrConnectWithoutDriverScoreInput = {
  create: ReservationCreateWithoutDriverScoreInput;
  where: ReservationWhereUniqueInput;
};

export type ReservationCreateOrConnectWithoutDriverWasAtPlaceInput = {
  create: ReservationCreateWithoutDriverWasAtPlaceInput;
  where: ReservationWhereUniqueInput;
};

export type ReservationCreateOrConnectWithoutIndirectFragmentInput = {
  create: ReservationCreateWithoutIndirectFragmentInput;
  where: ReservationWhereUniqueInput;
};

export type ReservationCreateOrConnectWithoutPassengerInput = {
  create: ReservationCreateWithoutPassengerInput;
  where: ReservationWhereUniqueInput;
};

export type ReservationCreateOrConnectWithoutPassengerScoreInput = {
  create: ReservationCreateWithoutPassengerScoreInput;
  where: ReservationWhereUniqueInput;
};

export type ReservationCreateOrConnectWithoutPassengerWasAtPlaceInput = {
  create: ReservationCreateWithoutPassengerWasAtPlaceInput;
  where: ReservationWhereUniqueInput;
};

export type ReservationCreateOrConnectWithoutReservationConflictInput = {
  create: ReservationCreateWithoutReservationConflictInput;
  where: ReservationWhereUniqueInput;
};

export type ReservationCreateWithoutDirectFragmentInput = {
  DriverWasAtPlace?: InputMaybe<AtTimeAtPlaceProofCreateNestedOneWithoutDriverReservationInput>;
  PassengerWasAtPlace?: InputMaybe<AtTimeAtPlaceProofCreateNestedOneWithoutPassengerReservationInput>;
  ReservationConflict?: InputMaybe<ReservationConflictCreateNestedOneWithoutReservationInput>;
  Status?: InputMaybe<ReservationStatus>;
  StatusAcceptance?: InputMaybe<ReservationAcceptanceStatus>;
  StatusPayment?: InputMaybe<ReservationPaymentStatus>;
  TicketReservation?: InputMaybe<TicketReservationCreateNestedManyWithoutReservationInput>;
  alreadyAskedForScore?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  driverScore?: InputMaybe<ScoreCreateNestedOneWithoutReservationDriverScoreInput>;
  id?: InputMaybe<Scalars['String']>;
  indirectFragment?: InputMaybe<FragmentsInReservationCreateNestedManyWithoutReservationInput>;
  passenger: UserCreateNestedOneWithoutReservationsInput;
  passengerScore?: InputMaybe<ScoreCreateNestedOneWithoutReservationPassengerScoreInput>;
  places: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
  reservationConflictId?: InputMaybe<Scalars['String']>;
};

export type ReservationCreateWithoutDriverScoreInput = {
  DriverWasAtPlace?: InputMaybe<AtTimeAtPlaceProofCreateNestedOneWithoutDriverReservationInput>;
  PassengerWasAtPlace?: InputMaybe<AtTimeAtPlaceProofCreateNestedOneWithoutPassengerReservationInput>;
  ReservationConflict?: InputMaybe<ReservationConflictCreateNestedOneWithoutReservationInput>;
  Status?: InputMaybe<ReservationStatus>;
  StatusAcceptance?: InputMaybe<ReservationAcceptanceStatus>;
  StatusPayment?: InputMaybe<ReservationPaymentStatus>;
  TicketReservation?: InputMaybe<TicketReservationCreateNestedManyWithoutReservationInput>;
  alreadyAskedForScore?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  directFragment: FragmentCreateNestedOneWithoutDirectReservationInput;
  id?: InputMaybe<Scalars['String']>;
  indirectFragment?: InputMaybe<FragmentsInReservationCreateNestedManyWithoutReservationInput>;
  passenger: UserCreateNestedOneWithoutReservationsInput;
  passengerScore?: InputMaybe<ScoreCreateNestedOneWithoutReservationPassengerScoreInput>;
  places: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
  reservationConflictId?: InputMaybe<Scalars['String']>;
};

export type ReservationCreateWithoutDriverWasAtPlaceInput = {
  PassengerWasAtPlace?: InputMaybe<AtTimeAtPlaceProofCreateNestedOneWithoutPassengerReservationInput>;
  ReservationConflict?: InputMaybe<ReservationConflictCreateNestedOneWithoutReservationInput>;
  Status?: InputMaybe<ReservationStatus>;
  StatusAcceptance?: InputMaybe<ReservationAcceptanceStatus>;
  StatusPayment?: InputMaybe<ReservationPaymentStatus>;
  TicketReservation?: InputMaybe<TicketReservationCreateNestedManyWithoutReservationInput>;
  alreadyAskedForScore?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  directFragment: FragmentCreateNestedOneWithoutDirectReservationInput;
  driverScore?: InputMaybe<ScoreCreateNestedOneWithoutReservationDriverScoreInput>;
  id?: InputMaybe<Scalars['String']>;
  indirectFragment?: InputMaybe<FragmentsInReservationCreateNestedManyWithoutReservationInput>;
  passenger: UserCreateNestedOneWithoutReservationsInput;
  passengerScore?: InputMaybe<ScoreCreateNestedOneWithoutReservationPassengerScoreInput>;
  places: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
  reservationConflictId?: InputMaybe<Scalars['String']>;
};

export type ReservationCreateWithoutIndirectFragmentInput = {
  DriverWasAtPlace?: InputMaybe<AtTimeAtPlaceProofCreateNestedOneWithoutDriverReservationInput>;
  PassengerWasAtPlace?: InputMaybe<AtTimeAtPlaceProofCreateNestedOneWithoutPassengerReservationInput>;
  ReservationConflict?: InputMaybe<ReservationConflictCreateNestedOneWithoutReservationInput>;
  Status?: InputMaybe<ReservationStatus>;
  StatusAcceptance?: InputMaybe<ReservationAcceptanceStatus>;
  StatusPayment?: InputMaybe<ReservationPaymentStatus>;
  TicketReservation?: InputMaybe<TicketReservationCreateNestedManyWithoutReservationInput>;
  alreadyAskedForScore?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  directFragment: FragmentCreateNestedOneWithoutDirectReservationInput;
  driverScore?: InputMaybe<ScoreCreateNestedOneWithoutReservationDriverScoreInput>;
  id?: InputMaybe<Scalars['String']>;
  passenger: UserCreateNestedOneWithoutReservationsInput;
  passengerScore?: InputMaybe<ScoreCreateNestedOneWithoutReservationPassengerScoreInput>;
  places: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
  reservationConflictId?: InputMaybe<Scalars['String']>;
};

export type ReservationCreateWithoutPassengerInput = {
  DriverWasAtPlace?: InputMaybe<AtTimeAtPlaceProofCreateNestedOneWithoutDriverReservationInput>;
  PassengerWasAtPlace?: InputMaybe<AtTimeAtPlaceProofCreateNestedOneWithoutPassengerReservationInput>;
  ReservationConflict?: InputMaybe<ReservationConflictCreateNestedOneWithoutReservationInput>;
  Status?: InputMaybe<ReservationStatus>;
  StatusAcceptance?: InputMaybe<ReservationAcceptanceStatus>;
  StatusPayment?: InputMaybe<ReservationPaymentStatus>;
  TicketReservation?: InputMaybe<TicketReservationCreateNestedManyWithoutReservationInput>;
  alreadyAskedForScore?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  directFragment: FragmentCreateNestedOneWithoutDirectReservationInput;
  driverScore?: InputMaybe<ScoreCreateNestedOneWithoutReservationDriverScoreInput>;
  id?: InputMaybe<Scalars['String']>;
  indirectFragment?: InputMaybe<FragmentsInReservationCreateNestedManyWithoutReservationInput>;
  passengerScore?: InputMaybe<ScoreCreateNestedOneWithoutReservationPassengerScoreInput>;
  places: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
  reservationConflictId?: InputMaybe<Scalars['String']>;
};

export type ReservationCreateWithoutPassengerScoreInput = {
  DriverWasAtPlace?: InputMaybe<AtTimeAtPlaceProofCreateNestedOneWithoutDriverReservationInput>;
  PassengerWasAtPlace?: InputMaybe<AtTimeAtPlaceProofCreateNestedOneWithoutPassengerReservationInput>;
  ReservationConflict?: InputMaybe<ReservationConflictCreateNestedOneWithoutReservationInput>;
  Status?: InputMaybe<ReservationStatus>;
  StatusAcceptance?: InputMaybe<ReservationAcceptanceStatus>;
  StatusPayment?: InputMaybe<ReservationPaymentStatus>;
  TicketReservation?: InputMaybe<TicketReservationCreateNestedManyWithoutReservationInput>;
  alreadyAskedForScore?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  directFragment: FragmentCreateNestedOneWithoutDirectReservationInput;
  driverScore?: InputMaybe<ScoreCreateNestedOneWithoutReservationDriverScoreInput>;
  id?: InputMaybe<Scalars['String']>;
  indirectFragment?: InputMaybe<FragmentsInReservationCreateNestedManyWithoutReservationInput>;
  passenger: UserCreateNestedOneWithoutReservationsInput;
  places: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
  reservationConflictId?: InputMaybe<Scalars['String']>;
};

export type ReservationCreateWithoutPassengerWasAtPlaceInput = {
  DriverWasAtPlace?: InputMaybe<AtTimeAtPlaceProofCreateNestedOneWithoutDriverReservationInput>;
  ReservationConflict?: InputMaybe<ReservationConflictCreateNestedOneWithoutReservationInput>;
  Status?: InputMaybe<ReservationStatus>;
  StatusAcceptance?: InputMaybe<ReservationAcceptanceStatus>;
  StatusPayment?: InputMaybe<ReservationPaymentStatus>;
  TicketReservation?: InputMaybe<TicketReservationCreateNestedManyWithoutReservationInput>;
  alreadyAskedForScore?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  directFragment: FragmentCreateNestedOneWithoutDirectReservationInput;
  driverScore?: InputMaybe<ScoreCreateNestedOneWithoutReservationDriverScoreInput>;
  id?: InputMaybe<Scalars['String']>;
  indirectFragment?: InputMaybe<FragmentsInReservationCreateNestedManyWithoutReservationInput>;
  passenger: UserCreateNestedOneWithoutReservationsInput;
  passengerScore?: InputMaybe<ScoreCreateNestedOneWithoutReservationPassengerScoreInput>;
  places: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
  reservationConflictId?: InputMaybe<Scalars['String']>;
};

export type ReservationCreateWithoutReservationConflictInput = {
  DriverWasAtPlace?: InputMaybe<AtTimeAtPlaceProofCreateNestedOneWithoutDriverReservationInput>;
  PassengerWasAtPlace?: InputMaybe<AtTimeAtPlaceProofCreateNestedOneWithoutPassengerReservationInput>;
  Status?: InputMaybe<ReservationStatus>;
  StatusAcceptance?: InputMaybe<ReservationAcceptanceStatus>;
  StatusPayment?: InputMaybe<ReservationPaymentStatus>;
  TicketReservation?: InputMaybe<TicketReservationCreateNestedManyWithoutReservationInput>;
  alreadyAskedForScore?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  directFragment: FragmentCreateNestedOneWithoutDirectReservationInput;
  driverScore?: InputMaybe<ScoreCreateNestedOneWithoutReservationDriverScoreInput>;
  id?: InputMaybe<Scalars['String']>;
  indirectFragment?: InputMaybe<FragmentsInReservationCreateNestedManyWithoutReservationInput>;
  passenger: UserCreateNestedOneWithoutReservationsInput;
  passengerScore?: InputMaybe<ScoreCreateNestedOneWithoutReservationPassengerScoreInput>;
  places: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
  reservationConflictId?: InputMaybe<Scalars['String']>;
};

export type ReservationListRelationFilter = {
  every?: InputMaybe<ReservationWhereInput>;
  none?: InputMaybe<ReservationWhereInput>;
  some?: InputMaybe<ReservationWhereInput>;
};

export type ReservationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ReservationOrderByWithRelationInput = {
  DriverAtTimeAtPlaceProofId?: InputMaybe<SortOrder>;
  DriverWasAtPlace?: InputMaybe<AtTimeAtPlaceProofOrderByWithRelationInput>;
  PassengerAtTimeAtPlaceProofId?: InputMaybe<SortOrder>;
  PassengerWasAtPlace?: InputMaybe<AtTimeAtPlaceProofOrderByWithRelationInput>;
  ReservationConflict?: InputMaybe<ReservationConflictOrderByWithRelationInput>;
  Status?: InputMaybe<SortOrder>;
  StatusAcceptance?: InputMaybe<SortOrder>;
  StatusPayment?: InputMaybe<SortOrder>;
  TicketReservation?: InputMaybe<TicketReservationOrderByRelationAggregateInput>;
  alreadyAskedForScore?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  directFragment?: InputMaybe<FragmentOrderByWithRelationInput>;
  directFragmentId?: InputMaybe<SortOrder>;
  driverScore?: InputMaybe<ScoreOrderByWithRelationInput>;
  driverScoreId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  indirectFragment?: InputMaybe<FragmentsInReservationOrderByRelationAggregateInput>;
  passenger?: InputMaybe<UserOrderByWithRelationInput>;
  passengerId?: InputMaybe<SortOrder>;
  passengerScore?: InputMaybe<ScoreOrderByWithRelationInput>;
  passengerScoreId?: InputMaybe<SortOrder>;
  places?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  reservationConflictId?: InputMaybe<SortOrder>;
};

export enum ReservationPaymentStatus {
  Accepted = 'ACCEPTED',
  Free = 'FREE',
  Refused = 'REFUSED',
  Torefound = 'TOREFOUND',
  Waiting = 'WAITING'
}

export type ReservationRelationFilter = {
  is?: InputMaybe<ReservationWhereInput>;
  isNot?: InputMaybe<ReservationWhereInput>;
};

export enum ReservationScalarFieldEnum {
  DriverAtTimeAtPlaceProofId = 'DriverAtTimeAtPlaceProofId',
  PassengerAtTimeAtPlaceProofId = 'PassengerAtTimeAtPlaceProofId',
  Status = 'Status',
  StatusAcceptance = 'StatusAcceptance',
  StatusPayment = 'StatusPayment',
  AlreadyAskedForScore = 'alreadyAskedForScore',
  CreatedAt = 'createdAt',
  DirectFragmentId = 'directFragmentId',
  DriverScoreId = 'driverScoreId',
  Id = 'id',
  PassengerId = 'passengerId',
  PassengerScoreId = 'passengerScoreId',
  Places = 'places',
  Price = 'price',
  ReservationConflictId = 'reservationConflictId'
}

export type ReservationScalarWhereInput = {
  AND?: InputMaybe<Array<ReservationScalarWhereInput>>;
  DriverAtTimeAtPlaceProofId?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<ReservationScalarWhereInput>>;
  OR?: InputMaybe<Array<ReservationScalarWhereInput>>;
  PassengerAtTimeAtPlaceProofId?: InputMaybe<StringNullableFilter>;
  Status?: InputMaybe<EnumReservationStatusFilter>;
  StatusAcceptance?: InputMaybe<EnumReservationAcceptanceStatusFilter>;
  StatusPayment?: InputMaybe<EnumReservationPaymentStatusFilter>;
  alreadyAskedForScore?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  directFragmentId?: InputMaybe<StringFilter>;
  driverScoreId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  passengerId?: InputMaybe<StringFilter>;
  passengerScoreId?: InputMaybe<StringNullableFilter>;
  places?: InputMaybe<IntFilter>;
  price?: InputMaybe<FloatNullableFilter>;
  reservationConflictId?: InputMaybe<StringNullableFilter>;
};

export enum ReservationStatus {
  Abortedbeforeacceptation = 'ABORTEDBEFOREACCEPTATION',
  Abortedbyacceptation = 'ABORTEDBYACCEPTATION',
  Abortedbyconductor = 'ABORTEDBYCONDUCTOR',
  Abortedbypassenger = 'ABORTEDBYPASSENGER',
  Abortedbytimeout = 'ABORTEDBYTIMEOUT',
  Acceptancewaiting = 'ACCEPTANCEWAITING',
  Accepted = 'ACCEPTED',
  Failedbyboth = 'FAILEDBYBOTH',
  Failedbydriver = 'FAILEDBYDRIVER',
  Failedbypassenger = 'FAILEDBYPASSENGER',
  Failedconflict = 'FAILEDCONFLICT',
  Failedtoresolve = 'FAILEDTORESOLVE',
  Paymentfailed = 'PAYMENTFAILED',
  Paymentwaiting = 'PAYMENTWAITING',
  Started = 'STARTED',
  Success = 'SUCCESS'
}

export type ReservationUpdateInput = {
  DriverWasAtPlace?: InputMaybe<AtTimeAtPlaceProofUpdateOneWithoutDriverReservationInput>;
  PassengerWasAtPlace?: InputMaybe<AtTimeAtPlaceProofUpdateOneWithoutPassengerReservationInput>;
  ReservationConflict?: InputMaybe<ReservationConflictUpdateOneWithoutReservationInput>;
  Status?: InputMaybe<EnumReservationStatusFieldUpdateOperationsInput>;
  StatusAcceptance?: InputMaybe<EnumReservationAcceptanceStatusFieldUpdateOperationsInput>;
  StatusPayment?: InputMaybe<EnumReservationPaymentStatusFieldUpdateOperationsInput>;
  TicketReservation?: InputMaybe<TicketReservationUpdateManyWithoutReservationInput>;
  alreadyAskedForScore?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  directFragment?: InputMaybe<FragmentUpdateOneRequiredWithoutDirectReservationInput>;
  driverScore?: InputMaybe<ScoreUpdateOneWithoutReservationDriverScoreInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  indirectFragment?: InputMaybe<FragmentsInReservationUpdateManyWithoutReservationInput>;
  passenger?: InputMaybe<UserUpdateOneRequiredWithoutReservationsInput>;
  passengerScore?: InputMaybe<ScoreUpdateOneWithoutReservationPassengerScoreInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  reservationConflictId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ReservationUpdateManyMutationInput = {
  Status?: InputMaybe<EnumReservationStatusFieldUpdateOperationsInput>;
  StatusAcceptance?: InputMaybe<EnumReservationAcceptanceStatusFieldUpdateOperationsInput>;
  StatusPayment?: InputMaybe<EnumReservationPaymentStatusFieldUpdateOperationsInput>;
  alreadyAskedForScore?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  reservationConflictId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ReservationUpdateManyWithWhereWithoutDirectFragmentInput = {
  data: ReservationUpdateManyMutationInput;
  where: ReservationScalarWhereInput;
};

export type ReservationUpdateManyWithWhereWithoutDriverScoreInput = {
  data: ReservationUpdateManyMutationInput;
  where: ReservationScalarWhereInput;
};

export type ReservationUpdateManyWithWhereWithoutPassengerInput = {
  data: ReservationUpdateManyMutationInput;
  where: ReservationScalarWhereInput;
};

export type ReservationUpdateManyWithWhereWithoutPassengerScoreInput = {
  data: ReservationUpdateManyMutationInput;
  where: ReservationScalarWhereInput;
};

export type ReservationUpdateManyWithoutDirectFragmentInput = {
  connect?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReservationCreateOrConnectWithoutDirectFragmentInput>>;
  create?: InputMaybe<Array<ReservationCreateWithoutDirectFragmentInput>>;
  createMany?: InputMaybe<ReservationCreateManyDirectFragmentInputEnvelope>;
  delete?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReservationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  set?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  update?: InputMaybe<Array<ReservationUpdateWithWhereUniqueWithoutDirectFragmentInput>>;
  updateMany?: InputMaybe<Array<ReservationUpdateManyWithWhereWithoutDirectFragmentInput>>;
  upsert?: InputMaybe<Array<ReservationUpsertWithWhereUniqueWithoutDirectFragmentInput>>;
};

export type ReservationUpdateManyWithoutDriverScoreInput = {
  connect?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReservationCreateOrConnectWithoutDriverScoreInput>>;
  create?: InputMaybe<Array<ReservationCreateWithoutDriverScoreInput>>;
  createMany?: InputMaybe<ReservationCreateManyDriverScoreInputEnvelope>;
  delete?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReservationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  set?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  update?: InputMaybe<Array<ReservationUpdateWithWhereUniqueWithoutDriverScoreInput>>;
  updateMany?: InputMaybe<Array<ReservationUpdateManyWithWhereWithoutDriverScoreInput>>;
  upsert?: InputMaybe<Array<ReservationUpsertWithWhereUniqueWithoutDriverScoreInput>>;
};

export type ReservationUpdateManyWithoutPassengerInput = {
  connect?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReservationCreateOrConnectWithoutPassengerInput>>;
  create?: InputMaybe<Array<ReservationCreateWithoutPassengerInput>>;
  createMany?: InputMaybe<ReservationCreateManyPassengerInputEnvelope>;
  delete?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReservationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  set?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  update?: InputMaybe<Array<ReservationUpdateWithWhereUniqueWithoutPassengerInput>>;
  updateMany?: InputMaybe<Array<ReservationUpdateManyWithWhereWithoutPassengerInput>>;
  upsert?: InputMaybe<Array<ReservationUpsertWithWhereUniqueWithoutPassengerInput>>;
};

export type ReservationUpdateManyWithoutPassengerScoreInput = {
  connect?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReservationCreateOrConnectWithoutPassengerScoreInput>>;
  create?: InputMaybe<Array<ReservationCreateWithoutPassengerScoreInput>>;
  createMany?: InputMaybe<ReservationCreateManyPassengerScoreInputEnvelope>;
  delete?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReservationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  set?: InputMaybe<Array<ReservationWhereUniqueInput>>;
  update?: InputMaybe<Array<ReservationUpdateWithWhereUniqueWithoutPassengerScoreInput>>;
  updateMany?: InputMaybe<Array<ReservationUpdateManyWithWhereWithoutPassengerScoreInput>>;
  upsert?: InputMaybe<Array<ReservationUpsertWithWhereUniqueWithoutPassengerScoreInput>>;
};

export type ReservationUpdateOneRequiredWithoutIndirectFragmentInput = {
  connect?: InputMaybe<ReservationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReservationCreateOrConnectWithoutIndirectFragmentInput>;
  create?: InputMaybe<ReservationCreateWithoutIndirectFragmentInput>;
  update?: InputMaybe<ReservationUpdateWithoutIndirectFragmentInput>;
  upsert?: InputMaybe<ReservationUpsertWithoutIndirectFragmentInput>;
};

export type ReservationUpdateOneRequiredWithoutReservationConflictInput = {
  connect?: InputMaybe<ReservationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReservationCreateOrConnectWithoutReservationConflictInput>;
  create?: InputMaybe<ReservationCreateWithoutReservationConflictInput>;
  update?: InputMaybe<ReservationUpdateWithoutReservationConflictInput>;
  upsert?: InputMaybe<ReservationUpsertWithoutReservationConflictInput>;
};

export type ReservationUpdateOneWithoutDriverWasAtPlaceInput = {
  connect?: InputMaybe<ReservationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReservationCreateOrConnectWithoutDriverWasAtPlaceInput>;
  create?: InputMaybe<ReservationCreateWithoutDriverWasAtPlaceInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ReservationUpdateWithoutDriverWasAtPlaceInput>;
  upsert?: InputMaybe<ReservationUpsertWithoutDriverWasAtPlaceInput>;
};

export type ReservationUpdateOneWithoutPassengerWasAtPlaceInput = {
  connect?: InputMaybe<ReservationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReservationCreateOrConnectWithoutPassengerWasAtPlaceInput>;
  create?: InputMaybe<ReservationCreateWithoutPassengerWasAtPlaceInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ReservationUpdateWithoutPassengerWasAtPlaceInput>;
  upsert?: InputMaybe<ReservationUpsertWithoutPassengerWasAtPlaceInput>;
};

export type ReservationUpdateWithWhereUniqueWithoutDirectFragmentInput = {
  data: ReservationUpdateWithoutDirectFragmentInput;
  where: ReservationWhereUniqueInput;
};

export type ReservationUpdateWithWhereUniqueWithoutDriverScoreInput = {
  data: ReservationUpdateWithoutDriverScoreInput;
  where: ReservationWhereUniqueInput;
};

export type ReservationUpdateWithWhereUniqueWithoutPassengerInput = {
  data: ReservationUpdateWithoutPassengerInput;
  where: ReservationWhereUniqueInput;
};

export type ReservationUpdateWithWhereUniqueWithoutPassengerScoreInput = {
  data: ReservationUpdateWithoutPassengerScoreInput;
  where: ReservationWhereUniqueInput;
};

export type ReservationUpdateWithoutDirectFragmentInput = {
  DriverWasAtPlace?: InputMaybe<AtTimeAtPlaceProofUpdateOneWithoutDriverReservationInput>;
  PassengerWasAtPlace?: InputMaybe<AtTimeAtPlaceProofUpdateOneWithoutPassengerReservationInput>;
  ReservationConflict?: InputMaybe<ReservationConflictUpdateOneWithoutReservationInput>;
  Status?: InputMaybe<EnumReservationStatusFieldUpdateOperationsInput>;
  StatusAcceptance?: InputMaybe<EnumReservationAcceptanceStatusFieldUpdateOperationsInput>;
  StatusPayment?: InputMaybe<EnumReservationPaymentStatusFieldUpdateOperationsInput>;
  TicketReservation?: InputMaybe<TicketReservationUpdateManyWithoutReservationInput>;
  alreadyAskedForScore?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  driverScore?: InputMaybe<ScoreUpdateOneWithoutReservationDriverScoreInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  indirectFragment?: InputMaybe<FragmentsInReservationUpdateManyWithoutReservationInput>;
  passenger?: InputMaybe<UserUpdateOneRequiredWithoutReservationsInput>;
  passengerScore?: InputMaybe<ScoreUpdateOneWithoutReservationPassengerScoreInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  reservationConflictId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ReservationUpdateWithoutDriverScoreInput = {
  DriverWasAtPlace?: InputMaybe<AtTimeAtPlaceProofUpdateOneWithoutDriverReservationInput>;
  PassengerWasAtPlace?: InputMaybe<AtTimeAtPlaceProofUpdateOneWithoutPassengerReservationInput>;
  ReservationConflict?: InputMaybe<ReservationConflictUpdateOneWithoutReservationInput>;
  Status?: InputMaybe<EnumReservationStatusFieldUpdateOperationsInput>;
  StatusAcceptance?: InputMaybe<EnumReservationAcceptanceStatusFieldUpdateOperationsInput>;
  StatusPayment?: InputMaybe<EnumReservationPaymentStatusFieldUpdateOperationsInput>;
  TicketReservation?: InputMaybe<TicketReservationUpdateManyWithoutReservationInput>;
  alreadyAskedForScore?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  directFragment?: InputMaybe<FragmentUpdateOneRequiredWithoutDirectReservationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  indirectFragment?: InputMaybe<FragmentsInReservationUpdateManyWithoutReservationInput>;
  passenger?: InputMaybe<UserUpdateOneRequiredWithoutReservationsInput>;
  passengerScore?: InputMaybe<ScoreUpdateOneWithoutReservationPassengerScoreInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  reservationConflictId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ReservationUpdateWithoutDriverWasAtPlaceInput = {
  PassengerWasAtPlace?: InputMaybe<AtTimeAtPlaceProofUpdateOneWithoutPassengerReservationInput>;
  ReservationConflict?: InputMaybe<ReservationConflictUpdateOneWithoutReservationInput>;
  Status?: InputMaybe<EnumReservationStatusFieldUpdateOperationsInput>;
  StatusAcceptance?: InputMaybe<EnumReservationAcceptanceStatusFieldUpdateOperationsInput>;
  StatusPayment?: InputMaybe<EnumReservationPaymentStatusFieldUpdateOperationsInput>;
  TicketReservation?: InputMaybe<TicketReservationUpdateManyWithoutReservationInput>;
  alreadyAskedForScore?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  directFragment?: InputMaybe<FragmentUpdateOneRequiredWithoutDirectReservationInput>;
  driverScore?: InputMaybe<ScoreUpdateOneWithoutReservationDriverScoreInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  indirectFragment?: InputMaybe<FragmentsInReservationUpdateManyWithoutReservationInput>;
  passenger?: InputMaybe<UserUpdateOneRequiredWithoutReservationsInput>;
  passengerScore?: InputMaybe<ScoreUpdateOneWithoutReservationPassengerScoreInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  reservationConflictId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ReservationUpdateWithoutIndirectFragmentInput = {
  DriverWasAtPlace?: InputMaybe<AtTimeAtPlaceProofUpdateOneWithoutDriverReservationInput>;
  PassengerWasAtPlace?: InputMaybe<AtTimeAtPlaceProofUpdateOneWithoutPassengerReservationInput>;
  ReservationConflict?: InputMaybe<ReservationConflictUpdateOneWithoutReservationInput>;
  Status?: InputMaybe<EnumReservationStatusFieldUpdateOperationsInput>;
  StatusAcceptance?: InputMaybe<EnumReservationAcceptanceStatusFieldUpdateOperationsInput>;
  StatusPayment?: InputMaybe<EnumReservationPaymentStatusFieldUpdateOperationsInput>;
  TicketReservation?: InputMaybe<TicketReservationUpdateManyWithoutReservationInput>;
  alreadyAskedForScore?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  directFragment?: InputMaybe<FragmentUpdateOneRequiredWithoutDirectReservationInput>;
  driverScore?: InputMaybe<ScoreUpdateOneWithoutReservationDriverScoreInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  passenger?: InputMaybe<UserUpdateOneRequiredWithoutReservationsInput>;
  passengerScore?: InputMaybe<ScoreUpdateOneWithoutReservationPassengerScoreInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  reservationConflictId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ReservationUpdateWithoutPassengerInput = {
  DriverWasAtPlace?: InputMaybe<AtTimeAtPlaceProofUpdateOneWithoutDriverReservationInput>;
  PassengerWasAtPlace?: InputMaybe<AtTimeAtPlaceProofUpdateOneWithoutPassengerReservationInput>;
  ReservationConflict?: InputMaybe<ReservationConflictUpdateOneWithoutReservationInput>;
  Status?: InputMaybe<EnumReservationStatusFieldUpdateOperationsInput>;
  StatusAcceptance?: InputMaybe<EnumReservationAcceptanceStatusFieldUpdateOperationsInput>;
  StatusPayment?: InputMaybe<EnumReservationPaymentStatusFieldUpdateOperationsInput>;
  TicketReservation?: InputMaybe<TicketReservationUpdateManyWithoutReservationInput>;
  alreadyAskedForScore?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  directFragment?: InputMaybe<FragmentUpdateOneRequiredWithoutDirectReservationInput>;
  driverScore?: InputMaybe<ScoreUpdateOneWithoutReservationDriverScoreInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  indirectFragment?: InputMaybe<FragmentsInReservationUpdateManyWithoutReservationInput>;
  passengerScore?: InputMaybe<ScoreUpdateOneWithoutReservationPassengerScoreInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  reservationConflictId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ReservationUpdateWithoutPassengerScoreInput = {
  DriverWasAtPlace?: InputMaybe<AtTimeAtPlaceProofUpdateOneWithoutDriverReservationInput>;
  PassengerWasAtPlace?: InputMaybe<AtTimeAtPlaceProofUpdateOneWithoutPassengerReservationInput>;
  ReservationConflict?: InputMaybe<ReservationConflictUpdateOneWithoutReservationInput>;
  Status?: InputMaybe<EnumReservationStatusFieldUpdateOperationsInput>;
  StatusAcceptance?: InputMaybe<EnumReservationAcceptanceStatusFieldUpdateOperationsInput>;
  StatusPayment?: InputMaybe<EnumReservationPaymentStatusFieldUpdateOperationsInput>;
  TicketReservation?: InputMaybe<TicketReservationUpdateManyWithoutReservationInput>;
  alreadyAskedForScore?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  directFragment?: InputMaybe<FragmentUpdateOneRequiredWithoutDirectReservationInput>;
  driverScore?: InputMaybe<ScoreUpdateOneWithoutReservationDriverScoreInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  indirectFragment?: InputMaybe<FragmentsInReservationUpdateManyWithoutReservationInput>;
  passenger?: InputMaybe<UserUpdateOneRequiredWithoutReservationsInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  reservationConflictId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ReservationUpdateWithoutPassengerWasAtPlaceInput = {
  DriverWasAtPlace?: InputMaybe<AtTimeAtPlaceProofUpdateOneWithoutDriverReservationInput>;
  ReservationConflict?: InputMaybe<ReservationConflictUpdateOneWithoutReservationInput>;
  Status?: InputMaybe<EnumReservationStatusFieldUpdateOperationsInput>;
  StatusAcceptance?: InputMaybe<EnumReservationAcceptanceStatusFieldUpdateOperationsInput>;
  StatusPayment?: InputMaybe<EnumReservationPaymentStatusFieldUpdateOperationsInput>;
  TicketReservation?: InputMaybe<TicketReservationUpdateManyWithoutReservationInput>;
  alreadyAskedForScore?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  directFragment?: InputMaybe<FragmentUpdateOneRequiredWithoutDirectReservationInput>;
  driverScore?: InputMaybe<ScoreUpdateOneWithoutReservationDriverScoreInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  indirectFragment?: InputMaybe<FragmentsInReservationUpdateManyWithoutReservationInput>;
  passenger?: InputMaybe<UserUpdateOneRequiredWithoutReservationsInput>;
  passengerScore?: InputMaybe<ScoreUpdateOneWithoutReservationPassengerScoreInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  reservationConflictId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ReservationUpdateWithoutReservationConflictInput = {
  DriverWasAtPlace?: InputMaybe<AtTimeAtPlaceProofUpdateOneWithoutDriverReservationInput>;
  PassengerWasAtPlace?: InputMaybe<AtTimeAtPlaceProofUpdateOneWithoutPassengerReservationInput>;
  Status?: InputMaybe<EnumReservationStatusFieldUpdateOperationsInput>;
  StatusAcceptance?: InputMaybe<EnumReservationAcceptanceStatusFieldUpdateOperationsInput>;
  StatusPayment?: InputMaybe<EnumReservationPaymentStatusFieldUpdateOperationsInput>;
  TicketReservation?: InputMaybe<TicketReservationUpdateManyWithoutReservationInput>;
  alreadyAskedForScore?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  directFragment?: InputMaybe<FragmentUpdateOneRequiredWithoutDirectReservationInput>;
  driverScore?: InputMaybe<ScoreUpdateOneWithoutReservationDriverScoreInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  indirectFragment?: InputMaybe<FragmentsInReservationUpdateManyWithoutReservationInput>;
  passenger?: InputMaybe<UserUpdateOneRequiredWithoutReservationsInput>;
  passengerScore?: InputMaybe<ScoreUpdateOneWithoutReservationPassengerScoreInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  reservationConflictId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ReservationUpsertWithWhereUniqueWithoutDirectFragmentInput = {
  create: ReservationCreateWithoutDirectFragmentInput;
  update: ReservationUpdateWithoutDirectFragmentInput;
  where: ReservationWhereUniqueInput;
};

export type ReservationUpsertWithWhereUniqueWithoutDriverScoreInput = {
  create: ReservationCreateWithoutDriverScoreInput;
  update: ReservationUpdateWithoutDriverScoreInput;
  where: ReservationWhereUniqueInput;
};

export type ReservationUpsertWithWhereUniqueWithoutPassengerInput = {
  create: ReservationCreateWithoutPassengerInput;
  update: ReservationUpdateWithoutPassengerInput;
  where: ReservationWhereUniqueInput;
};

export type ReservationUpsertWithWhereUniqueWithoutPassengerScoreInput = {
  create: ReservationCreateWithoutPassengerScoreInput;
  update: ReservationUpdateWithoutPassengerScoreInput;
  where: ReservationWhereUniqueInput;
};

export type ReservationUpsertWithoutDriverWasAtPlaceInput = {
  create: ReservationCreateWithoutDriverWasAtPlaceInput;
  update: ReservationUpdateWithoutDriverWasAtPlaceInput;
};

export type ReservationUpsertWithoutIndirectFragmentInput = {
  create: ReservationCreateWithoutIndirectFragmentInput;
  update: ReservationUpdateWithoutIndirectFragmentInput;
};

export type ReservationUpsertWithoutPassengerWasAtPlaceInput = {
  create: ReservationCreateWithoutPassengerWasAtPlaceInput;
  update: ReservationUpdateWithoutPassengerWasAtPlaceInput;
};

export type ReservationUpsertWithoutReservationConflictInput = {
  create: ReservationCreateWithoutReservationConflictInput;
  update: ReservationUpdateWithoutReservationConflictInput;
};

export type ReservationWhereInput = {
  AND?: InputMaybe<Array<ReservationWhereInput>>;
  DriverAtTimeAtPlaceProofId?: InputMaybe<StringNullableFilter>;
  DriverWasAtPlace?: InputMaybe<AtTimeAtPlaceProofRelationFilter>;
  NOT?: InputMaybe<Array<ReservationWhereInput>>;
  OR?: InputMaybe<Array<ReservationWhereInput>>;
  PassengerAtTimeAtPlaceProofId?: InputMaybe<StringNullableFilter>;
  PassengerWasAtPlace?: InputMaybe<AtTimeAtPlaceProofRelationFilter>;
  ReservationConflict?: InputMaybe<ReservationConflictRelationFilter>;
  Status?: InputMaybe<EnumReservationStatusFilter>;
  StatusAcceptance?: InputMaybe<EnumReservationAcceptanceStatusFilter>;
  StatusPayment?: InputMaybe<EnumReservationPaymentStatusFilter>;
  TicketReservation?: InputMaybe<TicketReservationListRelationFilter>;
  alreadyAskedForScore?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  directFragment?: InputMaybe<FragmentRelationFilter>;
  directFragmentId?: InputMaybe<StringFilter>;
  driverScore?: InputMaybe<ScoreRelationFilter>;
  driverScoreId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  indirectFragment?: InputMaybe<FragmentsInReservationListRelationFilter>;
  passenger?: InputMaybe<UserRelationFilter>;
  passengerId?: InputMaybe<StringFilter>;
  passengerScore?: InputMaybe<ScoreRelationFilter>;
  passengerScoreId?: InputMaybe<StringNullableFilter>;
  places?: InputMaybe<IntFilter>;
  price?: InputMaybe<FloatNullableFilter>;
  reservationConflictId?: InputMaybe<StringNullableFilter>;
};

export type ReservationWhereUniqueInput = {
  DriverAtTimeAtPlaceProofId?: InputMaybe<Scalars['String']>;
  PassengerAtTimeAtPlaceProofId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  reservationConflictId?: InputMaybe<Scalars['String']>;
};

export enum Role {
  Admin = 'ADMIN',
  Superadmin = 'SUPERADMIN',
  User = 'USER'
}

export type ScanTicketProofResult = ScanTicketProofResultError | ScanTicketProofResultSuccess;

export type ScanTicketProofResultError = {
  __typename?: 'ScanTicketProofResultError';
  code: ScanTicketProofResultErrorDto;
};

export enum ScanTicketProofResultErrorDto {
  InvalidCode = 'INVALID_CODE',
  InvalidReservationStatus = 'INVALID_RESERVATION_STATUS',
  TooEarly = 'TOO_EARLY',
  Unauthorized = 'UNAUTHORIZED'
}

export type ScanTicketProofResultSuccess = {
  __typename?: 'ScanTicketProofResultSuccess';
  status: ReservationStatus;
};

export type Score = {
  __typename?: 'Score';
  ReservationDriverScore: Array<Reservation>;
  ReservationPassengerScore: Array<Reservation>;
  _count?: Maybe<ScoreCount>;
  applicable: Scalars['Boolean'];
  comment: Scalars['String'];
  id: Scalars['String'];
  score: Scalars['Int'];
  scoredUserId: Scalars['String'];
  scorerUserId: Scalars['String'];
  visible: Scalars['Boolean'];
};


export type ScoreReservationDriverScoreArgs = {
  cursor?: InputMaybe<ReservationWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReservationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReservationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReservationWhereInput>;
};


export type ScoreReservationPassengerScoreArgs = {
  cursor?: InputMaybe<ReservationWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReservationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReservationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReservationWhereInput>;
};

export type ScoreCount = {
  __typename?: 'ScoreCount';
  ReservationDriverScore: Scalars['Int'];
  ReservationPassengerScore: Scalars['Int'];
};

export type ScoreCreateNestedOneWithoutReservationDriverScoreInput = {
  connect?: InputMaybe<ScoreWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScoreCreateOrConnectWithoutReservationDriverScoreInput>;
  create?: InputMaybe<ScoreCreateWithoutReservationDriverScoreInput>;
};

export type ScoreCreateNestedOneWithoutReservationPassengerScoreInput = {
  connect?: InputMaybe<ScoreWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScoreCreateOrConnectWithoutReservationPassengerScoreInput>;
  create?: InputMaybe<ScoreCreateWithoutReservationPassengerScoreInput>;
};

export type ScoreCreateOrConnectWithoutReservationDriverScoreInput = {
  create: ScoreCreateWithoutReservationDriverScoreInput;
  where: ScoreWhereUniqueInput;
};

export type ScoreCreateOrConnectWithoutReservationPassengerScoreInput = {
  create: ScoreCreateWithoutReservationPassengerScoreInput;
  where: ScoreWhereUniqueInput;
};

export type ScoreCreateWithoutReservationDriverScoreInput = {
  ReservationPassengerScore?: InputMaybe<ReservationCreateNestedManyWithoutPassengerScoreInput>;
  applicable?: InputMaybe<Scalars['Boolean']>;
  comment: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  score: Scalars['Int'];
  scoredUserId: Scalars['String'];
  scorerUserId: Scalars['String'];
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type ScoreCreateWithoutReservationPassengerScoreInput = {
  ReservationDriverScore?: InputMaybe<ReservationCreateNestedManyWithoutDriverScoreInput>;
  applicable?: InputMaybe<Scalars['Boolean']>;
  comment: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  score: Scalars['Int'];
  scoredUserId: Scalars['String'];
  scorerUserId: Scalars['String'];
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type ScoreOrderByWithRelationInput = {
  ReservationDriverScore?: InputMaybe<ReservationOrderByRelationAggregateInput>;
  ReservationPassengerScore?: InputMaybe<ReservationOrderByRelationAggregateInput>;
  applicable?: InputMaybe<SortOrder>;
  comment?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  score?: InputMaybe<SortOrder>;
  scoredUserId?: InputMaybe<SortOrder>;
  scorerUserId?: InputMaybe<SortOrder>;
  visible?: InputMaybe<SortOrder>;
};

export type ScoreRelationFilter = {
  is?: InputMaybe<ScoreWhereInput>;
  isNot?: InputMaybe<ScoreWhereInput>;
};

export type ScoreUpdateOneWithoutReservationDriverScoreInput = {
  connect?: InputMaybe<ScoreWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScoreCreateOrConnectWithoutReservationDriverScoreInput>;
  create?: InputMaybe<ScoreCreateWithoutReservationDriverScoreInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ScoreUpdateWithoutReservationDriverScoreInput>;
  upsert?: InputMaybe<ScoreUpsertWithoutReservationDriverScoreInput>;
};

export type ScoreUpdateOneWithoutReservationPassengerScoreInput = {
  connect?: InputMaybe<ScoreWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScoreCreateOrConnectWithoutReservationPassengerScoreInput>;
  create?: InputMaybe<ScoreCreateWithoutReservationPassengerScoreInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ScoreUpdateWithoutReservationPassengerScoreInput>;
  upsert?: InputMaybe<ScoreUpsertWithoutReservationPassengerScoreInput>;
};

export type ScoreUpdateWithoutReservationDriverScoreInput = {
  ReservationPassengerScore?: InputMaybe<ReservationUpdateManyWithoutPassengerScoreInput>;
  applicable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  comment?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  score?: InputMaybe<IntFieldUpdateOperationsInput>;
  scoredUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  scorerUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ScoreUpdateWithoutReservationPassengerScoreInput = {
  ReservationDriverScore?: InputMaybe<ReservationUpdateManyWithoutDriverScoreInput>;
  applicable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  comment?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  score?: InputMaybe<IntFieldUpdateOperationsInput>;
  scoredUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  scorerUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ScoreUpsertWithoutReservationDriverScoreInput = {
  create: ScoreCreateWithoutReservationDriverScoreInput;
  update: ScoreUpdateWithoutReservationDriverScoreInput;
};

export type ScoreUpsertWithoutReservationPassengerScoreInput = {
  create: ScoreCreateWithoutReservationPassengerScoreInput;
  update: ScoreUpdateWithoutReservationPassengerScoreInput;
};

export type ScoreWhereInput = {
  AND?: InputMaybe<Array<ScoreWhereInput>>;
  NOT?: InputMaybe<Array<ScoreWhereInput>>;
  OR?: InputMaybe<Array<ScoreWhereInput>>;
  ReservationDriverScore?: InputMaybe<ReservationListRelationFilter>;
  ReservationPassengerScore?: InputMaybe<ReservationListRelationFilter>;
  applicable?: InputMaybe<BoolFilter>;
  comment?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  score?: InputMaybe<IntFilter>;
  scoredUserId?: InputMaybe<StringFilter>;
  scorerUserId?: InputMaybe<StringFilter>;
  visible?: InputMaybe<BoolFilter>;
};

export type ScoreWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type SearchConversationResult = SearchConversationResultError | SearchConversationResultSuccess;

export type SearchConversationResultError = {
  __typename?: 'SearchConversationResultError';
  code: SearchConversationResultErrorDto;
};

export enum SearchConversationResultErrorDto {
  NoAuthorized = 'NO_AUTHORIZED',
  NoConversation = 'NO_CONVERSATION'
}

export type SearchConversationResultSuccess = {
  __typename?: 'SearchConversationResultSuccess';
  conversation: Conversation;
};

export type SearchUserResult = SearchUserResultError | SearchUserResultSuccess;

export type SearchUserResultError = {
  __typename?: 'SearchUserResultError';
  code: SearchUserResultErrorDto;
};

export enum SearchUserResultErrorDto {
  NoUserFound = 'NO_USER_FOUND'
}

export type SearchUserResultSuccess = {
  __typename?: 'SearchUserResultSuccess';
  userInfo: UserInfo;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type Step = {
  __typename?: 'Step';
  Traject: Traject;
  _count?: Maybe<StepCount>;
  city: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date: Scalars['DateTime'];
  distanceFromOrigin: Scalars['Int'];
  id: Scalars['String'];
  isStart?: Maybe<Scalars['Boolean']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  nextFragment: Array<Fragment>;
  order: Scalars['Int'];
  place: Scalars['String'];
  placeId: Scalars['String'];
  previousFragment: Array<Fragment>;
  trajectId: Scalars['String'];
  utcInHours: Scalars['Float'];
};


export type StepNextFragmentArgs = {
  cursor?: InputMaybe<FragmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<FragmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FragmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FragmentWhereInput>;
};


export type StepPreviousFragmentArgs = {
  cursor?: InputMaybe<FragmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<FragmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FragmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FragmentWhereInput>;
};

export type StepCount = {
  __typename?: 'StepCount';
  nextFragment: Scalars['Int'];
  previousFragment: Scalars['Int'];
};

export type StepCreateManyTrajectInput = {
  city: Scalars['String'];
  completedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date: Scalars['DateTime'];
  distanceFromOrigin: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  isStart?: InputMaybe<Scalars['Boolean']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  order: Scalars['Int'];
  place: Scalars['String'];
  placeId: Scalars['String'];
  utcInHours: Scalars['Float'];
};

export type StepCreateManyTrajectInputEnvelope = {
  data: Array<StepCreateManyTrajectInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type StepCreateNestedManyWithoutTrajectInput = {
  connect?: InputMaybe<Array<StepWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepCreateOrConnectWithoutTrajectInput>>;
  create?: InputMaybe<Array<StepCreateWithoutTrajectInput>>;
  createMany?: InputMaybe<StepCreateManyTrajectInputEnvelope>;
};

export type StepCreateNestedOneWithoutNextFragmentInput = {
  connect?: InputMaybe<StepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StepCreateOrConnectWithoutNextFragmentInput>;
  create?: InputMaybe<StepCreateWithoutNextFragmentInput>;
};

export type StepCreateNestedOneWithoutPreviousFragmentInput = {
  connect?: InputMaybe<StepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StepCreateOrConnectWithoutPreviousFragmentInput>;
  create?: InputMaybe<StepCreateWithoutPreviousFragmentInput>;
};

export type StepCreateOrConnectWithoutNextFragmentInput = {
  create: StepCreateWithoutNextFragmentInput;
  where: StepWhereUniqueInput;
};

export type StepCreateOrConnectWithoutPreviousFragmentInput = {
  create: StepCreateWithoutPreviousFragmentInput;
  where: StepWhereUniqueInput;
};

export type StepCreateOrConnectWithoutTrajectInput = {
  create: StepCreateWithoutTrajectInput;
  where: StepWhereUniqueInput;
};

export type StepCreateWithoutNextFragmentInput = {
  Traject: TrajectCreateNestedOneWithoutStepsInput;
  city: Scalars['String'];
  completedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date: Scalars['DateTime'];
  distanceFromOrigin: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  isStart?: InputMaybe<Scalars['Boolean']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  order: Scalars['Int'];
  place: Scalars['String'];
  placeId: Scalars['String'];
  previousFragment?: InputMaybe<FragmentCreateNestedManyWithoutEndStepInput>;
  utcInHours: Scalars['Float'];
};

export type StepCreateWithoutPreviousFragmentInput = {
  Traject: TrajectCreateNestedOneWithoutStepsInput;
  city: Scalars['String'];
  completedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date: Scalars['DateTime'];
  distanceFromOrigin: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  isStart?: InputMaybe<Scalars['Boolean']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  nextFragment?: InputMaybe<FragmentCreateNestedManyWithoutStartStepInput>;
  order: Scalars['Int'];
  place: Scalars['String'];
  placeId: Scalars['String'];
  utcInHours: Scalars['Float'];
};

export type StepCreateWithoutTrajectInput = {
  city: Scalars['String'];
  completedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date: Scalars['DateTime'];
  distanceFromOrigin: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  isStart?: InputMaybe<Scalars['Boolean']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  nextFragment?: InputMaybe<FragmentCreateNestedManyWithoutStartStepInput>;
  order: Scalars['Int'];
  place: Scalars['String'];
  placeId: Scalars['String'];
  previousFragment?: InputMaybe<FragmentCreateNestedManyWithoutEndStepInput>;
  utcInHours: Scalars['Float'];
};

export type StepListRelationFilter = {
  every?: InputMaybe<StepWhereInput>;
  none?: InputMaybe<StepWhereInput>;
  some?: InputMaybe<StepWhereInput>;
};

export type StepOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StepOrderByWithRelationInput = {
  Traject?: InputMaybe<TrajectOrderByWithRelationInput>;
  city?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  distanceFromOrigin?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isStart?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  nextFragment?: InputMaybe<FragmentOrderByRelationAggregateInput>;
  order?: InputMaybe<SortOrder>;
  place?: InputMaybe<SortOrder>;
  placeId?: InputMaybe<SortOrder>;
  previousFragment?: InputMaybe<FragmentOrderByRelationAggregateInput>;
  trajectId?: InputMaybe<SortOrder>;
  utcInHours?: InputMaybe<SortOrder>;
};

export type StepRelationFilter = {
  is?: InputMaybe<StepWhereInput>;
  isNot?: InputMaybe<StepWhereInput>;
};

export enum StepScalarFieldEnum {
  City = 'city',
  CompletedAt = 'completedAt',
  CreatedAt = 'createdAt',
  Date = 'date',
  DistanceFromOrigin = 'distanceFromOrigin',
  Id = 'id',
  IsStart = 'isStart',
  Latitude = 'latitude',
  Longitude = 'longitude',
  Order = 'order',
  Place = 'place',
  PlaceId = 'placeId',
  TrajectId = 'trajectId',
  UtcInHours = 'utcInHours'
}

export type StepScalarWhereInput = {
  AND?: InputMaybe<Array<StepScalarWhereInput>>;
  NOT?: InputMaybe<Array<StepScalarWhereInput>>;
  OR?: InputMaybe<Array<StepScalarWhereInput>>;
  city?: InputMaybe<StringFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  date?: InputMaybe<DateTimeFilter>;
  distanceFromOrigin?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  isStart?: InputMaybe<BoolNullableFilter>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  order?: InputMaybe<IntFilter>;
  place?: InputMaybe<StringFilter>;
  placeId?: InputMaybe<StringFilter>;
  trajectId?: InputMaybe<StringFilter>;
  utcInHours?: InputMaybe<FloatFilter>;
};

export type StepUpdateManyMutationInput = {
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  distanceFromOrigin?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isStart?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  place?: InputMaybe<StringFieldUpdateOperationsInput>;
  placeId?: InputMaybe<StringFieldUpdateOperationsInput>;
  utcInHours?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type StepUpdateManyWithWhereWithoutTrajectInput = {
  data: StepUpdateManyMutationInput;
  where: StepScalarWhereInput;
};

export type StepUpdateManyWithoutTrajectInput = {
  connect?: InputMaybe<Array<StepWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepCreateOrConnectWithoutTrajectInput>>;
  create?: InputMaybe<Array<StepCreateWithoutTrajectInput>>;
  createMany?: InputMaybe<StepCreateManyTrajectInputEnvelope>;
  delete?: InputMaybe<Array<StepWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StepScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StepWhereUniqueInput>>;
  set?: InputMaybe<Array<StepWhereUniqueInput>>;
  update?: InputMaybe<Array<StepUpdateWithWhereUniqueWithoutTrajectInput>>;
  updateMany?: InputMaybe<Array<StepUpdateManyWithWhereWithoutTrajectInput>>;
  upsert?: InputMaybe<Array<StepUpsertWithWhereUniqueWithoutTrajectInput>>;
};

export type StepUpdateOneRequiredWithoutNextFragmentInput = {
  connect?: InputMaybe<StepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StepCreateOrConnectWithoutNextFragmentInput>;
  create?: InputMaybe<StepCreateWithoutNextFragmentInput>;
  update?: InputMaybe<StepUpdateWithoutNextFragmentInput>;
  upsert?: InputMaybe<StepUpsertWithoutNextFragmentInput>;
};

export type StepUpdateOneRequiredWithoutPreviousFragmentInput = {
  connect?: InputMaybe<StepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StepCreateOrConnectWithoutPreviousFragmentInput>;
  create?: InputMaybe<StepCreateWithoutPreviousFragmentInput>;
  update?: InputMaybe<StepUpdateWithoutPreviousFragmentInput>;
  upsert?: InputMaybe<StepUpsertWithoutPreviousFragmentInput>;
};

export type StepUpdateWithWhereUniqueWithoutTrajectInput = {
  data: StepUpdateWithoutTrajectInput;
  where: StepWhereUniqueInput;
};

export type StepUpdateWithoutNextFragmentInput = {
  Traject?: InputMaybe<TrajectUpdateOneRequiredWithoutStepsInput>;
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  distanceFromOrigin?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isStart?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  place?: InputMaybe<StringFieldUpdateOperationsInput>;
  placeId?: InputMaybe<StringFieldUpdateOperationsInput>;
  previousFragment?: InputMaybe<FragmentUpdateManyWithoutEndStepInput>;
  utcInHours?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type StepUpdateWithoutPreviousFragmentInput = {
  Traject?: InputMaybe<TrajectUpdateOneRequiredWithoutStepsInput>;
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  distanceFromOrigin?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isStart?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  nextFragment?: InputMaybe<FragmentUpdateManyWithoutStartStepInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  place?: InputMaybe<StringFieldUpdateOperationsInput>;
  placeId?: InputMaybe<StringFieldUpdateOperationsInput>;
  utcInHours?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type StepUpdateWithoutTrajectInput = {
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  distanceFromOrigin?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isStart?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  nextFragment?: InputMaybe<FragmentUpdateManyWithoutStartStepInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  place?: InputMaybe<StringFieldUpdateOperationsInput>;
  placeId?: InputMaybe<StringFieldUpdateOperationsInput>;
  previousFragment?: InputMaybe<FragmentUpdateManyWithoutEndStepInput>;
  utcInHours?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type StepUpsertWithWhereUniqueWithoutTrajectInput = {
  create: StepCreateWithoutTrajectInput;
  update: StepUpdateWithoutTrajectInput;
  where: StepWhereUniqueInput;
};

export type StepUpsertWithoutNextFragmentInput = {
  create: StepCreateWithoutNextFragmentInput;
  update: StepUpdateWithoutNextFragmentInput;
};

export type StepUpsertWithoutPreviousFragmentInput = {
  create: StepCreateWithoutPreviousFragmentInput;
  update: StepUpdateWithoutPreviousFragmentInput;
};

export type StepWhereInput = {
  AND?: InputMaybe<Array<StepWhereInput>>;
  NOT?: InputMaybe<Array<StepWhereInput>>;
  OR?: InputMaybe<Array<StepWhereInput>>;
  Traject?: InputMaybe<TrajectRelationFilter>;
  city?: InputMaybe<StringFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  date?: InputMaybe<DateTimeFilter>;
  distanceFromOrigin?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  isStart?: InputMaybe<BoolNullableFilter>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  nextFragment?: InputMaybe<FragmentListRelationFilter>;
  order?: InputMaybe<IntFilter>;
  place?: InputMaybe<StringFilter>;
  placeId?: InputMaybe<StringFilter>;
  previousFragment?: InputMaybe<FragmentListRelationFilter>;
  trajectId?: InputMaybe<StringFilter>;
  utcInHours?: InputMaybe<FloatFilter>;
};

export type StepWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  newTravel: Travel;
};

export type TicketReservation = {
  __typename?: 'TicketReservation';
  Date: Scalars['DateTime'];
  StatusAfter?: Maybe<ReservationStatus>;
  StatusBefore?: Maybe<ReservationStatus>;
  StatusPaymentAfter?: Maybe<ReservationPaymentStatus>;
  StatusPaymentBefore?: Maybe<ReservationPaymentStatus>;
  id: Scalars['String'];
  reservationId: Scalars['String'];
};

export type TicketReservationCreateManyReservationInput = {
  Date?: InputMaybe<Scalars['DateTime']>;
  StatusAfter?: InputMaybe<ReservationStatus>;
  StatusBefore?: InputMaybe<ReservationStatus>;
  StatusPaymentAfter?: InputMaybe<ReservationPaymentStatus>;
  StatusPaymentBefore?: InputMaybe<ReservationPaymentStatus>;
  id?: InputMaybe<Scalars['String']>;
};

export type TicketReservationCreateManyReservationInputEnvelope = {
  data: Array<TicketReservationCreateManyReservationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TicketReservationCreateNestedManyWithoutReservationInput = {
  connect?: InputMaybe<Array<TicketReservationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TicketReservationCreateOrConnectWithoutReservationInput>>;
  create?: InputMaybe<Array<TicketReservationCreateWithoutReservationInput>>;
  createMany?: InputMaybe<TicketReservationCreateManyReservationInputEnvelope>;
};

export type TicketReservationCreateOrConnectWithoutReservationInput = {
  create: TicketReservationCreateWithoutReservationInput;
  where: TicketReservationWhereUniqueInput;
};

export type TicketReservationCreateWithoutReservationInput = {
  Date?: InputMaybe<Scalars['DateTime']>;
  StatusAfter?: InputMaybe<ReservationStatus>;
  StatusBefore?: InputMaybe<ReservationStatus>;
  StatusPaymentAfter?: InputMaybe<ReservationPaymentStatus>;
  StatusPaymentBefore?: InputMaybe<ReservationPaymentStatus>;
  id?: InputMaybe<Scalars['String']>;
};

export type TicketReservationListRelationFilter = {
  every?: InputMaybe<TicketReservationWhereInput>;
  none?: InputMaybe<TicketReservationWhereInput>;
  some?: InputMaybe<TicketReservationWhereInput>;
};

export type TicketReservationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TicketReservationOrderByWithRelationInput = {
  Date?: InputMaybe<SortOrder>;
  StatusAfter?: InputMaybe<SortOrder>;
  StatusBefore?: InputMaybe<SortOrder>;
  StatusPaymentAfter?: InputMaybe<SortOrder>;
  StatusPaymentBefore?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  reservation?: InputMaybe<ReservationOrderByWithRelationInput>;
  reservationId?: InputMaybe<SortOrder>;
};

export enum TicketReservationScalarFieldEnum {
  Date = 'Date',
  StatusAfter = 'StatusAfter',
  StatusBefore = 'StatusBefore',
  StatusPaymentAfter = 'StatusPaymentAfter',
  StatusPaymentBefore = 'StatusPaymentBefore',
  Id = 'id',
  ReservationId = 'reservationId'
}

export type TicketReservationScalarWhereInput = {
  AND?: InputMaybe<Array<TicketReservationScalarWhereInput>>;
  Date?: InputMaybe<DateTimeFilter>;
  NOT?: InputMaybe<Array<TicketReservationScalarWhereInput>>;
  OR?: InputMaybe<Array<TicketReservationScalarWhereInput>>;
  StatusAfter?: InputMaybe<EnumReservationStatusNullableFilter>;
  StatusBefore?: InputMaybe<EnumReservationStatusNullableFilter>;
  StatusPaymentAfter?: InputMaybe<EnumReservationPaymentStatusNullableFilter>;
  StatusPaymentBefore?: InputMaybe<EnumReservationPaymentStatusNullableFilter>;
  id?: InputMaybe<StringFilter>;
  reservationId?: InputMaybe<StringFilter>;
};

export type TicketReservationUpdateManyMutationInput = {
  Date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  StatusAfter?: InputMaybe<NullableEnumReservationStatusFieldUpdateOperationsInput>;
  StatusBefore?: InputMaybe<NullableEnumReservationStatusFieldUpdateOperationsInput>;
  StatusPaymentAfter?: InputMaybe<NullableEnumReservationPaymentStatusFieldUpdateOperationsInput>;
  StatusPaymentBefore?: InputMaybe<NullableEnumReservationPaymentStatusFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TicketReservationUpdateManyWithWhereWithoutReservationInput = {
  data: TicketReservationUpdateManyMutationInput;
  where: TicketReservationScalarWhereInput;
};

export type TicketReservationUpdateManyWithoutReservationInput = {
  connect?: InputMaybe<Array<TicketReservationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TicketReservationCreateOrConnectWithoutReservationInput>>;
  create?: InputMaybe<Array<TicketReservationCreateWithoutReservationInput>>;
  createMany?: InputMaybe<TicketReservationCreateManyReservationInputEnvelope>;
  delete?: InputMaybe<Array<TicketReservationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TicketReservationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TicketReservationWhereUniqueInput>>;
  set?: InputMaybe<Array<TicketReservationWhereUniqueInput>>;
  update?: InputMaybe<Array<TicketReservationUpdateWithWhereUniqueWithoutReservationInput>>;
  updateMany?: InputMaybe<Array<TicketReservationUpdateManyWithWhereWithoutReservationInput>>;
  upsert?: InputMaybe<Array<TicketReservationUpsertWithWhereUniqueWithoutReservationInput>>;
};

export type TicketReservationUpdateWithWhereUniqueWithoutReservationInput = {
  data: TicketReservationUpdateWithoutReservationInput;
  where: TicketReservationWhereUniqueInput;
};

export type TicketReservationUpdateWithoutReservationInput = {
  Date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  StatusAfter?: InputMaybe<NullableEnumReservationStatusFieldUpdateOperationsInput>;
  StatusBefore?: InputMaybe<NullableEnumReservationStatusFieldUpdateOperationsInput>;
  StatusPaymentAfter?: InputMaybe<NullableEnumReservationPaymentStatusFieldUpdateOperationsInput>;
  StatusPaymentBefore?: InputMaybe<NullableEnumReservationPaymentStatusFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TicketReservationUpsertWithWhereUniqueWithoutReservationInput = {
  create: TicketReservationCreateWithoutReservationInput;
  update: TicketReservationUpdateWithoutReservationInput;
  where: TicketReservationWhereUniqueInput;
};

export type TicketReservationWhereInput = {
  AND?: InputMaybe<Array<TicketReservationWhereInput>>;
  Date?: InputMaybe<DateTimeFilter>;
  NOT?: InputMaybe<Array<TicketReservationWhereInput>>;
  OR?: InputMaybe<Array<TicketReservationWhereInput>>;
  StatusAfter?: InputMaybe<EnumReservationStatusNullableFilter>;
  StatusBefore?: InputMaybe<EnumReservationStatusNullableFilter>;
  StatusPaymentAfter?: InputMaybe<EnumReservationPaymentStatusNullableFilter>;
  StatusPaymentBefore?: InputMaybe<EnumReservationPaymentStatusNullableFilter>;
  id?: InputMaybe<StringFilter>;
  reservation?: InputMaybe<ReservationRelationFilter>;
  reservationId?: InputMaybe<StringFilter>;
};

export type TicketReservationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Traject = {
  __typename?: 'Traject';
  Travel: Travel;
  _count?: Maybe<TrajectCount>;
  fragments: Array<Fragment>;
  id: Scalars['String'];
  steps: Array<Step>;
  travelId: Scalars['String'];
};


export type TrajectFragmentsArgs = {
  cursor?: InputMaybe<FragmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<FragmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FragmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FragmentWhereInput>;
};


export type TrajectStepsArgs = {
  cursor?: InputMaybe<StepWhereUniqueInput>;
  distinct?: InputMaybe<Array<StepScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StepOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StepWhereInput>;
};

export type TrajectCount = {
  __typename?: 'TrajectCount';
  fragments: Scalars['Int'];
  steps: Scalars['Int'];
};

export type TrajectCreateManyTravelInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type TrajectCreateManyTravelInputEnvelope = {
  data: Array<TrajectCreateManyTravelInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TrajectCreateNestedManyWithoutTravelInput = {
  connect?: InputMaybe<Array<TrajectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TrajectCreateOrConnectWithoutTravelInput>>;
  create?: InputMaybe<Array<TrajectCreateWithoutTravelInput>>;
  createMany?: InputMaybe<TrajectCreateManyTravelInputEnvelope>;
};

export type TrajectCreateNestedOneWithoutFragmentsInput = {
  connect?: InputMaybe<TrajectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TrajectCreateOrConnectWithoutFragmentsInput>;
  create?: InputMaybe<TrajectCreateWithoutFragmentsInput>;
};

export type TrajectCreateNestedOneWithoutStepsInput = {
  connect?: InputMaybe<TrajectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TrajectCreateOrConnectWithoutStepsInput>;
  create?: InputMaybe<TrajectCreateWithoutStepsInput>;
};

export type TrajectCreateOrConnectWithoutFragmentsInput = {
  create: TrajectCreateWithoutFragmentsInput;
  where: TrajectWhereUniqueInput;
};

export type TrajectCreateOrConnectWithoutStepsInput = {
  create: TrajectCreateWithoutStepsInput;
  where: TrajectWhereUniqueInput;
};

export type TrajectCreateOrConnectWithoutTravelInput = {
  create: TrajectCreateWithoutTravelInput;
  where: TrajectWhereUniqueInput;
};

export type TrajectCreateWithoutFragmentsInput = {
  Travel: TravelCreateNestedOneWithoutTrajectInput;
  id?: InputMaybe<Scalars['String']>;
  steps?: InputMaybe<StepCreateNestedManyWithoutTrajectInput>;
};

export type TrajectCreateWithoutStepsInput = {
  Travel: TravelCreateNestedOneWithoutTrajectInput;
  fragments?: InputMaybe<FragmentCreateNestedManyWithoutTrajectInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type TrajectCreateWithoutTravelInput = {
  fragments?: InputMaybe<FragmentCreateNestedManyWithoutTrajectInput>;
  id?: InputMaybe<Scalars['String']>;
  steps?: InputMaybe<StepCreateNestedManyWithoutTrajectInput>;
};

export type TrajectListRelationFilter = {
  every?: InputMaybe<TrajectWhereInput>;
  none?: InputMaybe<TrajectWhereInput>;
  some?: InputMaybe<TrajectWhereInput>;
};

export type TrajectOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TrajectOrderByWithRelationInput = {
  Travel?: InputMaybe<TravelOrderByWithRelationInput>;
  fragments?: InputMaybe<FragmentOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  steps?: InputMaybe<StepOrderByRelationAggregateInput>;
  travelId?: InputMaybe<SortOrder>;
};

export type TrajectRelationFilter = {
  is?: InputMaybe<TrajectWhereInput>;
  isNot?: InputMaybe<TrajectWhereInput>;
};

export enum TrajectScalarFieldEnum {
  Id = 'id',
  TravelId = 'travelId'
}

export type TrajectScalarWhereInput = {
  AND?: InputMaybe<Array<TrajectScalarWhereInput>>;
  NOT?: InputMaybe<Array<TrajectScalarWhereInput>>;
  OR?: InputMaybe<Array<TrajectScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  travelId?: InputMaybe<StringFilter>;
};

export type TrajectUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TrajectUpdateManyWithWhereWithoutTravelInput = {
  data: TrajectUpdateManyMutationInput;
  where: TrajectScalarWhereInput;
};

export type TrajectUpdateManyWithoutTravelInput = {
  connect?: InputMaybe<Array<TrajectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TrajectCreateOrConnectWithoutTravelInput>>;
  create?: InputMaybe<Array<TrajectCreateWithoutTravelInput>>;
  createMany?: InputMaybe<TrajectCreateManyTravelInputEnvelope>;
  delete?: InputMaybe<Array<TrajectWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TrajectScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TrajectWhereUniqueInput>>;
  set?: InputMaybe<Array<TrajectWhereUniqueInput>>;
  update?: InputMaybe<Array<TrajectUpdateWithWhereUniqueWithoutTravelInput>>;
  updateMany?: InputMaybe<Array<TrajectUpdateManyWithWhereWithoutTravelInput>>;
  upsert?: InputMaybe<Array<TrajectUpsertWithWhereUniqueWithoutTravelInput>>;
};

export type TrajectUpdateOneRequiredWithoutStepsInput = {
  connect?: InputMaybe<TrajectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TrajectCreateOrConnectWithoutStepsInput>;
  create?: InputMaybe<TrajectCreateWithoutStepsInput>;
  update?: InputMaybe<TrajectUpdateWithoutStepsInput>;
  upsert?: InputMaybe<TrajectUpsertWithoutStepsInput>;
};

export type TrajectUpdateOneWithoutFragmentsInput = {
  connect?: InputMaybe<TrajectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TrajectCreateOrConnectWithoutFragmentsInput>;
  create?: InputMaybe<TrajectCreateWithoutFragmentsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<TrajectUpdateWithoutFragmentsInput>;
  upsert?: InputMaybe<TrajectUpsertWithoutFragmentsInput>;
};

export type TrajectUpdateWithWhereUniqueWithoutTravelInput = {
  data: TrajectUpdateWithoutTravelInput;
  where: TrajectWhereUniqueInput;
};

export type TrajectUpdateWithoutFragmentsInput = {
  Travel?: InputMaybe<TravelUpdateOneRequiredWithoutTrajectInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  steps?: InputMaybe<StepUpdateManyWithoutTrajectInput>;
};

export type TrajectUpdateWithoutStepsInput = {
  Travel?: InputMaybe<TravelUpdateOneRequiredWithoutTrajectInput>;
  fragments?: InputMaybe<FragmentUpdateManyWithoutTrajectInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TrajectUpdateWithoutTravelInput = {
  fragments?: InputMaybe<FragmentUpdateManyWithoutTrajectInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  steps?: InputMaybe<StepUpdateManyWithoutTrajectInput>;
};

export type TrajectUpsertWithWhereUniqueWithoutTravelInput = {
  create: TrajectCreateWithoutTravelInput;
  update: TrajectUpdateWithoutTravelInput;
  where: TrajectWhereUniqueInput;
};

export type TrajectUpsertWithoutFragmentsInput = {
  create: TrajectCreateWithoutFragmentsInput;
  update: TrajectUpdateWithoutFragmentsInput;
};

export type TrajectUpsertWithoutStepsInput = {
  create: TrajectCreateWithoutStepsInput;
  update: TrajectUpdateWithoutStepsInput;
};

export type TrajectWhereInput = {
  AND?: InputMaybe<Array<TrajectWhereInput>>;
  NOT?: InputMaybe<Array<TrajectWhereInput>>;
  OR?: InputMaybe<Array<TrajectWhereInput>>;
  Travel?: InputMaybe<TravelRelationFilter>;
  fragments?: InputMaybe<FragmentListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  steps?: InputMaybe<StepListRelationFilter>;
  travelId?: InputMaybe<StringFilter>;
};

export type TrajectWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  travelId?: InputMaybe<Scalars['String']>;
};

export type Travel = {
  __typename?: 'Travel';
  PaymentStatus: TravelPaymentStatus;
  PaymentType: TravelPaymentType;
  Status: TravelStatus;
  _count?: Maybe<TravelCount>;
  comment: Scalars['String'];
  conductorUser: User;
  conductorUserId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  directReservation: Scalars['Boolean'];
  driverPreventedBeforeStart: Scalars['Boolean'];
  flexSteps: Scalars['Boolean'];
  id: Scalars['String'];
  localeUTCInHours: Scalars['Float'];
  places: Scalars['Int'];
  priceCurrency: Currency;
  startHour: Scalars['DateTime'];
  totalDistance: Scalars['Int'];
  totalPrice: Scalars['Float'];
  traject: Array<Traject>;
};


export type TravelTrajectArgs = {
  cursor?: InputMaybe<TrajectWhereUniqueInput>;
  distinct?: InputMaybe<Array<TrajectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TrajectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TrajectWhereInput>;
};

export type TravelCount = {
  __typename?: 'TravelCount';
  traject: Scalars['Int'];
};

export type TravelCreateInput = {
  PaymentStatus?: InputMaybe<TravelPaymentStatus>;
  PaymentType?: InputMaybe<TravelPaymentType>;
  Status?: InputMaybe<TravelStatus>;
  comment?: InputMaybe<Scalars['String']>;
  conductorUser: UserCreateNestedOneWithoutTravelsAsConductorInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  directReservation?: InputMaybe<Scalars['Boolean']>;
  driverPreventedBeforeStart?: InputMaybe<Scalars['Boolean']>;
  flexSteps?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  localeUTCInHours?: InputMaybe<Scalars['Float']>;
  places: Scalars['Int'];
  priceCurrency: Currency;
  startHour: Scalars['DateTime'];
  totalDistance: Scalars['Int'];
  totalPrice: Scalars['Float'];
  traject?: InputMaybe<TrajectCreateNestedManyWithoutTravelInput>;
};

export type TravelCreateManyConductorUserInput = {
  PaymentStatus?: InputMaybe<TravelPaymentStatus>;
  PaymentType?: InputMaybe<TravelPaymentType>;
  Status?: InputMaybe<TravelStatus>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  directReservation?: InputMaybe<Scalars['Boolean']>;
  driverPreventedBeforeStart?: InputMaybe<Scalars['Boolean']>;
  flexSteps?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  localeUTCInHours?: InputMaybe<Scalars['Float']>;
  places: Scalars['Int'];
  priceCurrency: Currency;
  startHour: Scalars['DateTime'];
  totalDistance: Scalars['Int'];
  totalPrice: Scalars['Float'];
};

export type TravelCreateManyConductorUserInputEnvelope = {
  data: Array<TravelCreateManyConductorUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TravelCreateNestedManyWithoutConductorUserInput = {
  connect?: InputMaybe<Array<TravelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TravelCreateOrConnectWithoutConductorUserInput>>;
  create?: InputMaybe<Array<TravelCreateWithoutConductorUserInput>>;
  createMany?: InputMaybe<TravelCreateManyConductorUserInputEnvelope>;
};

export type TravelCreateNestedOneWithoutTrajectInput = {
  connect?: InputMaybe<TravelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TravelCreateOrConnectWithoutTrajectInput>;
  create?: InputMaybe<TravelCreateWithoutTrajectInput>;
};

export type TravelCreateOrConnectWithoutConductorUserInput = {
  create: TravelCreateWithoutConductorUserInput;
  where: TravelWhereUniqueInput;
};

export type TravelCreateOrConnectWithoutTrajectInput = {
  create: TravelCreateWithoutTrajectInput;
  where: TravelWhereUniqueInput;
};

export type TravelCreateWithoutConductorUserInput = {
  PaymentStatus?: InputMaybe<TravelPaymentStatus>;
  PaymentType?: InputMaybe<TravelPaymentType>;
  Status?: InputMaybe<TravelStatus>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  directReservation?: InputMaybe<Scalars['Boolean']>;
  driverPreventedBeforeStart?: InputMaybe<Scalars['Boolean']>;
  flexSteps?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  localeUTCInHours?: InputMaybe<Scalars['Float']>;
  places: Scalars['Int'];
  priceCurrency: Currency;
  startHour: Scalars['DateTime'];
  totalDistance: Scalars['Int'];
  totalPrice: Scalars['Float'];
  traject?: InputMaybe<TrajectCreateNestedManyWithoutTravelInput>;
};

export type TravelCreateWithoutTrajectInput = {
  PaymentStatus?: InputMaybe<TravelPaymentStatus>;
  PaymentType?: InputMaybe<TravelPaymentType>;
  Status?: InputMaybe<TravelStatus>;
  comment?: InputMaybe<Scalars['String']>;
  conductorUser: UserCreateNestedOneWithoutTravelsAsConductorInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  directReservation?: InputMaybe<Scalars['Boolean']>;
  driverPreventedBeforeStart?: InputMaybe<Scalars['Boolean']>;
  flexSteps?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  localeUTCInHours?: InputMaybe<Scalars['Float']>;
  places: Scalars['Int'];
  priceCurrency: Currency;
  startHour: Scalars['DateTime'];
  totalDistance: Scalars['Int'];
  totalPrice: Scalars['Float'];
};

export type TravelListRelationFilter = {
  every?: InputMaybe<TravelWhereInput>;
  none?: InputMaybe<TravelWhereInput>;
  some?: InputMaybe<TravelWhereInput>;
};

export type TravelOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TravelOrderByWithRelationInput = {
  PaymentStatus?: InputMaybe<SortOrder>;
  PaymentType?: InputMaybe<SortOrder>;
  Status?: InputMaybe<SortOrder>;
  comment?: InputMaybe<SortOrder>;
  conductorUser?: InputMaybe<UserOrderByWithRelationInput>;
  conductorUserId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  directReservation?: InputMaybe<SortOrder>;
  driverPreventedBeforeStart?: InputMaybe<SortOrder>;
  flexSteps?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  localeUTCInHours?: InputMaybe<SortOrder>;
  places?: InputMaybe<SortOrder>;
  priceCurrency?: InputMaybe<SortOrder>;
  startHour?: InputMaybe<SortOrder>;
  totalDistance?: InputMaybe<SortOrder>;
  totalPrice?: InputMaybe<SortOrder>;
  traject?: InputMaybe<TrajectOrderByRelationAggregateInput>;
};

export enum TravelPaymentStatus {
  Accepted = 'ACCEPTED',
  Nostarted = 'NOSTARTED',
  Refused = 'REFUSED'
}

export enum TravelPaymentType {
  Free = 'FREE',
  Paid = 'PAID'
}

export type TravelRelationFilter = {
  is?: InputMaybe<TravelWhereInput>;
  isNot?: InputMaybe<TravelWhereInput>;
};

export enum TravelScalarFieldEnum {
  PaymentStatus = 'PaymentStatus',
  PaymentType = 'PaymentType',
  Status = 'Status',
  Comment = 'comment',
  ConductorUserId = 'conductorUserId',
  CreatedAt = 'createdAt',
  DirectReservation = 'directReservation',
  DriverPreventedBeforeStart = 'driverPreventedBeforeStart',
  FlexSteps = 'flexSteps',
  Id = 'id',
  LocaleUtcInHours = 'localeUTCInHours',
  Places = 'places',
  PriceCurrency = 'priceCurrency',
  StartHour = 'startHour',
  TotalDistance = 'totalDistance',
  TotalPrice = 'totalPrice'
}

export type TravelScalarWhereInput = {
  AND?: InputMaybe<Array<TravelScalarWhereInput>>;
  NOT?: InputMaybe<Array<TravelScalarWhereInput>>;
  OR?: InputMaybe<Array<TravelScalarWhereInput>>;
  PaymentStatus?: InputMaybe<EnumTravelPaymentStatusFilter>;
  PaymentType?: InputMaybe<EnumTravelPaymentTypeFilter>;
  Status?: InputMaybe<EnumTravelStatusFilter>;
  comment?: InputMaybe<StringFilter>;
  conductorUserId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  directReservation?: InputMaybe<BoolFilter>;
  driverPreventedBeforeStart?: InputMaybe<BoolFilter>;
  flexSteps?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  localeUTCInHours?: InputMaybe<FloatFilter>;
  places?: InputMaybe<IntFilter>;
  priceCurrency?: InputMaybe<EnumCurrencyFilter>;
  startHour?: InputMaybe<DateTimeFilter>;
  totalDistance?: InputMaybe<IntFilter>;
  totalPrice?: InputMaybe<FloatFilter>;
};

export enum TravelStatus {
  Deleted = 'DELETED',
  Failed = 'FAILED',
  Finished = 'FINISHED',
  Nostarted = 'NOSTARTED',
  Started = 'STARTED',
  Success = 'SUCCESS'
}

export type TravelUpdateInput = {
  PaymentStatus?: InputMaybe<EnumTravelPaymentStatusFieldUpdateOperationsInput>;
  PaymentType?: InputMaybe<EnumTravelPaymentTypeFieldUpdateOperationsInput>;
  Status?: InputMaybe<EnumTravelStatusFieldUpdateOperationsInput>;
  comment?: InputMaybe<StringFieldUpdateOperationsInput>;
  conductorUser?: InputMaybe<UserUpdateOneRequiredWithoutTravelsAsConductorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  directReservation?: InputMaybe<BoolFieldUpdateOperationsInput>;
  driverPreventedBeforeStart?: InputMaybe<BoolFieldUpdateOperationsInput>;
  flexSteps?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  localeUTCInHours?: InputMaybe<FloatFieldUpdateOperationsInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  priceCurrency?: InputMaybe<EnumCurrencyFieldUpdateOperationsInput>;
  startHour?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  totalDistance?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  traject?: InputMaybe<TrajectUpdateManyWithoutTravelInput>;
};

export type TravelUpdateManyMutationInput = {
  PaymentStatus?: InputMaybe<EnumTravelPaymentStatusFieldUpdateOperationsInput>;
  PaymentType?: InputMaybe<EnumTravelPaymentTypeFieldUpdateOperationsInput>;
  Status?: InputMaybe<EnumTravelStatusFieldUpdateOperationsInput>;
  comment?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  directReservation?: InputMaybe<BoolFieldUpdateOperationsInput>;
  driverPreventedBeforeStart?: InputMaybe<BoolFieldUpdateOperationsInput>;
  flexSteps?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  localeUTCInHours?: InputMaybe<FloatFieldUpdateOperationsInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  priceCurrency?: InputMaybe<EnumCurrencyFieldUpdateOperationsInput>;
  startHour?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  totalDistance?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type TravelUpdateManyWithWhereWithoutConductorUserInput = {
  data: TravelUpdateManyMutationInput;
  where: TravelScalarWhereInput;
};

export type TravelUpdateManyWithoutConductorUserInput = {
  connect?: InputMaybe<Array<TravelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TravelCreateOrConnectWithoutConductorUserInput>>;
  create?: InputMaybe<Array<TravelCreateWithoutConductorUserInput>>;
  createMany?: InputMaybe<TravelCreateManyConductorUserInputEnvelope>;
  delete?: InputMaybe<Array<TravelWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TravelScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TravelWhereUniqueInput>>;
  set?: InputMaybe<Array<TravelWhereUniqueInput>>;
  update?: InputMaybe<Array<TravelUpdateWithWhereUniqueWithoutConductorUserInput>>;
  updateMany?: InputMaybe<Array<TravelUpdateManyWithWhereWithoutConductorUserInput>>;
  upsert?: InputMaybe<Array<TravelUpsertWithWhereUniqueWithoutConductorUserInput>>;
};

export type TravelUpdateOneRequiredWithoutTrajectInput = {
  connect?: InputMaybe<TravelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TravelCreateOrConnectWithoutTrajectInput>;
  create?: InputMaybe<TravelCreateWithoutTrajectInput>;
  update?: InputMaybe<TravelUpdateWithoutTrajectInput>;
  upsert?: InputMaybe<TravelUpsertWithoutTrajectInput>;
};

export type TravelUpdateWithWhereUniqueWithoutConductorUserInput = {
  data: TravelUpdateWithoutConductorUserInput;
  where: TravelWhereUniqueInput;
};

export type TravelUpdateWithoutConductorUserInput = {
  PaymentStatus?: InputMaybe<EnumTravelPaymentStatusFieldUpdateOperationsInput>;
  PaymentType?: InputMaybe<EnumTravelPaymentTypeFieldUpdateOperationsInput>;
  Status?: InputMaybe<EnumTravelStatusFieldUpdateOperationsInput>;
  comment?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  directReservation?: InputMaybe<BoolFieldUpdateOperationsInput>;
  driverPreventedBeforeStart?: InputMaybe<BoolFieldUpdateOperationsInput>;
  flexSteps?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  localeUTCInHours?: InputMaybe<FloatFieldUpdateOperationsInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  priceCurrency?: InputMaybe<EnumCurrencyFieldUpdateOperationsInput>;
  startHour?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  totalDistance?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  traject?: InputMaybe<TrajectUpdateManyWithoutTravelInput>;
};

export type TravelUpdateWithoutTrajectInput = {
  PaymentStatus?: InputMaybe<EnumTravelPaymentStatusFieldUpdateOperationsInput>;
  PaymentType?: InputMaybe<EnumTravelPaymentTypeFieldUpdateOperationsInput>;
  Status?: InputMaybe<EnumTravelStatusFieldUpdateOperationsInput>;
  comment?: InputMaybe<StringFieldUpdateOperationsInput>;
  conductorUser?: InputMaybe<UserUpdateOneRequiredWithoutTravelsAsConductorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  directReservation?: InputMaybe<BoolFieldUpdateOperationsInput>;
  driverPreventedBeforeStart?: InputMaybe<BoolFieldUpdateOperationsInput>;
  flexSteps?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  localeUTCInHours?: InputMaybe<FloatFieldUpdateOperationsInput>;
  places?: InputMaybe<IntFieldUpdateOperationsInput>;
  priceCurrency?: InputMaybe<EnumCurrencyFieldUpdateOperationsInput>;
  startHour?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  totalDistance?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type TravelUpsertWithWhereUniqueWithoutConductorUserInput = {
  create: TravelCreateWithoutConductorUserInput;
  update: TravelUpdateWithoutConductorUserInput;
  where: TravelWhereUniqueInput;
};

export type TravelUpsertWithoutTrajectInput = {
  create: TravelCreateWithoutTrajectInput;
  update: TravelUpdateWithoutTrajectInput;
};

export type TravelWhereInput = {
  AND?: InputMaybe<Array<TravelWhereInput>>;
  NOT?: InputMaybe<Array<TravelWhereInput>>;
  OR?: InputMaybe<Array<TravelWhereInput>>;
  PaymentStatus?: InputMaybe<EnumTravelPaymentStatusFilter>;
  PaymentType?: InputMaybe<EnumTravelPaymentTypeFilter>;
  Status?: InputMaybe<EnumTravelStatusFilter>;
  comment?: InputMaybe<StringFilter>;
  conductorUser?: InputMaybe<UserRelationFilter>;
  conductorUserId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  directReservation?: InputMaybe<BoolFilter>;
  driverPreventedBeforeStart?: InputMaybe<BoolFilter>;
  flexSteps?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  localeUTCInHours?: InputMaybe<FloatFilter>;
  places?: InputMaybe<IntFilter>;
  priceCurrency?: InputMaybe<EnumCurrencyFilter>;
  startHour?: InputMaybe<DateTimeFilter>;
  totalDistance?: InputMaybe<IntFilter>;
  totalPrice?: InputMaybe<FloatFilter>;
  traject?: InputMaybe<TrajectListRelationFilter>;
};

export type TravelWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type UpdateUserResult = UpdateUserResultError | UpdateUserResultSuccess;

export type UpdateUserResultError = {
  __typename?: 'UpdateUserResultError';
  code: UpdateUserResultErrorDto;
};

export enum UpdateUserResultErrorDto {
  InternalError = 'INTERNAL_ERROR'
}

export type UpdateUserResultSuccess = {
  __typename?: 'UpdateUserResultSuccess';
  user: User;
};

export type User = {
  __typename?: 'User';
  _count?: Maybe<UserCount>;
  birthDate?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hadChangePhoto: Scalars['Boolean'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  memberSince: Scalars['DateTime'];
  photoURL?: Maybe<Scalars['String']>;
  profileDescription?: Maybe<Scalars['String']>;
  role: Role;
  secondName?: Maybe<Scalars['String']>;
  status: UserStatus;
  telephone?: Maybe<Scalars['String']>;
  userType: UserType;
  ville?: Maybe<Scalars['String']>;
};

export type UserCount = {
  __typename?: 'UserCount';
  ConversationAsConductor: Scalars['Int'];
  ConversationAsPassenger: Scalars['Int'];
  Message: Scalars['Int'];
  Notification: Scalars['Int'];
  payerPushToken: Scalars['Int'];
  reservations: Scalars['Int'];
  travelsAsConductor: Scalars['Int'];
};

export type UserCreateNestedOneWithoutConversationAsConductorInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutConversationAsConductorInput>;
  create?: InputMaybe<UserCreateWithoutConversationAsConductorInput>;
};

export type UserCreateNestedOneWithoutConversationAsPassengerInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutConversationAsPassengerInput>;
  create?: InputMaybe<UserCreateWithoutConversationAsPassengerInput>;
};

export type UserCreateNestedOneWithoutMessageInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutMessageInput>;
  create?: InputMaybe<UserCreateWithoutMessageInput>;
};

export type UserCreateNestedOneWithoutReservationsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReservationsInput>;
  create?: InputMaybe<UserCreateWithoutReservationsInput>;
};

export type UserCreateNestedOneWithoutTravelsAsConductorInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTravelsAsConductorInput>;
  create?: InputMaybe<UserCreateWithoutTravelsAsConductorInput>;
};

export type UserCreateOrConnectWithoutConversationAsConductorInput = {
  create: UserCreateWithoutConversationAsConductorInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutConversationAsPassengerInput = {
  create: UserCreateWithoutConversationAsPassengerInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutMessageInput = {
  create: UserCreateWithoutMessageInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutReservationsInput = {
  create: UserCreateWithoutReservationsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTravelsAsConductorInput = {
  create: UserCreateWithoutTravelsAsConductorInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutConversationAsConductorInput = {
  ConversationAsPassenger?: InputMaybe<ConversationCreateNestedManyWithoutUserPassengerInput>;
  Message?: InputMaybe<MessageCreateNestedManyWithoutSenderInput>;
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutUserRecipientInput>;
  birthDate?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firebaseId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hadChangePhoto?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  memberSince?: InputMaybe<Scalars['DateTime']>;
  payerPushToken?: InputMaybe<UserPushTokenCreateNestedManyWithoutUserInput>;
  photoURL?: InputMaybe<Scalars['String']>;
  profileDescription?: InputMaybe<Scalars['String']>;
  reservations?: InputMaybe<ReservationCreateNestedManyWithoutPassengerInput>;
  role?: InputMaybe<Role>;
  secondName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  telephone?: InputMaybe<Scalars['String']>;
  travelsAsConductor?: InputMaybe<TravelCreateNestedManyWithoutConductorUserInput>;
  userType?: InputMaybe<UserType>;
  ville?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutConversationAsPassengerInput = {
  ConversationAsConductor?: InputMaybe<ConversationCreateNestedManyWithoutUserConductorInput>;
  Message?: InputMaybe<MessageCreateNestedManyWithoutSenderInput>;
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutUserRecipientInput>;
  birthDate?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firebaseId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hadChangePhoto?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  memberSince?: InputMaybe<Scalars['DateTime']>;
  payerPushToken?: InputMaybe<UserPushTokenCreateNestedManyWithoutUserInput>;
  photoURL?: InputMaybe<Scalars['String']>;
  profileDescription?: InputMaybe<Scalars['String']>;
  reservations?: InputMaybe<ReservationCreateNestedManyWithoutPassengerInput>;
  role?: InputMaybe<Role>;
  secondName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  telephone?: InputMaybe<Scalars['String']>;
  travelsAsConductor?: InputMaybe<TravelCreateNestedManyWithoutConductorUserInput>;
  userType?: InputMaybe<UserType>;
  ville?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutMessageInput = {
  ConversationAsConductor?: InputMaybe<ConversationCreateNestedManyWithoutUserConductorInput>;
  ConversationAsPassenger?: InputMaybe<ConversationCreateNestedManyWithoutUserPassengerInput>;
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutUserRecipientInput>;
  birthDate?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firebaseId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hadChangePhoto?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  memberSince?: InputMaybe<Scalars['DateTime']>;
  payerPushToken?: InputMaybe<UserPushTokenCreateNestedManyWithoutUserInput>;
  photoURL?: InputMaybe<Scalars['String']>;
  profileDescription?: InputMaybe<Scalars['String']>;
  reservations?: InputMaybe<ReservationCreateNestedManyWithoutPassengerInput>;
  role?: InputMaybe<Role>;
  secondName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  telephone?: InputMaybe<Scalars['String']>;
  travelsAsConductor?: InputMaybe<TravelCreateNestedManyWithoutConductorUserInput>;
  userType?: InputMaybe<UserType>;
  ville?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutReservationsInput = {
  ConversationAsConductor?: InputMaybe<ConversationCreateNestedManyWithoutUserConductorInput>;
  ConversationAsPassenger?: InputMaybe<ConversationCreateNestedManyWithoutUserPassengerInput>;
  Message?: InputMaybe<MessageCreateNestedManyWithoutSenderInput>;
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutUserRecipientInput>;
  birthDate?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firebaseId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hadChangePhoto?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  memberSince?: InputMaybe<Scalars['DateTime']>;
  payerPushToken?: InputMaybe<UserPushTokenCreateNestedManyWithoutUserInput>;
  photoURL?: InputMaybe<Scalars['String']>;
  profileDescription?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role>;
  secondName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  telephone?: InputMaybe<Scalars['String']>;
  travelsAsConductor?: InputMaybe<TravelCreateNestedManyWithoutConductorUserInput>;
  userType?: InputMaybe<UserType>;
  ville?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutTravelsAsConductorInput = {
  ConversationAsConductor?: InputMaybe<ConversationCreateNestedManyWithoutUserConductorInput>;
  ConversationAsPassenger?: InputMaybe<ConversationCreateNestedManyWithoutUserPassengerInput>;
  Message?: InputMaybe<MessageCreateNestedManyWithoutSenderInput>;
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutUserRecipientInput>;
  birthDate?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firebaseId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hadChangePhoto?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  memberSince?: InputMaybe<Scalars['DateTime']>;
  payerPushToken?: InputMaybe<UserPushTokenCreateNestedManyWithoutUserInput>;
  photoURL?: InputMaybe<Scalars['String']>;
  profileDescription?: InputMaybe<Scalars['String']>;
  reservations?: InputMaybe<ReservationCreateNestedManyWithoutPassengerInput>;
  role?: InputMaybe<Role>;
  secondName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  telephone?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<UserType>;
  ville?: InputMaybe<Scalars['String']>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  scoresReceived: Array<Score>;
  travelsSharedFinished: Scalars['Float'];
  user: User;
};

export type UserOrderByWithRelationInput = {
  ConversationAsConductor?: InputMaybe<ConversationOrderByRelationAggregateInput>;
  ConversationAsPassenger?: InputMaybe<ConversationOrderByRelationAggregateInput>;
  Message?: InputMaybe<MessageOrderByRelationAggregateInput>;
  Notification?: InputMaybe<NotificationOrderByRelationAggregateInput>;
  birthDate?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firebaseId?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  hadChangePhoto?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  memberSince?: InputMaybe<SortOrder>;
  payerPushToken?: InputMaybe<UserPushTokenOrderByRelationAggregateInput>;
  photoURL?: InputMaybe<SortOrder>;
  profileDescription?: InputMaybe<SortOrder>;
  reservations?: InputMaybe<ReservationOrderByRelationAggregateInput>;
  role?: InputMaybe<SortOrder>;
  secondName?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  telephone?: InputMaybe<SortOrder>;
  travelsAsConductor?: InputMaybe<TravelOrderByRelationAggregateInput>;
  userType?: InputMaybe<SortOrder>;
  ville?: InputMaybe<SortOrder>;
};

export type UserPushToken = {
  __typename?: 'UserPushToken';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  pushToken: Scalars['String'];
  userId: Scalars['String'];
};

export type UserPushTokenCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  pushToken: Scalars['String'];
};

export type UserPushTokenCreateManyUserInputEnvelope = {
  data: Array<UserPushTokenCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserPushTokenCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserPushTokenWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserPushTokenCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserPushTokenCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserPushTokenCreateManyUserInputEnvelope>;
};

export type UserPushTokenCreateOrConnectWithoutUserInput = {
  create: UserPushTokenCreateWithoutUserInput;
  where: UserPushTokenWhereUniqueInput;
};

export type UserPushTokenCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  pushToken: Scalars['String'];
};

export type UserPushTokenListRelationFilter = {
  every?: InputMaybe<UserPushTokenWhereInput>;
  none?: InputMaybe<UserPushTokenWhereInput>;
  some?: InputMaybe<UserPushTokenWhereInput>;
};

export type UserPushTokenOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserPushTokenScalarWhereInput = {
  AND?: InputMaybe<Array<UserPushTokenScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserPushTokenScalarWhereInput>>;
  OR?: InputMaybe<Array<UserPushTokenScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  pushToken?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserPushTokenUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  pushToken?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserPushTokenUpdateManyWithWhereWithoutUserInput = {
  data: UserPushTokenUpdateManyMutationInput;
  where: UserPushTokenScalarWhereInput;
};

export type UserPushTokenUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserPushTokenWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserPushTokenCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserPushTokenCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserPushTokenCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserPushTokenWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserPushTokenScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserPushTokenWhereUniqueInput>>;
  set?: InputMaybe<Array<UserPushTokenWhereUniqueInput>>;
  update?: InputMaybe<Array<UserPushTokenUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserPushTokenUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserPushTokenUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserPushTokenUpdateWithWhereUniqueWithoutUserInput = {
  data: UserPushTokenUpdateWithoutUserInput;
  where: UserPushTokenWhereUniqueInput;
};

export type UserPushTokenUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  pushToken?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserPushTokenUpsertWithWhereUniqueWithoutUserInput = {
  create: UserPushTokenCreateWithoutUserInput;
  update: UserPushTokenUpdateWithoutUserInput;
  where: UserPushTokenWhereUniqueInput;
};

export type UserPushTokenWhereInput = {
  AND?: InputMaybe<Array<UserPushTokenWhereInput>>;
  NOT?: InputMaybe<Array<UserPushTokenWhereInput>>;
  OR?: InputMaybe<Array<UserPushTokenWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  pushToken?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserPushTokenWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  pushToken?: InputMaybe<Scalars['String']>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  BirthDate = 'birthDate',
  Email = 'email',
  FirebaseId = 'firebaseId',
  FirstName = 'firstName',
  HadChangePhoto = 'hadChangePhoto',
  Id = 'id',
  IsActive = 'isActive',
  MemberSince = 'memberSince',
  PhotoUrl = 'photoURL',
  ProfileDescription = 'profileDescription',
  Role = 'role',
  SecondName = 'secondName',
  SignKey = 'signKey',
  Status = 'status',
  Telephone = 'telephone',
  UserType = 'userType',
  Ville = 'ville'
}

export enum UserStatus {
  Blocked = 'BLOCKED',
  Closed = 'CLOSED',
  Open = 'OPEN'
}

export enum UserType {
  Free = 'FREE',
  Paying = 'PAYING'
}

export type UserUpdateInput = {
  ConversationAsConductor?: InputMaybe<ConversationUpdateManyWithoutUserConductorInput>;
  ConversationAsPassenger?: InputMaybe<ConversationUpdateManyWithoutUserPassengerInput>;
  Message?: InputMaybe<MessageUpdateManyWithoutSenderInput>;
  Notification?: InputMaybe<NotificationUpdateManyWithoutUserRecipientInput>;
  birthDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firebaseId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hadChangePhoto?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  memberSince?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  payerPushToken?: InputMaybe<UserPushTokenUpdateManyWithoutUserInput>;
  photoURL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  profileDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reservations?: InputMaybe<ReservationUpdateManyWithoutPassengerInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  secondName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  telephone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  travelsAsConductor?: InputMaybe<TravelUpdateManyWithoutConductorUserInput>;
  userType?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
  ville?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateOneRequiredWithoutConversationAsConductorInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutConversationAsConductorInput>;
  create?: InputMaybe<UserCreateWithoutConversationAsConductorInput>;
  update?: InputMaybe<UserUpdateWithoutConversationAsConductorInput>;
  upsert?: InputMaybe<UserUpsertWithoutConversationAsConductorInput>;
};

export type UserUpdateOneRequiredWithoutConversationAsPassengerInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutConversationAsPassengerInput>;
  create?: InputMaybe<UserCreateWithoutConversationAsPassengerInput>;
  update?: InputMaybe<UserUpdateWithoutConversationAsPassengerInput>;
  upsert?: InputMaybe<UserUpsertWithoutConversationAsPassengerInput>;
};

export type UserUpdateOneRequiredWithoutMessageInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutMessageInput>;
  create?: InputMaybe<UserCreateWithoutMessageInput>;
  update?: InputMaybe<UserUpdateWithoutMessageInput>;
  upsert?: InputMaybe<UserUpsertWithoutMessageInput>;
};

export type UserUpdateOneRequiredWithoutReservationsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReservationsInput>;
  create?: InputMaybe<UserCreateWithoutReservationsInput>;
  update?: InputMaybe<UserUpdateWithoutReservationsInput>;
  upsert?: InputMaybe<UserUpsertWithoutReservationsInput>;
};

export type UserUpdateOneRequiredWithoutTravelsAsConductorInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTravelsAsConductorInput>;
  create?: InputMaybe<UserCreateWithoutTravelsAsConductorInput>;
  update?: InputMaybe<UserUpdateWithoutTravelsAsConductorInput>;
  upsert?: InputMaybe<UserUpsertWithoutTravelsAsConductorInput>;
};

export type UserUpdateWithoutConversationAsConductorInput = {
  ConversationAsPassenger?: InputMaybe<ConversationUpdateManyWithoutUserPassengerInput>;
  Message?: InputMaybe<MessageUpdateManyWithoutSenderInput>;
  Notification?: InputMaybe<NotificationUpdateManyWithoutUserRecipientInput>;
  birthDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firebaseId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hadChangePhoto?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  memberSince?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  payerPushToken?: InputMaybe<UserPushTokenUpdateManyWithoutUserInput>;
  photoURL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  profileDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reservations?: InputMaybe<ReservationUpdateManyWithoutPassengerInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  secondName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  telephone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  travelsAsConductor?: InputMaybe<TravelUpdateManyWithoutConductorUserInput>;
  userType?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
  ville?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutConversationAsPassengerInput = {
  ConversationAsConductor?: InputMaybe<ConversationUpdateManyWithoutUserConductorInput>;
  Message?: InputMaybe<MessageUpdateManyWithoutSenderInput>;
  Notification?: InputMaybe<NotificationUpdateManyWithoutUserRecipientInput>;
  birthDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firebaseId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hadChangePhoto?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  memberSince?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  payerPushToken?: InputMaybe<UserPushTokenUpdateManyWithoutUserInput>;
  photoURL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  profileDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reservations?: InputMaybe<ReservationUpdateManyWithoutPassengerInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  secondName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  telephone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  travelsAsConductor?: InputMaybe<TravelUpdateManyWithoutConductorUserInput>;
  userType?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
  ville?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutMessageInput = {
  ConversationAsConductor?: InputMaybe<ConversationUpdateManyWithoutUserConductorInput>;
  ConversationAsPassenger?: InputMaybe<ConversationUpdateManyWithoutUserPassengerInput>;
  Notification?: InputMaybe<NotificationUpdateManyWithoutUserRecipientInput>;
  birthDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firebaseId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hadChangePhoto?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  memberSince?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  payerPushToken?: InputMaybe<UserPushTokenUpdateManyWithoutUserInput>;
  photoURL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  profileDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reservations?: InputMaybe<ReservationUpdateManyWithoutPassengerInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  secondName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  telephone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  travelsAsConductor?: InputMaybe<TravelUpdateManyWithoutConductorUserInput>;
  userType?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
  ville?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutReservationsInput = {
  ConversationAsConductor?: InputMaybe<ConversationUpdateManyWithoutUserConductorInput>;
  ConversationAsPassenger?: InputMaybe<ConversationUpdateManyWithoutUserPassengerInput>;
  Message?: InputMaybe<MessageUpdateManyWithoutSenderInput>;
  Notification?: InputMaybe<NotificationUpdateManyWithoutUserRecipientInput>;
  birthDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firebaseId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hadChangePhoto?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  memberSince?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  payerPushToken?: InputMaybe<UserPushTokenUpdateManyWithoutUserInput>;
  photoURL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  profileDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  secondName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  telephone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  travelsAsConductor?: InputMaybe<TravelUpdateManyWithoutConductorUserInput>;
  userType?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
  ville?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutTravelsAsConductorInput = {
  ConversationAsConductor?: InputMaybe<ConversationUpdateManyWithoutUserConductorInput>;
  ConversationAsPassenger?: InputMaybe<ConversationUpdateManyWithoutUserPassengerInput>;
  Message?: InputMaybe<MessageUpdateManyWithoutSenderInput>;
  Notification?: InputMaybe<NotificationUpdateManyWithoutUserRecipientInput>;
  birthDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firebaseId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hadChangePhoto?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  memberSince?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  payerPushToken?: InputMaybe<UserPushTokenUpdateManyWithoutUserInput>;
  photoURL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  profileDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reservations?: InputMaybe<ReservationUpdateManyWithoutPassengerInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  secondName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  telephone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userType?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
  ville?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpsertWithoutConversationAsConductorInput = {
  create: UserCreateWithoutConversationAsConductorInput;
  update: UserUpdateWithoutConversationAsConductorInput;
};

export type UserUpsertWithoutConversationAsPassengerInput = {
  create: UserCreateWithoutConversationAsPassengerInput;
  update: UserUpdateWithoutConversationAsPassengerInput;
};

export type UserUpsertWithoutMessageInput = {
  create: UserCreateWithoutMessageInput;
  update: UserUpdateWithoutMessageInput;
};

export type UserUpsertWithoutReservationsInput = {
  create: UserCreateWithoutReservationsInput;
  update: UserUpdateWithoutReservationsInput;
};

export type UserUpsertWithoutTravelsAsConductorInput = {
  create: UserCreateWithoutTravelsAsConductorInput;
  update: UserUpdateWithoutTravelsAsConductorInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  ConversationAsConductor?: InputMaybe<ConversationListRelationFilter>;
  ConversationAsPassenger?: InputMaybe<ConversationListRelationFilter>;
  Message?: InputMaybe<MessageListRelationFilter>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  Notification?: InputMaybe<NotificationListRelationFilter>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  birthDate?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  firebaseId?: InputMaybe<StringNullableFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  hadChangePhoto?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  memberSince?: InputMaybe<DateTimeFilter>;
  payerPushToken?: InputMaybe<UserPushTokenListRelationFilter>;
  photoURL?: InputMaybe<StringNullableFilter>;
  profileDescription?: InputMaybe<StringNullableFilter>;
  reservations?: InputMaybe<ReservationListRelationFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  secondName?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumUserStatusFilter>;
  telephone?: InputMaybe<StringNullableFilter>;
  travelsAsConductor?: InputMaybe<TravelListRelationFilter>;
  userType?: InputMaybe<EnumUserTypeFilter>;
  ville?: InputMaybe<StringNullableFilter>;
};

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  firebaseId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};

export type VerifyNumberCodeResult = VerifyNumberCodeResultError | VerifyNumberCodeResultSuccess;

export type VerifyNumberCodeResultError = {
  __typename?: 'VerifyNumberCodeResultError';
  code: VerifyNumberCodeResultErrorDto;
};

export enum VerifyNumberCodeResultErrorDto {
  InvalidCode = 'INVALID_CODE',
  OtherError = 'OTHER_ERROR'
}

export type VerifyNumberCodeResultSuccess = {
  __typename?: 'VerifyNumberCodeResultSuccess';
  state: Scalars['String'];
};

export type VerifyNumberResult = VerifyNumberResultError | VerifyNumberResultSuccess;

export type VerifyNumberResultError = {
  __typename?: 'VerifyNumberResultError';
  code: VerifyNumberResultErrorDto;
};

export enum VerifyNumberResultErrorDto {
  InvalidNumber = 'INVALID_NUMBER',
  NumberUsed = 'NUMBER_USED',
  OtherError = 'OTHER_ERROR'
}

export type VerifyNumberResultSuccess = {
  __typename?: 'VerifyNumberResultSuccess';
  state: Scalars['String'];
};

export type VersionApp = {
  __typename?: 'VersionApp';
  WhitelistsQueries?: Maybe<WhitelistsQueries>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  type: AppType;
  version: Scalars['String'];
};

export type VersionAppCreateInput = {
  WhitelistsQueries?: InputMaybe<WhitelistsQueriesCreateNestedOneWithoutVersionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  type: AppType;
  version: Scalars['String'];
};

export type VersionAppCreateNestedOneWithoutWhitelistsQueriesInput = {
  connect?: InputMaybe<VersionAppWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VersionAppCreateOrConnectWithoutWhitelistsQueriesInput>;
  create?: InputMaybe<VersionAppCreateWithoutWhitelistsQueriesInput>;
};

export type VersionAppCreateOrConnectWithoutWhitelistsQueriesInput = {
  create: VersionAppCreateWithoutWhitelistsQueriesInput;
  where: VersionAppWhereUniqueInput;
};

export type VersionAppCreateWithoutWhitelistsQueriesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  type: AppType;
  version: Scalars['String'];
};

export type VersionAppOrderByWithRelationInput = {
  WhitelistsQueries?: InputMaybe<WhitelistsQueriesOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  version?: InputMaybe<SortOrder>;
};

export type VersionAppRelationFilter = {
  is?: InputMaybe<VersionAppWhereInput>;
  isNot?: InputMaybe<VersionAppWhereInput>;
};

export enum VersionAppScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Type = 'type',
  Version = 'version'
}

export type VersionAppUpdateInput = {
  WhitelistsQueries?: InputMaybe<WhitelistsQueriesUpdateOneWithoutVersionInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumAppTypeFieldUpdateOperationsInput>;
  version?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type VersionAppUpdateOneRequiredWithoutWhitelistsQueriesInput = {
  connect?: InputMaybe<VersionAppWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VersionAppCreateOrConnectWithoutWhitelistsQueriesInput>;
  create?: InputMaybe<VersionAppCreateWithoutWhitelistsQueriesInput>;
  update?: InputMaybe<VersionAppUpdateWithoutWhitelistsQueriesInput>;
  upsert?: InputMaybe<VersionAppUpsertWithoutWhitelistsQueriesInput>;
};

export type VersionAppUpdateWithoutWhitelistsQueriesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumAppTypeFieldUpdateOperationsInput>;
  version?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type VersionAppUpsertWithoutWhitelistsQueriesInput = {
  create: VersionAppCreateWithoutWhitelistsQueriesInput;
  update: VersionAppUpdateWithoutWhitelistsQueriesInput;
};

export type VersionAppWhereInput = {
  AND?: InputMaybe<Array<VersionAppWhereInput>>;
  NOT?: InputMaybe<Array<VersionAppWhereInput>>;
  OR?: InputMaybe<Array<VersionAppWhereInput>>;
  WhitelistsQueries?: InputMaybe<WhitelistsQueriesRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumAppTypeFilter>;
  version?: InputMaybe<StringFilter>;
};

export type VersionAppWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  version_type?: InputMaybe<VersionAppVersionTypeCompoundUniqueInput>;
};

export type WhitelistsQueries = {
  __typename?: 'WhitelistsQueries';
  content: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  version: VersionApp;
  versionAppId: Scalars['String'];
};

export type WhitelistsQueriesCreateInput = {
  content: Scalars['JSON'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  version: VersionAppCreateNestedOneWithoutWhitelistsQueriesInput;
};

export type WhitelistsQueriesCreateNestedOneWithoutVersionInput = {
  connect?: InputMaybe<WhitelistsQueriesWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WhitelistsQueriesCreateOrConnectWithoutVersionInput>;
  create?: InputMaybe<WhitelistsQueriesCreateWithoutVersionInput>;
};

export type WhitelistsQueriesCreateOrConnectWithoutVersionInput = {
  create: WhitelistsQueriesCreateWithoutVersionInput;
  where: WhitelistsQueriesWhereUniqueInput;
};

export type WhitelistsQueriesCreateWithoutVersionInput = {
  content: Scalars['JSON'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
};

export type WhitelistsQueriesOrderByWithRelationInput = {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  version?: InputMaybe<VersionAppOrderByWithRelationInput>;
  versionAppId?: InputMaybe<SortOrder>;
};

export type WhitelistsQueriesRelationFilter = {
  is?: InputMaybe<WhitelistsQueriesWhereInput>;
  isNot?: InputMaybe<WhitelistsQueriesWhereInput>;
};

export enum WhitelistsQueriesScalarFieldEnum {
  Content = 'content',
  CreatedAt = 'createdAt',
  Id = 'id',
  VersionAppId = 'versionAppId'
}

export type WhitelistsQueriesUpdateInput = {
  content?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  version?: InputMaybe<VersionAppUpdateOneRequiredWithoutWhitelistsQueriesInput>;
};

export type WhitelistsQueriesUpdateOneWithoutVersionInput = {
  connect?: InputMaybe<WhitelistsQueriesWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WhitelistsQueriesCreateOrConnectWithoutVersionInput>;
  create?: InputMaybe<WhitelistsQueriesCreateWithoutVersionInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<WhitelistsQueriesUpdateWithoutVersionInput>;
  upsert?: InputMaybe<WhitelistsQueriesUpsertWithoutVersionInput>;
};

export type WhitelistsQueriesUpdateWithoutVersionInput = {
  content?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type WhitelistsQueriesUpsertWithoutVersionInput = {
  create: WhitelistsQueriesCreateWithoutVersionInput;
  update: WhitelistsQueriesUpdateWithoutVersionInput;
};

export type WhitelistsQueriesWhereInput = {
  AND?: InputMaybe<Array<WhitelistsQueriesWhereInput>>;
  NOT?: InputMaybe<Array<WhitelistsQueriesWhereInput>>;
  OR?: InputMaybe<Array<WhitelistsQueriesWhereInput>>;
  content?: InputMaybe<JsonFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  version?: InputMaybe<VersionAppRelationFilter>;
  versionAppId?: InputMaybe<StringFilter>;
};

export type WhitelistsQueriesWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  versionAppId?: InputMaybe<Scalars['String']>;
};

export type VersionAppVersionTypeCompoundUniqueInput = {
  type: AppType;
  version: Scalars['String'];
};

export type GetLatestAppFaqContentQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLatestAppFaqContentQuery = { __typename?: 'Query', getLatestAppFaqContent: { __typename?: 'AppFaqContent', id: string, createdAt: any, content: { __typename?: 'AppFaqContentContentDto', title?: string | null, categories: Array<{ __typename?: 'AppFaqContentCategoryDto', title: string, description?: string | null, posts: Array<{ __typename?: 'AppFaqContentPostDto', title: string, subtitle?: string | null, content: string }> }> } } };

export type GetLatestAppBasicContentQueryVariables = Exact<{
  type: AppContentType;
}>;


export type GetLatestAppBasicContentQuery = { __typename?: 'Query', getLatestAppBasicContent: { __typename?: 'AppBasicContent', type: AppContentType, content: { __typename?: 'AppBasicContentHtmlDto', html: string, title?: string | null } } };

export type UpdateAppFaqContentMutationVariables = Exact<{
  categories: Array<AppFaqContentCategoryInput> | AppFaqContentCategoryInput;
  title?: InputMaybe<Scalars['String']>;
}>;


export type UpdateAppFaqContentMutation = { __typename?: 'Mutation', updateAppFaqContent: { __typename?: 'AppFaqContent', content: { __typename?: 'AppFaqContentContentDto', categories: Array<{ __typename?: 'AppFaqContentCategoryDto', posts: Array<{ __typename?: 'AppFaqContentPostDto', title: string, content: string }> }> } } };

export type UpdateAppBasicContentMutationVariables = Exact<{
  html: Scalars['String'];
  type: AppContentType;
}>;


export type UpdateAppBasicContentMutation = { __typename?: 'Mutation', updateAppBasicContent: { __typename?: 'AppBasicContent', type: AppContentType, content: { __typename?: 'AppBasicContentHtmlDto', html: string, title?: string | null } } };

export type GetVersionsQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<VersionAppOrderByWithRelationInput> | VersionAppOrderByWithRelationInput>;
}>;


export type GetVersionsQuery = { __typename?: 'Query', versionApps: Array<{ __typename?: 'VersionApp', id: string, type: AppType, version: string, WhitelistsQueries?: { __typename?: 'WhitelistsQueries', id: string } | null }> };

export type DeleteVersionAppMutationVariables = Exact<{
  where: VersionAppWhereUniqueInput;
}>;


export type DeleteVersionAppMutation = { __typename?: 'Mutation', deleteVersionApp?: { __typename?: 'VersionApp', id: string } | null };

export type UpdateVersionsMutationVariables = Exact<{
  data: VersionAppUpdateInput;
  where: VersionAppWhereUniqueInput;
}>;


export type UpdateVersionsMutation = { __typename?: 'Mutation', updateVersionApp?: { __typename?: 'VersionApp', id: string } | null };

export type CreateVersionAppMutationVariables = Exact<{
  data: VersionAppCreateInput;
}>;


export type CreateVersionAppMutation = { __typename?: 'Mutation', createVersionApp: { __typename?: 'VersionApp', id: string, createdAt: any } };

export type GetWhitelistsQueriesQueryVariables = Exact<{
  where?: InputMaybe<WhitelistsQueriesWhereInput>;
  orderBy?: InputMaybe<Array<WhitelistsQueriesOrderByWithRelationInput> | WhitelistsQueriesOrderByWithRelationInput>;
}>;


export type GetWhitelistsQueriesQuery = { __typename?: 'Query', findManyWhitelistsQueries: Array<{ __typename?: 'WhitelistsQueries', content: any, version: { __typename?: 'VersionApp', id: string, type: AppType, version: string } }> };

export type UpdateWhitelistsQueriesMutationVariables = Exact<{
  data: WhitelistsQueriesUpdateInput;
  where: WhitelistsQueriesWhereUniqueInput;
}>;


export type UpdateWhitelistsQueriesMutation = { __typename?: 'Mutation', updateWhitelistsQueries?: { __typename?: 'WhitelistsQueries', content: any, version: { __typename?: 'VersionApp', type: AppType, version: string } } | null };

export type CreateWhitelistMutationVariables = Exact<{
  data: WhitelistsQueriesCreateInput;
}>;


export type CreateWhitelistMutation = { __typename?: 'Mutation', createWhitelistsQueries: { __typename?: 'WhitelistsQueries', content: any, version: { __typename?: 'VersionApp', type: AppType, version: string } } };

export type TravelsAdminQueryVariables = Exact<{
  cursor?: InputMaybe<TravelWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TravelOrderByWithRelationInput> | TravelOrderByWithRelationInput>;
}>;


export type TravelsAdminQuery = { __typename?: 'Query', travels: Array<{ __typename?: 'Travel', id: string, conductorUserId: string, totalPrice: number, places: number, Status: TravelStatus, startHour: any, createdAt: any, traject: Array<{ __typename?: 'Traject', steps: Array<{ __typename?: 'Step', order: number, city: string }> }> }> };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', SearchPrivateUser: { __typename?: 'User', firebaseId?: string | null, email: string, id: string, photoURL?: string | null, firstName?: string | null, secondName?: string | null, birthDate?: any | null, telephone?: string | null, ville?: string | null, isActive: boolean, role: Role } };

export type PCreateUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
}>;


export type PCreateUserMutation = { __typename?: 'Mutation', PCreateUserResolver: { __typename?: 'CreateUserResultError', code: CreateUserResultErrorDto } | { __typename?: 'CreateUserResultSuccess', user: { __typename?: 'User', email: string, id: string, firstName?: string | null, secondName?: string | null, telephone?: string | null, role: Role } } };

export type GetFaqQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFaqQuery = { __typename?: 'Query', getLatestAppFaqContent: { __typename?: 'AppFaqContent', createdAt: any, content: { __typename?: 'AppFaqContentContentDto', title?: string | null, categories: Array<{ __typename?: 'AppFaqContentCategoryDto', title: string, posts: Array<{ __typename?: 'AppFaqContentPostDto', title: string, subtitle?: string | null, content: string }>, subcategories?: Array<{ __typename?: 'AppFaqContentCategoryDto', title: string }> | null }> } } };

export type PSendCodeMutationVariables = Exact<{
  phoneNumber: Scalars['String'];
}>;


export type PSendCodeMutation = { __typename?: 'Mutation', PVerifyPhoneNumber: { __typename?: 'VerifyNumberResultError', code: VerifyNumberResultErrorDto } | { __typename?: 'VerifyNumberResultSuccess', state: string } };

export type PVerifyCodeMutationVariables = Exact<{
  phoneNumber: Scalars['String'];
  code: Scalars['String'];
}>;


export type PVerifyCodeMutation = { __typename?: 'Mutation', PVerifyCodePhoneNumber: { __typename?: 'VerifyNumberCodeResultError', code: VerifyNumberCodeResultErrorDto } | { __typename?: 'VerifyNumberCodeResultSuccess', state: string } };


export const GetLatestAppFaqContentDocument = gql`
    query GetLatestAppFaqContent {
  getLatestAppFaqContent {
    id
    createdAt
    content {
      title
      categories {
        title
        description
        posts {
          title
          subtitle
          content
        }
      }
    }
  }
}
    `;

/**
 * __useGetLatestAppFaqContentQuery__
 *
 * To run a query within a React component, call `useGetLatestAppFaqContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestAppFaqContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestAppFaqContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestAppFaqContentQuery(baseOptions?: Apollo.QueryHookOptions<GetLatestAppFaqContentQuery, GetLatestAppFaqContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestAppFaqContentQuery, GetLatestAppFaqContentQueryVariables>(GetLatestAppFaqContentDocument, options);
      }
export function useGetLatestAppFaqContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestAppFaqContentQuery, GetLatestAppFaqContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestAppFaqContentQuery, GetLatestAppFaqContentQueryVariables>(GetLatestAppFaqContentDocument, options);
        }
export type GetLatestAppFaqContentQueryHookResult = ReturnType<typeof useGetLatestAppFaqContentQuery>;
export type GetLatestAppFaqContentLazyQueryHookResult = ReturnType<typeof useGetLatestAppFaqContentLazyQuery>;
export type GetLatestAppFaqContentQueryResult = Apollo.QueryResult<GetLatestAppFaqContentQuery, GetLatestAppFaqContentQueryVariables>;
export const GetLatestAppBasicContentDocument = gql`
    query getLatestAppBasicContent($type: AppContentType!) {
  getLatestAppBasicContent(type: $type) {
    content {
      html
      title
    }
    type
  }
}
    `;

/**
 * __useGetLatestAppBasicContentQuery__
 *
 * To run a query within a React component, call `useGetLatestAppBasicContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestAppBasicContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestAppBasicContentQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetLatestAppBasicContentQuery(baseOptions: Apollo.QueryHookOptions<GetLatestAppBasicContentQuery, GetLatestAppBasicContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestAppBasicContentQuery, GetLatestAppBasicContentQueryVariables>(GetLatestAppBasicContentDocument, options);
      }
export function useGetLatestAppBasicContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestAppBasicContentQuery, GetLatestAppBasicContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestAppBasicContentQuery, GetLatestAppBasicContentQueryVariables>(GetLatestAppBasicContentDocument, options);
        }
export type GetLatestAppBasicContentQueryHookResult = ReturnType<typeof useGetLatestAppBasicContentQuery>;
export type GetLatestAppBasicContentLazyQueryHookResult = ReturnType<typeof useGetLatestAppBasicContentLazyQuery>;
export type GetLatestAppBasicContentQueryResult = Apollo.QueryResult<GetLatestAppBasicContentQuery, GetLatestAppBasicContentQueryVariables>;
export const UpdateAppFaqContentDocument = gql`
    mutation UpdateAppFaqContent($categories: [AppFaqContentCategoryInput!]!, $title: String) {
  updateAppFaqContent(categories: $categories, title: $title) {
    content {
      categories {
        posts {
          title
          content
        }
      }
    }
  }
}
    `;
export type UpdateAppFaqContentMutationFn = Apollo.MutationFunction<UpdateAppFaqContentMutation, UpdateAppFaqContentMutationVariables>;

/**
 * __useUpdateAppFaqContentMutation__
 *
 * To run a mutation, you first call `useUpdateAppFaqContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppFaqContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppFaqContentMutation, { data, loading, error }] = useUpdateAppFaqContentMutation({
 *   variables: {
 *      categories: // value for 'categories'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateAppFaqContentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppFaqContentMutation, UpdateAppFaqContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppFaqContentMutation, UpdateAppFaqContentMutationVariables>(UpdateAppFaqContentDocument, options);
      }
export type UpdateAppFaqContentMutationHookResult = ReturnType<typeof useUpdateAppFaqContentMutation>;
export type UpdateAppFaqContentMutationResult = Apollo.MutationResult<UpdateAppFaqContentMutation>;
export type UpdateAppFaqContentMutationOptions = Apollo.BaseMutationOptions<UpdateAppFaqContentMutation, UpdateAppFaqContentMutationVariables>;
export const UpdateAppBasicContentDocument = gql`
    mutation UpdateAppBasicContent($html: String!, $type: AppContentType!) {
  updateAppBasicContent(html: $html, type: $type) {
    content {
      html
      title
    }
    type
  }
}
    `;
export type UpdateAppBasicContentMutationFn = Apollo.MutationFunction<UpdateAppBasicContentMutation, UpdateAppBasicContentMutationVariables>;

/**
 * __useUpdateAppBasicContentMutation__
 *
 * To run a mutation, you first call `useUpdateAppBasicContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppBasicContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppBasicContentMutation, { data, loading, error }] = useUpdateAppBasicContentMutation({
 *   variables: {
 *      html: // value for 'html'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateAppBasicContentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppBasicContentMutation, UpdateAppBasicContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppBasicContentMutation, UpdateAppBasicContentMutationVariables>(UpdateAppBasicContentDocument, options);
      }
export type UpdateAppBasicContentMutationHookResult = ReturnType<typeof useUpdateAppBasicContentMutation>;
export type UpdateAppBasicContentMutationResult = Apollo.MutationResult<UpdateAppBasicContentMutation>;
export type UpdateAppBasicContentMutationOptions = Apollo.BaseMutationOptions<UpdateAppBasicContentMutation, UpdateAppBasicContentMutationVariables>;
export const GetVersionsDocument = gql`
    query getVersions($orderBy: [VersionAppOrderByWithRelationInput!]) {
  versionApps(orderBy: $orderBy) {
    id
    type
    version
    WhitelistsQueries {
      id
    }
  }
}
    `;

/**
 * __useGetVersionsQuery__
 *
 * To run a query within a React component, call `useGetVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVersionsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetVersionsQuery(baseOptions?: Apollo.QueryHookOptions<GetVersionsQuery, GetVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVersionsQuery, GetVersionsQueryVariables>(GetVersionsDocument, options);
      }
export function useGetVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVersionsQuery, GetVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVersionsQuery, GetVersionsQueryVariables>(GetVersionsDocument, options);
        }
export type GetVersionsQueryHookResult = ReturnType<typeof useGetVersionsQuery>;
export type GetVersionsLazyQueryHookResult = ReturnType<typeof useGetVersionsLazyQuery>;
export type GetVersionsQueryResult = Apollo.QueryResult<GetVersionsQuery, GetVersionsQueryVariables>;
export const DeleteVersionAppDocument = gql`
    mutation deleteVersionApp($where: VersionAppWhereUniqueInput!) {
  deleteVersionApp(where: $where) {
    id
  }
}
    `;
export type DeleteVersionAppMutationFn = Apollo.MutationFunction<DeleteVersionAppMutation, DeleteVersionAppMutationVariables>;

/**
 * __useDeleteVersionAppMutation__
 *
 * To run a mutation, you first call `useDeleteVersionAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVersionAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVersionAppMutation, { data, loading, error }] = useDeleteVersionAppMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteVersionAppMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVersionAppMutation, DeleteVersionAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVersionAppMutation, DeleteVersionAppMutationVariables>(DeleteVersionAppDocument, options);
      }
export type DeleteVersionAppMutationHookResult = ReturnType<typeof useDeleteVersionAppMutation>;
export type DeleteVersionAppMutationResult = Apollo.MutationResult<DeleteVersionAppMutation>;
export type DeleteVersionAppMutationOptions = Apollo.BaseMutationOptions<DeleteVersionAppMutation, DeleteVersionAppMutationVariables>;
export const UpdateVersionsDocument = gql`
    mutation UpdateVersions($data: VersionAppUpdateInput!, $where: VersionAppWhereUniqueInput!) {
  updateVersionApp(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateVersionsMutationFn = Apollo.MutationFunction<UpdateVersionsMutation, UpdateVersionsMutationVariables>;

/**
 * __useUpdateVersionsMutation__
 *
 * To run a mutation, you first call `useUpdateVersionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVersionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVersionsMutation, { data, loading, error }] = useUpdateVersionsMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateVersionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVersionsMutation, UpdateVersionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVersionsMutation, UpdateVersionsMutationVariables>(UpdateVersionsDocument, options);
      }
export type UpdateVersionsMutationHookResult = ReturnType<typeof useUpdateVersionsMutation>;
export type UpdateVersionsMutationResult = Apollo.MutationResult<UpdateVersionsMutation>;
export type UpdateVersionsMutationOptions = Apollo.BaseMutationOptions<UpdateVersionsMutation, UpdateVersionsMutationVariables>;
export const CreateVersionAppDocument = gql`
    mutation createVersionApp($data: VersionAppCreateInput!) {
  createVersionApp(data: $data) {
    id
    createdAt
  }
}
    `;
export type CreateVersionAppMutationFn = Apollo.MutationFunction<CreateVersionAppMutation, CreateVersionAppMutationVariables>;

/**
 * __useCreateVersionAppMutation__
 *
 * To run a mutation, you first call `useCreateVersionAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVersionAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVersionAppMutation, { data, loading, error }] = useCreateVersionAppMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateVersionAppMutation(baseOptions?: Apollo.MutationHookOptions<CreateVersionAppMutation, CreateVersionAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVersionAppMutation, CreateVersionAppMutationVariables>(CreateVersionAppDocument, options);
      }
export type CreateVersionAppMutationHookResult = ReturnType<typeof useCreateVersionAppMutation>;
export type CreateVersionAppMutationResult = Apollo.MutationResult<CreateVersionAppMutation>;
export type CreateVersionAppMutationOptions = Apollo.BaseMutationOptions<CreateVersionAppMutation, CreateVersionAppMutationVariables>;
export const GetWhitelistsQueriesDocument = gql`
    query getWhitelistsQueries($where: WhitelistsQueriesWhereInput, $orderBy: [WhitelistsQueriesOrderByWithRelationInput!]) {
  findManyWhitelistsQueries(where: $where, orderBy: $orderBy) {
    content
    version {
      id
      type
      version
    }
  }
}
    `;

/**
 * __useGetWhitelistsQueriesQuery__
 *
 * To run a query within a React component, call `useGetWhitelistsQueriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWhitelistsQueriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWhitelistsQueriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetWhitelistsQueriesQuery(baseOptions?: Apollo.QueryHookOptions<GetWhitelistsQueriesQuery, GetWhitelistsQueriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWhitelistsQueriesQuery, GetWhitelistsQueriesQueryVariables>(GetWhitelistsQueriesDocument, options);
      }
export function useGetWhitelistsQueriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWhitelistsQueriesQuery, GetWhitelistsQueriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWhitelistsQueriesQuery, GetWhitelistsQueriesQueryVariables>(GetWhitelistsQueriesDocument, options);
        }
export type GetWhitelistsQueriesQueryHookResult = ReturnType<typeof useGetWhitelistsQueriesQuery>;
export type GetWhitelistsQueriesLazyQueryHookResult = ReturnType<typeof useGetWhitelistsQueriesLazyQuery>;
export type GetWhitelistsQueriesQueryResult = Apollo.QueryResult<GetWhitelistsQueriesQuery, GetWhitelistsQueriesQueryVariables>;
export const UpdateWhitelistsQueriesDocument = gql`
    mutation UpdateWhitelistsQueries($data: WhitelistsQueriesUpdateInput!, $where: WhitelistsQueriesWhereUniqueInput!) {
  updateWhitelistsQueries(data: $data, where: $where) {
    content
    version {
      type
      version
    }
  }
}
    `;
export type UpdateWhitelistsQueriesMutationFn = Apollo.MutationFunction<UpdateWhitelistsQueriesMutation, UpdateWhitelistsQueriesMutationVariables>;

/**
 * __useUpdateWhitelistsQueriesMutation__
 *
 * To run a mutation, you first call `useUpdateWhitelistsQueriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWhitelistsQueriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWhitelistsQueriesMutation, { data, loading, error }] = useUpdateWhitelistsQueriesMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateWhitelistsQueriesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWhitelistsQueriesMutation, UpdateWhitelistsQueriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWhitelistsQueriesMutation, UpdateWhitelistsQueriesMutationVariables>(UpdateWhitelistsQueriesDocument, options);
      }
export type UpdateWhitelistsQueriesMutationHookResult = ReturnType<typeof useUpdateWhitelistsQueriesMutation>;
export type UpdateWhitelistsQueriesMutationResult = Apollo.MutationResult<UpdateWhitelistsQueriesMutation>;
export type UpdateWhitelistsQueriesMutationOptions = Apollo.BaseMutationOptions<UpdateWhitelistsQueriesMutation, UpdateWhitelistsQueriesMutationVariables>;
export const CreateWhitelistDocument = gql`
    mutation createWhitelist($data: WhitelistsQueriesCreateInput!) {
  createWhitelistsQueries(data: $data) {
    content
    version {
      type
      version
    }
  }
}
    `;
export type CreateWhitelistMutationFn = Apollo.MutationFunction<CreateWhitelistMutation, CreateWhitelistMutationVariables>;

/**
 * __useCreateWhitelistMutation__
 *
 * To run a mutation, you first call `useCreateWhitelistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWhitelistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWhitelistMutation, { data, loading, error }] = useCreateWhitelistMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWhitelistMutation(baseOptions?: Apollo.MutationHookOptions<CreateWhitelistMutation, CreateWhitelistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWhitelistMutation, CreateWhitelistMutationVariables>(CreateWhitelistDocument, options);
      }
export type CreateWhitelistMutationHookResult = ReturnType<typeof useCreateWhitelistMutation>;
export type CreateWhitelistMutationResult = Apollo.MutationResult<CreateWhitelistMutation>;
export type CreateWhitelistMutationOptions = Apollo.BaseMutationOptions<CreateWhitelistMutation, CreateWhitelistMutationVariables>;
export const TravelsAdminDocument = gql`
    query TravelsAdmin($cursor: TravelWhereUniqueInput, $take: Int, $skip: Int, $orderBy: [TravelOrderByWithRelationInput!]) {
  travels(cursor: $cursor, take: $take, skip: $skip, orderBy: $orderBy) {
    id
    conductorUserId
    traject {
      steps {
        order
        city
      }
    }
    totalPrice
    places
    Status
    startHour
    createdAt
  }
}
    `;

/**
 * __useTravelsAdminQuery__
 *
 * To run a query within a React component, call `useTravelsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelsAdminQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useTravelsAdminQuery(baseOptions?: Apollo.QueryHookOptions<TravelsAdminQuery, TravelsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TravelsAdminQuery, TravelsAdminQueryVariables>(TravelsAdminDocument, options);
      }
export function useTravelsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TravelsAdminQuery, TravelsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TravelsAdminQuery, TravelsAdminQueryVariables>(TravelsAdminDocument, options);
        }
export type TravelsAdminQueryHookResult = ReturnType<typeof useTravelsAdminQuery>;
export type TravelsAdminLazyQueryHookResult = ReturnType<typeof useTravelsAdminLazyQuery>;
export type TravelsAdminQueryResult = Apollo.QueryResult<TravelsAdminQuery, TravelsAdminQueryVariables>;
export const GetUserDocument = gql`
    query GetUser {
  SearchPrivateUser {
    firebaseId
    email
    id
    photoURL
    firstName
    secondName
    birthDate
    telephone
    ville
    isActive
    role
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const PCreateUserDocument = gql`
    mutation PCreateUser($email: String!, $password: String!, $phoneNumber: String, $firstName: String, $lastName: String) {
  PCreateUserResolver(
    email: $email
    password: $password
    phoneNumber: $phoneNumber
    firstName: $firstName
    lastName: $lastName
  ) {
    ... on CreateUserResultSuccess {
      user {
        email
        id
        firstName
        secondName
        telephone
        role
      }
    }
    ... on CreateUserResultError {
      code
    }
  }
}
    `;
export type PCreateUserMutationFn = Apollo.MutationFunction<PCreateUserMutation, PCreateUserMutationVariables>;

/**
 * __usePCreateUserMutation__
 *
 * To run a mutation, you first call `usePCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pCreateUserMutation, { data, loading, error }] = usePCreateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      phoneNumber: // value for 'phoneNumber'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function usePCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<PCreateUserMutation, PCreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PCreateUserMutation, PCreateUserMutationVariables>(PCreateUserDocument, options);
      }
export type PCreateUserMutationHookResult = ReturnType<typeof usePCreateUserMutation>;
export type PCreateUserMutationResult = Apollo.MutationResult<PCreateUserMutation>;
export type PCreateUserMutationOptions = Apollo.BaseMutationOptions<PCreateUserMutation, PCreateUserMutationVariables>;
export const GetFaqDocument = gql`
    query getFaq {
  getLatestAppFaqContent {
    content {
      title
      categories {
        title
        posts {
          title
          subtitle
          content
        }
        subcategories {
          title
        }
      }
    }
    createdAt
  }
}
    `;

/**
 * __useGetFaqQuery__
 *
 * To run a query within a React component, call `useGetFaqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFaqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFaqQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFaqQuery(baseOptions?: Apollo.QueryHookOptions<GetFaqQuery, GetFaqQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFaqQuery, GetFaqQueryVariables>(GetFaqDocument, options);
      }
export function useGetFaqLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFaqQuery, GetFaqQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFaqQuery, GetFaqQueryVariables>(GetFaqDocument, options);
        }
export type GetFaqQueryHookResult = ReturnType<typeof useGetFaqQuery>;
export type GetFaqLazyQueryHookResult = ReturnType<typeof useGetFaqLazyQuery>;
export type GetFaqQueryResult = Apollo.QueryResult<GetFaqQuery, GetFaqQueryVariables>;
export const PSendCodeDocument = gql`
    mutation PSendCode($phoneNumber: String!) {
  PVerifyPhoneNumber(phoneNumber: $phoneNumber) {
    ... on VerifyNumberResultSuccess {
      state
    }
    ... on VerifyNumberResultError {
      code
    }
  }
}
    `;
export type PSendCodeMutationFn = Apollo.MutationFunction<PSendCodeMutation, PSendCodeMutationVariables>;

/**
 * __usePSendCodeMutation__
 *
 * To run a mutation, you first call `usePSendCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePSendCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pSendCodeMutation, { data, loading, error }] = usePSendCodeMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function usePSendCodeMutation(baseOptions?: Apollo.MutationHookOptions<PSendCodeMutation, PSendCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PSendCodeMutation, PSendCodeMutationVariables>(PSendCodeDocument, options);
      }
export type PSendCodeMutationHookResult = ReturnType<typeof usePSendCodeMutation>;
export type PSendCodeMutationResult = Apollo.MutationResult<PSendCodeMutation>;
export type PSendCodeMutationOptions = Apollo.BaseMutationOptions<PSendCodeMutation, PSendCodeMutationVariables>;
export const PVerifyCodeDocument = gql`
    mutation PVerifyCode($phoneNumber: String!, $code: String!) {
  PVerifyCodePhoneNumber(phoneNumber: $phoneNumber, code: $code) {
    ... on VerifyNumberCodeResultSuccess {
      state
    }
    ... on VerifyNumberCodeResultError {
      code
    }
  }
}
    `;
export type PVerifyCodeMutationFn = Apollo.MutationFunction<PVerifyCodeMutation, PVerifyCodeMutationVariables>;

/**
 * __usePVerifyCodeMutation__
 *
 * To run a mutation, you first call `usePVerifyCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePVerifyCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pVerifyCodeMutation, { data, loading, error }] = usePVerifyCodeMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      code: // value for 'code'
 *   },
 * });
 */
export function usePVerifyCodeMutation(baseOptions?: Apollo.MutationHookOptions<PVerifyCodeMutation, PVerifyCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PVerifyCodeMutation, PVerifyCodeMutationVariables>(PVerifyCodeDocument, options);
      }
export type PVerifyCodeMutationHookResult = ReturnType<typeof usePVerifyCodeMutation>;
export type PVerifyCodeMutationResult = Apollo.MutationResult<PVerifyCodeMutation>;
export type PVerifyCodeMutationOptions = Apollo.BaseMutationOptions<PVerifyCodeMutation, PVerifyCodeMutationVariables>;