import { useLoadScript } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ArgCoord } from "../../settings/searchSettings";
import { AppMap } from "../Map";

export const MapScreen = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries: ["places"],
  });

  const [center, setCenter] = useState(ArgCoord);

  const { placeId } = useParams();

  useEffect(() => {
    if (isLoaded) {
      const geocoder = new google.maps.Geocoder();

      geocoder
        .geocode({
          placeId: placeId,
        })
        .then((place) => {
          console.log(place);
          setCenter({
            lat: place.results[0].geometry.location.lat(),
            lng: place.results[0].geometry.location.lng(),
          });
        });
    }
  }, [isLoaded]);
  console.log(placeId);

  return (
    <>
      {isLoaded && (
        <div className="map-screen-container">
          <AppMap center={center} zoom={17} />
        </div>
      )}
    </>
  );
};
