import React, { FC } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { travelAction } from "../reducers/travelReducer";

interface PlacesStepProps {
  valuesForm: any;
  setValuesForm: ({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) => void;
  setDirectReservation: (arg0: boolean) => void;
  directReservation: boolean;
  actionOnChangePlaces: (places: number) => travelAction;
}

export const PlacesStepForm: FC<PlacesStepProps> = ({
  valuesForm,
  setValuesForm,
  setDirectReservation,
  actionOnChangePlaces,
  directReservation,
}) => {
  const dispatch = useDispatch();
  console.log(directReservation);

  return (
    <div>
      <h5>Lugares disponibles :</h5>
      <input
        type="number"
        placeholder="Lugares"
        name="places"
        value={valuesForm.places}
        onChange={(e) => {
          setValuesForm(e);
          dispatch(actionOnChangePlaces(Number(e.target.value)));
        }}
        className="home-input"
      />
      <h5>Aceptar las reservaciones directamente?</h5>
      <select
        name="directReservation"
        className="home-input"
        value={directReservation ? "1" : "0"}
        onChange={(v) => {
          setDirectReservation(v.target.value === "1" ? true : false);
        }}
      >
        <option value={1}>Si</option>
        <option value={0}>No</option>
      </select>
    </div>
  );
};
