import React from "react";
import { ApolloProvider } from "@apollo/client";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { client } from "./api/apollo/apolloConfig";
import { AuthenticationContextProvider } from "./contexts/AuthenticationContext";
import { TravelContextProvider } from "./contexts/TravelContext";
import { MelApp } from "./MelApp";
import { AppRouter } from "./routers/AppRouter";
import { store } from "./store/store";
import "./styles/styles.scss";
import { AppContextProvider } from "./contexts/AppContext";
import { render } from "react-dom";
import { ModalContextProvider } from "./contexts/ModalContext";
// require("dotenv").config();
// const NodePolyfillPlugin = require("node-polyfill-webpack-plugin")

// console.log(new NodePolyfillPlugin())

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter>
          <AppContextProvider>
            <ModalContextProvider>
              <AuthenticationContextProvider>
                <TravelContextProvider>
                  <AppRouter />
                </TravelContextProvider>
              </AuthenticationContextProvider>
            </ModalContextProvider>
          </AppContextProvider>
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
