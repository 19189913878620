export const types = {
  login: "[Auth] Login",
  logout: "[Auth] Logout",

  addStep: "[Travel] Add step",
  updatePlaces: "[Travel] Update places",
  updateDate: "[Travel] Update date",
  cleanTravel: "[Travel) Clean travel",
};

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;
