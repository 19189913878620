import { Route, Routes, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import {
  useGetVersionsQuery,
  GetVersionsQuery,
  VersionApp,
  AppType,
  useCreateVersionAppMutation,
  useDeleteVersionAppMutation,
  useGetWhitelistsQueriesQuery,
  WhitelistsQueries,
  useCreateWhitelistMutation,
  GetWhitelistsQueriesQuery,
  useUpdateWhitelistsQueriesMutation,
} from "../../../graphql/generated";
import { useState } from "react";
import { TextInput } from "../../commons/TextInput";
import Select from "react-select";
import Swal from "sweetalert2";
import { ArrayElement } from "../../../types/types";

export const QueriesWLScreen = () => {
  return (
    <div className="faq-container">
      <Routes>
        <Route path="/" element={<QueriesWLMain />} />
        <Route path="edit-versions" element={<EditVersions />} />
        <Route path="edit-queries" element={<EditWLs />} />
      </Routes>
    </div>
  );
};

const LINKS = [
  {
    path: "edit-queries",
    label: "Editar las whitelists",
  },
  {
    path: "edit-versions",
    label: "Modificar las versiones",
  },
];

export const QueriesWLMain = () => {
  const navigate = useNavigate();

  return (
    <div className="faq-main">
      {LINKS.map((link) => (
        <Button className="btn btn-normal" onClick={() => navigate(link.path)}>
          {link.label}
        </Button>
      ))}
    </div>
  );
};

const EditWLs = () => {
  const [creating, setCreating] = useState(false);
  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
    refetch,
  } = useGetWhitelistsQueriesQuery({ fetchPolicy: "no-cache" });

  const {
    data: queryVersionsData,
    loading: querVersionsLoading,
    error: queryVersionsError,
  } = useGetVersionsQuery({ fetchPolicy: "no-cache" });

  const navigate = useNavigate();

  return (
    <>
      <>
        {!creating && queryData?.findManyWhitelistsQueries.length === 0 ? (
          <>No hay wls</>
        ) : (
          queryData?.findManyWhitelistsQueries.map((wl) => (
            <EditOneWL
              wl={wl}
              versions={queryVersionsData?.versionApps}
              version={wl.version}
              onUpdate={async () => {
                await refetch();
              }}
            />
          ))
        )}
        {creating ? (
          <EditOneWL
            newWL
            versions={queryVersionsData?.versionApps}
            onCreate={async () => {
              setCreating(false);
              await refetch();
            }}
          />
        ) : (
          <Button
            className="btn btn-normal"
            onClick={() => setCreating((c) => !c)}
          >
            Agregar wl
          </Button>
        )}
        <Button className="btn btn-normal" onClick={() => navigate(-1)}>
          Listo
        </Button>
      </>
    </>
  );
};

const EditOneWL = ({
  wl,
  versions,
  newWL = false,
  onCreate,
  onUpdate,
}: {
  version?: Pick<VersionApp, "type" | "version" | "id">;
  versions?: Array<Pick<VersionApp, "type" | "version" | "id">>;
  //   wl?: Pick<WhitelistsQueries, "content">;
  wl?: ArrayElement<GetWhitelistsQueriesQuery["findManyWhitelistsQueries"]>;
  newWL?: boolean;
  onCreate?: () => void;
  onUpdate?: () => void;
}) => {
  const [editing, setEditing] = useState(newWL);
  const [createWhitelist] = useCreateWhitelistMutation();
  const [update] = useUpdateWhitelistsQueriesMutation();

  const [localWl, setLocalWl] = useState<
    Partial<
      ArrayElement<GetWhitelistsQueriesQuery["findManyWhitelistsQueries"]>
    >
  >(newWL ? {} : wl ?? {});

  return (
    <div className="faq-posts">
      <TextInput
        disabled={!editing}
        label="WL Json"
        mode="textarea"
        setValue={(e) => {
          setLocalWl((p) => ({ ...p, content: e }));
        }}
        value={localWl?.content}
        multiline
      />
      <Select
        isDisabled={!editing}
        options={versions?.map((v) => ({
          value: v.id,
          label: `${v.type + " " + v.version}`,
        }))}
        value={{
          value: localWl?.version?.id,
          label: `${
            (localWl?.version?.type ?? " ") +
            " " +
            (localWl?.version?.version ?? " ")
          }`,
        }}
        placeholder={"Seleccione un tipo de app"}
        className="input-select"
        onChange={(value) =>
          value?.label &&
          setLocalWl((wl) => ({
            ...wl,
            version: versions?.filter((v) => v.id === value.value)[0],
          }))
        }
      />
      <Button
        disabled={!localWl.content || !localWl.version}
        className="btn btn-normal"
        onClick={
          newWL
            ? () => {
                if (localWl.content && localWl.version) {
                  createWhitelist({
                    variables: {
                      data: {
                        version: {
                          connect: {
                            id: localWl.version.id,
                          },
                        },
                        content: localWl.content,
                      },
                    },
                    onCompleted(data) {
                      onCreate?.();
                    },
                    onError(error) {
                      Swal.fire({
                        title: "Error",
                        text: error.message,
                        icon: "error",
                      });
                    },
                  });
                }
              }
            : () => {
                editing
                  ? Swal.fire({
                      text: "Estas seguro?",
                      showCancelButton: true,
                    }).then((result) => {
                      result.isConfirmed &&
                        localWl.content &&
                        localWl.version &&
                        update({
                          variables: {
                            where: {
                              versionAppId: localWl.version.id,
                            },
                            data: {
                              content: localWl.content,
                            },
                          },
                          onCompleted(data) {
                            onUpdate?.();
                            setEditing(false);
                          },
                          onError(error) {
                            Swal.fire({
                              title: "Error",
                              text: error.message,
                              icon: "error",
                            });
                          },
                        });
                    })
                  : setEditing(true);
              }
        }
      >
        {newWL ? "Crear" : editing ? "Guardar" : "Modificar"}
      </Button>
    </div>
  );
};

const EditVersions = () => {
  const [creating, setCreating] = useState(false);
  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
    refetch,
  } = useGetVersionsQuery({ fetchPolicy: "no-cache" });

  const navigate = useNavigate();

  return (
    <>
      <>
        {!creating &&
          queryData?.versionApps.map((version) => (
            <EditOneVersion
              version={version}
              hasWhitelist={!!version.WhitelistsQueries}
              onDelete={async () => {
                await refetch();
              }}
            />
          ))}
        {creating ? (
          <EditOneVersion
            newVersion
            onCreate={async () => {
              setCreating(false);
              await refetch();
            }}
          />
        ) : (
          <Button
            className="btn btn-normal"
            onClick={() => setCreating((c) => !c)}
          >
            Crear version
          </Button>
        )}
        <Button className="btn btn-normal" onClick={() => navigate(-1)}>
          Listo
        </Button>
      </>
    </>
  );
};

const EditOneVersion = ({
  version,
  newVersion = false,
  onCreate,
  onDelete,
  hasWhitelist,
}: {
  version?: Pick<VersionApp, "type" | "version">;
  newVersion?: boolean;
  onCreate?: () => void;
  onDelete?: () => void;
  hasWhitelist?: boolean;
}) => {
  const [createVersion] = useCreateVersionAppMutation();
  const [deleteVersion] = useDeleteVersionAppMutation();
  const [localVersion, setLocalVersion] = useState<
    Partial<Pick<VersionApp, "type" | "version">>
  >(newVersion ? {} : version ?? {});

  const types = Object.keys(AppType) as Array<AppType>;

  return (
    <div className="faq-posts">
      <TextInput
        disabled={!newVersion}
        label="Version"
        setValue={(e) => {
          setLocalVersion((p) => ({ ...p, version: e }));
        }}
        value={localVersion?.version}
        multiline
      />
      <Select
        isDisabled={!newVersion}
        options={types.map((type) => ({ value: type, label: type }))}
        value={{
          value: localVersion?.type,
          label: localVersion?.type,
        }}
        placeholder={"Seleccione un tipo de app"}
        className="input-select"
        onChange={(value) =>
          value?.label &&
          setLocalVersion((v) => ({ ...v, type: value?.label! }))
        }
      />
      <div className="faq-alert">
        <h4>
          {!newVersion &&
            (hasWhitelist ? "Tiene whitelist" : "No tiene whitelist")}
        </h4>
      </div>
      <Button
        disabled={!localVersion.type || !localVersion.version}
        className="btn btn-normal"
        onClick={
          newVersion
            ? () => {
                if (localVersion.type && localVersion.version) {
                  createVersion({
                    variables: {
                      data: {
                        version: localVersion.version,
                        type: localVersion.type,
                      },
                    },
                    onCompleted(data) {
                      onCreate?.();
                    },
                    onError(error) {
                      Swal.fire({
                        title: "Error",
                        text: error.message,
                        icon: "error",
                      });
                    },
                  });
                }
              }
            : () => {
                Swal.fire({
                  text: "Estas seguro?",
                  showCancelButton: true,
                }).then((result) => {
                  result.isConfirmed &&
                    localVersion.type &&
                    localVersion.version &&
                    deleteVersion({
                      variables: {
                        where: {
                          version_type: {
                            version: localVersion.version,
                            type: localVersion.type,
                          },
                        },
                      },
                      onCompleted(data) {
                        onDelete?.();
                      },
                    });
                });
              }
        }
      >
        {newVersion ? "Crear" : "Eliminar"}
      </Button>
    </div>
  );
};
