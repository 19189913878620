import { DateTime } from "luxon";
import { Button } from "react-bootstrap";
import { IoIosMail, IoMdMap } from "react-icons/io";
import { useNavigate, useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuthenticationContext } from "../contexts/AuthenticationContext";

import { formatToDeleteTravel } from "../helpers/formatForQuery";
import voca from "voca";

export const TravelExpandBySearch = () => {
  console.log("HERE TRAVEL FOUND");
  const navigate = useNavigate();
  const { travelId } = useParams();

  const { user } = useAuthenticationContext();
  // const { data, loading, error, startPolling } = useTravelsQuery({
  //   variables: formatToQueryTravelById(travelId!),
  //   pollInterval: 1000,
  // });

  // const [PCreateReservation] = usePCreateReservationMutation({
  //   onCompleted: (e) => {
  //     console.log(data, loading, error);
  //     Swal.fire({
  //       icon: "success",
  //       title: "Tu reservacion fue completada",
  //       text: "",
  //     }).then(() => {
  //       console.log(e);
  //       navigate(`/search/pay/${travelId}/${e.PCreateReservationResolver.id}`);
  //     });
  //   },

  //   onError: (e) => console.log(e),
  // });

  // const onReserve = async () => {
  //   await PCreateReservation({
  //     variables: {
  //       data: {
  //         passenger: {
  //           connect: {
  //             firebaseId: user?.firebaseId,
  //           },
  //         },
  //         directFragment: {
  //           connect: {
  //             id: travelId,
  //           },
  //         },
  //       },
  //     },
  //   });
  // };

  // const { data, loading, error } = useGetFragmentQuery({
  //   variables: {
  //     where: {
  //       id: {
  //         equals: travelId,
  //       },
  //     },
  //   },
  // });

  // const fragment = data?.fragments[0];

  // const { userAuth } = useAuthenticationContext();
  // console.log(data, loading);
  // const [PDeleteTravel] = usePDeleteTravelMutation({
  //   onCompleted: () => {
  //     Swal.fire({
  //       icon: "success",
  //       title: "Tu viaje fue eliminado",
  //       text: "",
  //     });
  //   },
  //   refetchQueries: "all",
  // });
  // // startPolling(100);
  // const [AcceptOrDeclineReservation] = useAcceptOrDeclineReservationMutation({
  //   onCompleted: (e) => {
  //     console.log(e);
  //     Swal.fire({
  //       icon: "success",
  //       title: "La reservacion fue aceptada",
  //       text: "",
  //     });
  //   },
  // });

  // const travel = data && data.fragments[0].Traject?.Travel;

  // const handleDelete = async () => {
  //   const confirmation = await Swal.fire({
  //     icon: "warning",
  //     title: "Desea eliminar su viaje?",
  //     text: "Si su viaje contiene una reservacion, puede ser penalizado",
  //     showCancelButton: true,
  //     allowOutsideClick: false,
  //     cancelButtonText: "Volver",
  //     confirmButtonText: "Eliminar",
  //   });
  //   if (confirmation.isConfirmed) {
  //     PDeleteTravel({ variables: formatToDeleteTravel(travelId!) });
  //     navigate("/user/my-travels");
  //   }
  // };

  // const [CreateConversation] = useCreateConversationMutation();
  // const goToConversation = async () => {
  //   if (!userAuth) {
  //     console.log("se connecter");
  //   }

  //   await CreateConversation({
  //     variables: {
  //       data: {
  //         fragment: {
  //           connect: {
  //             id: fragment?.id,
  //           },
  //         },
  //         userPassenger: {
  //           connect: {
  //             firebaseId: userAuth?.uid,
  //           },
  //         },
  //         userConductor: {
  //           connect: {
  //             id: travel?.conductorUser.id,
  //           },
  //         },
  //       },
  //     },
  //   }).then((conversation) => {
  //     navigate(`/user/messages/${conversation.data?.PCreateConversation.id}`);
  //   });
  // };

  return <></>;

  // return (
  //   <div className="container-information">
  //     {!loading && data && (
  //       <div className="travel-container">
  //         {/* <h3>
  //           {/* {travel?.id} }
  //           {travel!.traject[0].steps[0].city} a{" "}
  //           {travel!.traject[0].steps[travel!.traject[0].steps.length - 1].city}
  //         </h3> */}
  //         <h2>
  //           {voca.capitalize(
  //             DateTime.fromISO(fragment?.startStep.date).toLocaleString(
  //               {
  //                 weekday: "long",
  //                 day: "numeric",
  //                 month: "long",
  //               },
  //               {
  //                 locale: "es-ES",
  //               }
  //             )
  //           )}
  //         </h2>

  //         <div className="travel-steps-container">
  //           <Link
  //             to={`/place/${fragment?.startStep.placeId}`}
  //             className="travel-step-container"
  //           >
  //             <div>
  //               <h4> {voca.capitalize(fragment?.startStep.city)}</h4>
  //               <h6>{fragment?.startStep.place.split(",")[0]}</h6>
  //             </div>
  //             <h4>
  //               {DateTime.fromISO(fragment?.startStep.date).toLocaleString({
  //                 hour: "2-digit",
  //                 minute: "2-digit",
  //               })}
  //             </h4>
  //           </Link>

  //           <Link
  //             to={`/place/${fragment?.endStep.placeId}`}
  //             className="travel-step-container"
  //           >
  //             <div>
  //               {/* <IoMdMap /> */}
  //               <h4> {fragment?.endStep.city}</h4>
  //               <h6>{fragment?.endStep.place.split(",")[0]}</h6>
  //             </div>
  //             <h4>
  //               {DateTime.fromISO(fragment?.endStep.date).toLocaleString({
  //                 hour: "2-digit",
  //                 minute: "2-digit",
  //               })}
  //             </h4>
  //           </Link>
  //         </div>
  //         <div className="travel-conductors-container">
  //           <h3>Precio por pasajero </h3>
  //           <div className="travel-conductor-container">
  //             <div>
  //               <h4>$ {fragment?.price} </h4>
  //             </div>
  //           </div>
  //         </div>

  //         <div className="travel-conductors-container">
  //           <h3>Conductor </h3>
  //           <div className="travel-conductor-container">
  //             <div className="chat-one-conversation-user-info">
  //               <div className="user-photo-large">
  //                 <img
  //                   src={travel!.conductorUser.photoURL!}
  //                   height={80}
  //                   width={80}
  //                   alt="foto de perfil"
  //                 />
  //               </div>
  //               <h4> {travel?.conductorUser.firstName}</h4>
  //             </div>
  //             <div>
  //               <Button className="btn btn-primary" onClick={goToConversation}>
  //                 <IoIosMail size={24} className="btn-icon" />{" "}
  //               </Button>
  //             </div>
  //           </div>
  //         </div>
  //         <button
  //           className="btn btn-primary payTravel-button-pay"
  //           onClick={() => {
  //             onReserve();
  //           }}
  //         >
  //           {" "}
  //           Reservar!
  //         </button>
  //       </div>
  //     )}
  //   </div>
  // );
};
