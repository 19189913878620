import { types } from "../types/types";
import { orderByDistance } from "geolib";

export interface Step {
  placeId: string;
  address: string;
  city: string;
  latitude: number;
  longitude: number;
}

interface payloadAction {
  step?: Step;
  places?: Number;
  date?: Date;
  totalPrice?: Number;
}

export interface travelAction {
  payload?: payloadAction;
  type: string;
}

const initialTravel: {
  places: number;
  startDate: Date;
  steps: Step[];
  totalPrice: number;
} = {
  places: 0,
  startDate: new Date(),
  steps: [],
  totalPrice: 0,
};

export const travelReducer = (state = initialTravel, action: travelAction) => {
  switch (action.type) {
    case types.addStep:
      let stepsWithNewStep = state.steps;
      stepsWithNewStep.push(action.payload?.step!);
      if (state.steps.length === (0 | 1)) {
        return { ...state, steps: stepsWithNewStep };
      }
      stepsWithNewStep = orderByDistance(
        state.steps[0],
        stepsWithNewStep
      ) as Step[];
      return { ...state, steps: stepsWithNewStep };
    case types.updatePlaces:
      return {
        ...state,
        places: action.payload?.places,
      };

    case types.updateDate:
      return {
        ...state,
        startDate: action.payload?.date,
      };

    case types.cleanTravel:
      return {...initialTravel, steps: []};

    default:
      return state;
  }
};
