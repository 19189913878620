import { useEffect, useMemo, useState } from "react";
import {
  Travel,
  useTravelsAdminLazyQuery,
  useTravelsAdminQuery,
} from "../../../graphql/generated";
import { DataGrid } from "@mui/x-data-grid";
import { Navigate, useNavigate } from "react-router-dom";

export const TravelsAdmin = () => {
  const [query, { data, loading, error }] = useTravelsAdminLazyQuery({
    variables: {
      //   cursor: {
      //     id: "8928be0a-8180-4f5d-b8c3-0ac093171243",
      //   },
      //   take: 2,
    },
  });

  const navigate = useNavigate();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 3,
    page: 0,
  });

  useEffect(() => {
    query({
      variables: {
        take: paginationModel.pageSize * (paginationModel.page + 2),
        // skip: paginationModel.page * paginationModel.pageSize,
      },
    });
  }, [paginationModel]);

  const rows = useMemo(() => {
    const object = data?.travels.map((travel) => {
      const travelToReturn: any = { ...travel };
      travelToReturn.startStep = travel.traject[0].steps.find(
        (s) => s.order === 0
      )?.city;

      travelToReturn.endStep = [...travel.traject[0].steps].sort(
        (a, b) => b.order - a.order
      )?.[0].city;

      return travelToReturn;
    });
    return object;
  }, [data]);

  const columns =
    rows &&
    Object.keys(rows[0]).filter((a) => !["__typename", "traject"].includes(a));

  if (error) return <>error...</>;

  return (
    <>
      {data?.travels && columns && (
        <DataGrid
          style={{ width: "100%" }}
          rows={rows}
          rowCount={rows.length}
          loading={loading}
          columns={columns?.map((key) => ({ field: key, flex: 1 }))}
          pageSizeOptions={[1, 2, 3]}
          paginationModel={paginationModel}
          onRowClick={(params, e, detail) => {
            console.log({ params, e, detail });
            navigate(params.id.toString());
          }}
          onPaginationModelChange={setPaginationModel}
        />
      )}
    </>
  );
};
